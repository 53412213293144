import Page from 'components/Page';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
} from 'reactstrap';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import * as XLSX from 'xlsx';
import { Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {
  extractInfo,
  generateFilterList,
} from '../AdminPages/AdminProductCatalogue';
import AWS from 'aws-sdk';

import moment from 'moment';
import SubmitRangelist from './SubmitRangelist.js';
import SubmitCatalogue from './SubmitCatalogue.js';

const AdminProductCatalogue = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const searchInput = useRef(null);

  const [value, setValue] = React.useState('1');
  const [tableData, setTableData] = React.useState([]);
  const [rangelistTableData, setRangelistTableData] = React.useState([]);
  const [rangelistDocTableData, setRangelistDocTableData] = React.useState([]);
  const [supplierInfo, setSupplierInfo] = React.useState([]);
  const [latestRangeListInfo, setLatestRangeListInfo] = React.useState();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterLists, setFilterLists] = useState({
    Department: [],
    'Sub Department': [],
    'Item Retail Grade': [],
    State: ['Live', 'Archived', 'CSO', 'Restricted', 'Promotion'],
  });

  const [excelValues, setExcelValues] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [ProductCatalogueField, setProductCatalogueField] = React.useState({
    // supplier_id: '',
    // supplier_name: '',
    catalogues: [],
  });
  const [rangelistField, setRangelistField] = React.useState({
    // supplier_id: '',
    // supplier_name: '',
    catalogues: [],
  });
  const [actionHint, setActionHint] = useState({
    open: false,
    text: '',
    title: '',
  });

  const [loading, setLoading] = React.useState(false);

  const getTableData = supplierInfo => {

    setLoading(true)
    // return;
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE +
        'product-catalogue?supplier_id=' +
        supplierInfo.id,
    })
      .then(response => {
        // console.log('cata res ', response);
        if (response.status == 200) {
          const result = response.data.body;
          if (Array.isArray(result) && result.length > 0) {
            const list = [];
            result.forEach(item => {
              Array.isArray(item.catalogues) &&
                item.catalogues.forEach(cata => {
                  list.push({
                    supplier_id: item.supplier_id,
                    supplier_name: item.supplier_name,
                    datetime: item.datetime,
                    doc_name: cata.document,
                    comment: cata.comments,
                    status: item.status,
                    notes: item.notes,
                    doc_type: item.doc_type,
                  });
                });
            });
            // console.log('generate cata table', list);
            setTableData(list);
            setLoading(false)

          } else {
            setTableData([]);
            setLoading(false)
          }

        } else {
          console.log('not 200 status', response);
          setLoading(false)
        }
      })
      .catch(err => {
        console.log('get catas fail...', err);
        setLoading(false)
      });
  };
  const getRangelistTableData = supplierInfo => {

    setLoading(true)
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE +
        'rangelist?supplier_id=' +
        supplierInfo.id,
    })
      .then(response => {
        const result = response.data.body.Items;
        if (Array.isArray(result) && result.length > 0) {
          // generate range list document table
          const docList = result.filter(item => item.upload_by == 'supplier');
          setRangelistDocTableData(docList);
          // generate range list table
          const approvedOneRangelist = result.filter(
            item => item.status.toLowerCase() == 'approved',
          );
          if (approvedOneRangelist.length == 1) {
            const oneSupResult = approvedOneRangelist[0];

            const allDepartments = extractInfo(
              oneSupResult.items,
              'Department',
            );
            const allSubDepartments = extractInfo(
              oneSupResult.items,
              'Sub Department',
            );
            setLatestRangeListInfo(oneSupResult);
            setRangelistTableData(oneSupResult.items);
            setFilterLists({
              Department: generateFilterList(allDepartments),
              'Sub Department': generateFilterList(allSubDepartments),
              'Item Retail Grade': generateFilterList(
                Array.from(Array(6))
                  .map((e, i) => i + 65)
                  .map(x => String.fromCharCode(x)),
              ),
              State: generateFilterList([
                'Live',
                'Archived',
                'CSO',
                'Restricted',
                'Promotion',
                'Closed',
                'Suspended',
                'Quit(S3)',
              ]),
            });

            setLoading(false)
          }
        }
        setLoading(false)
      })
      .catch(error => {
        console.log('doc for supplier fail...', error);
        setLoading(false)
      });
  };

  useEffect(() => {
    // get supplier info then get the table data

    // get supplier username if supplier login or get parent supplier username if sub user login
    const curUsername = sessionStorage.getItem('parentSupUsername')
      ? sessionStorage.getItem('parentSupUsername')
      : sessionStorage.getItem('username');

    setLoading(true)
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE +
        'admin-portal/supplier-details?username=' +
        curUsername,
    })
      .then(response => {
        if (response.status == 200) {
          const result = response.data.body;
          if (Array.isArray(result) && result.length > 0) {
            const supList = result.filter(item => item.role == 'supplier');
            const supInfo = supList[0];
            setSupplierInfo(supInfo);
          }
          setLoading(false)
        } else {
          console.log('not 200 status', response);
          setLoading(false)
        }
      })
      .catch(err => {
        console.log('get sup fail...', err);
        setLoading(false)
      });
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : '',
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  const filter = data => {
    data.map(rec => {
      // console.log('rec====>', rec);
      setFilteredData(rec);
    });
  };




  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const cancelDoc = (e, cancelDocType) => {
    if (cancelDocType == 'Rangelist') {
      setRangelistField({
        ...rangelistField,
        catalogues: [],
      });
    } else {
      setProductCatalogueField({
        ...ProductCatalogueField,
        catalogues: [],
      });
    }
  };

  // only he rangelist will have the third parameter
  const submitDocRequestToDB = (submitDocType, uploadDate, rangelistS3Key) => {
    console.log('submitDocRequest to DB', submitDocType);

    // api will fetch those info based on username
    const supplier_id = supplierInfo.id;
    const supplier_name = supplierInfo.full_company_name;

    let docNameList = [];
    let data = {};
    let url = '';

    // submit rangelist request
    if (submitDocType == 'Rangelist') {
      // console.log('use old products info to post');
      data = {
        supplier_id,
        supplier_name,
        rangelist_name: rangelistField.catalogues[0].document.name,
        comments: rangelistField.catalogues[0].comments,
        // datetime: new Date(),
        datetime: uploadDate,
        status: 'Waiting for approval',
        notes: '',
        // items: [...rangelistTableData],
        s3_url: rangelistS3Key,
        doc_type: submitDocType,
        upload_by: 'supplier',
      };
      url = process.env.REACT_APP_API_BASE + 'rangelist';
    }
    // submit catalogue request
    else {
      docNameList = ProductCatalogueField.catalogues.map(item => {
        return {
          document: item.document.name,
          comments: item.comments,
        };
      });
      data = {
        supplier_id,
        supplier_name,
        document: ProductCatalogueField.catalogues[0].document.name,
        catalogues: docNameList,
        datetime: new Date(),
        status: 'Waiting for approval',
        notes: '',
        doc_type: submitDocType,
        upload_by: 'supplier',
      };
      url = process.env.REACT_APP_API_BASE + 'product-catalogue';
    }
    // console.log('uploadDoc cata payload', url, data);
    axios({
      method: 'POST',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url,
      data: data,
    }).then(response => {
      // console.log(response);
      if (response.status == 200) {
        setActionHint({
          ...actionHint,
          open: true,
          text: `${submitDocType} successfully submitted`,
          title: 'Thank you',
        });
        setTimeout(() => {
          setActionHint({
            ...actionHint,
            open: false,
          });
        }, 6000);
        if (submitDocType == 'Rangelist') {
          setRangelistField({
            catalogues: [],
          });
          getRangelistTableData(supplierInfo);
        } else {
          setProductCatalogueField({
            // ...ProductCatalogueField,
            catalogues: [],
          });
          getTableData(supplierInfo);
        }
      } else if (response.status == 401) {
        console.log('500,,,,', response);
      }
    });
    // .catch(error => {
    //   console.log('upload to DB cata fail...', error);
    //   console.log(error.response);
    //   // if (error.response.data.status == 'Document already exists') {
    //   //   setProductCatalogueField({
    //   //     ...ProductCatalogueField,
    //   //     catalogues: [],
    //   //   });

    //   //   setActionHint({
    //   //     ...actionHint,
    //   //     open: true,
    //   //     text: 'Document already exists',
    //   //     title: 'Sorry',
    //   //   });
    //   //   setTimeout(() => {
    //   //     setActionHint({
    //   //       ...actionHint,
    //   //       open: false,
    //   //     });
    //   //   }, 3000);
    //   // } else {
    //   //   console.log('cata for supplier fail...', error.status);
    //   // }
    // });
  };

  const uploadCataToS3 = (curKey, submitDocType, file) => {
    AWS.config.update({
      accessKeyId: sessionStorage.getItem('AccessKeyId'),
      secretAccessKey: sessionStorage.getItem('AccessKeySec'),
      sessionToken: sessionStorage.getItem('sessionToken'),
    });
    const myBucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
      region: 'ap-southeast-2',
    });
    const checkExistParams = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
    };
    myBucket.listObjectsV2(checkExistParams, (err, data) => {
      if (err) {
        console.log('check exist err', err);
        setActionHint({
          open: true,
          text: err.message + ' please login again',
          title: 'Sorry',
        });
        setTimeout(() => {
          setActionHint({
            open: true,
            text: err.message + ' please login again',
            title: 'Sorry',
          });
        }, 6000);
      } else {
        let fileExist = false;
        const allFileList = data.Contents;
        allFileList.forEach(item => {
          if (item.Key == curKey) {
            console.log('exist');
            fileExist = true;
            return;
          }
        });

        if (fileExist) {
          setActionHint({
            open: true,
            text: 'File already exist, please change file name',
            title: 'Sorry',
          });
          setTimeout(() => {
            setActionHint({
              ...actionHint,
              open: false,
            });
          }, 6000);
          return;
        } else {
          // upload the file if file not exist in bucket
          const params = {
            ACL: 'bucket-owner-full-control',
            Body: file,
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: curKey,
          };
          myBucket
            .putObject(params)
            .on('httpUploadProgress', (evt, response) => {
            })
            .send((err, data) => {
              if (err) {
                console.log(err);
              } else {
                if (data) {
                  // if doc upload to S3 then send the request DB
                  submitDocRequestToDB(submitDocType);
                }
              }
            });
        }
      }
    });
  };
  const uploadRangelistToS3 = (curKey, submitDocType, file, uploadDate) => {
    AWS.config.update({
      accessKeyId: sessionStorage.getItem('AccessKeyId'),
      secretAccessKey: sessionStorage.getItem('AccessKeySec'),
      sessionToken: sessionStorage.getItem('sessionToken'),
    });
    const myBucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
      region: 'ap-southeast-2',
    });
    const params = {
      ACL: 'bucket-owner-full-control',
      Body: file,
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: curKey,
    };
    myBucket
      .putObject(params)
      .on('httpUploadProgress', (evt, response) => {
      })
      .send((err, data) => {
        if (err) {
          console.log(err);
        } else {
          if (data) {
            // if doc upload to S3 then send the request DB
            submitDocRequestToDB(submitDocType, uploadDate, curKey);
          }
        }
      });
  };
  const uploadDocToS3 = submitDocType => {
    const supplier_id = supplierInfo.id;
    // ----------------------S3 process start ----------------
    // Generate uploadFileList based on submitDocTYpe
    let uploadFilesList = [];
    if (submitDocType == 'Catalogue') {
      uploadFilesList = ProductCatalogueField.catalogues.map(
        item => item.document,
      );
    } else {
      uploadFilesList = rangelistField.catalogues.map(item => item.document);
    }

    // upload file one by one, in this senario, only one obj in the array

    // TODO: improve this part for uploading catalogue, by fetching all object only once
    for (let i = 0; i < uploadFilesList.length; i++) {
      const file = uploadFilesList[i];
      let curKey = '';
      if (submitDocType == 'Catalogue') {
        // upload catalogue under admin folder
        curKey = `admin-documents/supplier/${supplier_id}/product-catalogues/${file.name}`;
        // console.log('curKey,, for cata', curKey);

        // Catalogue need to check the if the file already exist
        uploadCataToS3(curKey, submitDocType, file);
      } else {
        // upload rangelist under supplier folder

        const uploadDate = new Date();
        const formatedUploadDate = moment(uploadDate).format('DDMMYYYY-HHmmss');
        curKey = `supplier-documents/range-lists/${supplier_id}/${formatedUploadDate}/${file.name}`;

        // Rangelist need to replace current the if the file already exist(prev version)
        // Need to include the time to not replace the rangelist with same name
        // return;
        uploadRangelistToS3(curKey, submitDocType, file, uploadDate);
      }
    }
  };

  const submitDoc = (e, submitDocType) => {
    e.preventDefault();
    uploadDocToS3(submitDocType);
  };
  const handleClose = () => {
    setActionHint({
      ...actionHint,
      open: false,
    });
  };
  return (
    <>
      <Dialog open={actionHint.open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>{actionHint.title}</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>{actionHint.text}</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button
            onClick={e => {
              e.preventDefault();
              setActionHint({
                ...actionHint,
                open: false,
              });
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Document successfully uploaded
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Page>
        <Row>
          <Col xl={12} lg={12} md={12} style={{ paddingLeft: '0' }}>
            <Card id="userManagement">
              <CardHeader className="card-header-main">Catalogue</CardHeader>
              <CardBody style={{ background: '#fff', padding: '1.25rem' }}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { background: '#D71920' } }}
                        className="tabb"
                      >
                        <Tab
                          label="Submit Catalogue"
                          value="1"
                          name="submitCatalogue"
                        />
                        <Tab
                          label="Submit Range List"
                          value="2"
                          name="submitRangeList"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1" style={{ padding: '15px 0px 0px 0px' }}>
                      <SubmitCatalogue
                        filter={filter}
                        // handleSearch={handleSearch}
                        getColumnSearchProps={getColumnSearchProps}
                        filteredData={filteredData}
                        supplierInfo={supplierInfo}
                        cancelDoc={cancelDoc}
                        // actionHint={actionHint}
                        // setActionHint={setActionHint}
                        submitDoc={submitDoc}
                        ProductCatalogueField={ProductCatalogueField}
                        setProductCatalogueField={setProductCatalogueField}
                        tableData={tableData}
                        // setTableData={setTableData}
                        getTableData={getTableData}
                        loading={loading}
                      />

                    </TabPanel>
                    <TabPanel value="2" style={{ padding: '15px 0px 0px 0px' }}>
                      <SubmitRangelist
                        filter={filter}
                        // handleSearch={handleSearch}
                        getColumnSearchProps={getColumnSearchProps}
                        filteredData={filteredData}
                        supplierInfo={supplierInfo}
                        cancelDoc={cancelDoc}
                        // actionHint={actionHint}
                        // setActionHint={setActionHint}
                        submitDoc={submitDoc}
                        rangelistField={rangelistField}
                        setRangelistField={setRangelistField}
                        rangelistDocTableData={rangelistDocTableData}
                        rangelistTableData={rangelistTableData}
                        // setTableData={setTableData}
                        getRangelistTableData={getRangelistTableData}
                        filterLists={filterLists}
                        loading={loading}
                      />

                    </TabPanel>
                  </TabContext>
                </Box>
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </>
  );
};

export default AdminProductCatalogue;
