import Page from 'components/Page';
import React, { useState, useEffect } from 'react';
import workImage from 'assets/img/work.png';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import 'react-datepicker/dist/react-datepicker.css';
import validator from 'validator';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import CreatedAdhocRequestTable from '../Tables/CreatedAdhocRequestTable';
import moment from 'moment';
import S3 from 'react-aws-s3';
import AWS from 'aws-sdk';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import greyBanner from 'assets/img/logo/Banner Grey.png';
import {
  isInvalidTaskName,
  isInvalidUsername,
} from '../AdminPages/AddAdmin.js';
import { MAX_TASK_NAME_LENGTH } from '../../utils/constants.js';

const AdhocTask = props => {
  registerLocale('en-gb', enGB);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [isEdit, setIsEdit] = useState(false);
  // const [validate, setValidate] = React.useState(false);
  // const [createDate, setCreateDate] = React.useState('');
  // const [frequency, setFrequency] = React.useState('');
  // const [exceptionChecked, setExceptionChecked] = React.useState(false);

  // getting the values of local storage
  const getDataResult = taskResults => {
    const data = localStorage.getItem('adhocTask');
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  };
  const [isEdit, setIsEdit] = useState(false);
  const [validate, setValidate] = React.useState(false);
  const [createDate, setCreateDate] = React.useState('');
  const [frequency, setFrequency] = React.useState('');
  const [exceptionChecked, setExceptionChecked] = React.useState(false);
  const [taskResults, setTaskResults] = useState(getDataResult());
  const [selectedList, setSelectedList] = React.useState([]);

  const [idtoken, setIdtoken] = useState();
  const [isViewModes, setIsViewModes] = useState({
    isView: false,
    isStatusSubmitted: false,
  });
  const [requiredError, setRequiredError] = React.useState({
    reqId: '',
    reqDate: '',
  });
  const [adhocTaskField, setAdhocTaskField] = React.useState({
    role: 'supplier',
    username: '',
    subsupplier_username: '',
    add_exception: '',
    additional_note: '',
    create_date: '',
    description: '',
    documentAdd: [{ document: '', notes: '' }],
    frequency: '',
    task_id: '',
    taskName: '',
    responseAdd: [{ responseInput: '', responseNote: '' }],
    task_status: 'completed',
    admin_additional_info: [
      {
        additinal_info: '',
        additional_note: '',
      },
    ],
    appStatus: 'waitApproval',
  });

  useEffect(() => {
    // TODO: get supplier id after clear about it
    const role = localStorage.getItem('userRole');
    const idtoken = sessionStorage.getItem('idtoken');
    const username = sessionStorage.getItem('username');
    // const supplier_id = sessionStorage.getItem('sub');
    console.log('get user info', role, idtoken, username);
    // setUserInfo({
    //   role,
    //   username,
    //   idtoken,
    // });
    setIdtoken(idtoken);
    setAdhocTaskField({
      ...adhocTaskField,
      username,
      role,
    });
  }, []);
  const [idError, setIdError] = React.useState('');
  const idVal = (id, entity) => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idToken'),
      },
      url:
        process.env.REACT_APP_API_BASE +
        'id-validation?id=' +
        id.toUpperCase() +
        '&entity=' +
        entity,
    }).then(response => {
      console.log(response.data.body);
      const idVald = response.data.body;
      if (idVald == 'true') {
        setIdError('ID already exist');
      } else {
        setIdError('');
      }
    });
  };
  const generateTokens = () => {
    const accessToken = sessionStorage.getItem('accessToken');
    const idToken = sessionStorage.getItem('idToken');
    console.log('generateTokens', accessToken);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_BASE + 'auth',
      data: { access_token: accessToken },
      headers: {
        Authorization: sessionStorage.getItem('idToken'),
      },
    }).then(response => {
      console.log(response.data);
      // sessionStorage.setItem('AccessKeyId', response.data.AccessKeyId);
      sessionStorage.setItem('apikey', response.data.ApiKey);
      // sessionStorage.setItem('AccessKeySec', response.data.SecretAccessKey);
      // sessionStorage.setItem('sessionToken', response.data.SessionToken);
    });
  };

  useEffect(() => {
    generateTokens();
    setAdhocTaskField({
      ...adhocTaskField,
      task_id: localStorage.getItem('adhocID'),
    });
  }, []);
  ////// on Change function for frequency
  const onChangeValue = event => {
    if (event.target.value == 'exception') {
      setExceptionChecked(true);
      setFrequency(event.target.value);
      // setAdhocTaskField({...adhocTaskField, 'add_exception': });
    } else {
      setFrequency(event.target.value);
      console.log(event.target.value);
      setExceptionChecked(false);
      setAdhocTaskField({ ...adhocTaskField, add_exception: '' });
    }
  };

  const addDoc = () => {
    // setDocumentAdd([
    //   ...documentAdd,
    //   {
    //     document: '',
    //     notes: '',
    //   },
    // ]);
    setAdhocTaskField({
      ...adhocTaskField,
      documentAdd: [...adhocTaskField.documentAdd, { document: '', notes: '' }],
    });
  };
  const addResponse = e => {
    setAdhocTaskField({
      ...adhocTaskField,
      responseAdd: [
        ...adhocTaskField.responseAdd,
        { responseInput: '', responseNote: '' },
      ],
    });
  };

  //////// onchange input handler function
  const adhocTaskInputHandler = e => {
    const inputName = e.target.name;
    // if (e.target.name == 'create_date') {
    //   setRequiredError({ create_date: '' });
    // }
    if (inputName == 'frequency') {
      if (e.target.value == 'exception') {
        setExceptionChecked(true);
        setAdhocTaskField({
          ...adhocTaskField,
          frequency: '',
        });
      } else {
        setExceptionChecked(false);
        setAdhocTaskField({
          ...adhocTaskField,
          add_exception: '',
          [inputName]: e.target.value,
        });
      }
    } else {
      setAdhocTaskField({
        ...adhocTaskField,
        [inputName]: e.target.value,
      });
    }
    if (inputName == 'task_id') {
      setValidate(false);
      setRequiredError({ task_id: '' });
      idVal(e.target.value, 'adhoctask');
    }
  };

  //// Add Adhoc Task Data
  // const addAdhocTask = () => {
  //   if (adhocTaskField.request_id == '') {
  //     setValidate(true);
  //   } else {
  //     console.log({
  //       ...adhocTaskField,
  //       create_date: createDate,
  //       frequency: frequency,
  //     });
  //     console.log('created date djdjds', createDate);
  //     const dateString = createDate.toDateString();
  //     const formatedDate = moment(dateString).format('DD/MM/YYYY');
  //     setAdhocTaskField({
  //       ...adhocTaskField,
  //       create_date: formatedDate,
  //       frequency: frequency,
  //       task_status: 'completed',
  //     });
  //     const newData = {
  //       ...adhocTaskField,
  //       create_date: formatedDate,
  //       frequency: frequency,
  //       task_status: 'completed',
  //     };
  //     const latestTasks = JSON.parse(localStorage.getItem('adhocTask')) || [];
  //     localStorage.setItem(
  //       'adhocTask',
  //       JSON.stringify([...latestTasks, newData]),
  //     );
  //     setTaskResults([...latestTasks, newData]);
  //     setOpen(true);
  //   }
  // };

  ////// reset input Fields
  const resetFields = () => {
    setRequiredError('');
    setAdhocTaskField({
      role: 'supplier',
      add_exception: '',
      additional_note: '',
      create_date: '',
      description: '',
      documentAdd: [{ document: '', notes: '' }],
      frequency: '',
      task_id: '',
      taskName: '',
      responseAdd: [{ responseInput: '', responseNote: '' }],
      task_status: 'completed',
      admin_additional_info: [
        {
          additinal_info: '',
          additional_note: '',
        },
      ],
      appStatus: 'waitApproval',
    });
    setValidate('');

    setCreateDate('');
    setRequiredError('');
    // setFrequency('');
    setExceptionChecked(false);
    setIsViewModes({ adhocTaskField, isView: false, isStatusSubmitted: false });
  };
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedList([]);
  };
  const [openDraft, setOpenDraft] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  // 'required' is to adjust placehoders in select box
  useEffect(() => {
    const selectInputs = document.querySelectorAll('select');
    selectInputs.forEach(i => i.setAttribute('required', ''));
  }, []);

  const saveOrSubmitTask = (e, action) => {
    // remove attribute "required"
    const selectInputs = document.querySelectorAll('select');
    selectInputs.forEach(i => i.removeAttribute('required'));

    console.log('saveOrSubmitTask', action);
    e.preventDefault();
    // setAdhocTaskField({ ...adhocTaskField, task_status: 'draft' });
    if (adhocTaskField.task_id == '' || adhocTaskField.task_id == null) {
      setRequiredError({ reqId: 'Please Enter ID' });
      return;
    }
    if (
      adhocTaskField.taskName != '' &&
      adhocTaskField.taskName != null &&
      adhocTaskField.taskName.length > MAX_TASK_NAME_LENGTH
    ) {
      setRequiredError({
        taskName: 'Max Length Reached, please shorten your task name',
      });
      return;
    }
    if (
      adhocTaskField.taskName != '' &&
      adhocTaskField.taskName != null &&
      isInvalidTaskName(adhocTaskField.taskName)
    ) {
      setRequiredError({
        taskName: 'Invalid Task Name, please remove special characters',
      });
      return;
    }
    if (idError != '') {
      return;
    }
    if (
      adhocTaskField.create_date == '' ||
      adhocTaskField.create_date == null
    ) {
      setRequiredError({ reqDate: 'Please select date' });
      return;
    }
    const docNameList = adhocTaskField.documentAdd.map(item => {
      return {
        // TODO: change this according to type
        document:
          typeof item.document == 'string' ? item.document : item.document.name,
        notes: item.notes,
      };
    });
    const curUsername = sessionStorage.getItem('parentSupUsername')
      ? sessionStorage.getItem('parentSupUsername')
      : sessionStorage.getItem('username');
    console.log('curUsername===>', curUsername);
    const data = {
      ...adhocTaskField,
      task_id: adhocTaskField.task_id.toUpperCase(),
      username: curUsername,
      task_status: action === 'save' ? 'draft' : 'completed',
      documentAdd: docNameList,
    };
    console.log('saveOrSubmitTask payload', data);
    axios({
      method: isViewModes.isView ? 'PUT' : 'POST',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE + 'supplier-portal/supplier-adhoc-tasks',
      data: data,
    })
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          console.log('ad hoc added');
          if (action == 'save') {
            setOpenDraft(true);
          } else {
            setOpen(true);
          }
          // setOpenDraft(true);
          setTimeout(() => {
            setOpenDraft(false);
            setOpen(false);
          }, 3000);
          axios({
            method: 'GET',
            headers: {
              Authorization: sessionStorage.getItem('idtoken'),
            },
            url: process.env.REACT_APP_API_BASE + 'id-gen?type=adhoc',
          }).then(response => {
            const id = response.data.body;
            console.log(response.data.body);
            localStorage.setItem('adhocID', id);
            // setAdhocTaskField({ ...adhocTaskField, task_id: id })

            // reset input field
            setRequiredError('');
            setAdhocTaskField({
              role: 'supplier',
              username: '',
              add_exception: '',
              additional_note: '',
              create_date: '',
              description: '',
              documentAdd: [{ document: '', notes: '' }],
              frequency: '',
              task_id: id,
              taskName: '',
              responseAdd: [{ responseInput: '', responseNote: '' }],
              task_status: 'completed',
              admin_additional_info: [
                {
                  additinal_info: '',
                  additional_note: '',
                },
              ],
              appStatus: 'waitApproval',
            });
            setAdhocTaskField({
              role: 'supplier',
              username: '',
              add_exception: '',
              additional_note: '',
              create_date: '',
              description: '',
              documentAdd: [{ document: '', notes: '' }],
              frequency: '',
              task_id: id,
              taskName: '',
              responseAdd: [{ responseInput: '', responseNote: '' }],
              task_status: 'completed',
              admin_additional_info: [
                {
                  additinal_info: '',
                  additional_note: '',
                },
              ],
              appStatus: 'waitApproval',
            });
          });
          setExceptionChecked(false);
        }
      })
      .catch(error => {
        console.log('add ad hoc fail...', error);
      });

    // ----------------------S3 process start ----------------
    const uploadFilesList = adhocTaskField.documentAdd.map(
      item => item.document,
    );
    console.log('uploadFilesList', uploadFilesList);

    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      region: 'ap-southeast-2',
      dirName: 'supplier-documents',
      accessKeyId: sessionStorage.getItem('AccessKeyId'),
      secretAccessKey: sessionStorage.getItem('AccessKeySec'),
      sessionToken: sessionStorage.getItem('sessionToken'),
      // s3Url: `https://${ process.env.REACT_APP_BUCKET_NAME }.s3.amazonaws.com`,
      // Key: username/requst_id
    };
    console.log(config);
    const ReactS3Client = new S3(config);
    AWS.config.update({
      accessKeyId: sessionStorage.getItem('AccessKeyId'),
      secretAccessKey: sessionStorage.getItem('AccessKeySec'),
      sessionToken: sessionStorage.getItem('sessionToken'),
    });
    const myBucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
      region: 'ap-southeast-2',
    });
    const params = {
      ACL: 'bucket-owner-full-control',
      // Body: file,
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: 'supplier-documents/',
    };

    // ReactS3Client..uploadFilesList(uploadFilesList)

    // TODO: DELETE this
    //  after testing upload one file
    // const file = uploadFilesList[0];
    // const newFileName = file.name.replace(/\..+$/, '');
    // console.log('upload loop,,', file, newFileName);
    // ReactS3Client.uploadFile(file, newFileName)
    //   .then(data => {
    //     console.log(data);
    //     if (data.status === 204) {
    //       // setOpenThnk( true );
    //       // setTimeout( () => {
    //       //   // setOpenThnk( false );
    //       // }, 3000 )
    //       // listingBuck();
    //       console.log('success uploaded file');
    //     } else {
    //       console.log('fail upload, not 204...');
    //     }
    //   })
    //   .catch(err => {
    //     console.log('upload fail err...', err);
    //   });

    // upload file one by one
    for (let i = 0; i < uploadFilesList.length; i++) {
      const file = uploadFilesList[i];

      console.log('file==>', file);
      if (file) {
        const newFileName = file.name.replace(/\..+$/, '');
        console.log('upload loop,,', newFileName);
        const params = {
          ACL: 'bucket-owner-full-control',
          Body: file,
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: `supplier-documents/tasks/${adhocTaskField.task_id.toUpperCase()}/${
            file.name
          }`,
        };
        myBucket
          .putObject(params)
          .on('httpUploadProgress', (evt, response) => {
            console.log(Math.round((evt.loaded / evt.total) * 100));
          })
          .send((err, data) => {
            if (err) {
              console.log(err);
            } else {
              console.log(data);
              if (data) {
                console.log('data:=>', data);
                const fileFile = document.getElementById('file-input');
                fileFile.value = '';
                setAdhocTaskField({
                  role: 'supplier',
                  username: '',
                  add_exception: '',
                  additional_note: '',
                  create_date: '',
                  description: '',
                  documentAdd: [{ document: '', notes: '' }],
                  frequency: '',
                  task_id: '',
                  taskName: '',
                  responseAdd: [{ responseInput: '', responseNote: '' }],
                  task_status: 'completed',
                  admin_additional_info: [
                    {
                      additinal_info: '',
                      additional_note: '',
                    },
                  ],
                  appStatus: 'waitApproval',
                });
                // alert( 'Docs are uploaded' );
                // setOpenThnkFold( true );
                // setTimeout( () => {
                //   setOpenThnkFold( false );
                // }, 3000 )
                // setInputFieldSupplier( { folder_name: '' } )
                // listingBuck();
              }
            }
          });
      }

      // ReactS3Client.uploadFile( file, newFileName )
      //   .then( data => {
      //     console.log( data );
      //     if ( data.status === 204 ) {
      //       // setOpenThnk( true );
      //       // setTimeout( () => {
      //       //   // setOpenThnk( false );
      //       // }, 3000 )
      //       // listingBuck();
      //       console.log( 'success uploaded file' );
      //     } else {
      //       console.log( 'fail upload, not 204...' );
      //     }
      //   } )
      //   .catch( err => {
      //     console.log( 'upload fail err...', err );
      //   } );
    }

    // ----------------------S3 process END ----------------
  };
  const handleDelete = async (e, task_id, item) => {
    e.preventDefault();
    var files = adhocTaskField.documentAdd.filter(rec => rec.document != item);
    console.log('file===>', files);
    if (files.length == 0) {
      files = [{ document: '', notes: '' }];
    }
    setAdhocTaskField({
      ...adhocTaskField,
      documentAdd: [...files],
    });
    AWS.config.update({
      accessKeyId: sessionStorage.getItem('AccessKeyId'),
      secretAccessKey: sessionStorage.getItem('AccessKeySec'),
      sessionToken: sessionStorage.getItem('sessionToken'),
    });
    const myBucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
      region: 'ap-southeast-2',
    });
    var params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: `supplier-documents/tasks/${task_id}/${item}`,
    };
    try {
      myBucket.deleteObject(params, function (err, data) {
        if (err) console.log(err, err.stack); // error
        else console.log('deleted Data', data); // deleted
      });
    } catch (e) {
      console.log('Error ', e);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setOpenDraft(false);
  };
  ////Saving values to LocalStorage
  // React.useEffect(() => {
  //   localStorage.setItem('adhocTask' , JSON.stringify(taskResults));
  // }, [taskResults]);
  console.log('fields===>', adhocTaskField);

  const rowSelection = {
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      );
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
      setSelectedList(selectedRows);
      console.log(record);
      console.log(selectedList);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
      setSelectedList(selectedRows);
      console.log(selectedList);
    },
  };
  return (
    <>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Task details successfully added
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openDraft}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Task successfully added as a draft
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Page>
        <Row>
          <Col xl={12} lg={12} md={12} style={{ paddingLeft: '0' }}>
            <Card id="userManagement">
              <CardHeader className="card-header-main">
                Adhoc Request
              </CardHeader>
              <CardBody style={{ background: '#fff', padding: '1.25rem' }}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { background: '#D71920' } }}
                        className="tabb"
                      >
                        <Tab
                          label="Request Task"
                          value="1"
                          name="requestTask"
                        />
                        <Tab label="All Requests" value="2" name="allRequest" />
                        <Tab label="Draft" value="3" name="draft" />
                        <Tab label="Submitted" value="4" name="submitted" />
                        <Tab
                          label="Pending Info"
                          value="5"
                          name="pendingInfo"
                        />
                        <Tab label="Approved" value="6" name="approved" />
                        <Tab label="Archived" value="7" name="archived" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" style={{ padding: '15px 0px 0px 0px' }}>
                      <Row
                        style={{ paddingLeft: '15px', paddingRight: '15px' }}
                      >
                        <Col style={{ maxWidth: '50rem' }}>
                          <Form>
                            <FormGroup>
                              {adhocTaskField.admin_additional_info[0] &&
                              adhocTaskField.admin_additional_info[0]
                                .additinal_info &&
                              adhocTaskField.admin_additional_info[0]
                                .additinal_info.length > 0 ? (
                                <Row
                                  style={{
                                    paddingLeft: '15px',
                                  }}
                                >
                                  <div
                                    class="hide-scrollbar"
                                    style={{
                                      borderRadius: '32px',
                                      border: 'solid #E6E6E6 2px',
                                      width: '100%',
                                      padding: '10px 15px',
                                      marginBottom: '20px',
                                    }}
                                  >
                                    <Col
                                      style={{
                                        paddingLeft: '0',
                                        paddingRight: '0',
                                        width: '100%',
                                      }}
                                    >
                                      <Row>
                                        <Col>
                                          <label
                                            style={{ paddingLeft: '10px' }}
                                          >
                                            Pending Information
                                          </label>
                                          <Input
                                            type="text"
                                            name="admin_additional_info"
                                            value={
                                              adhocTaskField
                                                .admin_additional_info[0][
                                                'additinal_info'
                                              ]
                                            }
                                            disabled
                                            className="admin-input"
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </div>
                                </Row>
                              ) : null}

                              <Row
                                style={{
                                  paddingLeft: '15px',
                                }}
                              >
                                <div
                                  class="hide-scrollbar"
                                  style={{
                                    borderRadius: '32px',
                                    border: 'solid #E6E6E6 2px',
                                    width: '100%',
                                    padding: '10px 15px',
                                  }}
                                >
                                  <Col
                                    className="adhoc-box"
                                    style={{
                                      paddingLeft: '0',
                                      paddingRight: '0',
                                      width: '100%',
                                    }}
                                  >
                                    <Row>
                                      <Col md="6">
                                        <label style={{ paddingLeft: '10px' }}>
                                          Adhoc ID
                                          <span style={{ color: 'red' }}>
                                            {' '}
                                            *
                                          </span>{' '}
                                        </label>
                                        <Input
                                          type="text"
                                          name="task_id"
                                          onChange={adhocTaskInputHandler}
                                          value={adhocTaskField.task_id}
                                          disabled
                                          placeholder="Adhoc ID"
                                          className="admin-input"
                                          style={{ textTransform: 'uppercase' }}
                                        />
                                        {validate == true ? (
                                          <span
                                            style={{
                                              color: 'red',
                                              fontSize: '12px',
                                              paddingTop: '5px',
                                            }}
                                          >
                                            Please enter Adhoc ID
                                          </span>
                                        ) : null}
                                        <p
                                          style={{
                                            color: 'red',
                                            fontSize: '12px',
                                            paddingTop: '5px',
                                          }}
                                        >
                                          {requiredError.reqId}
                                          {idError}
                                        </p>
                                      </Col>
                                      <Col md="6">
                                        <label style={{ paddingLeft: '10px' }}>
                                          Task go live date
                                          <span style={{ color: 'red' }}>
                                            {' '}
                                            *
                                          </span>{' '}
                                        </label>
                                        <DatePicker
                                          calendarStartDay={1}
                                          locale="en-gb"
                                          className="textBox"
                                          name="create_date"
                                          dateFormat={'dd/MM/yyyy'}
                                          style={{
                                            border: '1px solid #ced4da',
                                          }}
                                          selected={adhocTaskField.create_date}
                                          onChange={(date, e) => {
                                            adhocTaskField.create_date = date;
                                            setAdhocTaskField({
                                              ...adhocTaskField,
                                            });
                                            if (
                                              e.target.name == 'create_date'
                                            ) {
                                              setRequiredError({
                                                create_date: '',
                                              });
                                            }
                                          }}
                                          placeholderText="Date"
                                          disabled={
                                            adhocTaskField.appStatus ==
                                            'approved'
                                          }
                                        />
                                        <p
                                          style={{
                                            color: 'red',
                                            fontSize: '12px',
                                          }}
                                        >
                                          {requiredError.reqDate}
                                        </p>
                                        {/* <Input
                                          type="date"
                                          name="create_date"
                                          className="admin-input"
                                          dateFormat={'dd/MM/yyyy'}
                                          style={{
                                            border: '1px solid #ced4da',
                                          }}
                                          closeOnScroll={true}
                                          selected={adhocTaskField.create_date}
                                          value={adhocTaskField.create_date}
                                          onChange={date => {
                                             const dateString = date.toDateString()
                                            const formatedDate =  moment(dateString).format('MM-DD-YYYY')
                                            // console.log('kkkkkkkk ', formatedDate);
                                            // setCreateDate(formatedDate)
                                            setCreateDate(date);
                                            console.log(typeof date);
                                            // adhocTaskInputHandler();
                                            setAdhocTaskField({
                                              ...adhocTaskField,
                                              create_date: date,
                                            });
                                          }}
                                        /> */}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="6">
                                        <label style={{ paddingLeft: '10px' }}>
                                          Task Name
                                          <span
                                            style={{ color: 'red' }}
                                          ></span>{' '}
                                        </label>
                                        <Input
                                          type="text"
                                          name="taskName"
                                          onChange={adhocTaskInputHandler}
                                          value={adhocTaskField.taskName}
                                          placeholder="Task Name"
                                          className="admin-input"
                                          disabled={
                                            adhocTaskField.appStatus ==
                                            'approved'
                                          }
                                        />
                                        <p
                                          style={{
                                            color: 'red',
                                            fontSize: '12px',
                                          }}
                                        >
                                          {requiredError.taskName}
                                        </p>
                                      </Col>
                                      <Col md="6">
                                        <label style={{ paddingLeft: '10px' }}>
                                          Direction/Description
                                          <span
                                            style={{ color: 'red' }}
                                          ></span>{' '}
                                        </label>
                                        <CKEditor
                                          editor={ClassicEditor}
                                          config={{
                                            toolbar: [
                                              'bold',
                                              'italic',
                                              'bulletedList',
                                              'numberedList',
                                              'undo',
                                              'redo',
                                            ],
                                          }}
                                          data={adhocTaskField.description}
                                          onChange={(e, editor) => {
                                            const data = editor.getData();
                                            setAdhocTaskField({
                                              ...adhocTaskField,
                                              ['description']: data,
                                            });
                                          }}
                                          disabled={
                                            adhocTaskField.appStatus ==
                                            'approved'
                                          }
                                        />
                                        {/* <Input
                                          type="textarea"
                                          name="description"
                                          onChange={adhocTaskInputHandler}
                                          value={adhocTaskField.description}
                                          placeholder="Direction/Description"
                                          style={{
                                            height:
                                              'calc(2rem + 0.75rem + 2px)',
                                            padding: '10px 5px 10px 5px',
                                          }}
                                          className="admin-input"
                                        /> */}
                                      </Col>
                                    </Row>
                                  </Col>
                                </div>
                              </Row>
                              <p style={{ padding: '20px 0px 0px 20px' }}>
                                Frequency
                              </p>
                              <Row
                                style={{
                                  marginLeft: '0',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Col
                                  className="adhoc-avalinfo1"
                                  md="6"
                                  style={{ paddingLeft: '0' }}
                                >
                                  <div
                                    class="admin-container hide-scrollbar"
                                    style={{
                                      height: '200px',
                                      padding: '10px 5px',
                                      width: 'auto',
                                    }}
                                  >
                                    <Col>
                                      <div
                                        onChange={adhocTaskInputHandler}
                                        style={{
                                          fontFamily: 'Arial Regular',
                                          fontSize: '14px',
                                        }}
                                      >
                                        <Col md="12">
                                          <Input
                                            type="radio"
                                            id="freqOne"
                                            value="one off"
                                            name="frequency"
                                            checked={
                                              adhocTaskField.frequency ===
                                              'one off'
                                            }
                                            disabled={
                                              adhocTaskField.appStatus ==
                                              'approved'
                                            }
                                          />
                                          <label for="freqOne">One off </label>
                                        </Col>
                                        {/* <Col md="12">
                                          <Input
                                            type="radio"
                                            id="freqWeek"
                                            value="Weekly"
                                            name="frequency"
                                            checked={
                                              adhocTaskField.frequency ===
                                              'Weekly'
                                            }
                                          />
                                          <label for="freqWeek">Weekly </label>
                                        </Col> */}
                                        {/* <Col md="12">
                                          <Input
                                            type="radio"
                                            id="freqFort"
                                            value="7"
                                            name="frequency"
                                            checked={
                                              adhocTaskField.frequency === '7'
                                            }
                                          />
                                          <label for="freqFort">Days 7 </label>
                                        </Col>
                                        <Col md="12">
                                          <Input
                                            type="radio"
                                            id="freqeven"
                                            value="14odd"
                                            name="frequency"
                                            checked={
                                              adhocTaskField.frequency ===
                                              '14odd'
                                            }
                                          />
                                          <label for="freqeven">
                                            Days 14 Odd{' '}
                                          </label>
                                        </Col>
                                        <Col md="12">
                                          <Input
                                            type="radio"
                                            id="14even"
                                            value="14even"
                                            name="frequency"
                                            checked={
                                              adhocTaskField.frequency ===
                                              '14even'
                                            }
                                          />
                                          <label for="14even">
                                            Days 14 Even{' '}
                                          </label>
                                        </Col>
                                        <Col md="12">
                                          <Input
                                            type="radio"
                                            id="freqMonth"
                                            value="28"
                                            name="frequency"
                                            checked={
                                              adhocTaskField.frequency === '28'
                                            }
                                          />
                                          <label for="freqMonth">
                                            Days 28{' '}
                                          </label>
                                        </Col> */}
                                        <Col md="12">
                                          <Input
                                            type="radio"
                                            id="freqExcep"
                                            value="exception"
                                            name="frequency"
                                            checked={
                                              adhocTaskField.frequency === '' &&
                                              exceptionChecked
                                            }
                                            disabled={
                                              adhocTaskField.appStatus ==
                                              'approved'
                                            }
                                          />
                                          <label for="freqExcep">
                                            Exception{' '}
                                          </label>
                                        </Col>
                                      </div>
                                      {exceptionChecked ? (
                                        <Row>
                                          <Col md="6">
                                            <Input
                                              type="textarea"
                                              name="add_exception"
                                              value={
                                                adhocTaskField.add_exception
                                              }
                                              onChange={adhocTaskInputHandler}
                                            ></Input>
                                          </Col>
                                        </Row>
                                      ) : null}
                                    </Col>
                                  </div>
                                </Col>
                                <Col
                                  className="adhoc-avalinfo2"
                                  md="6"
                                  style={{ paddingRight: '0' }}
                                >
                                  <div
                                    class="admin-container hide-scrollbar"
                                    style={{
                                      width: 'auto',
                                      height: '100%',
                                      padding: '15px 15px',
                                    }}
                                  >
                                    <div style={{ height: '100%' }}>
                                      <Input
                                        type="textarea"
                                        name="additional_note"
                                        onChange={adhocTaskInputHandler}
                                        value={adhocTaskField.additional_note}
                                        placeholder="Notes"
                                        className="admin-input"
                                        style={{
                                          height: '100%',
                                          padding: '10px 0 0 15px',
                                        }}
                                        disabled={
                                          adhocTaskField.appStatus == 'approved'
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginLeft: '0',
                                  marginTop: '20px',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Col
                                  className="adhoc-avalinfo1"
                                  md="6"
                                  style={{ paddingLeft: '0' }}
                                >
                                  <div
                                    class="admin-container hide-scrollbar"
                                    style={{
                                      width: 'auto',
                                      padding: '10px 0px',
                                    }}
                                  >
                                    {Array.isArray(
                                      adhocTaskField.documentAdd,
                                    ) &&
                                      adhocTaskField.documentAdd.map(
                                        (rec1, index) => (
                                          <Col key={index}>
                                            <Input
                                              type="file"
                                              id="file-input"
                                              name="document"
                                              style={{ padding: '30px 0px' }}
                                              onChange={e => {
                                                console.log(
                                                  'doc change',
                                                  e,
                                                  index,
                                                );
                                                adhocTaskField.documentAdd[
                                                  index
                                                ].document = e.target.files[0];
                                                // console.log( taskInfo )
                                                setAdhocTaskField({
                                                  ...adhocTaskField,
                                                  documentAdd: [
                                                    ...adhocTaskField.documentAdd,
                                                  ],
                                                });
                                              }}
                                              disabled={
                                                adhocTaskField.appStatus ==
                                                'approved'
                                              }
                                            />
                                            {isEdit ? (
                                              rec1.document &&
                                              typeof rec1.document ==
                                                'string' ? (
                                                <span>
                                                  <p>
                                                    <a
                                                      target={'_blank'}
                                                      href={`${process.env.REACT_APP_CDN}supplier-documents/tasks/${adhocTaskField.task_id}/${rec1.document}`}
                                                    >
                                                      {rec1.document}
                                                    </a>
                                                  </p>
                                                  <span
                                                    style={{
                                                      color: 'red',
                                                      cursor: 'pointer',
                                                    }}
                                                    onClick={e => {
                                                      if (
                                                        adhocTaskField.appStatus ==
                                                        'approved'
                                                      ) {
                                                        return;
                                                      }
                                                      handleDelete(
                                                        e,
                                                        adhocTaskField.task_id,
                                                        rec1.document,
                                                      );
                                                      // setIsEdit( false );
                                                    }}
                                                  >
                                                    X
                                                  </span>
                                                </span>
                                              ) : null
                                            ) : null}

                                            <Col style={{ padding: '0' }}>
                                              <label
                                                style={{ paddingLeft: '8px' }}
                                              >
                                                Notes
                                              </label>
                                              <Input
                                                type="textarea"
                                                name="notes"
                                                placeholder="Notes"
                                                className="admin-input"
                                                style={{
                                                  height:
                                                    'calc(2rem + 0.75rem + 2px)',
                                                  padding: '10px 5px',
                                                }}
                                                value={rec1.notes}
                                                onChange={e => {
                                                  adhocTaskField.documentAdd[
                                                    index
                                                  ].notes = e.target.value;
                                                  // console.log( taskInfo )
                                                  setAdhocTaskField({
                                                    ...adhocTaskField,
                                                    documentAdd: [
                                                      ...adhocTaskField.documentAdd,
                                                    ],
                                                  });
                                                  // setDocumentAdd([...documentAdd]);
                                                }}
                                                disabled={
                                                  adhocTaskField.appStatus ==
                                                  'approved'
                                                }
                                              />
                                            </Col>
                                            <Row style={{ marginLeft: '0px' }}>
                                              {index ==
                                              adhocTaskField.documentAdd
                                                .length -
                                                1 ? (
                                                <Row
                                                  style={{ marginLeft: '30%' }}
                                                >
                                                  <label
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      paddingRight: '15px',
                                                    }}
                                                  >
                                                    Add Document
                                                  </label>
                                                  <div
                                                    onClick={e => {
                                                      if (
                                                        adhocTaskField.appStatus ==
                                                        'approved'
                                                      ) {
                                                        return;
                                                      }
                                                      addDoc(e);
                                                    }}
                                                  >
                                                    <b
                                                      style={{
                                                        fontSize: '34px',
                                                        cursor: 'pointer',
                                                        color: '#D71920',
                                                      }}
                                                    >
                                                      +
                                                    </b>
                                                  </div>
                                                </Row>
                                              ) : null}
                                              {index > 0 &&
                                              index ==
                                                adhocTaskField.documentAdd
                                                  .length -
                                                  1 ? (
                                                <Col
                                                  style={{ marginLeft: '5%' }}
                                                >
                                                  <div
                                                    onClick={e => {
                                                      if (
                                                        adhocTaskField.appStatus ==
                                                        'approved'
                                                      ) {
                                                        return;
                                                      }
                                                      adhocTaskField.documentAdd.splice(
                                                        index,
                                                        1,
                                                      );
                                                      // setDocumentAdd([...documentAdd]);
                                                      setAdhocTaskField({
                                                        ...adhocTaskField,
                                                        documentAdd: [
                                                          ...adhocTaskField.documentAdd,
                                                        ],
                                                      });
                                                    }}
                                                  >
                                                    {' '}
                                                    <b
                                                      style={{
                                                        fontSize: '34px',
                                                        cursor: 'pointer',
                                                        color: '#D71920',
                                                      }}
                                                    >
                                                      -
                                                    </b>{' '}
                                                  </div>
                                                </Col>
                                              ) : null}
                                            </Row>
                                          </Col>
                                        ),
                                      )}
                                  </div>
                                </Col>
                                <Col
                                  className="adhoc-avalinfo2"
                                  md="6"
                                  style={{ paddingRight: '0' }}
                                >
                                  <div
                                    class="admin-container hide-scrollbar"
                                    style={{
                                      width: 'auto',
                                      padding: '10px 0px',
                                    }}
                                  >
                                    {Array.isArray(
                                      adhocTaskField.responseAdd,
                                    ) &&
                                      adhocTaskField.responseAdd.map(
                                        (rec1, index) => (
                                          <Col key={index}>
                                            <Col style={{ padding: '0' }}>
                                              <label
                                                style={{ paddingLeft: '10px' }}
                                              >
                                                Response
                                              </label>
                                              <Input
                                                type="select"
                                                name="responseInput"
                                                value={rec1.responseInput}
                                                className="admin-input"
                                                onChange={e => {
                                                  adhocTaskField.responseAdd[
                                                    index
                                                  ].responseInput =
                                                    e.target.value;
                                                  // console.log( taskInfo )
                                                  setAdhocTaskField({
                                                    ...adhocTaskField,
                                                    responseAdd: [
                                                      ...adhocTaskField.responseAdd,
                                                    ],
                                                  });
                                                  // setResponseAdd([...responseAdd]);
                                                }}
                                                disabled={
                                                  adhocTaskField.appStatus ==
                                                  'approved'
                                                }
                                              >
                                                <option value="" disabled>
                                                  Select Response
                                                </option>
                                                <option value="Photos">
                                                  Photos
                                                </option>
                                                <option value="Comments">
                                                  Comments
                                                </option>
                                                <option value="Surveys">
                                                  Surveys
                                                </option>
                                                {/* <option value="yes_no">
                                                  Yes or No
                                                </option> */}
                                              </Input>
                                            </Col>
                                            <Col style={{ padding: '0' }}>
                                              <label
                                                style={{ paddingLeft: '10px' }}
                                              >
                                                Notes
                                              </label>
                                              <Input
                                                type="text"
                                                name="responseNote"
                                                placeholder="Notes"
                                                className="admin-input"
                                                value={rec1.responseNote}
                                                onChange={e => {
                                                  adhocTaskField.responseAdd[
                                                    index
                                                  ].responseNote =
                                                    e.target.value;
                                                  // console.log( taskInfo )
                                                  setAdhocTaskField({
                                                    ...adhocTaskField,
                                                    responseAdd: [
                                                      ...adhocTaskField.responseAdd,
                                                    ],
                                                  });
                                                  // setResponseAdd([...responseAdd]);
                                                }}
                                                disabled={
                                                  adhocTaskField.appStatus ==
                                                  'approved'
                                                }
                                              />
                                            </Col>
                                            <Row>
                                              {index ==
                                              adhocTaskField.responseAdd
                                                .length -
                                                1 ? (
                                                <Row
                                                  style={{ marginLeft: '30%' }}
                                                >
                                                  <label
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      paddingRight: '15px',
                                                    }}
                                                  >
                                                    Add Response
                                                  </label>
                                                  <div
                                                    onClick={e => {
                                                      if (
                                                        adhocTaskField.appStatus ==
                                                        'approved'
                                                      ) {
                                                        return;
                                                      }
                                                      addResponse(e);
                                                    }}
                                                  >
                                                    <b
                                                      style={{
                                                        fontSize: '34px',
                                                        cursor: 'pointer',
                                                        color: '#D71920',
                                                      }}
                                                    >
                                                      +
                                                    </b>
                                                  </div>
                                                </Row>
                                              ) : null}
                                              {index > 0 &&
                                              index ==
                                                adhocTaskField.responseAdd
                                                  .length -
                                                  1 ? (
                                                <Col
                                                  style={{ marginLeft: '5%' }}
                                                >
                                                  <div
                                                    onClick={e => {
                                                      if (
                                                        adhocTaskField.appStatus ==
                                                        'approved'
                                                      ) {
                                                        return;
                                                      }
                                                      adhocTaskField.responseAdd.splice(
                                                        index,
                                                        1,
                                                      );
                                                      // setResponseAdd([...adhocTaskField.responseAdd]);
                                                      setAdhocTaskField({
                                                        ...adhocTaskField,
                                                        responseAdd: [
                                                          ...adhocTaskField.responseAdd,
                                                        ],
                                                      });
                                                    }}
                                                  >
                                                    {' '}
                                                    <b
                                                      style={{
                                                        fontSize: '34px',
                                                        cursor: 'pointer',
                                                        color: '#D71920',
                                                      }}
                                                    >
                                                      -
                                                    </b>{' '}
                                                  </div>
                                                </Col>
                                              ) : null}
                                            </Row>
                                          </Col>
                                        ),
                                      )}
                                  </div>
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col md={12} className="martop">
                                  <Button
                                    variant="outlined"
                                    disabled={
                                      adhocTaskField.appStatus == 'approved'
                                    }
                                    onClick={e => {
                                      saveOrSubmitTask(e, 'save');
                                    }}
                                    color="primary"
                                  >
                                    <span>
                                      {' '}
                                      <b>Save Task</b>{' '}
                                    </span>
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={
                                      adhocTaskField.appStatus == 'approved'
                                    }
                                    onClick={e => {
                                      saveOrSubmitTask(e, 'submit');
                                    }}
                                  >
                                    <span>
                                      {' '}
                                      <b>Submit Task</b>{' '}
                                    </span>
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={resetFields}
                                  >
                                    <span>
                                      {' '}
                                      <b> Cancel</b>{' '}
                                    </span>
                                  </Button>
                                </Col>
                              </Row>
                              {/* <Row>
                            {btnUpdate == false?(
                            <Col md={12} className="martop">
                              <Button onClick={addAdhocTask} variant="outlined"
                                color="primary" ><span> <b> Add Manager</b> </span></Button>
                                <Button onClick={cancelInputField} variant="outlined"
                                color="primary" ><span> <b>Cancel</b> </span></Button>
                            </Col>):(
                              <Col md={12} className="martop">
                            <Button variant="outlined" 
                              color="primary" onClick={updateAdhoc}><span> <b> Update</b> </span></Button>
                                <Button onClick={cancelInputField} variant="outlined"
                                color="primary" ><span> <b>Cancel</b> </span></Button>
                          </Col>
                            )}
                          </Row> */}
                              <br />
                              <br />
                            </FormGroup>
                          </Form>
                        </Col>
                        <Col md="6" className="adhocImage">
                          <div style={{ height: '100%', position: 'relative' }}>
                            <img
                              src={workImage}
                              style={{
                                position: 'absolute',
                                height: '16rem',
                                bottom: '7rem',
                                left: '30%',
                              }}
                            ></img>
                          </div>
                        </Col>
                      </Row>
                    </TabPanel>
                    <TabPanel value="2" style={{ padding: '15px 0px 0px 0px' }}>
                      <CreatedAdhocRequestTable
                        setAdhocTaskField={setAdhocTaskField}
                        isViewModes={isViewModes}
                        setIsViewModes={setIsViewModes}
                        value={value}
                        setValue={setValue}
                        setIsEdit={setIsEdit}
                        rowSelection={rowSelection}
                        setSelectedList={setSelectedList}
                        selectedList={selectedList}
                        tableType="all"
                        exceptionChecked={exceptionChecked}
                        setExceptionChecked={setExceptionChecked}
                      />
                    </TabPanel>
                    <TabPanel value="3" style={{ padding: '15px 0px 0px 0px' }}>
                      <CreatedAdhocRequestTable
                        setAdhocTaskField={setAdhocTaskField}
                        isViewModes={isViewModes}
                        setIsViewModes={setIsViewModes}
                        value={value}
                        setValue={setValue}
                        setIsEdit={setIsEdit}
                        rowSelection={rowSelection}
                        setSelectedList={setSelectedList}
                        selectedList={selectedList}
                        tableType="draft"
                        exceptionChecked={exceptionChecked}
                        setExceptionChecked={setExceptionChecked}
                      />
                    </TabPanel>
                    <TabPanel value="4" style={{ padding: '15px 0px 0px 0px' }}>
                      <CreatedAdhocRequestTable
                        setAdhocTaskField={setAdhocTaskField}
                        isViewModes={isViewModes}
                        setIsViewModes={setIsViewModes}
                        value={value}
                        setValue={setValue}
                        setIsEdit={setIsEdit}
                        rowSelection={rowSelection}
                        setSelectedList={setSelectedList}
                        selectedList={selectedList}
                        tableType="completed"
                        exceptionChecked={exceptionChecked}
                        setExceptionChecked={setExceptionChecked}
                      />
                    </TabPanel>
                    <TabPanel value="5" style={{ padding: '15px 0px 0px 0px' }}>
                      <CreatedAdhocRequestTable
                        setAdhocTaskField={setAdhocTaskField}
                        isViewModes={isViewModes}
                        setIsViewModes={setIsViewModes}
                        value={value}
                        setValue={setValue}
                        setIsEdit={setIsEdit}
                        rowSelection={rowSelection}
                        setSelectedList={setSelectedList}
                        selectedList={selectedList}
                        tableType="pending"
                        exceptionChecked={exceptionChecked}
                        setExceptionChecked={setExceptionChecked}
                      />
                    </TabPanel>
                    <TabPanel value="6" style={{ padding: '15px 0px 0px 0px' }}>
                      <CreatedAdhocRequestTable
                        setAdhocTaskField={setAdhocTaskField}
                        isViewModes={isViewModes}
                        setIsViewModes={setIsViewModes}
                        value={value}
                        setValue={setValue}
                        setIsEdit={setIsEdit}
                        rowSelection={rowSelection}
                        setSelectedList={setSelectedList}
                        selectedList={selectedList}
                        tableType="approved"
                        exceptionChecked={exceptionChecked}
                        setExceptionChecked={setExceptionChecked}
                      />
                    </TabPanel>
                    <TabPanel value="7" style={{ padding: '15px 0px 0px 0px' }}>
                      <CreatedAdhocRequestTable
                        setAdhocTaskField={setAdhocTaskField}
                        isViewModes={isViewModes}
                        setIsViewModes={setIsViewModes}
                        value={value}
                        setValue={setValue}
                        setIsEdit={setIsEdit}
                        rowSelection={rowSelection}
                        setSelectedList={setSelectedList}
                        selectedList={selectedList}
                        tableType="archived"
                        exceptionChecked={exceptionChecked}
                        setExceptionChecked={setExceptionChecked}
                      />
                    </TabPanel>
                  </TabContext>
                </Box>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <img src={greyBanner} className="greyBanner"></img>
          </Col>
        </Row>
      </Page>
    </>
  );
};

export default AdhocTask;
