import logo200Image from 'assets/img/logo/logo.png';
import logoWhiteNoBg from 'assets/img/logo/GF Logo_No Background_White.png';
import book from 'assets/img/book-black.svg';
import bookWT from 'assets/img/book-white.svg';
import admin from 'assets/img/admin-black.svg';
import adminWT from 'assets/img/admin-white.svg';

import SourceLink from 'components/SourceLink';
import React, { useEffect } from 'react';
import {
  MdAccountCircle,
  MdArrowDropDownCircle,
  MdBorderAll,
  MdBrush,
  MdChromeReaderMode,
  MdDashboard,
  MdExtension,
  MdGroupWork,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdNotificationsActive,
  MdPages,
  MdRadioButtonChecked,
  MdSend,
  MdStar,
  MdTextFields,
  MdViewCarousel,
  MdViewDay,
  MdViewList,
  MdWeb,
  MdWidgets,
  MdExitToApp,
  MdDateRange,
  MdLocationOn,
  MdPhoneAndroid,
  MdPermContactCalendar,
  MdSettings,
  MdDehaze,
} from 'react-icons/md';
import {
  RiHealthBookFill,
  RiHealthBookLine,
  RiRoadMapFill,
  RiAdminLine,
} from 'react-icons/ri';
import { GoTasklist } from 'react-icons/go';
import { BiStore, BiStoreAlt, BiTask } from 'react-icons/bi';
import { FaStore, FaTasks, FaFileInvoiceDollar } from 'react-icons/fa';
import { BsBook } from 'react-icons/bs';
import { VscReport, VscTasklist } from 'react-icons/vsc';
import { RiTaskLine } from 'react-icons/ri';
import { HiOutlineDocumentReport, HiDocumentReport } from 'react-icons/hi';
import { CgPlayListCheck } from 'react-icons/cg';
import {
  GrVmMaintenance,
  GrMap,
  GrTask,
  GrUserAdmin,
  GrUserManager,
  GrDocumentStore,
  GrContactInfo,
} from 'react-icons/gr';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
  ListGroup,
  Button,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { Auth } from 'aws-amplify';

// const navComponents = [
//   { to: '/buttons', name: 'buttons', exact: false, Icon: MdRadioButtonChecked },
//   {
//     to: '/button-groups',
//     name: 'button groups',
//     exact: false,
//     Icon: MdGroupWork,
//   },
//   { to: '/forms', name: 'forms', exact: false, Icon: MdChromeReaderMode },
//   { to: '/input-groups', name: 'input groups', exact: false, Icon: MdViewList },
//   {
//     to: '/dropdowns',
//     name: 'dropdowns',
//     exact: false,
//     Icon: MdArrowDropDownCircle,
//   },
//   { to: '/badges', name: 'badges', exact: false, Icon: MdStar },
//   { to: '/alerts', name: 'alerts', exact: false, Icon: MdNotificationsActive },
//   { to: '/progress', name: 'progress', exact: false, Icon: MdBrush },
//   { to: '/modals', name: 'modals', exact: false, Icon: MdViewDay },
// ];

// const navContents = [
//   { to: '/typography', name: 'typography', exact: false, Icon: MdTextFields },
//   { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
// ];

// const pageContents = [
//   { to: '/login', name: 'login / signup', exact: false, Icon: MdAccountCircle },
//   {
//     to: '/login-modal',
//     name: 'login modal',
//     exact: false,
//     Icon: MdViewCarousel,
//   },
// ];

// const pageContents = [
//   { to: '/login', name: 'login ', exact: false, Icon: MdAccountCircle },
//   {
//     to: '/',
//     name: 'Register /Sign Up',
//     exact: true,
//     Icon: MdViewCarousel,
//   },
// ];
// const [roleUser, setRoleUser] = React.useState(false);

// setRoleUser(userRole)

// }

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
    isActive: '',
  };

  constructor(props) {
    super(props);
    this.state.collapsed = false;
    const accessToken = sessionStorage.getItem('accessToken');
    // console.log('generateTokens', accessToken);
    // axios({
    //   method: 'POST',
    //   url: process.env.REACT_APP_API_BASE + 'auth',
    //   data: { access_token: accessToken },
    // }).then(response => {
    // console.log('side barrrrrrrr', localStorage.getItem('userRole'));
    // console.log(response.data);
    // setAccessKeyId(response.AccessKeyId)
    // sessionStorage.setItem( 'AccessKeyId', response.data.AccessKeyId );
    // sessionStorage.setItem('apikey', response.data.ApiKey);
    // sessionStorage.setItem( 'AccessKeySec', response.data.SecretAccessKey );
    // sessionStorage.setItem( 'sessionToken', response.data.SessionToken );

    // setAccessKeySec(response.SecretAccessKey)

    const curUserRole = localStorage.getItem('userRole');
    // console.log('preet', curUserRole);
    if (curUserRole == 'user') {
      // get current sub user parent supplier username
      axios({
        method: 'GET',
        headers: {
          Authorization: sessionStorage.getItem('idtoken'),
        },
        url: process.env.REACT_APP_API_BASE + 'admin-portal',
      }).then(response => {
        const curUsername = sessionStorage.getItem('username');
        const admindata = response.data.body;
        //
        const parentSupUsername = admindata.filter(
          item =>
            item.role && item.role == 'user' && item.username == curUsername,
        )[0].supplier_name;
        sessionStorage.setItem('parentSupUsername', parentSupUsername);
      });
    }
    // });
  }
  componentDidMount() {
    if (
      window.location.href.toLocaleLowerCase().includes('admin') ||
      window.location.href.toLocaleLowerCase().includes('administration')
    ) {
      // console.log(
      //   'window.location.href.toLocaleLowerCase().includes ',
      //   window.location.href.toLocaleLowerCase().includes('admin'),
      // );
      this.setState({
        ...this.state,
        isActive: 'admin',
      });
    } else if (
      window.location.href.toLocaleLowerCase().includes('catalogue') ||
      window.location.href.toLocaleLowerCase().includes('ProductCatalogue')
    ) {
      // console.log(
      //   'window.location.href.toLocaleLowerCase().includes ',
      //   window.location.href.toLocaleLowerCase().includes('catalogue') ||
      //     window.location.href.toLocaleLowerCase().includes('ProductCatalogue'),
      // );

      this.setState({
        ...this.state,
        isActive: 'catalogue',
      });
    }
  }
  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  signout = event => {
    event.preventDefault();
    event.stopPropagation();
    // {global: true}
    Auth.signOut({ global: true })
      .then(() => {
        // clearTimeout( this.timer );
        // Clear local storage when api is available
        // localStorage.clear();

        sessionStorage.clear();
        // localStorage.clear();
        // localStorage.removeItem( "accessLevel" );
        // reset page location
        console.log('logout succesfully');
        document.location.replace('/');
      })
      .catch(e => {
        console.log(`Sign out failed. Please try again.\n${e.message}`);
      })
      .finally(() => {
        // this.setState( {
        //   loading: false,
        // } );
      });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ collapsed: !this.state.collapsed });
    // console.log(this.state.collapsed);
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    document.querySelector('.cr-sidebar').toggleAttribute('removeBorderRadius');
    if (!this.state.collapsed) {
      document.querySelector('.navbar').style.padding = '0.5rem 2rem';
      document.querySelector('.signout').style.margin = '60px 0 0 30px';
      document.querySelector('.signout-text').innerText = 'Logout';
    } else {
      document.querySelector('.navbar').style.padding = '0';
      document.querySelector('.signout').style.margin = '2rem 0.2rem';
      document.querySelector('.signout-text').innerText = '';
    }

    const navItem = document.querySelectorAll('.nav-item');
    for (let i = 0; i < navItem.length; i++) {
      navItem[i].classList.toggle('square-border');
      navItem[i].classList.toggle('nav-item-closed');
    }
  };

  render() {
    const navComponents = [
      {
        to: '/adhoc',
        name: 'Adhoc Request',
        exact: false,
        Icon: HiDocumentReport,
      },
      {
        to: '/progressTask',
        name: 'Tasks Progress',
        exact: false,
        Icon: FaTasks,
      },
    ];
    var navItems = '';
    var nextItems = '';

    // Update the document title using the browser API

    const userRole = localStorage.getItem('userRole');
    // console.log(userRole);
    const roleType = sessionStorage.getItem('user_type');
    // if (userRole == 'supplier' && roleType == 'no') {
    //   nextItems = [
    //     { to: '/invoice', name: 'Invoice', id: 'dashboard', exact: false, Icon: FaFileInvoiceDollar },
    //     { to: '/product-catalogue', name: 'Catalogue', id: 'dashboard', exact: false, Icon: BsBook },
    //     { to: '/administration', name: 'Administration', id: 'dashboard', exact: false, Icon: RiAdminLine },

    //   ]
    // }
    if (userRole == 'supplier' || userRole == 'user') {
      navItems = [
        {
          to: '/dashboard',
          name: 'dashboard',
          id: 'dashboard',
          exact: true,
          Icon: MdDashboard,
        },
        // { to: '/t', name: 'Task Management', id:'dashboard', exact: false, Icon: FaTasks },
        // { to: '/invoice', name: 'Invoice',id:'dashboard', exact: false, Icon: FaFileInvoiceDollar },
        // { to: '/health', name: 'Health', id:'dashboard', exact: false, Icon: RiHealthBookLine },
        // { to: '/maintenance', name: 'maintenance', id:'dashboard', exact: false, Icon: GrVmMaintenance },
        // { to: '/rationconsumption', name: 'Pen Ration Consumption',id:'dashboard', exact: false, Icon: GiFoodChain },
        // { to: '/movement', name: 'Mob Split/ Movement', id:'dashboard', exact: false, Icon: RiRoadMapFill },
        // { to: '/contact', name: 'Customer Management',id:'dashboard', exact: false, Icon: MdPermContactCalendar },
      ];
      if (userRole == 'supplier') {
        nextItems = [
          {
            to: '/invoice',
            name: 'VIEW INVOICE',
            id: 'dashboard',
            exact: false,
            Icon: FaFileInvoiceDollar,
          },
          {
            to: '/product-catalogue',
            name: 'Catalogue',
            id: 'dashboard',
            exact: false,
            Img: this.state.isActive.toLowerCase().includes('catalogue')
              ? bookWT
              : book,
            Icon: BsBook,
          },
          {
            to: '/administration',
            name: 'Administration',
            id: 'dashboard',
            exact: false,
            Img: this.state.isActive.toLowerCase().includes('admin')
              ? adminWT
              : admin,
            Icon: RiAdminLine,
          },
        ];
      } else {
        nextItems = [
          {
            to: '/invoice',
            name: 'Invoice',
            id: 'dashboard',
            exact: false,
            Icon: FaFileInvoiceDollar,
          },
          {
            to: '/product-catalogue',
            name: 'Catalogue',
            id: 'dashboard',
            exact: false,
            Img: this.state.isActive.toLowerCase().includes('catalogue')
              ? bookWT
              : book,
            Icon: BsBook,
          },
        ];
      }

      // nextItems =[
      //   { to: '/product-catalogue', name: 'Catalogue',id:'dashboard', exact: false, Icon: FaFileInvoiceDollar },
      // ]
    }
    // else if (userRole == 'supplier' && roleType == 'no') {
    //   navItems = [
    //     { to: '/invoice', name: 'Invoice', id: 'dashboard', exact: false, Icon: FaFileInvoiceDollar },
    //     { to: '/product-catalogue', name: 'Catalogue', id: 'dashboard', exact: false, Icon: BsBook },
    //     { to: '/administration', name: 'Administration', id: 'dashboard', exact: false, Icon: RiAdminLine },

    //   ]
    // }
    else if (userRole == 'stock-hand') {
      navItems = [
        // { to: '/dailyHealth', name: 'Daily Health Check', id:'dashboard', exact: false, Icon: RiHealthBookLine }
      ];
    } else if (userRole == 'manager') {
      navItems = [
        {
          to: '/dashboard',
          name: 'dashboard',
          id: 'dashboard',
          exact: true,
          Icon: MdDashboard,
        },

        {
          to: '/task',
          name: 'Task Management',
          id: 'dashboard',
          exact: false,
          Icon: FaTasks,
          style: 'marginTop:2%',
        },
        {
          to: '/admin',
          name: 'Admin',
          id: 'dashboard',
          exact: false,
          Img: this.state.isActive.toLowerCase().includes('admin')
            ? adminWT
            : admin,
          Icon: RiAdminLine,
        },
        //  { to: '/reports', name: 'Reports',id:'dashboard', exact: false, Icon: HiOutlineDocumentReport },

        {
          to: '/ProductCatalogue',
          name: 'Product Catalogue',
          id: 'dashboard',
          exact: false,
          Img: this.state.isActive.toLowerCase().includes('catalogue')
            ? bookWT
            : book,
          Icon: BsBook,
        },
      ];
    } else {
      navItems = [
        {
          to: '/dashboard',
          name: 'dashboard',
          id: 'dashboard',
          exact: true,
          Icon: MdDashboard,
        },

        {
          to: '/task',
          name: 'Task Management',
          id: 'dashboard',
          exact: false,
          Icon: FaTasks,
          style: 'marginTop:2%',
        },
        {
          to: '/admin',
          name: 'Admin',
          id: 'dashboard',
          exact: false,
          Img: this.state.isActive.toLowerCase().includes('admin')
            ? adminWT
            : admin,
          Icon: RiAdminLine,
        },
        //  { to: '/reports', name: 'Reports',id:'dashboard', exact: false, Icon: HiOutlineDocumentReport },
        {
          to: '/runList',
          name: 'Adhoc Request',
          id: 'dashboard',
          exact: false,
          Icon: HiDocumentReport,
        },
        {
          to: '/ProductCatalogue',
          name: 'Product Catalogue',
          id: 'dashboard',
          exact: false,
          Img: this.state.isActive.toLowerCase().includes('catalogue')
            ? bookWT
            : book,
          Icon: BsBook,
        },
      ];
    }

    const logvar = localStorage.getItem('setlogin');
    // console.log( "Active", isActive )
    return (
      <aside className={bem.b()} style={{ maxHeight: '1080px' }}>
        <div className={bem.e('content')}>
          <Navbar className="d-flex justify-content-between">
            <SourceLink className="navbar-brand" style={{ width: '60%' }}>
              <img
                src={logoWhiteNoBg}
                className="imgside"
                alt=""
                width="80%"
                style={{ padding: '1.5rem 0 7% 0' }}
              />
            </SourceLink>
            <Button
              className="sidebar-crl-btn"
              outline
              onClick={this.handleSidebarControlButton}
            >
              <MdDehaze size={42} />
            </Button>
          </Navbar>
          <Nav vertical>
            {navItems &&
              navItems.map(({ to, id, name, exact, Icon, Img }, index) => (
                <NavItem
                  key={index}
                  className={bem.e('nav-item') + ' nav-item-closed'}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      isActive: name,
                    });
                  }}
                >
                  <BSNavLink
                    id={`navItem-${id}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    {/* <Icon className={bem.e( 'nav-item-icon' )} /> */}
                    {name.toLowerCase().includes('catalogue') ? (
                      <img
                        src={Img}
                        width="24px"
                        height="24px"
                        // className="catalogue-side-bar-icon"
                        className="catalogue-side-bar-icon catalogue-side-bar-icon-supplier"
                        style={{ marginRight: '10px', width: '25px' }}
                        alt="catalogue icon"
                      />
                    ) : name.toLowerCase().includes('admin') ? (
                      <img
                        src={Img}
                        width="24px"
                        height="24px"
                        // className="catalogue-side-bar-icon"
                        className={bem.e('nav-item-icon')}
                        style={{ marginRight: '10px', width: '25px' }}
                        alt="admin icon"
                      />
                    ) : (
                      <Icon className={bem.e('nav-item-icon')} />
                    )}
                    {/* {name.toLowerCase().includes('catalogue') ||
                      name.toLowerCase().includes('admin') ? (
                      <img
                        src={Img}
                        width="24px"
                        height="24px"
                        className="catalogue-side-bar-icon"
                        // className={bem.e('nav-item-icon')}
                        style={{ marginRight: '10px', width: '25px' }}
                      />
                    ) : (
                      <Icon className={bem.e('nav-item-icon')} />
                    )} */}
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}

            {localStorage.getItem('userRole') == 'supplier' ||
            localStorage.getItem('userRole') == 'user' ? (
              <>
                <NavItem
                  className={bem.e('nav-item') + ' nav-item-closed'}
                  onClick={this.handleClick('Components')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension className={bem.e('nav-item-icon')} />
                      <span
                        className="align-self-start"
                        style={{ marginTop: '7px' }}
                      >
                        Task Management
                      </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        transform: this.state.isOpenComponents
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        paddingRight: this.state.isOpenComponents
                          ? '0rem'
                          : '0rem',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={this.state.isOpenComponents}>
                  {navComponents &&
                    navComponents.map(
                      ({ to, name, exact, Icon, Img }, index) => (
                        <NavItem
                          key={index}
                          className={
                            bem.e('nav-item') + ' sub-nav-item nav-item-closed'
                          }
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              isActive: name,
                            });
                          }}
                        >
                          <BSNavLink
                            id={`navItem-${name}-${index}`}
                            className="text-uppercase"
                            tag={NavLink}
                            to={to}
                            activeClassName="active"
                            exact={exact}
                          >
                            {name.toLowerCase().includes('catalogue') ? (
                              <img
                                src={Img}
                                width="24px"
                                height="24px"
                                style={{ marginRight: '10px' }}
                                className="catalogue-side-bar-icon"
                                // className={bem.e('nav-item-icon')}
                                alt="catalogue icon"
                              />
                            ) : name.toLowerCase().includes('admin') ? (
                              <img
                                src={Img}
                                width="24px"
                                height="24px"
                                style={{ marginRight: '10px' }}
                                // className="catalogue-side-bar-icon"
                                className={bem.e('nav-item-icon')}
                                alt="admin icon"
                              />
                            ) : (
                              <Icon className={bem.e('nav-item-icon')} />
                            )}
                            {/* {name.toLowerCase().includes('catalogue') ||
                              name.toLowerCase().includes('admin') ? (
                              <img
                                src={Img}
                                width="24px"
                                height="24px"
                                style={{ marginRight: '10px' }}
                                className={bem.e('nav-item-icon')}
                              />
                            ) : (
                              <Icon className={bem.e('nav-item-icon')} />
                            )} */}
                            <span className="">{name}</span>
                          </BSNavLink>
                        </NavItem>
                      ),
                    )}
                </Collapse>
              </>
            ) : null}
            {nextItems &&
              nextItems.map(({ to, id, name, exact, Icon, Img }, index) => (
                <NavItem
                  key={index}
                  className={bem.e('nav-item') + ' nav-item-closed'}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      isActive: name,
                    });
                  }}
                >
                  <BSNavLink
                    id={`navItem-${id}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    {name.toLowerCase().includes('catalogue') ? (
                      <img
                        src={Img}
                        width="24px"
                        height="24px"
                        style={{ marginRight: '10px' }}
                        className="catalogue-side-bar-icon catalogue-side-bar-icon-supplier"
                        // className={bem.e('nav-item-icon')}
                        alt="catalogue icon"
                      />
                    ) : name.toLowerCase().includes('admin') ? (
                      <img
                        src={Img}
                        width="24px"
                        height="24px"
                        style={{ marginRight: '10px' }}
                        // className="catalogue-side-bar-icon"
                        className={bem.e('nav-item-icon')}
                        alt="admin icon"
                      />
                    ) : name.toLowerCase().includes('invoice') ? (
                      <Icon className="invoice-side-bar-icon" />
                    ) : (
                      <Icon className={bem.e('nav-item-icon')} />
                    )}
                    {/* {name.toLowerCase().includes('catalogue') ||
                      name.toLowerCase().includes('admin') ? (
                      <img
                        src={Img}
                        width="24px"
                        height="24px"
                        style={{ marginRight: '10px' }}
                        className={bem.e('nav-item-icon')}
                      />
                    ) : (
                      <Icon className={bem.e('nav-item-icon')} />
                    )} */}
                    <span className="active-sidebar-text">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}

            {/* <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Components')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Components</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenComponents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenComponents}>
              {navComponents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Contents')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSend className={bem.e('nav-item-icon')} />
                  <span className="">Contents</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenContents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenContents}>
              {navContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Pages')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdPages className={bem.e('nav-item-icon')} />
                  <span className="">Pages</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPages
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenPages}>
              {pageContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse> */}
          </Nav>

          <button onClick={this.signout} className="signout">
            <MdExitToApp size={25} />
            <p
              className="signout-text"
              style={{ display: 'inline', marginLeft: '0.5rem' }}
            ></p>
          </button>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
