import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { BiDownload } from 'react-icons/bi';
import { Table, Tag, Space, Popconfirm } from 'antd';
import 'antd/dist/antd.css';
import { Input, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import AWS from 'aws-sdk';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { confirmAlert } from 'react-confirm-alert';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const viewCatalogue = props => {
  const uploadData = [
    {
      seq: '1',
      supplier_name: 'aaa',
      doc_name: 'Doc001',
      comment: 'Comment Value',
    },
    {
      seq: '2',
      supplier_name: 'bbb',
      doc_name: 'Doc002',
      comment: 'Comment Value',
    },
  ];

  const [tableData, setTableData] = React.useState([]);
  const [filteredTableData, setFilteredTableData] = React.useState([]);
  const [selectedSupplier, setSelectedSupplier] = React.useState('');
  const [supplierList, setSupplierList] = React.useState([]);
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const [actionHint, setActionHint] = useState({
    open: false,
    text: '',
    title: 'Thank you',
  });
  const getSupplierList = () => {
    axios({
      method: 'GET',

      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    })
      .then(response => {
        const admindata = response.data.body;
        const roles = admindata.filter(item => {
          if (item.role && item.role == 'supplier') {
            return item;
          }
        });
        console.log('sup Roles', roles);
        setSupplierList(roles);
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };
  const getTableData = () => {
    setLoading(true);
    props.setSelectedList([]);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'product-catalogue',
    })
      .then(response => {
        console.log('cata res ', response);
        if (response.data.statusCode == 200) {
          const result = response.data.body.Items;
          if (Array.isArray(result) && result.length > 0) {
            const list = [];
            result.forEach(item => {
              // only display approved docs
              if (item.status == 'Approved') {
                Array.isArray(item.catalogues) &&
                  item.catalogues.forEach(cata => {
                    list.push({
                      supplier_id: item.supplier_id,
                      supplier_name: item.supplier_name,
                      datetime: item.datetime,
                      doc_name: cata.document,
                      comment: cata.comments,
                    });
                  });
              }
            });
            console.log('generate cata table', list);
            setTableData(list);
            setFilteredTableData(list);
            filter(list);
            setFilteredData(list);
          }
        } else {
          console.log('not 200 status', response);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log('get catas fail...', err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getSupplierList();
    getTableData();
  }, []);

  console.log(uploadData);
  const rec = uploadData.map(({ ...item }) => ({
    ...item,
  }));
  console.log('modifyData', rec);

  const deleteDocFromDB = record => {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE +
        'product-catalogue?supplier_id=' +
        record.supplier_id +
        '&datetime=' +
        record.datetime,
    })
      .then(response => {
        console.log('cata res ', response);
        if (response.data.statusCode == 200) {
          // const result = response.data.body.Items;
          deleteDocFromS3(record);
          // setActionHint({
          //   ...actionHint,
          //   open: true,
          //   text: 'File deleted successfully!',
          //   title: 'Thank you',
          // });
          // setTimeout(() => {
          //   setActionHint({
          //     ...actionHint,
          //     open: false,
          //     text: 'File deleted successfully!',
          //     title: 'Thank you',
          //   });
          // }, 3000);
          // getTableData();
        } else {
          console.log('not 200 status', response);
        }
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };
  const deleteDocFromS3 = record => {
    // delete doc in S3
    const curKey = `admin-documents/supplier/${record.supplier_id}/product-catalogues/${record.doc_name}`;

    var params = { Bucket: process.env.REACT_APP_BUCKET_NAME, Key: curKey };
    AWS.config.update({
      accessKeyId: sessionStorage.getItem('AccessKeyId'),
      secretAccessKey: sessionStorage.getItem('AccessKeySec'),
      sessionToken: sessionStorage.getItem('sessionToken'),
    });
    const myBucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
      region: 'ap-southeast-2',
    });

    myBucket.deleteObject(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
        setActionHint({
          ...actionHint,
          open: true,
          text: err.mes,
          title: 'Sorry',
        });
        setTimeout(() => {
          setActionHint({
            ...actionHint,
            open: false,
            text: 'File deleted successfully!',
            title: 'Thank you',
          });
        }, 3000);
      } else {
        console.log('file deleted from S3', data);
        setActionHint({
          ...actionHint,
          open: true,
          text: 'File deleted successfully!',
          title: 'Thank you',
        });
        setTimeout(() => {
          setActionHint({
            ...actionHint,
            open: false,
            text: 'File deleted successfully!',
            title: 'Thank you',
          });
        }, 3000);
        getTableData();
      }
    });
  };

  const deleteDoc = (e, record) => {
    console.log('Delete doc for a supplier', record);
    e.preventDefault();
    // deleteDocFromS3(record);
    // delete from DB then delete from S3
    deleteDocFromDB(record);
  };
  const downloadDoc = (e, record) => {
    console.log('download doc from', record);
    const downloadLink = `${process.env.REACT_APP_CDN}admin-documents/supplier/${record.supplier_id}/product-catalogues/${record.doc_name}`;
    console.log('downloadLink,,', downloadLink);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const modifyFilteredTableData = filteredTableData
    .sort((a, b) => a.supplier_name.localeCompare(b.supplier_name))
    .map(({ ...item }) => ({
      ...item,
    }));

  const filter = data => {
    data.map(rec => {
      console.log('rec====>', rec);
      setFilteredData(rec);
    });
  };

  const bulkDelete = e => {
    e.preventDefault();
    console.log('selectedList==>', props.selectedList);
    var arrData = [];
    var keyArray = [];
    props.selectedList.forEach(item => {
      console.log(item);
      // return
      if (item) {
        const obj = {};
        obj['datetime'] = item.datetime;
        obj['supplier_id'] = item.supplier_id;
        var curKey = `admin-documents/supplier/${item.supplier_id}/product-catalogues/${item.doc_name}`;
        arrData.push(obj);
        keyArray.push(curKey);
        console.log('arrData==>', arrData, curKey, keyArray);
        confirmAlert({
          title: 'Confirm to Delete',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'yes',
              onClick: () => {
                axios({
                  method: 'POST',
                  headers: {
                    Authorization: sessionStorage.getItem('idtoken'),
                  },
                  url:
                    process.env.REACT_APP_API_BASE +
                    'product-catalogue/bulk-delete',
                  data: arrData,
                })
                  .then(response => {
                    console.log(response);
                    console.log('bulk plano deleted !', response);
                    getTableData();
                    props.setSelectedList([]);
                    arrData = [];
                    bulkDeleteS3(keyArray);
                    props.setSelectedList([]);
                  })
                  .catch(error => {
                    console.log(error.response);
                    console.log(error.response.status);
                    if (
                      error.response.status == 401 &&
                      error.response.data.message ==
                        'The incoming token has expired'
                    ) {
                      alert('Session timed out, please login again.');
                      localStorage.clear();
                      sessionStorage.clear();
                      localStorage.clear();
                      // localStorage.removeItem( "accessLevel" );
                      // reset page location
                      console.log('logout succesfully');
                      document.location.replace('/');
                    }
                  });
              },
            },
            {
              label: 'No',
              onClick: () => {
                return;
              },
            },
          ],
        });
      }
    });
  };

  const bulkDeleteS3 = allKeys => {
    console.log('allkeys', allKeys);
    Array.isArray(allKeys) &&
      allKeys.length > 0 &&
      allKeys.forEach(curKey => {
        var params = { Bucket: process.env.REACT_APP_BUCKET_NAME, Key: curKey };
        AWS.config.update({
          accessKeyId: sessionStorage.getItem('AccessKeyId'),
          secretAccessKey: sessionStorage.getItem('AccessKeySec'),
          sessionToken: sessionStorage.getItem('sessionToken'),
        });
        const myBucket = new AWS.S3({
          params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
          region: 'ap-southeast-2',
        });

        myBucket.deleteObject(params, function (err, data) {
          if (err) {
            console.log(err, err.stack);
          } else {
            console.log('file deleted from S3', data);
            getTableData();
          }
        });
      });
  };

  const columns = [
    {
      title: 'Seq',
      dataIndex: 'seq',
      align: 'center',
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: 'Supplier ID',
      dataIndex: 'supplier_id',
      align: 'center',
      ...getColumnSearchProps('supplier_id'),
    },
    {
      title: 'Supplier Name',
      dataIndex: 'supplier_name',
      align: 'center',
      ...getColumnSearchProps('supplier_name'),
    },
    {
      title: 'Document Name',
      dataIndex: 'doc_name',
      align: 'center',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      align: 'center',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <button
              style={{ padding: '0', marginLeft: '6px' }}
              // onClick={e => downloadDoc(e, record)}
              // TODO: download is giving the access deny
            >
              <a
                href={`${process.env.REACT_APP_CDN}admin-documents/supplier/${record.supplier_id}/product-catalogues/${record.doc_name}`}
              >
                <BiDownload
                  style={{ fontSize: '20px', color: 'rgb(35, 34, 36)' }}
                />
              </a>
            </button>
            <button
              style={{ marginRight: '0', backgroundColor: 'transparent' }}
              onClick={e => deleteDoc(e, record)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </button>
          </>
        );
      },
    },
  ];

  const supplierChangeHandler = e => {
    console.log('supplierChangeHandler', e.target.value, tableData);
    const selectedVal = e.target.value;
    setSelectedSupplier(selectedVal);
    if (selectedVal == '') {
      setFilteredTableData(tableData);
    } else {
      const selectedValArr = e.target.value.split(',');
      const newTableData = tableData.filter(
        item => item.supplier_id == selectedValArr[0],
      );
      // console.log('newtttt', newTableData);
      setFilteredTableData(newTableData);
    }
  };
  const handleClose = () => {
    setActionHint({
      open: false,
      text: 'File deleted successfully!',
      title: 'Thank you',
    });
  };

  const renderSupplierOptions = () => {
    return supplierList.map(item => (
      <option value={item.id}>
        {item.id} - {item.full_company_name}
      </option>
    ));
  };
  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Dialog open={actionHint.open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>{actionHint.title}</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>{actionHint.text}</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      {/* <Row>
        <Col md="3">
          <label>Supplier</label>
          <Input
            type="select"
            name="retailer_name"
            onChange={supplierChangeHandler}
            value={selectedSupplier}
            className="admin-input"
          >
            <option selected value="">
              Select Supplier
            </option>
            {renderSupplierOptions()}
          </Input>
        </Col>
      </Row> */}
      <Col
        md={9}
        style={{ float: 'right', textAlign: 'right', marginBottom: '4px' }}
        className="uploadExcel"
      >
        <button
          title="Select records to delete!"
          type="button"
          variant="outlined"
          class="btn btn-primary"
          onClick={e => {
            bulkDelete(e);
          }}
          disabled={props.selectedList.length == 0}
        >
          {' '}
          Delete
        </button>
      </Col>
      <div style={{ clear: 'both' }}></div>
      <div className="antdTable view-catalogue-table">
        {/* {filteredTableData ? */}
        <Table
          columns={columns}
          // scroll={{ x: 1500 }}
          dataSource={modifyFilteredTableData}
          rowSelection={{ ...props.rowSelection }}
          rowKey={record => record.supplier_id + record.datetime}
          onChange={(pagination, filters, sorter, currentPageData) =>
            filter(currentPageData.currentDataSource)
          }
          pagination={{
            total: filteredData.length,
            showTotal: total => `Total ${total} items`,
            pageSizeOptions: ['10', '50', '100', '200', '500'],
          }}
          // bordered
        />
        {/* : null
        } */}
      </div>
    </>
  );
};

export default viewCatalogue;
