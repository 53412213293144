import axios from 'axios';
import Page from 'components/Page';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { BiDownload } from 'react-icons/bi';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { extractInfo, generateFilterList } from '../AdminPages/AdminProductCatalogue.js';
import AWS from 'aws-sdk';
import moment from 'moment';
import { Table, Checkbox, Upload as UploadAnt, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import greyBanner from 'assets/img/logo/Banner Grey.png';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;


const SubmitCatalogue = (props) => {
    const { filteredData, supplierInfo, cancelDoc, submitDoc, filter, getColumnSearchProps, ProductCatalogueField, setProductCatalogueField, tableData, getTableData, loading } = props



    useEffect(() => {
        // get supplier info then get the table data

        if (supplierInfo && supplierInfo.id) {
            getTableData(supplierInfo);

        }

    }, [supplierInfo]);

    const beforeUploadHandler = (e, file) => {
        // console.log('>>> beforeUpload', file);
        // check if has that file already
        // const curFileName = file.name;
        ProductCatalogueField.catalogues.push({
            document: file,
            comments: '',
        });
        // console.log('product cat', ProductCatalogueField);
        setProductCatalogueField({
            ...ProductCatalogueField,
            catalogues: [...ProductCatalogueField.catalogues],
        });

        // in order to select the same file twice
        e.target.value = '';
    };

    const columns = [
        {
            title: 'Seq',
            dataIndex: 'seq',
            align: 'center',
            editable: true,
            render: (text, record, index) => <>{index + 1}</>,
        },
        {
            title: 'Submitted Date',
            dataIndex: 'datetime',
            align: 'center',
            editable: true,
            render: (text, record) => (
                <>{moment(record.datetime).format('DD/MM/YYYY HH:mm:ss')}</>
            ),
        },
        {
            title: 'Document Name',
            dataIndex: 'doc_name',
            align: 'center',
            editable: true,
            ...getColumnSearchProps('doc_name'),
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            align: 'center',
            editable: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            editable: true,
            filters: ['Waiting for approve', 'Approved', 'Rejected', 'Redundant'].map(
                item => ({
                    text: item,
                    value: item,
                }),
            ),
            onFilter: (value, record) => {
                return record.status == value;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            align: 'center',
            render: (text, record) => {
                return record.status == 'Approved' ? (
                    <>
                        <a
                            href={`${process.env.REACT_APP_CDN}admin-documents/supplier/${record.supplier_id}/product-catalogues/${record.doc_name}`}
                        >
                            <BiDownload
                                style={{ fontSize: '20px', color: 'rgb(35, 34, 36)' }}
                            />
                        </a>
                    </>
                ) : record.status == 'Rejected' ? (
                    <>
                        Rejected Reason: <br></br>
                        {record.notes}
                    </>
                ) : (
                    <>None</>
                );
            },
        },
    ];

    return loading ? (
        <div className="overlay-box">
            <ClipLoader
                color={"#fffff"}
                loading={loading}
                css={override}
                size={150}
            />
        </div>

    ) : (
        <Form>
            <FormGroup>
                <Row>
                    <Col style={{ maxWidth: '40rem' }}>
                        <label
                            className="upload-label"
                            style={{
                                height: '13vh',
                                padding: '4px',
                                border: '2px solid #E6E6E6',
                                borderRadius: '32px',
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                        >
                            <Input
                                // style={{ padding: '30%' }}
                                type="file"
                                disabled={
                                    ProductCatalogueField.catalogues.length == 1
                                }
                                onChange={e => {
                                    // console.log(' cata eee', e.target.files);
                                    beforeUploadHandler(e, e.target.files[0]);
                                }}
                                style={{ display: 'none' }}
                                accept=".pdf, .doc,.docx, .xls, .xlsx, .ppt, .pptx"
                            />
                            {/* <Input
                                  // style={{ padding: '30%' }}
                                  type="file"
                                  disabled={
                                    ProductCatalogueField.catalogues.length == 1
                                  }
                                  onChange={e =>
                                    beforeUploadHandler( e.target.files[0] )
                                  }
                                  style={{ display: 'none' }}
                                /> */}
                            <div>
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        margin: '0px 0px 5% 0px',
                                    }}
                                >
                                    Upload Catalogue{' '}
                                </span>

                                <span
                                    style={{
                                        fontSize: '0.9rem',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        margin: '0px 0px 5% 0px',
                                    }}
                                >
                                    {' '}
                                    Click here to browse your files
                                </span>
                            </div>
                            {/* </Input> */}
                        </label>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 0 }}>
                    <Col style={{ marginBottom: 0 }}>
                        <label
                            className="upload-label"
                            style={{
                                color: 'gray',
                            }}
                        >
                            Please only upload PDF or Office file here.
                        </label>
                    </Col>
                </Row>
                {ProductCatalogueField.catalogues.length > 0 ? (
                    <>
                        <Row>
                            <Col md="5">
                                <label style={{ padding: '0px 0px 0px 8px' }}>
                                    Catalogue Name
                                </label>
                            </Col>
                            <Col md="5">
                                <label style={{ padding: '0px 0px 0px 8px' }}>
                                    {' '}
                                    Comments{' '}
                                </label>
                            </Col>
                        </Row>
                        {ProductCatalogueField.catalogues.map(
                            (item, index) => (
                                <Row>
                                    <Col md="5">
                                        <Input
                                            type="text"
                                            placeHolder="Product Sheet"
                                            name="document_name"
                                            disabled
                                            value={
                                                ProductCatalogueField.catalogues[
                                                    index
                                                ].document.name
                                            }
                                            className="admin-input"
                                        />
                                    </Col>
                                    <Col md="5">
                                        <Input
                                            type="text"
                                            className="admin-input"
                                            // style={{ marginTop: '7px' }}
                                            name="comments"
                                            onChange={e => {
                                                ProductCatalogueField.catalogues[
                                                    index
                                                ].comments = e.target.value;
                                                setProductCatalogueField({
                                                    ...ProductCatalogueField,
                                                    catalogues: [
                                                        ...ProductCatalogueField.catalogues,
                                                    ],
                                                });
                                            }}
                                            value={
                                                ProductCatalogueField.catalogues[
                                                    index
                                                ].comments
                                            }
                                        />
                                    </Col>
                                </Row>
                            ),
                        )}
                    </>
                ) : null}
                <Row style={{ marginTop: '20px' }}>
                    <Col lg="8" md="8">
                        <Button
                            variant="outlined"
                            onClick={e => {
                                submitDoc(e, 'Catalogue');
                            }}
                            color="primary"
                            disabled={
                                ProductCatalogueField.catalogues[0]
                                    ? false
                                    : true
                            }
                        >
                            {' '}
                            Submit
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={e => {
                                cancelDoc(e, 'Catalogue');
                            }}
                            color="primary"
                            disabled={
                                ProductCatalogueField.catalogues[0]
                                    ? false
                                    : true
                            }
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="antdTable">
                            {tableData ? (
                                <Table
                                    columns={columns}
                                    // scroll={{ x: 1500 }}
                                    dataSource={tableData}
                                    onChange={(
                                        pagination,
                                        filters,
                                        sorter,
                                        currentPageData,
                                    ) =>
                                        filter(currentPageData.currentDataSource)
                                    }
                                    pagination={{
                                        total: filteredData.length,
                                        showTotal: total =>
                                            `Total ${total} items`,
                                        pageSizeOptions: [
                                            '10',
                                            '50',
                                            '100',
                                            '200',
                                            '500',
                                        ],
                                    }}
                                // bordered
                                />
                            ) : null}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <img
                            src={greyBanner}
                            className="greyBanner"
                        ></img>
                    </Col>
                </Row>
            </FormGroup>
        </Form>
    )

}

export default SubmitCatalogue