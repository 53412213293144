import PropTypes from 'prop-types';
import React from 'react';

import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import EmailValidator from 'email-validator';
import Amplify, { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import logo from 'assets/img/logo/logo.png';
import { useState } from 'react';
import axios from 'axios';
const amplifyConfig = {
  Auth: {
    region: 'ap-southeast-2',
    userPoolId: `${process.env.REACT_APP_COGNITO_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_CLIENT_KEY}`,
    authenticationFlowType: 'USER_SRP_AUTH',
  },
};
class AuthForm extends React.Component {
  // const [passwordShown, setPasswordShown] = React.useState(false);
  //  togglePassword = () => {
  //   setPasswordShown(!passwordShown);
  //   };

  componentDidMount() {
    // this.checkStorage();
    Amplify.configure(amplifyConfig);
    // console.log(objectOf(localStorage.getItem('remeberMe')))
    console.log(localStorage.getItem('username'));
    console.log(localStorage.getItem('password'));
    if (localStorage.getItem('remeberMe') == 'true') {
      console.log(localStorage.getItem('remeberMe'));
      console.log(localStorage.getItem('username'));
      this.setState({ email: localStorage.getItem('username') });
      this.setState({ password: localStorage.getItem('password') });
      // this.password = localStorage.getItem('password');
      // console.log(this.state.password)
      console.log(this.state.email);
      console.log(this.state.password);
      // console.log(this.password)
    } else {
      this.email = '';
      this.password = '';
    }
  }
  constructor() {
    super();

    this.state = {
      checked: false,
      email: '',
      isLogin: true,
      usernamee: '',
      code: '',
      newPassword: '',
      password: '',
      auth: '',
      remember: '',
      isNewPassword: false,
      isForgotPwd: false,
      ismessage: false,
      forgotMsgCode: false,
      isForgotPwdSub: false,
      showPwd: false,
    };

    this.handleChange = this.handleChange.bind(this);
    // this.state = {email: '',password :''};
    // this.handleChange1 = this.handleChange1.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.state = {
    //   auth: any,
    // }
  }

  // setTimeout(
  // () => const cognitoUser = Auth.currentAuthenticatedUser();
  // const currentSession = Auth.currentSession();
  // cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
  //   console.log('session', err, session);
  //   const { idToken, refreshToken, accessToken } = session;
  //   // do whatever you want to do now :)
  // });,
  // 3000
  // );
  togglePwd(e) {
    e.preventDefault();
    console.log(this.state.showPwd);
    this.setState({ showPwd: !this.state.showPwd });
    console.log(this.state.showPwd);
  }
  remeberMe(e) {
    // console.log(e);
    const chk = e.target.checked;
    localStorage.setItem('remeberMe', chk);
    console.log(chk);
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    this.textvalue = event.target.value;
    this.textname = event.target.name;
    // if (event.target.name = 'email') {
    //   console.log(this.state.email);
    // }
    if (event.target.name == 'usernamee') {
      this.uservalue = event.target.value;
      this.username = event.target.name;
    }
    if (event.target.name == 'remember') {
      this.remember = event.target.checked;
    }

    if (event.target.name == 'resetpassword') {
      this.firstTimePassReset = event.target.value;
      let firstTimePassReset = event.target.value;
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      if (firstTimePassReset.length > 7) {
        document.querySelector('.lengthCheck').style.color = 'green';
        document.querySelector('.lengthCheck').style.fontWeight = 'bold';
        this.lengthCheck = true;
        if (
          document.querySelector('.lengthCheck').innerHTML.includes('✓') ==
          false
        ) {
          document.querySelector('.lengthCheck').innerHTML += '  ✓';
        }
      } else {
        document.querySelector('.lengthCheck').style.color = 'black';
        document.querySelector('.lengthCheck').style.fontWeight = 'normal';
        document.querySelector('.lengthCheck').innerHTML = document
          .querySelector('.lengthCheck')
          .innerHTML.replace('✓', '');
        this.lengthCheck = false;
      }

      if (specialChars.test(firstTimePassReset) == true) {
        document.querySelector('.specialCheck').style.color = 'green';
        document.querySelector('.specialCheck').style.fontWeight = 'bold';
        this.specialCheck = true;
        if (
          document.querySelector('.specialCheck').innerHTML.includes('✓') ==
          false
        ) {
          document.querySelector('.specialCheck').innerHTML += '  ✓';
        }
      } else {
        document.querySelector('.specialCheck').style.color = 'black';
        document.querySelector('.specialCheck').innerHTML = document
          .querySelector('.specialCheck')
          .innerHTML.replace('✓', '');
        document.querySelector('.specialCheck').style.fontWeight = 'normal';
        this.specialCheck = false;
      }

      if (firstTimePassReset.toLowerCase() == firstTimePassReset) {
        document.querySelector('.uppercaseCheck').style.color = 'black';
        document.querySelector('.uppercaseCheck').style.fontWeight = 'normal';
        document.querySelector('.uppercaseCheck').innerHTML = document
          .querySelector('.uppercaseCheck')
          .innerHTML.replace('✓', '');
        this.uppercaseCheck = false;
      } else {
        document.querySelector('.uppercaseCheck').style.color = 'green';
        document.querySelector('.uppercaseCheck').style.fontWeight = 'bold';
        this.uppercaseCheck = true;
        if (
          document.querySelector('.uppercaseCheck').innerHTML.includes('✓') ==
          false
        ) {
          document.querySelector('.uppercaseCheck').innerHTML += '  ✓';
        }
      }

      if (/\d/.test(firstTimePassReset) == true) {
        document.querySelector('.numberCheck').style.color = 'green';
        document.querySelector('.numberCheck').style.fontWeight = 'bold';
        this.numberCheck = true;
        if (
          document.querySelector('.numberCheck').innerHTML.includes('✓') ==
          false
        ) {
          document.querySelector('.numberCheck').innerHTML += '  ✓';
        }
      } else {
        document.querySelector('.numberCheck').style.color = 'black';
        document.querySelector('.numberCheck').style.fontWeight = 'normal';
        document.querySelector('.numberCheck').innerHTML = document
          .querySelector('.numberCheck')
          .innerHTML.replace('✓', '');
        this.numberCheck = false;
      }
    }

    if (event.target.name == 'confirmpassword') {
      console.log(event.target.value);
      if (this.firstTimePassReset == event.target.value) {
        document.querySelector('.matchCheck').innerHTML =
          'Password match successful';
        document.querySelector('.matchCheck').style.color = 'green';
        document.querySelector('.matchCheck').style.fontWeight = 'bold';
        if (
          this.numberCheck == true &&
          this.uppercaseCheck == true &&
          this.specialCheck == true &&
          this.lengthCheck == true
        ) {
          document.querySelector('.resetPassButton').classList.remove('hidden');
        } else {
          document.querySelector('.resetPassButton').classList.add('hidden');
        }
      } else {
        document.querySelector('.matchCheck').innerHTML =
          'Password does not match';
        document.querySelector('.matchCheck').style.color = 'red';
        document.querySelector('.matchCheck').style.fontWeight = 'normal';
        document.querySelector('.resetPassButton').classList.add('hidden');
      }
    }

    if (event.target.name == 'code') {
      this.code = event.target.value;
    }
    if (event.target.name == 'newpasswordd') {
      this.newPassword = event.target.value;
    }

    if (event.target.name == 'password') {
      if (event.target.charCode == 13) {
        console.log(' oooooo');
      }
    }
    // event.target
    // this.setState({
    // 	checked: !this.state.checked
    // })
  }
  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  // get isSignup() {
  //   return this.props.authState === STATE_SIGNUP;
  // }

  // changeAuthState = authState => event => {
  //   event.preventDefault();

  //   this.props.onChangeAuthState(authState);
  // };
  // handleKeypress = e => {
  //   if (e.keyCode === 13) {
  //     this.handleSubmit();
  //   }
  // };
  handleSubmit = event => {
    console.log('event==>', event);

    if (!this.state.isNewPassword) {
      document.querySelector('.bg-gradient-theme-left').classList.add('hidden');
      document.querySelector('.loading-container').classList.remove('hidden');
      const { email, password } = this.state;
      // validate Email
      if (!email) {
        document
          .querySelector('.bg-gradient-theme-left')
          .classList.remove('hidden');
        document.querySelector('.loading-container').classList.add('hidden');
        return;
      }

      // validate password
      if (!password) {
        document
          .querySelector('.bg-gradient-theme-left')
          .classList.remove('hidden');
        document.querySelector('.loading-container').classList.add('hidden');
        return;
      }
      if (email == '') {
        alert('Please enter username');
        return;
      }
      if (password == '') {
        alert('Please enter password');
        return;
      }
      this.amplifySignIn(email, password);
    } else {
      console.log('reset pw for first time login', this.state.isLogin);
      const { auth } = this.state;
      console.log(auth);
      console.log(this.textname);
      const passwvalue = this.textvalue;
      this.setState({ isLogin: false });
      // TODO: remove this after testing the loading issue
      // setTimeout(() => {
      //   if (
      //     'rep' ==
      //     'rep'
      //   ) {
      //     this.messagee = 'User Does not exist';
      //     this.setState({ showResults: this.messagee });
      //     return;
      //   } else {
      //     // if (response.challengeParam.userAttributes['custom:user-type'] == 'no') {
      //     //   this.props.history.push('/adhoc');
      //     // }
      //     // else {
      //     this.props.history.push('/dashboard');
      //     // }
      //   }

      // }, 2000);
      Auth.completeNewPassword(auth, passwvalue)
        .then(response => {
          console.log('ResetPassword', response);

          sessionStorage.setItem(
            'idtoken',
            response.signInUserSession.idToken.jwtToken,
          );
          // sessionStorage.setItem(
          //   'user_type',
          //   response.attributes['custom:user-type'],
          // );
          localStorage.setItem('username', response.username);
          localStorage.setItem('password', response.userpwd);

          const curUserRole =
            response.signInUserSession.idToken.payload['cognito:groups'][0];

          localStorage.setItem(
            'userRole',
            response.signInUserSession.idToken.payload['cognito:groups'][0],
          );
          if (
            response.signInUserSession.idToken.payload['cognito:groups'][0] ==
            'rep'
          ) {
            this.messagee = 'User Does not exist';
            this.setState({ showResults: this.messagee });
            return;
          } else {
            // if (response.challengeParam.userAttributes['custom:user-type'] == 'no') {
            //   this.props.history.push('/adhoc');
            // }
            // else {

            // save manager state information to see tasks in manager's state
            if (curUserRole == 'manager') {
              const tokenn = response.signInUserSession.idToken.jwtToken;
              this.storeManagerState(response.username, tokenn);
            }
            this.props.history.push('/dashboard');
            // }
          }
        })
        .catch(err => {
          console.log('completeNewPassword fail', err);
        });
    }
  };

  // configAxio = ( jwtToken ) => {
  //   axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
  // };
  getCognitoIdentityCredentials = async idToken => {
    // const region = 'ap-southeast-2';
    //  console.log(localStorage.getItem('idtoken'));
    // const idToken = localStorage.getItem('idtoken');
    AWS.config.region = 'ap-southeast-2';
    const sts = new AWS.STS();
    var cognitoidentity = new AWS.CognitoIdentity();
    var params = {
      IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID /* required */,
      AccountId: process.env.REACT_APP_ACCOUNT,
      Logins: {
        ['cognito-idp.ap-southeast-2.amazonaws.com/' +
        process.env.REACT_APP_COGNITO_ID]: idToken,
        /* '<IdentityProviderName>': ... */
      },
    };

    cognitoidentity.getId(params, function (err, data) {
      if (err) console.log(err, err.stack); // an error occurred
      else {
        console.log(data);
        var params = {
          IdentityId: data.IdentityId /* required */,
          Logins: {
            ['cognito-idp.ap-southeast-2.amazonaws.com/' +
            process.env.REACT_APP_COGNITO_ID]: idToken,
            /* '<IdentityProviderName>': ... */
          },
        };
        cognitoidentity.getOpenIdToken(params, async function (err, data) {
          if (err) console.log(err, err.stack); // an error occurred
          else {
            console.log(data); // successful response
            const stsParams = {
              DurationSeconds: 43200,
              // optional / intersection: Policy: "{\"Version\":\" etc.
              RoleArn:
                'arn:aws:iam::' +
                process.env.REACT_APP_ACCOUNT +
                ':role/gforce-CognitoAuthorizedRole',
              RoleSessionName: 'gforceSession', // probably can be anything that's not too long
              WebIdentityToken: data.Token,
            };

            // optional / intersection: Policy: "{\"Version\":\" etc.            RoleArn: "arn:aws:iam::917249922596:role/football-20181219132837-authRole",            RoleSessionName: userEmail, // probably can be anything that's not too long            WebIdentityToken: openIdToken.Token,        };
            // // @ts-ignore -- code copied from API; maybe types for this method are wrong
            // setTimeout(() => {
            const credentials = await sts
              .assumeRoleWithWebIdentity(stsParams)
              .promise();
            console.log(credentials.Credentials);
            sessionStorage.setItem(
              'AccessKeyId',
              credentials.Credentials.AccessKeyId,
            );
            sessionStorage.setItem(
              'AccessKeySec',
              credentials.Credentials.SecretAccessKey,
            );
            sessionStorage.setItem(
              'sessionToken',
              credentials.Credentials.SessionToken,
            );
            // }, 3000);
          }
        });
      } // successful response
    });

    // var loginMap = {};
    // loginMap[
    //   'cognito-idp.' +
    //     'ap-southeast-2' +
    //     '.amazonaws.com/' +
    //     `${process.env.REACT_APP_COGNITO_ID}`
    // ] = idToken;
    // }

    // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    //   IdentityPoolId: 'ap-southeast-2:74ff084e-1c35-4a40-a023-3d8463095bd2',
    //   Logins: loginMap,
    //   // CustomRoleArn: 'arn:aws:iam::346052443073:role/gforce-CognitoUnauthorizedRole'
    // });
    // console.log(idToken);
    // console.log(loginMap);
    // const sts = new AWS.STS();
    // const stsParams = {
    //   DurationSeconds: 43200,
    //   // optional / intersection: Policy: "{\"Version\":\" etc.
    //   RoleArn: 'arn:aws:iam::346052443073:role/gforce-CognitoAuthorizedRole',
    //   RoleSessionName: 'preetssaession', // probably can be anything that's not too long
    //   WebIdentityToken: idToken,
    // };

    // optional / intersection: Policy: "{\"Version\":\" etc.            RoleArn: "arn:aws:iam::917249922596:role/football-20181219132837-authRole",            RoleSessionName: userEmail, // probably can be anything that's not too long            WebIdentityToken: openIdToken.Token,        };
    // // @ts-ignore -- code copied from API; maybe types for this method are wrong
    // const credentials = sts.assumeRoleWithWebIdentity(stsParams).promise();

    // console.log(AWS.config.credentials);
    // AWS.config.credentials.clearCachedId();

    // AWS.config.credentials.get(function (err) {
    //   if (err) {
    //     console.log(err.message);
    //   } else {
    //   }
    // });
  };

  automaticTimeout = time => {
    // console.log('okkqw', time - 240000, 60 * 60 * 3000);
    localStorage.setItem('expiry', time);
    console.log('okkqw', localStorage.getItem('expiry'));
    this.timer = setTimeout(() => {
      // eslint-disable-next-line no-alert
      alert('Session time out, please sign in again');
      sessionStorage.clear();
      localStorage.clear();
      // // localStorage.removeItem( "accessLevel" );
      // // reset page location
      // // console.log('logout succesfully');
      document.location.replace('/');
      // }, 1000);
    }, time - 60 * 60 * 4000);
  };

  storeManagerState = (username, idToken) => {
    // console.log('storeManagerState, ', idToken, username);

    axios({
      method: 'GET',
      headers: {
        Authorization: idToken,
      },
      url:
        process.env.REACT_APP_API_BASE +
        'admin-portal/rep-details?username=' +
        username,
    })
      .then(response => {
        const admindata = response.data.body;

        // console.log('storeManagerState for manager', response);
        // console.log('storeManagerState for manager', admindata);
        const curManager = admindata.filter(
          item => item.username == username,
        )[0];
        // console.log('storeManagerState, ', curManager);
        localStorage.setItem('userState', curManager.state);
      })
      .catch(error => {
        console.log('get manager detail error11...', error);
      });
  };

  onReady = response => {
    // console.log('resss', response);
    // Auth.currentCredentials()
    //   .then(credentials => {
    //     console.log(credentials)
    //     // const route53 = new Route53({
    //     //   apiVersion: '2013-04-01',
    //     //   credentials: Auth.essentialCredentials(credentials)
    //     // });
    //   })

    const { signInUserSession, username } = response;
    const { idToken } = signInUserSession;
    const { jwtToken, payload } = idToken;
    const { exp } = payload;
    // const difference = exp * 1000 - new Date().getTime();exp * 1000 + new Date().getTime()
    const difference = exp * 1000 - new Date().getTime();
    console.log('difference', difference);
    this.automaticTimeout(difference);
    // this.configAxio( jwtToken );
    const tokenn = response.signInUserSession.idToken.jwtToken;
    sessionStorage.setItem(
      'idtoken',
      response.signInUserSession.idToken.jwtToken,
    );
    this.getCognitoIdentityCredentials(tokenn);
    sessionStorage.setItem(
      'accessToken',
      response.signInUserSession.accessToken.jwtToken,
    );
    // console.log(
    //   response.signInUserSession.idToken.payload['cognito:groups'][0],
    // );
    sessionStorage.setItem(
      'user_type',
      response.attributes['custom:user-type'],
    );
    sessionStorage.setItem('username', response.username);
    // sessionStorage.setItem('password', password);
    sessionStorage.setItem('sub', response.attributes.sub);
    // await localStorage.SetItemAsync<string>('userRole', response.signInUserSession.idToken.payload["cognito:groups"][0] ?? String.Empty);

    const curUserRole =
      response.signInUserSession.idToken.payload['cognito:groups'][0];

    localStorage.setItem(
      'userRole',
      response.signInUserSession.idToken.payload['cognito:groups'][0],
    );
    // console.log(this.state.remember);

    // api to get user's state if it is a manager
    if (curUserRole == 'manager') {
      // console.log(' mamager login get the state information');
      this.storeManagerState(response.username, tokenn);
    }

    if (
      response.signInUserSession.idToken.payload['cognito:groups'][0] == 'rep'
    ) {
      this.messagee = 'User Does not exist';
      this.setState({ showResults: this.messagee });
      return;
    } else {
      // if (response.attributes['custom:user-type'] == 'no') {
      //   this.props.history.push('/adhoc');
      // }
      // else {
      this.props.history.push('/dashboard');
      // }
    }
    // this.props.history.push('/dashboard');
  };
  amplifySignIn = (email, password) => {
    localStorage.setItem('password', password);
    localStorage.setItem('username', email);
    // console.log(AWS.config.credentials);
    if (email == '') {
      alert('please enter username');
      return;
    }
    if (password == '') {
      alert('please enter password');
      return;
    }
    Auth.signIn(email.trim(), password.trim())
      .then(response => {
        //  if(response.session){

        // console.log( response.signInUserSession.idToken.jwtToken);
        //  }
        this.setState({
          auth: response,
        });
        // switch ( response.challengeName ) {
        //   case  response.challengeName: {
        //     this.setState({isNewPassword: true})
        //     break;
        //   }
        //   case undefined: {
        //     this.onReady( response );
        //     break;
        //   }
        //   default: {
        //     console.log( 'Challenge', response.challengeName );
        //   }
        // }
        // switch ( response.challengeName ) {
        //   case undefined: {
        //     const { signInUserSession, username } = response;
        //     sessionStorage.setItem('idtoken', response.signInUserSession.idToken.jwtToken)

        //     const { idToken } = signInUserSession;
        //     const { jwtToken, payload } = idToken;
        //     // console.log("urllll",`${this.state.redirectUrl}?state=${this.state.stat}&access_token=${jwtToken}`)
        //     this.props.history.push('/dashboard');
        //     break;
        //   }
        //   default: {
        //     console.log( 'Challenge', response.challengeName );
        //   }
        // }
        if (response.challengeName) {
          // console.log('inside reset');

          this.setState({ isNewPassword: true });
        } else {
          // console.log('inside Dashboard');
          this.onReady(response);
          // sessionStorage.setItem('idtoken', response.signInUserSession.idToken.jwtToken)
          // this.props.histo ßry.push('/dashboard');
        }
      })
      .catch(e => {
        document
          .querySelector('.bg-gradient-theme-left')
          .classList.remove('hidden');
        document.querySelector('.loading-container').classList.add('hidden');
        console.log(e);
        this.messagee = e.message;
        this.setState({ showResults: e.message });
      });
  };
  forgotPwd = () => {
    this.setState({ isForgotPwd: true, isNewPassword: false, isLogin: false });
    console.log('in forgot');
  };
  forgotPassword = () => {
    const passwvalue11 = this.uservalue;

    // const passusername1 = this.username;
    if (passwvalue11 == undefined) {
      return;
    }
    // if (!EmailValidator.validate(passwvalue11)) {
    //   this.setState({ ismessage: true });
    //   console.log('in user name');
    //   return;
    // }
    else {
      this.setState({ isForgotPwdSub: true });

      this.setState({ ismessage: false, forgotMsgCode: true });

      // return;
    }
    // console.log(passwvalue11);
    // return;
    Auth.forgotPassword(passwvalue11)
      .then(data => console.log(data))
      .catch(err => console.log(err));
  };
  forgotPasswordSub = () => {
    const code = this.code;
    const newPwd = this.newPassword;
    const useremail = this.uservalue;
    // console.log(useremail);
    // console.log(code);
    // console.log(newPwd);
    // return;
    Auth.forgotPasswordSubmit(useremail, code, newPwd)
      .then(data1 =>
        this.setState({
          isLogin: true,
          isForgotPwd: false,
          isForgotPwdSub: false,
          forgotMsgCode: false,
          isNewPassword: false,
        }),
      )
      .catch(err => console.log(err));
  };

  // }
  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    // if (!buttonText && this.isSignup) {
    //   return 'Signup';
    // }
    if (!buttonText && this.isResetPwd) {
      return 'Reset Password';
    }
    return buttonText;
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameLabelUI,
      usernameLabelFN,
      usernameLabelLN,
      usernameInputProps,
      usernameInputPropsUI,
      usernameInputFNProps,
      usernameInputLNProps,
      passwordLabel,
      titleInputProps,
      postInputProps,
      postcodeLabel,
      cityLabel,
      cityInputProps,
      streetLabel,
      streetInputProps,
      titleLabel,
      memhsipLabel,
      membshipInputProps,
      phoneLabel,
      phoneInputProps,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      countryLabel,
      countryInputProps,
      children,
      onLogoClick,
    } = this.props;

    const hidden = this.state.checked ? '' : 'hidden';

    return (
      <div style={{ position: 'relative' }}>
        <div style={{ textAlign: 'center' }}>
          <img
            src={logo}
            width="121.69px"
            height="50.6px"
            alt=""
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '6%',
            }}
          />{' '}
        </div>
        <div style={{ color: 'red', paddingBottom: '4%', textAlign: 'center' }}>
          {this.state.showResults ? <span>{this.messagee}</span> : null}
          {/* {this.state.ismessage ? (
            <p style={{ color: 'red', fontSize: '12px' }}>
              Please enter a valid email address{' '}
            </p>
          ) : null} */}
        </div>

        <Form>
          {this.state.isLogin && !this.state.isNewPassword && (
            <FormGroup className="Colbl">
              <Label for={usernameLabelUI}>{usernameLabelUI}</Label>
              <Input
                placeholder="Username"
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </FormGroup>
          )}
          {this.state.isLogin && !this.state.isNewPassword && (
            <FormGroup className="Colbl" style={{ position: 'relative' }}>
              <Label for={passwordLabel}>{passwordLabel}</Label>
              <Input
                placeholder="Password"
                type={this.state.showPwd == true ? 'text' : 'password'}
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                onKeyUp={e => {
                  if (e.keyCode === 13) {
                    this.handleSubmit(e);
                  }
                }}
              />
              <span
                onClick={e => {
                  this.togglePwd(e);
                }}
                style={{
                  marginRight: '0px',
                  marginLeft: '5px',
                  backgroundColor: 'transparent',
                  // float: 'right',
                  position: 'absolute',
                  top: '40px',
                  right: '10px',
                }}
              >
                <svg
                  height="18"
                  width="18"
                  fill="#000000"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 3"
                  viewBox="0 0 24 24"
                  x="0px"
                  y="0px"
                >
                  <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                  <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
                </svg>{' '}
              </span>
            </FormGroup>
          )}
          {!this.state.isNewPassword && !this.state.isForgotPwd && (
            <>
              {/* <p style={{fontSize:'12px'}}>Password must be a minimum of 8 characters and contain a capital letter and alphanumeric character​</p><br/> */}

              <FormGroup className="Colbl" check>
                <Label
                  check
                  style={{ fontSize: '12px' }}
                  className="remember-me"
                >
                  <Input
                    type="checkbox"
                    style={{ marginTop: '0' }}
                    name="remember"
                    onChange={this.remeberMe}
                  />
                  {'Remember me'}
                </Label>
                <a
                  onClick={this.forgotPwd}
                  className="Forpas"
                  style={{
                    fontSize: '12px',
                    cursor: 'pointer',
                    paddingTop: '5px',
                  }}
                >
                  Forgot Password ?
                </a>
              </FormGroup>
              <br />
            </>
          )}
          {this.state.isNewPassword && !this.state.isForgotPwd && (
            <FormGroup className="Colbl">
              <Label for={passwordLabel}>{passwordLabel}</Label>
              <Input
                type="password"
                name="resetpassword"
                onChange={this.handleChange}
              />
              <br />
              <span className="lengthCheck">
                Password contains at least 8 characters.
              </span>
              <br />
              <span className="specialCheck">
                Password contains at least 1 special character.{' '}
              </span>
              <br />
              <span className="uppercaseCheck">
                Password contains at least 1 upper case character.
              </span>
              <br />
              <span className="numberCheck">
                Password contains at least 1 number.
              </span>
              <br />
            </FormGroup>
          )}
          {this.state.isNewPassword && !this.state.isForgotPwd && (
            <FormGroup className="Colbl">
              <Label for={passwordLabel}>Confirm Password </Label>
              <Input
                type="password"
                name="confirmpassword"
                onChange={this.handleChange}
              />
              <br />
              <span className="matchCheck">Match the password to confirm</span>
              <br />

              {/* <p style={{ fontSize: '12px' }}>
                Password must be a minimum of 18 characters and contain a capital
                letter and alphanumeric character
              </p> */}
              <br />
            </FormGroup>
          )}
          {this.state.isForgotPwd && (
            <FormGroup className="Colbl">
              <Label for={usernameLabelUI}>{usernameLabelUI}</Label>
              <Input
                type="email"
                name="usernamee"
                onChange={this.handleChange}
              />
            </FormGroup>
          )}
          {this.state.forgotMsgCode && (
            <>
              <FormGroup className="Colbl">
                <Label>Enter Securtiy Code</Label>
                <Input type="text" name="code" onChange={this.handleChange} />
              </FormGroup>
              <FormGroup className="Colbl">
                <Label>Enter New Password</Label>
                <Input
                  type="password"
                  name="newpasswordd"
                  onChange={this.handleChange}
                />
                <br />
                <p style={{ fontSize: '12px' }}>
                  Password must be a minimum of 8 characters and contain a
                  capital letter and alphanumeric character
                </p>
                <br />
              </FormGroup>
            </>
          )}
          {/* <hr /> */}
          {!this.state.isNewPassword && !this.state.isForgotPwd && (
            <Button
              size="lg"
              className="bg-gradient-theme-left border-0"
              block
              onClick={this.handleSubmit}
              // onKeyPress={this.handleKeypress}
            >
              {this.renderButtonText()}
            </Button>
          )}
          {this.state.isNewPassword && !this.state.isForgotPwd && (
            <Button
              size="lg"
              className="bg-gradient-theme-left border-0 resetPassButton hidden"
              block
              onClick={this.handleSubmit}
              // onKeyPress={this.handleKeypress}
            >
              Reset Password
            </Button>
          )}
          {this.state.isForgotPwd && !this.state.isForgotPwdSub && (
            <Button
              size="lg"
              className="bg-gradient-theme-left border-0"
              block
              onClick={this.forgotPassword}
            >
              Submit
            </Button>
          )}
          {this.state.isForgotPwdSub && (
            <Button
              size="lg"
              className="bg-gradient-theme-left border-0"
              block
              onClick={this.forgotPasswordSub}
            >
              Submit code
            </Button>
          )}
          {this.isLogin == true && this.state.isNewPassword == false ? (
            <div className="loading-container hidden">
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div
                className=""
                style={{
                  color: '#D71920',
                  float: 'right',
                  margin: '28px 20px 0px 0px',
                }}
              >
                Loading
              </div>
            </div>
          ) : null}
          {children}
        </Form>
      </div>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
// export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameLabelUI: PropTypes.string,
  usernameInputProps: PropTypes.object,
  usernameInputFNProps: PropTypes.object,
  usernameInputLNProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: '/',
  showLogo: true,
  usernameLabelUI: 'Username',
  usernameInputPropsUI: {
    type: 'email',
    name: 'userid',
    value: 'userid',
  },

  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    name: 'userpasswrd',
    value: 'userpwd',
  },
  onLogoClick: () => {},
};

export default AuthForm;
