
import Page from 'components/Page';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    // Table,
} from 'reactstrap';
import Upload from 'rc-upload';
import { BiUpload } from 'react-icons/bi';
import AWS from 'aws-sdk';
import axios from 'axios';
import { Table, Tag, Space, Popconfirm } from 'antd';
import JSZip from 'jszip';
import { extractInfo, generateFilterList } from './AdminProductCatalogue.js';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { BiDownload } from 'react-icons/bi';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const AdminUploadRangelist = (props) => {

    const { renderSupplierOptions, setUploadCataHint, uploadCataHint, hasChosen, setHasChosen, supplierList } = props


    const [rangelistField, setRangelistField] = React.useState({
        supplier_id: '',
        // supplier_name: '',
        rangelist: '',
        comments: '',
    });
    const [fileName, setFileName] = useState(null);

    const [zipFile, setZipFile] = React.useState(null);

    const [selectedList, setSelectedList] = React.useState([]);

    const [filteredData, setFilteredData] = useState([]);

    const [finalData, setFinalData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const [planoField, setPlanoField] = useState([
        {
            planogram_name: '',
            planogram_comment: '',
            range_list: '',
            supplier_id: '',
        },
    ]);

    const [filterLists, setFilterLists] = useState({
        Department: [],
        'Sub Department': [],
        'Item Retail Grade': [],
        State: ['Live', 'Archived', 'CSO', 'Restricted', 'Promotion'],
    });

    const enabled = selectedList.length > 0;

    useEffect(() => {
        window.scrollTo(0, 0);
        getAllProductsRangeList();
        // getItemImages();
    }, []);

    const getAllProductsRangeList = () => {

        setLoading(true);

        axios({
            method: 'GET',
            headers: {
                Authorization: sessionStorage.getItem('idtoken'),
            },
            url: process.env.REACT_APP_API_BASE + 'rangelist/get-all-products',
        })
            .then(response => {
                // console.log('get the all products', response);
                const result = response.data.items

                const allDepartments = extractInfo(result, 'Department');
                const allSubDepartments = extractInfo(
                    result,
                    'Sub Department',
                );

                setFinalData(result);
                filter(result);
                // setFilteredData(result);
                setLoading(false)

                setFilterLists({
                    Department: generateFilterList(allDepartments),
                    'Sub Department': generateFilterList(allSubDepartments),
                    'Item Retail Grade': generateFilterList(
                        Array.from(Array(6))
                            .map((e, i) => i + 65)
                            .map(x => String.fromCharCode(x)),
                    ),
                    State: generateFilterList([
                        'Live',
                        'Archived',
                        'CSO',
                        'Restricted',
                        'Promotion',
                        'Closed',
                        'Suspended',
                        'Quit(S3)',
                    ]),
                });
            }).catch(error => {
                console.log('doc for supplier fail...', error);
                setLoading(false);
            })
    };

    const rangelistInputHandler = e => {
        setRangelistField({
            ...rangelistField,
            [e.target.name]: e.target.value,
            rangelist: '',
        });
        setFileName(null);
        setHasChosen({
            ...hasChosen,
            rangelistChose: false,
        });
    };

    const planoModalandler = e => {
        setPlanoField({ ...planoField, [e.target.name]: e.target.value });
        if (e.target.name == 'planogram_name') {
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        // this.setState({
        //   searchText: selectedKeys[0],
        //   searchedColumn: dataIndex,
        // });
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    // ref={node => {
                    //   this.searchInput = node;
                    // }}
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => {
                        // console.log('cahngeddd', e.target.value);
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                    }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    {/* <Button
                onClick={() =>
                  handleReset(clearFilters, confirm, selectedKeys, setSelectedKeys)
                }
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button> */}
                    {/* <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
                  // this.setState({
                  //   searchText: selectedKeys[0],
                  //   searchedColumn: dataIndex,
                  // });
                }}
              >
                Filter
              </Button> */}
                </Space>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : '',
        // TODO: if in future, no need, remove this
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => {
                    // console.log(typeof searchInput.current, searchInput.current);
                    // searchInput && searchInput.current.select();
                }, 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const planoColumns = [
        {
            title: 'Item No.',
            dataIndex: 'Item',
            align: 'center',
            // key: 'item',
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier_name',
            align: 'center',
            key: 'supplier_name',
        },
        {
            title: 'Department',
            dataIndex: 'Department',
            align: 'center',
            key: 'Department',
        },
        {
            title: 'Sub Department',
            dataIndex: 'Sub Department',
            align: 'center',
            key: 'Sub Department',
        },
        {
            title: 'Item Name',
            dataIndex: 'ItemName',
            align: 'center',
            key: 'ItemName',
        },
    ];

    const columns = [
        {
            title: 'Item No.',
            dataIndex: 'Item',
            align: 'center',
            key: 'Item',
            ...getColumnSearchProps('Item'),
            // sorter: (a, b) => a.Item.length - b.Item.length,
            // sortOrder: sortedInfo.columnKey === 'Item' && sortedInfo.order,
            // ...getColumnSearchProps('Item'),
            width: 130,
        },
        {
            title: 'Supplier ID',
            dataIndex: 'supplier_id',
            align: 'center',
            key: 'supplier_id',
            width: 80,
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier_name',
            align: 'center',
            key: 'supplier_name',
            filters:
                Array.isArray(supplierList) &&
                supplierList.map(item => ({
                    text: item.full_company_name,
                    value: item.full_company_name,
                })),
            // filteredValue: (filterInfo && filterInfo.supplier_name) || null,
            onFilter: (value, record) => {
                // console.log('vvv', value, record);
                return record.supplier_name == value;
            },
            filterSearch: true,
            width: 130,
        },
        {
            title: 'Department',
            dataIndex: 'Department',
            align: 'center',
            key: 'Department',
            filters: filterLists.Department,
            filterSearch: true,
            onFilter: (value, record) => {
                return record.Department == value;
            },
            width: 150,
        },
        {
            title: 'Sub Department',
            dataIndex: 'Sub Department',
            align: 'center',
            key: 'Sub Department',
            filters:
                Array.isArray(filterLists['Sub Department']) &&
                filterLists['Sub Department'],
            onFilter: (value, record) => {
                return record['Sub Department'] == value;
            },
            width: 150,
        },
        {
            title: 'Item Name',
            dataIndex: 'ItemName',
            align: 'center',
            key: 'ItemName',
            // render: (text, record) => <span>{record['']}</span>,
            ...getColumnSearchProps('ItemName'),
            width: 200,
        },
        {
            title: 'Item Retail Grade',
            dataIndex: 'Item Retail Grade',
            align: 'center',
            key: 'Item Retail Grade',
            filters:
                Array.isArray(filterLists['Item Retail Grade']) &&
                filterLists['Item Retail Grade'],
            onFilter: (value, record) => {
                return record['Item Retail Grade'] == value;
            },
            width: 100,
        },
        {
            title: 'ACT',
            dataIndex: 'ACT',
            align: 'center',
            key: 'ACT',
            filters: Array.isArray(filterLists['State']) && filterLists['State'],
            onFilter: (value, record) => {
                // console.log('vvvvv', value == record.ACT, value, record.ACT);
                return record['ACT'] == value;
            },
            width: 100,
        },
        {
            title: 'NQ',
            dataIndex: 'NQ',
            align: 'center',
            key: 'NQ',
            filters: Array.isArray(filterLists['State']) && filterLists['State'],
            onFilter: (value, record) => {
                return record['NQ'] == value;
            },
            width: 100,
        },
        {
            title: 'NSW',
            dataIndex: 'NSW',
            align: 'center',
            key: 'NSW',
            filters: Array.isArray(filterLists['State']) && filterLists['State'],
            onFilter: (value, record) => {
                return record['NSW'] == value;
            },
            width: 100,
        },
        {
            title: 'NT',
            dataIndex: 'NT',
            align: 'center',
            key: 'NT',
            filters: Array.isArray(filterLists['State']) && filterLists['State'],
            onFilter: (value, record) => {
                return record['NT'] == value;
            },
            width: 100,
        },
        {
            title: 'QLD',
            dataIndex: 'QLD',
            align: 'center',
            key: 'QLD',
            filters: Array.isArray(filterLists['State']) && filterLists['State'],
            onFilter: (value, record) => {
                return record['QLD'] == value;
            },
            width: 100,
        },
        {
            title: 'SA',
            dataIndex: 'SA',
            align: 'center',
            key: 'SA',
            filters: Array.isArray(filterLists['State']) && filterLists['State'],
            onFilter: (value, record) => {
                return record['SA'] == value;
            },
            width: 100,
        },
        {
            title: 'TAS',
            dataIndex: 'TAS',
            align: 'center',
            key: 'TAS',
            filters: Array.isArray(filterLists['State']) && filterLists['State'],
            onFilter: (value, record) => {
                return record['TAS'] == value;
            },
            width: 100,
        },
        {
            title: 'VIC',
            dataIndex: 'VIC',
            align: 'center',
            key: 'VIC',
            filters: Array.isArray(filterLists['State']) && filterLists['State'],
            onFilter: (value, record) => {
                return record['VIC'] == value;
            },
            width: 100,
        },
        {
            title: 'WA',
            dataIndex: 'WA',
            align: 'center',
            key: 'WA',
            filters: Array.isArray(filterLists['State']) && filterLists['State'],
            onFilter: (value, record) => {
                return record['WA'] == value;
            },
            width: 100,
        },
        {
            title: 'GP (%)',
            dataIndex: 'GP %',
            align: 'center',
            key: 'GP',
            render: (text, record, index) => (
                <span>{(record['GP %'] * 100).toFixed(2)} % </span>
            ),
            width: 100,
        },
        {
            title: 'Upload Image',
            dataIndex: 'Upload',
            align: 'center',
            key: 'upload',
            render: (text, record) => (
                <span
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    {/* <UploadAnt
                {...uploadProps}
                onChange={({ file, fileList }) =>
                  uploadImagesForOneItem(fileList, record)
                }
              >
                <BiUpload style={{ fontSize: '18px', color: 'rgb(35, 34, 36)' }} />
              </UploadAnt> */}
                    <BiUpload
                        style={{
                            fontSize: '18px',
                            color: 'rgb(35, 34, 36)',
                            marginBottom: '-30px',
                        }}
                    />
                    <input
                        type="file"
                        style={{ opacity: '0' }}
                        accept="image/png, image/jpeg, image/jpg"
                        multiple
                        onChange={
                            e => {
                                // console.log('filesss', e, e.target.files);
                                const fileList = e.target.files;
                                uploadImagesForOneItem(fileList, record);
                            }
                            // uploadImagesForOneItem(fileList, record)
                        }
                    />
                </span>
            ),
            width: 200,
        },
        {
            title: 'Uploaded Images',
            dataIndex: 'uploadImgs',
            align: 'center',
            width: 220,
            key: 'uploadImgs',
            render: (text, record) => (
                <ul style={{ listStyle: 'none', paddingInlineStart: '0px' }}>
                    {(record.uploadImgs.length > 0) ? (
                        record.uploadImgs.map(item => item.length > 0 ? (
                            <li style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ width: '80%' }}>{item.split('/')[4]}</span>
                                <a href={`${process.env.REACT_APP_CDN}${item}`}>
                                    <BiDownload
                                        style={{ fontSize: '18px', color: 'rgb(35, 34, 36)' }}
                                    />
                                </a>
                                <button
                                    style={{ marginRight: '0' }}
                                    // onClick={e => deleteImageFromDB(item, record)}
                                    onClick={e => deleteImageFromS3(e, item, record)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#000"
                                        className="bi bi-trash"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path
                                            fillRule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                        />
                                    </svg>
                                </button>
                            </li>
                        ) : null)
                    ) : (
                        <li style={{ display: 'flex', alignItems: 'center' }}>No Images</li>
                    )}
                </ul>
            ),
            // render: (text, record) => record.uploadImgs,
        },
    ];

    // rowSelection objects indicates the need for row selection
    const rowSelection = {
        preserveSelectedRowKeys: false,
        onChange: (selectedRowKeys, selectedRows) => {
            // console.log(
            //   `selectedRowKeys: ${selectedRowKeys}`,
            //   'selectedRows: ',
            //   selectedRows,
            // );
        },
        onSelect: (record, selected, selectedRows) => {
            // console.log(record, selected, selectedRows);
            setSelectedList(selectedRows);
            // console.log(record);
            // console.log(selectedList);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            // console.log(selected, selectedRows, changeRows);
            setSelectedList(selectedRows);
            // console.log(selectedList);
        },
    };

    // console.log('finalData',)
    const modifyFinalData = finalData
        .sort((a, b) => a.supplier_name.localeCompare(b.supplier_name))
        .map(({ ...item }) => ({
            ...item,
        }));

    const filter = data => {
        setFilteredData(data);
        // data.map(rec => {
        //     // console.log('rec====>', rec)
        //     setFilteredData(rec);
        // });
    };

    const dropHandler = ev => {
        if (rangelistField.supplier_id != '') {
            // console.log('File(s) dropped');

            // Prevent default behavior (Prevent file from being opened)
            ev.preventDefault();

            if (ev.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (ev.dataTransfer.items[i].kind === 'file') {
                        var file = ev.dataTransfer.items[i].getAsFile();
                        // console.log('... file[' + i + '].name = ' + file.name);
                    }
                }
            } else {
                // Use DataTransfer interface to access the file(s)
                for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                    // console.log(
                    //   '... file[' + i + '].name = ' + ev.dataTransfer.files[i].name,
                    // );
                }
            }
        }
    };

    const dragOverHandler = ev => {
        if (rangelistField.supplier_id != '') {
            // console.log('File(s) in drop zone');

            // Prevent default behavior (Prevent file from being opened)
            ev.preventDefault();
        }
    };

    const handleUpload = e => {
        e.preventDefault();
        var file = e.target.files,
            f = file[0]; //file info, get file
        // console.log('upload which range list', f);
        if (f) {
            // TODO: MAYBE check the uploaded file has the correct format or not
            setFileName(f.name);
            setRangelistField({
                ...rangelistField,
                rangelist: f,
            });
        }
        e.target.value = '';
    };

    const handleUploadZip = e => {
        console.log('handleUploadZip eee');
        e.preventDefault();
        var file = e.target.files,
            f = file[0]; //file info, get file
        console.log('zip', f);
        if (f) {
            // TODO: MAYBE check the uploaded file has the correct format or not
            setZipFile(f);
        }
        e.target.value = '';
    };

    const handleClose = () => {

        setShowModal(false);
        // console.log({ ...planoField, range_list: selectedList });
        setPlanoField({
            supplier_id: '',
            planogram_comment: '',
            planogram_name: '',
            range_list: [],
        });
        // console.log(planoField);
        // setSelectedList([]);
        // setLoading(true);
        setUploadCataHint({
            open: false,
            text: '',
            title: '',
        });
    };

    const uploadRangeList = e => {
        e.preventDefault();

        // 1. upload product information for that supplier
        // uploadRangeListToDB();

        // 2.upload range list to S3 first then update infor in DB
        uploadRangeListToS3();

        // 3. update the whole range list
        // getAllProductsRangeList();
    };

    const uploadRangeListToS3 = () => {
        // console.log('upload range list', rangelistField);
        const supplierInfoArr = rangelistField.supplier_id.split(',');
        const supplier_id = supplierInfoArr[0];
        const supplier_name = supplierInfoArr[1];

        setUploadCataHint({
            ...uploadCataHint,
            open: true,
            text: "Uploading file, please wait...",
            title: 'Thank you'
        })
        // Extract data from raw file
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const tabNameIndex = readedData.SheetNames.indexOf('By Item');
            const wsname = readedData.SheetNames[tabNameIndex];
            // const wsname = readedData.SheetNames[3];
            // console.log(wsname);
            const ws = readedData.Sheets[wsname];
            const sheet_supplier_name = ws.A2.h;

            // the sheet supplier name include some html entities
            // which need to convert to string to compare with supplier name get from Databse
            const formatedSheetSupplierName = new DOMParser().parseFromString(sheet_supplier_name, "text/html").documentElement.textContent

            console.log('sheet supplier name ', sheet_supplier_name, ' formatedSheetSupplierName ', formatedSheetSupplierName);
            if (formatedSheetSupplierName.toLowerCase() != supplier_name.toLowerCase()) {
                // console.log('diff supplier names');
                setUploadCataHint({
                    ...uploadCataHint,
                    open: true,
                    text: "Selected supplier and uploaded file's supplier not match",
                    title: 'Sorry',
                });
                setRangelistField({
                    ...rangelistField,
                    rangelist: '',
                    comments: '',
                });
                setFileName('');

                setTimeout(() => {
                    setUploadCataHint({
                        ...uploadCataHint,
                        open: false,
                    });
                }, 6000);

                return;
            } else {
                // suppplier name match

                // return
                AWS.config.update({
                    accessKeyId: sessionStorage.getItem('AccessKeyId'),
                    secretAccessKey: sessionStorage.getItem('AccessKeySec'),
                    sessionToken: sessionStorage.getItem('sessionToken'),
                });
                const myBucket = new AWS.S3({
                    params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
                    region: 'ap-southeast-2',
                });
                // One supplier has only one rangelist, and everytime for uploading rangelist, need to replace the existing one
                // The key for range list for one supplier will be 'supplier-documents/range-list/{supplier_id}'
                // Need to include the date time in the folder structure because the rangelist can be the same
                const uploadDate = new Date();
                const formatedUploadDate = moment(uploadDate).format('DDMMYYYY-HHmmss');
                const curKey = `admin-documents/range-lists/${supplier_id}/${formatedUploadDate}/${rangelistField.rangelist.name}`;
                // const curKey = `admin-documents/range-lists/${supplier_id}/${rangelistField.rangelist.name}`;
                // console.log('curKey to upload rangelist to s3,', curKey);
                // return;

                const params = {
                    ACL: 'bucket-owner-full-control',
                    Body: rangelistField.rangelist,
                    Bucket: process.env.REACT_APP_BUCKET_NAME,
                    Key: curKey,
                };
                myBucket
                    .putObject(params)
                    .on('httpUploadProgress', (evt, response) => {
                        // console.log(Math.round((evt.loaded / evt.total) * 100));
                    })
                    .send((err, data) => {
                        if (err) {
                            console.log(err);
                        } else {
                            // console.log(data);
                            if (data) {
                                // console.log('range list uploaded to s3 data:=====>', data);

                                // reset everything here before call the api
                                setUploadCataHint({
                                    ...uploadCataHint,
                                    text: 'Data is processing in background, you will be notified by email once complete',
                                    open: true,
                                    title: 'Thank you'
                                });


                                setRangelistField({
                                    ...rangelistField,
                                    rangelist: '',
                                    comments: '',
                                });
                                setFileName('');

                                // setTimeout(() => {
                                //     setUploadCataHint({
                                //         ...uploadCataHint,
                                //         open: false,
                                //     });
                                // }, 6000);

                                setHasChosen({
                                    ...hasChosen,
                                    rangelistChose: false,
                                });

                                uploadRangeListToDB(uploadDate, curKey);
                            }
                        }
                    });
            }
        };
        reader.readAsBinaryString(rangelistField.rangelist);
    };

    const uploadRangeListToDB = (uploadDate, s3Key) => {
        const supplierInfoArr = rangelistField.supplier_id.split(',');
        const supplier_id = supplierInfoArr[0];
        const supplier_name = supplierInfoArr[1];

        // console.log('filter lll', filteredList);
        const updateRangeUrl = process.env.REACT_APP_API_BASE + 'rangelist';
        const newApiData = {
            supplier_id,
            supplier_name,
            rangelist_name: fileName,
            status: 'Approved',
            datetime: uploadDate,
            notes: '',
            s3_url: s3Key,
            doc_type: 'Rangelist',
            upload_by: 'admin',
            comments: rangelistField.comments,
        };

        // console.log('new api data', newApiData)

        axios({
            method: 'POST',
            headers: {
                Authorization: sessionStorage.getItem('idtoken'),
            },
            url: updateRangeUrl,
            data: newApiData,
        })
            .then(res => {
                // console.log('rangelist sent to db', res);
            })
            .catch(err => {
                console.log('make waiting to approve fail', err);
            });



    };

    // const uploadRangeListToDB = uploadDate => {
    //     // console.log('upload range list', rangelistField);
    //     const supplierInfoArr = rangelistField.supplier_id.split(',');
    //     const supplier_id = supplierInfoArr[0];
    //     const supplier_name = supplierInfoArr[1];

    //     // Extract data from raw file
    //     var reader = new FileReader();
    //     reader.onload = function (e) {
    //         var data = e.target.result;
    //         let readedData = XLSX.read(data, { type: 'binary' });
    //         const tabNameIndex = readedData.SheetNames.indexOf('By Item');
    //         const wsname = readedData.SheetNames[tabNameIndex];
    //         // const wsname = readedData.SheetNames[3];

    //         const ws = readedData.Sheets[wsname];
    //         /* Convert array to json*/
    //         var dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '' });
    //         // console.log('dataParse', dataParse);
    //         const headers = dataParse[5];
    //         // console.log('headers', headers);
    //         const heads = headers.map(head => ({ title: head, field: head }));
    //         dataParse.splice(0, 6);
    //         // console.log('after dataParse', dataParse);
    //         const list = dataParse.map((itemArr, index) => {
    //             const itemObj = {};
    //             itemArr.forEach((item, idx) => {
    //                 let attrName = headers[idx];
    //                 if (attrName == '') attrName = 'ItemName';
    //                 itemObj[attrName] = item;

    //                 // if ((idx >= 0 && idx <= 14) || idx === 19) {
    //                 //   let attrName = headers[idx];
    //                 //   if (attrName == '') attrName = 'ItemName';
    //                 //   itemObj[attrName] = item;
    //                 // } else {
    //                 //   return;
    //                 // }
    //             });
    //             itemObj['uploadImgs'] = [''];
    //             return itemObj;
    //         });
    //         // filter the items without id(which is the Item attributes)
    //         const filteredList = list.filter(item => item.Item != '');


    //         // console.log('filter lll', filteredList);
    //         const updateRangeUrl = process.env.REACT_APP_API_BASE + 'rangelist';
    //         const newApiData = {
    //             supplier_id,
    //             supplier_name,
    //             rangelist_name: fileName,
    //             status: 'Approved',
    //             datetime: uploadDate,
    //             notes: '',
    //             // items: filteredList,
    //             s3_url: ,
    //             doc_type: 'Rangelist',
    //             upload_by: 'admin',
    //             comments: rangelistField.comments,
    //         };

    //         console.log('new api data', newApiData)

    //         axios({
    //             method: 'POST',
    //             headers: {
    //                 Authorization: sessionStorage.getItem('idtoken'),
    //             },
    //             url: updateRangeUrl,
    //             data: newApiData,
    //         })
    //             .then(res => {
    //                 if (res) {
    //                     getAllProductsRangeList();
    //                     setUploadCataHint({
    //                         open: true,
    //                         text: 'Rangelist successfully uploaded',
    //                         title: 'Thank you',
    //                     });
    //                     setRangelistField({
    //                         ...rangelistField,
    //                         rangelist: '',
    //                         comments: '',
    //                     });
    //                     setFileName('');

    //                     setTimeout(() => {
    //                         setUploadCataHint({
    //                             ...uploadCataHint,
    //                             open: false,
    //                         });
    //                     }, 6000);

    //                     setHasChosen({
    //                         ...hasChosen,
    //                         rangelistChose: false,
    //                     });
    //                 } else {
    //                     console.log('rangelist some part wrong', res);
    //                 }
    //             })
    //             .catch(err => {
    //                 console.log('make waiting to approve fail');
    //             });

    //         // // old logic of handling the file in the font end
    //         // //  make other list for that supplier Redundant, then send data to DB
    //         // axios({
    //         //     method: 'GET',
    //         //     headers: {
    //         //         Authorization: sessionStorage.getItem('idtoken'),
    //         //     },
    //         //     url:
    //         //         process.env.REACT_APP_API_BASE +
    //         //         'rangelist?supplier_id=' +
    //         //         supplier_id,
    //         // })
    //         //     .then(response => {
    //         //         // console.log('get range prod for one sup ', response);
    //         //         // TODO: change it according to the response
    //         //         const result = response.data.body.Items;
    //         //         console.log('result of range list for clicked sup', result);
    //         //         const updateRangeUrl = process.env.REACT_APP_API_BASE + 'rangelist';
    //         //         // has some rangelist item
    //         //         if (Array.isArray(result) && result.length > 0) {
    //         //             const docList = result;
    //         //             const approvedOneRangelist = docList.filter(
    //         //                 item => item.status.toLowerCase() == 'approved',
    //         //             );

    //         //             if (approvedOneRangelist.length == 1) {
    //         //                 // console.log('has one approved', approvedOneRangelist);
    //         //                 const approvedRange = approvedOneRangelist[0];

    //         //                 const updateRangeUrl =
    //         //                     process.env.REACT_APP_API_BASE + 'rangelist';

    //         //                 const apiDataToMakeRedun = {
    //         //                     ...approvedRange,
    //         //                     status: 'Redundant',
    //         //                 };

    //         //                 // console.log(
    //         //                 //   'change cur approve to redun payload',
    //         //                 //   apiDataToMakeRedun,
    //         //                 // );

    //         //                 axios({
    //         //                     method: 'PUT',
    //         //                     headers: {
    //         //                         Authorization: sessionStorage.getItem('idtoken'),
    //         //                     },
    //         //                     url: updateRangeUrl,
    //         //                     data: apiDataToMakeRedun,
    //         //                 })
    //         //                     .then(response => {
    //         //                         // console.log('make on appro to redunc', response);
    //         //                         if (response.status == 200) {
    //         //                             // console.log(
    //         //                             //   'current approved changed to redundant in DB for one supplier in admin',
    //         //                             //   response,
    //         //                             // );

    //         //                             const newApiData = {
    //         //                                 supplier_id,
    //         //                                 supplier_name,
    //         //                                 rangelist_name: fileName,
    //         //                                 status: 'Approved',
    //         //                                 datetime: uploadDate,
    //         //                                 // datetime: new Date(),
    //         //                                 notes: '',
    //         //                                 items: filteredList,
    //         //                                 doc_type: 'Rangelist',
    //         //                                 upload_by: 'admin',
    //         //                                 comments: rangelistField.comments,
    //         //                             };
    //         //                             // console.log('range list to db payload', newApiData);


    //         //                             axios({
    //         //                                 method: 'POST',
    //         //                                 headers: {
    //         //                                     Authorization: sessionStorage.getItem('idtoken'),
    //         //                                 },
    //         //                                 url: updateRangeUrl,
    //         //                                 data: newApiData,
    //         //                             })
    //         //                                 .then(res => {
    //         //                                     // console.log(
    //         //                                     //   'current appload to approved in DB for one supplier',
    //         //                                     //   res,
    //         //                                     // );
    //         //                                     if (res) {
    //         //                                         getAllProductsRangeList();
    //         //                                         setUploadCataHint({
    //         //                                             open: true,
    //         //                                             text: 'Rangelist successfully uploaded',
    //         //                                             title: 'Thank you',
    //         //                                         });
    //         //                                         setRangelistField({
    //         //                                             ...rangelistField,
    //         //                                             rangelist: '',
    //         //                                             comments: '',
    //         //                                         });
    //         //                                         setFileName('');

    //         //                                         setTimeout(() => {
    //         //                                             setUploadCataHint({
    //         //                                                 ...uploadCataHint,
    //         //                                                 open: false,
    //         //                                             });
    //         //                                         }, 6000);

    //         //                                         setHasChosen({
    //         //                                             ...hasChosen,
    //         //                                             rangelistChose: false,
    //         //                                         });
    //         //                                     } else {
    //         //                                         console.log('rangelist some part wrong', response);
    //         //                                     }
    //         //                                 })
    //         //                                 .catch(err => {
    //         //                                     console.log('make waiting to approve fail');
    //         //                                 });
    //         //                         } else if (response.status == 250) {
    //         //                             console.log('rangelist some part wrong', response);
    //         //                         }
    //         //                     })
    //         //                     .catch(error => {
    //         //                         console.log('doc for supplier fail...', error);
    //         //                     });
    //         //             }
    //         //             // there is no approved one, make current uploaded one approved
    //         //             else {
    //         //                 const newApiData = {
    //         //                     supplier_id,
    //         //                     supplier_name,
    //         //                     rangelist_name: fileName,
    //         //                     status: 'Approved',
    //         //                     datetime: uploadDate,
    //         //                     // datetime: new Date(),
    //         //                     notes: '',
    //         //                     items: filteredList,
    //         //                     doc_type: 'Rangelist',
    //         //                     upload_by: 'admin',
    //         //                     comments: rangelistField.comments,
    //         //                 };

    //         //                 // console.log(
    //         //                 //   'make cur uploaded one Approved payload',
    //         //                 //   updateRangeUrl,
    //         //                 //   newApiData,
    //         //                 // );
    //         //                 axios({
    //         //                     method: 'POST',
    //         //                     headers: {
    //         //                         Authorization: sessionStorage.getItem('idtoken'),
    //         //                     },
    //         //                     url: updateRangeUrl,
    //         //                     data: newApiData,
    //         //                 })
    //         //                     .then(res => {
    //         //                         // console.log(
    //         //                         //   'current appload to approved in DB for one supplier',
    //         //                         //   res,
    //         //                         // );
    //         //                         if (res) {
    //         //                             getAllProductsRangeList();
    //         //                             setUploadCataHint({
    //         //                                 open: true,
    //         //                                 text: 'Rangelist successfully uploaded',
    //         //                                 title: 'Thank you',
    //         //                             });
    //         //                             setRangelistField({
    //         //                                 ...rangelistField,
    //         //                                 rangelist: '',
    //         //                                 comments: '',
    //         //                             });
    //         //                             setFileName('');

    //         //                             setTimeout(() => {
    //         //                                 setUploadCataHint({
    //         //                                     ...uploadCataHint,
    //         //                                     open: false,
    //         //                                 });
    //         //                             }, 6000);

    //         //                             setHasChosen({
    //         //                                 ...hasChosen,
    //         //                                 rangelistChose: false,
    //         //                             });
    //         //                         } else {
    //         //                             console.log('rangelist some part wrong', response);
    //         //                         }
    //         //                     })
    //         //                     .catch(err => {
    //         //                         console.log('make waiting to approve fail');
    //         //                     });
    //         //             }
    //         //         }
    //         //         // has no rangelist item
    //         //         else {
    //         //             const newApiData = {
    //         //                 supplier_id,
    //         //                 supplier_name,
    //         //                 rangelist_name: fileName,
    //         //                 status: 'Approved',
    //         //                 datetime: uploadDate,
    //         //                 notes: '',
    //         //                 items: filteredList,
    //         //                 doc_type: 'Rangelist',
    //         //                 upload_by: 'admin',
    //         //                 comments: rangelistField.comments,
    //         //             };
    //         //             // console.log(
    //         //             //   '  has no rangelist item  range list to db payload',
    //         //             //   newApiData,
    //         //             // );

    //         //             // console.log(
    //         //             //   ' has no rangelist item  make cur uploaded one Approved payload',
    //         //             //   updateRangeUrl,
    //         //             //   newApiData,
    //         //             // );
    //         //             axios({
    //         //                 method: 'POST',
    //         //                 headers: {
    //         //                     Authorization: sessionStorage.getItem('idtoken'),
    //         //                 },
    //         //                 url: updateRangeUrl,
    //         //                 data: newApiData,
    //         //             })
    //         //                 .then(res => {
    //         //                     // console.log(
    //         //                     //   ' has no rangelist item current appload to approved in DB for one supplier',
    //         //                     //   res,
    //         //                     // );
    //         //                     if (res) {
    //         //                         getAllProductsRangeList();
    //         //                         setUploadCataHint({
    //         //                             open: true,
    //         //                             text: 'Rangelist successfully uploaded',
    //         //                             title: 'Thank you',
    //         //                         });
    //         //                         setRangelistField({
    //         //                             ...rangelistField,
    //         //                             rangelist: '',
    //         //                             comments: '',
    //         //                         });
    //         //                         setFileName('');

    //         //                         setTimeout(() => {
    //         //                             setUploadCataHint({
    //         //                                 ...uploadCataHint,
    //         //                                 open: false,
    //         //                             });
    //         //                         }, 6000);

    //         //                         setHasChosen({
    //         //                             ...hasChosen,
    //         //                             rangelistChose: false,
    //         //                         });
    //         //                     } else {
    //         //                         console.log('rangelist some part wrong', response);
    //         //                     }
    //         //                 })
    //         //                 .catch(err => {
    //         //                     console.log('make waiting to approve fail');
    //         //                 });
    //         //         }
    //         //     })
    //         //     .catch(error => {
    //         //         console.log('range for all supplier fail...', error);
    //         //     });
    //     };
    //     reader.readAsBinaryString(rangelistField.rangelist);
    // };

    const openCreatePlanogram = e => {
        setShowModal(true);
        // setLoading(true);
    };

    const getOneImgPromise = (myBucket, file, record) => {
        const originFileObj = file;
        const itemNo = originFileObj.name.split('_')[0];
        const supplier_id = record.supplier_id;
        const curKey = `admin-documents/item-images/${supplier_id}/${itemNo}/${originFileObj.name}`;

        const params = {
            ACL: 'bucket-owner-full-control',
            Body: originFileObj,
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: curKey,
        };

        return new Promise((resolve, reject) => {
            myBucket
                .putObject(params)
                .on('httpUploadProgress', (evt, response) => {
                    // console.log(Math.round((evt.loaded / evt.total) * 100));
                })
                .send((err, data) => {
                    if (err) {
                        console.log(err);
                        return reject(err);
                    } else {
                        if (data) {
                            console.log('current image uploaded');
                            return resolve('Done');
                            // getAllProductsRangeList();
                        }
                    }
                });
        });
    };

    const uploadImagesForOneItem = (fileList, record) => {
        // console.log('uploadImagesForOneItem', fileList);
        if (fileList.length > 0) {
            AWS.config.update({
                accessKeyId: sessionStorage.getItem('AccessKeyId'),
                secretAccessKey: sessionStorage.getItem('AccessKeySec'),
                sessionToken: sessionStorage.getItem('sessionToken'),
            });
            const myBucket = new AWS.S3({
                params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
                region: 'ap-southeast-2',
            });

            // if any upload has error, then show the error
            const notMatchImages = [];
            const uploadPromises = [];
            const toUploadFileList = [];
            for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i];
                const originFileObj = file;
                // const originFileObj = file.originFileObj;
                const itemNo = originFileObj.name.split('_')[0];
                if (itemNo == record.Item) {
                    const newPromise = getOneImgPromise(myBucket, file, record);
                    uploadPromises.push(newPromise);
                    toUploadFileList.push(file)
                } else {
                    console.log('current images not match current product item, skip');
                    notMatchImages.push(originFileObj.name);
                }
                // if (itemNo !== record.Item) {
                //   console.log('current images not match current product item, skip');
                //   notMatchImages.push(originFileObj.name);
                // } else {
                //   const newPromise = getOneImgPromise(myBucket, file, record);
                //   uploadPromises.push(newPromise);
                // }
            }
            // fileList.forEach(file => {
            //   const originFileObj = file;
            //   // const originFileObj = file.originFileObj;
            //   const itemNo = originFileObj.name.split('_')[0];
            //   if (itemNo !== record.Item) {
            //     console.log('current images not match current product item, skip');
            //     notMatchImages.push(originFileObj.name);
            //     return;
            //   } else {
            //     const newPromise = getOneImgPromise(myBucket, file);
            //     uploadPromises.push(newPromise);
            //   }
            // });

            // TODO: use the other if to make sure the file is uploaded and filelist exist
            if (toUploadFileList.length > 0) {
                // if (toUploadFileList.length > 0 && uploadPromises.length>0) {

                const itemsListForDB = toUploadFileList.map(item => {
                    const itemNo = item.name.split('_')[0];
                    return ({
                        item_no: itemNo,
                        s3_url: `admin-documents/item-images/${record.supplier_id}/${itemNo}/${item.name}`
                    })
                })


                // TODO: the endpoint might need to be changed
                const updateRangeUrl = process.env.REACT_APP_API_BASE + 'rangelist';
                const payload = {
                    supplier_id: record.supplier_id,
                    images: [...itemsListForDB],
                };

                // console.log('upload images for single product item ', payload)



                // return

                Promise.all(uploadPromises)
                    .then(res => {
                        if (res) {
                            // if uploaded successfully, then call the api to update in the data base

                            axios({
                                method: 'PUT',
                                headers: {
                                    Authorization: sessionStorage.getItem('idtoken'),
                                },
                                url: updateRangeUrl,
                                data: payload,
                            })
                                .then(res => {
                                    if (res) {

                                        setUploadCataHint({
                                            ...uploadCataHint,
                                            open: true,
                                            text:
                                                notMatchImages.length > 0
                                                    ? `${fileList.length - notMatchImages.length
                                                    } images successfully uploaded, but ${notMatchImages.reduce(
                                                        (prevVal, curName, curIndex) => `${prevVal}, ${curName}`,
                                                    )} images not match current items`
                                                    : `All images successfully uploaded`,
                                            title: 'Thank you',
                                        });

                                        if (notMatchImages.length == 0) {
                                            setTimeout(() => {
                                                setUploadCataHint({
                                                    ...uploadCataHint,
                                                    open: false,
                                                });
                                                handleClose();
                                            }, 6000);
                                        }

                                        getAllProductsRangeList();

                                    } else {
                                        // console.log('rangelist some part wrong', res);
                                    }
                                })
                                .catch(err => {
                                    console.log('make waiting to approve fail');
                                });

                            // setUploadCataHint({
                            //     ...uploadCataHint,
                            //     open: true,
                            //     text:
                            //         notMatchImages.length > 0
                            //             ? `${fileList.length - notMatchImages.length
                            //             } images successfully uploaded, but ${notMatchImages.reduce(
                            //                 (prevVal, curName, curIndex) => `${prevVal}, ${curName}`,
                            //             )} images not match current items`
                            //             : `All images successfully uploaded`,
                            //     title: 'Thank you',
                            // });

                            // if (notMatchImages.length == 0) {
                            //     setTimeout(() => {
                            //         setUploadCataHint({
                            //             ...uploadCataHint,
                            //             open: false,
                            //         });
                            //         handleClose();
                            //     }, 6000);
                            // }

                            // setZipFile(null);
                        }
                    })
                    .catch(err => {
                        console.log('upload all for task fail...', err);
                        setUploadCataHint({
                            ...uploadCataHint,
                            open: true,
                            text: err.message,
                            title: 'Sorry',
                        });
                        setTimeout(() => {
                            setUploadCataHint({
                                ...uploadCataHint,
                                open: false,
                            });
                            handleClose();
                        }, 6000);
                    });

            } else {

                setUploadCataHint({
                    ...uploadCataHint,
                    open: true,
                    text:
                        notMatchImages.length > 0
                            ? `${fileList.length - notMatchImages.length
                            } images successfully uploaded, but ${notMatchImages.reduce(
                                (prevVal, curName, curIndex) => `${prevVal}, ${curName}`,
                            )} images not match current items`
                            : `All images successfully uploaded`,
                    title: 'Thank you',
                });

            }





        } else {
            console.log('no files');
        }
    };

    const deleteImageFromS3 = (e, imgKey, prodItemRecord) => {
        e.preventDefault();
        const imgName = imgKey.split('/')[imgKey.split('/').length - 1];
        confirmAlert({
            style: { position: 'relative', zIndex: '9999' },
            message: `Are you sure to delete: ${imgName} ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        // delete doc in S3
                        const curKey = imgKey;
                        // console.log('delete key', curKey);

                        var params = {
                            Bucket: process.env.REACT_APP_BUCKET_NAME,
                            Key: curKey,
                        };
                        AWS.config.update({
                            accessKeyId: sessionStorage.getItem('AccessKeyId'),
                            secretAccessKey: sessionStorage.getItem('AccessKeySec'),
                            sessionToken: sessionStorage.getItem('sessionToken'),
                        });
                        const myBucket = new AWS.S3({
                            params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
                            region: 'ap-southeast-2',
                        });

                        setLoading(true)
                        myBucket.deleteObject(params, function (err, data) {
                            if (err) {
                                console.log(err, err.stack);
                                setLoading(false)
                                setUploadCataHint({
                                    ...uploadCataHint,
                                    open: true,
                                    text: err.mes,
                                    title: 'Sorry',
                                });
                                setTimeout(() => {
                                    setUploadCataHint({
                                        ...uploadCataHint,
                                        open: false,
                                    });
                                }, 6000);

                            } else {
                                // console.log('file deleted from S3', data);
                                // setUploadCataHint({
                                //     ...uploadCataHint,
                                //     open: true,
                                //     text: 'File deleted successfully!',
                                //     title: 'Thank you',
                                // });
                                // setTimeout(() => {
                                //     setUploadCataHint({
                                //         ...uploadCataHint,
                                //         open: false,
                                //     });
                                // }, 6000);

                                deleteImageFromDB(imgKey, prodItemRecord)
                                // getAllProductsRangeList();
                            }
                        });
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    },
                },
            ],
        });
    };

    const deleteImageFromDB = (imgKey, prodItemRecord) => {
        const supplierId = prodItemRecord.supplier_id
        const itemNo = prodItemRecord.Item

        const updateRangeUrl = process.env.REACT_APP_API_BASE + 'rangelist';
        const payload = {
            supplier_id: supplierId,
            item: itemNo,
            delete_image: imgKey,
        };

        console.log('deleteImageFromDB payload', payload)

        axios({
            method: 'PUT',
            headers: {
                Authorization: sessionStorage.getItem('idtoken'),
            },
            url: updateRangeUrl,
            data: payload,
        })
            .then(res => {
                if (res) {

                    setUploadCataHint({
                        ...uploadCataHint,
                        open: true,
                        text: 'File deleted successfully!',
                        title: 'Thank you',
                    });
                    setTimeout(() => {
                        setUploadCataHint({
                            ...uploadCataHint,
                            open: false,
                        });
                    }, 6000);

                    getAllProductsRangeList();

                } else {
                    // console.log('rangelist some part wrong', res);
                }
            })
            .catch(err => {
                console.log('make delete put call fail...', err);
            });


    }

    const getOneImgPromiseInZip = (myBucket, zipEntry) => {
        // console.log(zipEntry, zipEntry.name);
        const itemNo = zipEntry.name.split('_')[0];
        const supId = rangelistField.supplier_id.split(',')[0];
        return new Promise((resolve, reject) => {
            zipEntry.async('blob').then(data => {
                const newF = new File([data], zipEntry.name, {
                    type: 'application/json',
                });
                // console.log('new F ', newF);

                const curKey = `admin-documents/item-images/${supId}/${itemNo}/${newF.name}`;
                // console.log('curKey to image to s3,', curKey);

                const params = {
                    ACL: 'bucket-owner-full-control',
                    Body: newF,
                    Bucket: process.env.REACT_APP_BUCKET_NAME,
                    Key: curKey,
                };
                myBucket
                    .putObject(params)
                    .on('httpUploadProgress', (evt, response) => {
                        // console.log(Math.round((evt.loaded / evt.total) * 100));
                    })
                    .send((err, data) => {
                        if (err) {
                            console.log(err);
                            return reject(err);
                        } else {
                            if (data) {

                                return resolve(data);
                            }
                        }
                    });
            });
        });
    };

    const uploadZip = e => {
        e.preventDefault();
        const f = zipFile;
        AWS.config.update({
            accessKeyId: sessionStorage.getItem('AccessKeyId'),
            secretAccessKey: sessionStorage.getItem('AccessKeySec'),
            sessionToken: sessionStorage.getItem('sessionToken'),
        });
        const myBucket = new AWS.S3({
            params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
            region: 'ap-southeast-2',
        });

        const supId = rangelistField.supplier_id.split(',')[0];
        const zipKey = `admin-documents/item-images/zip/${supId}/${f.name}`;
        const params = {
            ACL: 'bucket-owner-full-control',
            Body: f,
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: zipKey,
        };
        myBucket
            .putObject(params)
            .on('httpUploadProgress', (evt, response) => {
                // console.log(Math.round((evt.loaded / evt.total) * 100));
            })
            .send((err, data) => {
                if (err) {
                    console.log(err);
                } else {
                    if (data) {
                        // console.log('item images zip uploaded to s3 data:=====>', data);
                        uploadImagesInZip(myBucket);
                    }
                }
            });
    };



    // TODO: need to change to use new api for this call
    const uploadImagesInZip = myBucket => {
        const supId = rangelistField.supplier_id.split(',')[0];
        // get all product item for one supplier
        const allProdNoForOneSup = [];

        finalData.forEach(item => {
            if (item.supplier_id == supId) {
                allProdNoForOneSup.push(JSON.parse(JSON.stringify(item.Item)));
                // allProdNoForOneSup.push(JSON.stringify(item.Item));
            }
        });
        // console.log('allProdNoForOneSup ', allProdNoForOneSup);

        // console.log('upload zip images one by one');
        const f = zipFile;
        const jszip = new JSZip();

        jszip
            .loadAsync(f) // 1) read the Blob
            .then(
                function (zip) {
                    // console.log(zip);
                    const notMatchImages = [];
                    const promises = [];
                    const toUploadFileList = [];
                    // for each key of obj , do the same to upload to s3
                    zip.forEach(function (relativePath, zipEntry) {

                        const itemNo = zipEntry.name.split('_')[0];
                        // console.log('sup include the item number', itemNo);
                        if (allProdNoForOneSup.includes(itemNo)) {
                            // console.log('sup include the item number', itemNo);

                            const newProm = getOneImgPromiseInZip(myBucket, zipEntry);

                            promises.push(newProm);

                            toUploadFileList.push(zipEntry)

                        } else {
                            notMatchImages.push(itemNo);
                        }


                    });

                    if (toUploadFileList.length > 0) {
                        // if (toUploadFileList.length > 0 && uploadPromises.length>0) {

                        // TODO: remove this after api done
                        const itemsListForDB = toUploadFileList.map(item => {
                            const itemNo = item.name.split('_')[0];
                            return ({
                                item_no: itemNo,
                                s3_url: `admin-documents/item-images/${supId}/${itemNo}/${item.name}`
                            })
                        })


                        // TODO: the endpoint might need to be changed
                        const updateRangeUrl = process.env.REACT_APP_API_BASE + 'rangelist';
                        const payload = {
                            supplier_id: supId,
                            images: [...itemsListForDB],
                        };

                        console.log('upload images for single product item ', payload)

                        Promise.all(promises)
                            .then(values => {
                                // console.log('all promise for done for zip');

                                axios({
                                    method: 'PUT',
                                    headers: {
                                        Authorization: sessionStorage.getItem('idtoken'),
                                    },
                                    url: updateRangeUrl,
                                    data: payload,
                                })
                                    .then(res => {
                                        if (res) {

                                            getAllProductsRangeList();
                                            setUploadCataHint({
                                                ...uploadCataHint,
                                                open: true,
                                                text:
                                                    notMatchImages.length > 0
                                                        ? `${Object.keys(zip.files).length - notMatchImages.length
                                                        } images successfully uploaded, but ${notMatchImages.reduce(
                                                            (prevVal, curName, curIndex) =>
                                                                `${prevVal}, ${curName}`,
                                                        )} images not match any items for current supplier`
                                                        : `All images successfully uploaded`,
                                                title: 'Thank you',
                                            });
                                            if (notMatchImages.length == 0) {
                                                setTimeout(() => {
                                                    setUploadCataHint({
                                                        ...uploadCataHint,
                                                        open: false,
                                                    });
                                                    handleClose();
                                                }, 6000);
                                            }

                                            setZipFile(null);

                                        } else {
                                            // console.log('rangelist some part wrong', res);
                                        }
                                    })
                                    .catch(err => {
                                        console.log('make waiting to approve fail');
                                    });
                                // getAllProductsRangeList();
                                // setUploadCataHint({
                                //     ...uploadCataHint,
                                //     open: true,
                                //     text:
                                //         notMatchImages.length > 0
                                //             ? `${Object.keys(zip.files).length - notMatchImages.length
                                //             } images successfully uploaded, but ${notMatchImages.reduce(
                                //                 (prevVal, curName, curIndex) =>
                                //                     `${prevVal}, ${curName}`,
                                //             )} images not match any items for current supplier`
                                //             : `All images successfully uploaded`,
                                //     title: 'Thank you',
                                // });
                                // if (notMatchImages.length == 0) {
                                //     setTimeout(() => {
                                //         setUploadCataHint({
                                //             ...uploadCataHint,
                                //             open: false,
                                //         });
                                //         handleClose();
                                //     }, 6000);
                                // }

                                // setZipFile(null);
                            })
                            .catch(err => {
                                console.log('all promise for zip fail...', err);
                            });

                    } else {

                        setUploadCataHint({
                            ...uploadCataHint,
                            open: true,
                            text:
                                notMatchImages.length > 0
                                    ? `${Object.keys(zip.files).length - notMatchImages.length
                                    } images successfully uploaded, but ${notMatchImages.reduce(
                                        (prevVal, curName, curIndex) =>
                                            `${prevVal}, ${curName}`,
                                    )} images not match any items for current supplier`
                                    : `All images successfully uploaded`,
                            title: 'Thank you',
                        });

                    }


                },
                function (error) {
                    console.log('read zip fail', error);
                },
            );
    };

    const confirmCreatePlanogram = () => {
        const { planogram_name, supplier_id } = planoField;
        if (!planogram_name) {
            setUploadCataHint({
                ...uploadCataHint,
                open: true,
                text: 'Supplier and Name are required',
                title: 'Sorry',
            });
            setTimeout(() => {
                setUploadCataHint({
                    ...uploadCataHint,
                    open: false,
                });
            }, 6000);
            return;
        } else {
            // const supplierInfoArr = planoField.supplier_id.split(',');
            const { planogram_comment, planogram_name } = planoField;
            // const selectedListWithoutImg = selectedList.map(item => ({
            //     ...item,
            //     uploadImgs: [''],
            // }));

            // keep the uploaded img array in the palnogram payload
            const data = {
                planogram_comment,
                planogram_name,
                supplier_id: selectedList[0].supplier_id,
                range_list: [...selectedList],
                // range_list: selectedListWithoutImg,
            };
            // console.log('plano payload selectedList', selectedList);
            // save data to DB
            // return
            axios({
                method: 'POST',
                headers: {
                    Authorization: sessionStorage.getItem('idtoken'),
                },
                url: process.env.REACT_APP_API_BASE + 'planogram',
                data: data,
            })
                .then(response => {
                    // console.log(response);
                    if (response.status == 200) {
                        // console.log('plano for supplier added');

                        setUploadCataHint({
                            ...uploadCataHint,
                            open: true,
                            text: 'Planogram successfully created',
                            title: 'Thank you',
                        });
                        setTimeout(() => {
                            setUploadCataHint({
                                ...uploadCataHint,
                                open: false,
                            });
                            handleClose();
                        }, 6000);
                        setPlanoField({
                            supplier_id: '',
                            planogram_comment: '',
                            planogram_name: '',
                            range_list: [],
                        });
                        // setSelectedList([]);
                    }
                })
                .catch(error => {
                    // console.log(error.response);
                    // console.log(error.response.status);
                    if (
                        error.response.status == 401 &&
                        error.response.data.message == 'The incoming token has expired'
                    ) {
                        alert('Session timed out, please login again.');
                        localStorage.clear();
                        sessionStorage.clear();
                        localStorage.clear();
                        // localStorage.removeItem( "accessLevel" );
                        // reset page location
                        console.log('logout succesfully');
                        document.location.replace('/');
                    }
                });
        }
    };

    return loading ? (
        <div className="overlay-box">
            <ClipLoader
                color={"#fffff"}
                loading={loading}
                css={override}
                size={150}
            />
        </div>

    ) : (
        <>
            <Modal isOpen={showModal} className="plan-modal-dialog">
                <ModalHeader style={{ justifyContent: 'center' }}>
                    Create Planogram
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <label>Supplier</label>
                            <Input
                                type="text"
                                name="supplier_id"
                                disabled
                                // onChange={planoModalandler}
                                value={
                                    Array.isArray(selectedList) && selectedList.length > 0
                                        ? selectedList[0] &&
                                        selectedList[0].supplier_name &&
                                        selectedList[0].supplier_name
                                        : ''
                                }
                                className="admin-input"
                            ></Input>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Planogram Name</label>
                            <Input
                                type="text"
                                name="planogram_name"
                                onChange={planoModalandler}
                                value={planoField.planogram_name}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Comment</label>
                            <Input
                                type="text"
                                name="planogram_comment"
                                onChange={planoModalandler}
                                value={planoField.planogram_comment}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col>
              <label>Comment</label>
              <Input
                type="text"
                name="planogram_comment"
                onChange={planoModalandler}
                value={planoField.planogram_comment}
              />
            </Col> */}
                        <Col>
                            <label style={{ marginLeft: '14px', marginTop: '15px' }}>
                                Range List
                            </label>
                            <Col style={{ marginLeft: '-3%' }}>
                                <label className="callCycle">
                                    <div className="antdTable">
                                        <Table
                                            columns={planoColumns}
                                            dataSource={selectedList}
                                            bordered
                                            rowKey="Item"
                                        />
                                    </div>
                                </label>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="modalRow">
                        <Col>
                            <Button
                                onClick={confirmCreatePlanogram}
                                variant="outlined"
                                color="primary"
                            >
                                Confirm
                            </Button>
                            <Button onClick={handleClose} variant="outlined" color="primary">
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Row
                style={{
                    justifyContent: 'space-between',
                    marginRight: '45px',
                }}
            >
                <Col md="3">
                    <label>Supplier</label>
                    <Input
                        type="select"
                        style={{ marginTop: '7px', maxWidth: '30rem' }}
                        name="supplier_id"
                        onChange={rangelistInputHandler}
                        value={rangelistField.supplier_id}
                        className="admin-input"
                    >
                        <option value="">Select Supplier</option>
                        {renderSupplierOptions()}
                    </Input>
                </Col>
                <Col
                    md="7"
                    className="exposmall"
                    style={{ textAlign: 'left', marginTop: '29px' }}
                >
                    {/* <label style={{ color: 'transparent' }}>okkay</label> */}
                    <br />
                    <a
                        style={{
                            color: '#fff',
                            background: '#D71920',
                            padding: '10px',
                            borderRadius: '32px',
                        }}
                        href="https://staging-cdn.gforceportal.com.au/rangelist-callcycle/RangeListTemplate.xlsx"
                    >
                        Export Template
                    </a>
                </Col>
                <Col md="2"></Col>
            </Row>
            <Row></Row>
            <Row>
                <Col lg="3" md="2">
                    {fileName && (
                        <>
                            <label style={{ padding: '4px' }}>
                                {' '}
                                File Name
                            </label>
                            <p style={{ marginBottom: '0px' }}>
                                <span>{fileName}</span>
                            </p>
                        </>
                    )}
                </Col>
                <Col lg="3" md="2">
                    {fileName && (
                        <>
                            <label style={{ padding: '4px' }}>
                                {' '}
                                Comments
                            </label>

                            <Input
                                type="text"
                                // style={{ marginTop: '7px' }}
                                name="comments"
                                onChange={e => {
                                    rangelistField.comments = e.target.value;
                                    setRangelistField({
                                        ...rangelistField,
                                        comments: e.target.value,
                                    });
                                }}
                                value={rangelistField.comments}
                                className="admin-input"
                                placeHolder="Comments/Notes"
                            />
                        </>
                    )}
                </Col>
            </Row>
            {/* </Row> */}
            <Row>
                <Col
                    md="4"
                    style={{
                        margin: '0px 15px 10px 15px',
                        maxWidth: '30rem',
                    }}
                >
                    <Row>
                        <label
                            htmlFor="uploadFile"
                            className="FileCss"
                            // onDrop={e => dropHandler(e)}
                            // onDragOver={e => dragOverHandler(e)}
                            title={
                                rangelistField.supplier_id == ''
                                    ? 'Please select supplier first'
                                    : ''
                            }
                            style={{
                                border: '4px solid #E6E6E6',
                                borderRadius: '32px',
                                cursor:
                                    rangelistField.supplier_id == ''
                                        ? 'not-allowed'
                                        : fileName
                                            ? 'not-allowed'
                                            : 'pointer',
                            }}
                        // disabled={rangelistField.supplier_id == '' || !fileName}
                        >
                            <label style={{ fontWeight: 'bold' }}>
                                Upload Supplier Range List{' '}
                            </label>
                            <br />
                            Click here to browse your files
                        </label>
                        <Input
                            id="uploadFile"
                            name="csvFile"
                            type="file"
                            accept=".xlsx"
                            style={{ display: 'none' }}
                            onChange={e => {
                                handleUpload(e);
                            }}
                            disabled={
                                rangelistField.supplier_id == ''
                                    ? true
                                    : fileName
                                        ? true
                                        : false
                            }
                        />
                    </Row>
                    <Row></Row>
                    <Row>
                        <Button
                            variant="outlined"
                            onClick={e => {
                                uploadRangeList(e);
                            }}
                            color="primary"
                            disabled={rangelistField.rangelist ? false : true}
                            style={{ marginRight: '10px' }}
                        >
                            {' '}
                            Upload Range List
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={e => {
                                setFileName('');
                                setRangelistField({
                                    ...rangelistField,
                                    rangelist: '',
                                    comments: '',
                                });
                            }}
                            color="primary"
                        // disabled={rangelistField.rangelist ? false : true}
                        >
                            {' '}
                            Cancel
                        </Button>
                    </Row>
                </Col>
                <Col
                    md="4"
                    style={{
                        maxWidth: '30rem',
                        margin: '0px 15px 10px 15px',
                    }}
                >
                    <Row>
                        <label
                            htmlFor="uploadZipFile"
                            className="FileCss"
                            onDrop={e => dropHandler(e)}
                            onDragOver={e => dragOverHandler(e)}
                            style={{
                                border: '4px solid #E6E6E6',
                                borderRadius: '32px',
                                cursor:
                                    rangelistField.supplier_id == ''
                                        ? 'not-allowed'
                                        : 'pointer',
                            }}
                        >
                            <label style={{ fontWeight: 'bold' }}>
                                Upload Images
                            </label>
                            <br /> Click here to browse your files
                        </label>
                        <Input
                            id="uploadZipFile"
                            name="zipFile"
                            type="file"
                            accept=".zip"
                            style={{ display: 'none' }}
                            disabled={rangelistField.supplier_id == ''}
                            title={
                                rangelistField.supplier_id == ''
                                    ? 'Please select supplier first'
                                    : ''
                            }
                            onChange={e => {
                                // console.log('e==>');
                                handleUploadZip(e);
                            }}
                        />
                    </Row>
                    <Row>
                        <Button
                            variant="outlined"
                            onClick={e => {
                                uploadZip(e);
                            }}
                            color="primary"
                            disabled={zipFile ? false : true}
                        >
                            {' '}
                            Upload Bulk Image
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={e => {
                                // console.log('cancel zip file');
                                // setZipFile();
                                setZipFile(null);
                            }}
                            color="primary"
                        >
                            Cancel
                        </Button>
                    </Row>
                    <Row>
                        {zipFile && (
                            <>
                                <label style={{ padding: '4px' }}>
                                    {' '}
                                    File Name:
                                </label>
                                <label style={{ padding: '4px' }}>
                                    {' '}
                                    {zipFile.name}
                                </label>
                            </>
                        )}
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: '20px', marginRight: '0px' }}>
                <Col md="4"></Col>
            </Row>
            <Col>
                <Row style={{ marginBottom: '10px' }}>
                    <Button
                        // style={{ float: 'right', marginLeft: '30px' }}
                        disabled={!enabled}
                        variant="outlined"
                        onClick={e => {
                            openCreatePlanogram(e);
                        }}
                        color="primary"
                    >
                        {' '}
                        Create Planogram
                    </Button>
                    <span style={{ marginTop: '10px' }}>
                        Selected Item: {selectedList.length}
                    </span>
                </Row>
            </Col>
            <Row style={{ marginRight: '0px' }}>
                {modifyFinalData ? (
                    <Table
                        className="TableSet"
                        columns={columns}
                        // size="small"
                        scroll={{ y: 700 }}
                        dataSource={modifyFinalData}
                        bordered
                        rowSelection={{ ...rowSelection }}
                        rowKey={record => record.Item + record.supplier_id}
                        onChange={(
                            pagination,
                            filters,
                            sorter,
                            currentPageData,
                        ) => filter(currentPageData.currentDataSource)}
                        pagination={{
                            total: filteredData.length,
                            showTotal: total => `Total ${total} items`,
                            pageSizeOptions: [
                                '10',
                                '50',
                                '100',
                                '200',
                                '500',
                            ],
                        }}
                    />
                ) : null}
            </Row>
        </>
    )
}

export default AdminUploadRangelist
