import Page from 'components/Page';
import React, { useState } from 'react';
import { useEffect } from 'react';
import greyBanner from 'assets/img/logo/Banner Grey.png';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import Upload from 'rc-upload';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import PropTypes, { string } from 'prop-types';
import DatePicker from 'react-datepicker';
import PhoneInput from 'react-phone-number-input';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { BiUpload } from 'react-icons/bi';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import S3 from 'react-aws-s3';
import AWS from 'aws-sdk';
import * as XLSX from 'xlsx';
import validator from 'validator';
import { isInvalidID, isInvalidUsername, isValidID } from './AddAdmin.js';

const Supplier = props => {
  console.log(props);
  const [myBucket, setMyBucket] = React.useState(false);
  const [allSuppliers, setAllSuppliers] = React.useState([]);

  const getSupplierList = () => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    })
      .then(response => {
        const admindata = response.data.body;
        // const admindata = response.data.body.Items;
        const roles = admindata.filter(item => {
          if (item.role && item.role == 'supplier') {
            return item;
          }
        });
        console.log('sup Roles set up', roles);
        setAllSuppliers(roles);
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };

  useEffect(() => {
    AWS.config.update({
      accessKeyId: sessionStorage.getItem('AccessKeyId'),
      secretAccessKey: sessionStorage.getItem('AccessKeySec'),
      sessionToken: sessionStorage.getItem('sessionToken'),
    });
    var myBucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
      region: 'ap-southeast-2',
    });
    setMyBucket(myBucket);
    getSupplierList();
    window.scrollTo(0, 0);
  }, []);
  const {
    supplierInputField,
    setSupplierInputField,
    supplierHandler,
    fieldDisabled,
    setFieldDisabled,
    mobNums,
    setmobNums,
    handleMobChange,
    isViewModes,
    setIsViewModes,
    retailerList,
    supplierList,
    statusValue,
    setStatusValue,
    setRequiredError,
    requiredError,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openSupp, setOpenSupp] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [openUpd, setOpenUpd] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const addContInfo = () => {
    setSupplierInputField({
      ...supplierInputField,
      contactInfo: [
        ...supplierInputField.contactInfo,
        {
          first_name: '',
          last_name: '',
          email: '',
          ph_number: '',
        },
      ],
    });
  };
  const adddashMore = () => {
    setSupplierInputField({
      ...supplierInputField,
      dashId: [
        ...supplierInputField.dashId,
        {
          dashIDs: '',
        },
      ],
    });
  };
  const [errorM, setError] = useState('');

  const isFullCompanyNameExist = companyName => {
    console.log('called', companyName, allSuppliers);
    let exist = false;
    for (let i = 0; i < allSuppliers.length; i++) {
      const { full_company_name } = allSuppliers[i];
      if (full_company_name == companyName) {
        exist = true;
        break;
      }

    }
    console.log('the full company name exist', exist);
    return exist;
  };

  const addsupplier = (e, action) => {
    if (supplierInputField.id == '' || supplierInputField.id == null) {
      setRequiredError({ supplierID: 'Please Enter ID' });
      return;
    }
    if (
      supplierInputField.id != '' &&
      supplierInputField.id !== null &&
      isInvalidID(supplierInputField.id)
    ) {
      setRequiredError({
        supplierID:
          'Invalid ID, please remove special characters or shorten the ID',
      });
      return;
    }
    if (props.idError != '') {
      return;
    }
    if (
      supplierInputField.username == '' ||
      supplierInputField.username == null
    ) {
      setRequiredError({ supplierUser: 'Please Enter Username' });
      return;
    }
    if (
      supplierInputField.username != '' &&
      supplierInputField.username != null &&
      isInvalidUsername(supplierInputField.username)
    ) {
      setRequiredError({
        supplierUser:
          'Invalid username, please remove special characters, space or shorten the username',
      });
      return;
    }

    if (
      supplierInputField.full_company_name == '' ||
      supplierInputField.full_company_name == null
    ) {
      setRequiredError({ supplierFullComapany: 'Please Enter Company Name' });
      return;
    }

    if (
      supplierInputField.full_company_name != '' &&
      supplierInputField.full_company_name != null &&
      isFullCompanyNameExist(supplierInputField.full_company_name)
    ) {
      setRequiredError({ supplierFullComapany: 'Company Name Already Exists' });
      return;
    }

    if (
      supplierInputField.country == '' ||
      supplierInputField.country == null
    ) {
      setRequiredError({ country: 'Please Select Country' });
      return;
    }
    if (supplierInputField.state == '' || supplierInputField.state == null) {
      setRequiredError({ state: 'Please Select State' });
      return;
    }
    if (
      supplierInputField['user-type'] == '' ||
      supplierInputField['user-type'] == null
    ) {
      setRequiredError({ supplierType: 'Please Select User Type' });
      return;
    }
    // if (supplierInputField.dashId[0].dashIDs == '' || supplierInputField.dashId[0].dashIDs == null) {
    //   setRequiredError({ reportId: 'Please Enter ID' });
    //   return;
    // }
    if (!validator.isEmail(supplierInputField.business_email)) {
      setRequiredError({ supplierEmail: 'Enter Valid Email' });
      return;
    }

    const logoName = supplierInputField.logo_img.name;
    console.log('logoName', logoName);
    console.log('suppDoc===>', supplierInputField.supplierDoc);
    const docNameList =
      supplierInputField.supplierDoc &&
      supplierInputField.supplierDoc.map(item => {
        console.log('item.document.name', item.document.name);
        return {
          document: item.document.name || '',
          document_type: item.document_type || '',
        };
      });
    console.log({ ...supplierInputField });
    const data = {
      ...supplierInputField,
      logo_img: logoName || '',
      id: supplierInputField.id.toUpperCase(),
      status: statusValue ? 'active' : 'inactive',
      mob_number: mobNums.supplier_mob_number,
      supplierDoc: docNameList,
    };
    console.log('add supplier payload', data);
    var dashIDs = [];
    data.dashId &&
      data.dashId.map(rec => {
        dashIDs.push(rec.dashIDs);
      });

    const payloadSupplier = {
      role: 'supplier',

      details: [{ ...data, dashIDs: dashIDs }],
    };
    console.log('payloadSupplier ==> ', payloadSupplier);
    // return;
    axios({
      method: 'POST',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
      data: payloadSupplier,
    })
      .then(response => {
        console.log(response);
        if (response) {
          console.log('supplier added');
          setOpenSupp(true);
          setTimeout(() => {
            setOpenSupp(false);
          }, 3000);
          props.setStatusValue(true);
          props.setToggleText('Active');
          setSupplierInputField({
            dashId: [
              {
                dashIDs: '',
              },
            ],
            role: 'supplier',
            logo_img: '',
            status: 'inactive',
            id: '',
            short_name: '',
            full_company_name: '',
            abn_no: '',
            mob_number: '',
            mini_ordr_value: '',
            business_email: '',
            contract_type: '',
            address: '',
            state: '',
            country: '',
            record_time: 'no',
            username: '',
            notes: '',
            contactInfo: [
              {
                first_name: '',
                last_name: '',
                email: '',
                ph_number: '',
              },
            ],
            supplierDoc: [
              {
                document_type: '',
                document: '',
              },
            ],
            call_cycle: [
              {
                callcycle_id: '',
              },
            ],
          });
          setDashID([{ dashIDs: '' }]);
          // })
          setmobNums({
            manager_mob_number: '',
            supplier_ph_number: [],
          });
        }
      })
      .catch(error => {
        console.log(error.response)
        if (error.response.status === 401) {
          if (error.response.data && error.response.data.error && error.response.data.error.includes("Dash ids:")) {
            setOpenError(true);
            setError(`${error.response.data.error}`);

          } else {
            setOpenError(true);
            setError('Email already in use');
          }
          // setOpenError(true);
          // setError('Email already in use');
          // // setSupplierInputField({
          // //   ...supplierInputField,
          // //   business_email: '',
          // // });
        } else {
          setOpenError(true);

          setError('somthing went wrong ,please try again');
        }
      });

    // ----------------------S3 process start ----------------
    //// upload logo
    const uploadLogo = supplierInputField.logo_img;
    console.log('uploadLogo', uploadLogo);
    //// upload document
    const uploadFilesList = supplierInputField.supplierDoc.map(
      item => item.document,
    );
    console.log('uploadFilesList', uploadFilesList);

    const file = uploadLogo;
    console.log('file==>', file);
    if (file) {
      const newFileName = file.name;
      console.log('upload file,,', newFileName);
      const params = {
        ACL: 'bucket-owner-full-control',
        Body: file,
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: `supplier-documents/logo/${supplierInputField.id.toUpperCase()}/${newFileName}`,
      };
      console.log(params);
      myBucket
        .putObject(params)
        .on('httpUploadProgress', (evt, response) => {
          console.log(Math.round((evt.loaded / evt.total) * 100));
          console.log(response);
        })
        .send((err, data) => {
          if (err) {
            console.log(err);
          } else {
            console.log(data);
            if (data) {
              console.log('data:=>', data);
              // alert("Docs are uploaded")
            }
          }
        });
    }
    for (let i = 0; i < uploadFilesList.length; i++) {
      const file = uploadFilesList[i];
      console.log('file==>', file);
      if (file) {
        const newFileName = file.name;
        // const newFileName = file.name.replace(/\..+$/, '');
        console.log('upload file,,', newFileName);
        const params = {
          ACL: 'bucket-owner-full-control',
          Body: file,
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: `admin-documents/supplier-docs/${supplierInputField.id.toUpperCase()}/documents/${newFileName}`,
        };
        console.log(params);
        myBucket
          .putObject(params)
          .on('httpUploadProgress', (evt, response) => {
            console.log(Math.round((evt.loaded / evt.total) * 100));
            console.log(response);
          })
          .send((err, data) => {
            if (err) {
              console.log(err);
            } else {
              console.log(data);
              if (data) {
                console.log('data:=>', data);
                const fileFile = document.getElementById('file-input');
                fileFile.value = '';
                // alert("Docs are uploaded")
              }
            }
          });
      }
    }
  };

  // ----------------------S3 process END ----------------

  const cancelInputField = () => {
    props.setStatusValue(true);
    props.setToggleText('Active');
    setSupplierInputField({
      role: 'supplier',
      logo_img: '',
      status: 'inactive',
      id: '',
      dashId: [{ dashIDs: '' }],
      short_name: '',
      full_company_name: '',
      abn_no: '',
      mob_number: '',
      mini_ordr_value: '',
      business_email: '',
      contract_type: '',
      address: '',
      state: '',
      country: '',
      record_time: 'no',
      username: '',
      notes: '',
      contactInfo: [
        {
          first_name: '',
          last_name: '',
          email: '',
          ph_number: '',
        },
      ],
      supplierDoc: [
        {
          document_type: '',
          document: '',
        },
      ],
      call_cycle: [
        {
          callcycle_id: '',
        },
      ],
    });
    setmobNums({
      manager_mob_number: '',
      supplier_ph_number: [],
    });
    setIsViewModes({
      ...isViewModes,
      isViewMode_supplier: false,
    });
    setFieldDisabled({
      ...fieldDisabled,
      fieldDisabled_supplier: false,
    });
  };

  ///// Update supplier
  const updateSupplier = () => {
    // Need to do the same validation as add supplier -- qian
    if (supplierInputField.id == '' || supplierInputField.id == null) {
      setRequiredError({ supplierID: 'Please Enter ID' });
      return;
    }
    if (
      supplierInputField.id != '' &&
      supplierInputField.id !== null &&
      isInvalidID(supplierInputField.id)
    ) {
      setRequiredError({
        supplierID:
          'Invalid ID, please remove special characters or shorten the ID',
      });
      return;
    }
    if (props.idError != '') {
      return;
    }
    if (
      supplierInputField.username == '' ||
      supplierInputField.username == null
    ) {
      setRequiredError({ supplierUser: 'Please Enter Username' });
      return;
    }
    if (
      supplierInputField.username != '' &&
      supplierInputField.username != null &&
      isInvalidUsername(supplierInputField.username)
    ) {
      setRequiredError({
        supplierUser:
          'Invalid username, please remove special characters, space or shorten the username',
      });
      return;
    }

    if (
      supplierInputField.full_company_name == '' ||
      supplierInputField.full_company_name == null
    ) {
      setRequiredError({ supplierFullComapany: 'Please Enter Company Name' });
      return;
    }

    // if (supplierInputField.full_company_name != '' && supplierInputField.full_company_name != null && isFullCompanyNameExist(supplierInputField.full_company_name)) {
    //   setRequiredError({ supplierFullComapany: 'Company Name Already Exists' });
    //   return;
    // }

    if (
      supplierInputField.country == '' ||
      supplierInputField.country == null
    ) {
      setRequiredError({ country: 'Please Select Country' });
      return;
    }
    if (supplierInputField.state == '' || supplierInputField.state == null) {
      setRequiredError({ state: 'Please Select State' });
      return;
    }
    if (
      supplierInputField['user-type'] == '' ||
      supplierInputField['user-type'] == null
    ) {
      setRequiredError({ supplierType: 'Please Select User Type' });
      return;
    }
    // if (supplierInputField.dashId[0].dashIDs == '' || supplierInputField.dashId[0].dashIDs == null) {
    //   setRequiredError({ reportId: 'Please Enter ID' });
    //   return;
    // }
    if (!validator.isEmail(supplierInputField.business_email)) {
      setRequiredError({ supplierEmail: 'Enter Valid Email' });
      return;
    }

    console.log('logo_img', supplierInputField.logo_img);

    // props.setIsDisabled(false);

    var img_logo = '';
    if (supplierInputField.logo_img) {
      img_logo = supplierInputField.logo_img.name;
    }
    // else {
    //   img_logo = ''
    // }
    console.log(supplierInputField.supplierDoc);
    const docNameList =
      supplierInputField.supplierDoc &&
      supplierInputField.supplierDoc.map(item => {
        console.log('item===>', item);
        console.log('item===>', typeof item.document);
        // if(item.document== )
        if (typeof item.document == 'string' && item.document != '') {
          console.log('insideifff', item.document);
          return {
            document: item.document,
            document_type: item.document_type,
          };
        } else if (typeof item.document == 'object' && item.document != '') {
          console.log('inside elseeif', item.document);
          return {
            document: item.document.name,
            document_type: item.document_type,
          };
        } else {
          console.log('inside else', item.document);
          return {
            document: '',
            document_type: item.document_type,
          };
        }
        // else {
        //   return {
        //     document: item.document.name || "",
        //     document_type: item.document_type || "",
        //   };
        // }
        // {typeof supplierInputField.supplierDoc[index].document == 'string' ? supplierInputField.supplierDoc[index].document : supplierInputField.supplierDoc[index].document.name}

        // return {

        //   document: item.document.name,
        //   document_type: item.document_type,
        // };
      });
    console.log(docNameList);
    // return;

    const data = {
      ...supplierInputField,
      logo_img: img_logo,
      mob_number: mobNums.supplier_mob_number,
      supplierDoc: docNameList,
      status: statusValue ? 'active' : 'inactive',
      id: supplierInputField.id.toUpperCase(),
    };
    var dashIDs = [];
    data.dashId.map(rec => {
      dashIDs.push(rec.dashIDs);
    });


    const payloadSupplier = {
      role: 'supplier',
      details: [{ ...data, dashIDs: dashIDs }],
    };
    console.log('payloadSupplier', payloadSupplier);

    axios({
      method: 'PUT',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
      data: payloadSupplier,
    })
      .then(response => {
        console.log(response);
        console.log(response.data.body);

        if (response.data && response.data.body && response.data.body && response.data.body.includes("Dash ids:")) {
          const errMsgObj = JSON.parse(response.data.body)
          console.log(' errMsgObj', errMsgObj)
          setOpenError(true);
          setError(`${errMsgObj.error}`);
          return

        }

        if (!response.data.errorMessage) {
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setIsViewModes({
            ...isViewModes,
            isViewMode_supplier: false,
          });
          props.setStatusValue(true);
          props.setToggleText('Active');
          setSupplierInputField({
            dashId: [{ dashIDs: '' }],
            role: 'supplier',
            logo_img: '',
            status: 'inactive',
            id: '',
            short_name: '',
            full_company_name: '',
            abn_no: '',
            mob_number: '',
            mini_ordr_value: '',
            business_email: '',
            contract_type: '',
            address: '',
            state: '',
            country: '',
            record_time: 'no',
            username: '',
            notes: '',
            contactInfo: [
              {
                first_name: '',
                last_name: '',
                email: '',
                ph_number: '',
              },
            ],
            supplierDoc: [
              {
                document_type: '',
                document: '',
              },
            ],
            call_cycle: [
              {
                callcycle_id: '',
              },
            ],
          });
          setmobNums({
            manager_mob_number: '',
            supplier_ph_number: [],
          });
          setIsViewModes({
            ...isViewModes,
            isViewMode_supplier: false,
          });
          setFieldDisabled({
            ...fieldDisabled,
            fieldDisabled_supplier: false,
          });
        }
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
    // ----------------------S3 process start ----------------
    //// upload logo
    const uploadLogo = supplierInputField.logo_img;
    console.log('uploadLogo', uploadLogo);
    // upload document
    const uploadFilesList = supplierInputField.supplierDoc.map(
      item => item.document,
    );
    console.log('uploadFilesList', uploadFilesList);

    const file = uploadLogo;
    console.log('file==>', file);

    if (
      file &&
      typeof file !== 'string'
      //  && file == "" && file != undefined
    ) {
      // const newFileName = file.name.replace(/\..+$/, '');
      const newFileName = file.name;
      console.log('upload file,,', newFileName);
      const params = {
        ACL: 'bucket-owner-full-control',
        Body: file,
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: `supplier-documents/logo/${supplierInputField.id.toUpperCase()}/${newFileName}`,
      };
      console.log(params);
      myBucket
        .putObject(params)
        .on('httpUploadProgress', (evt, response) => {
          console.log(Math.round((evt.loaded / evt.total) * 100));
          console.log(response);
        })
        .send((err, data) => {
          if (err) {
            console.log(err);
          } else {
            console.log(data);
            if (data) {
              console.log('data:=>', data);
              // alert("Docs are uploaded")
            }
          }
        });
    }
    for (let i = 0; i < uploadFilesList.length; i++) {
      const file = uploadFilesList[i];
      console.log('file==>', typeof file);
      if (file) {
        var newFileName;
        if (typeof file != 'string') {
          newFileName = file.name;
        } else {
          newFileName = file;
        }
        if (typeof file != 'string') {
          newFileName = file.name;
          console.log('newFileName', newFileName);
          const params = {
            ACL: 'bucket-owner-full-control',
            Body: file,
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: `admin-documents/supplier-docs/${supplierInputField.id.toUpperCase()}/documents/${newFileName}`,
          };
          console.log(params);
          myBucket
            .putObject(params)
            .on('httpUploadProgress', (evt, response) => {
              console.log(Math.round((evt.loaded / evt.total) * 100));
              console.log(response);
            })
            .send((err, data) => {
              if (err) {
                console.log(err);
              } else {
                console.log(data);
                if (data) {
                  console.log('data:=>', data);
                  const fileFile = document.getElementById('file-input');
                  fileFile.value = '';
                  // alert("Docs are uploaded")
                }
              }
            });
        } else {
          console.log('upload not a file');
        }

        console.log('upload file,,', typeof newFileName);
        // return;
        // const params = {
        //   ACL: 'bucket-owner-full-control',
        //   Body: file,
        //   Bucket: process.env.REACT_APP_BUCKET_NAME,
        //   Key: `admin-documents/supplier-docs/${supplierInputField.id}/documents/${newFileName}`
        // };
        // console.log(params);
        // myBucket.putObject(params)
        //   .on('httpUploadProgress', (evt, response) => {
        //     console.log(Math.round((evt.loaded / evt.total) * 100))
        //     console.log(response);
        //   })
        //   .send((err, data) => {
        //     if (err) {
        //       console.log(err);
        //     }
        //     else {
        //       console.log(data);
        //       if (data) {
        //         console.log("data:=>", data)
        //         alert("Docs are uploaded")
        //       }
        //     }
        //   })
      }
    }
  };

  // ----------------------S3 process END ----------------
  const [dashId, setDashID] = React.useState([{ dashIDs: '' }]);
  const addDoc = () => {
    setSupplierInputField({
      ...supplierInputField,
      supplierDoc: [
        ...supplierInputField.supplierDoc,
        {
          document_type: '',
          document: '',
        },
      ],
    });
  };
  const handleCloses = () => {
    setOpenError(false);
  };
  const handleClose = () => {
    props.setStatusValue(true);
    props.setToggleText('Active');
    setOpenError(false);
    setOpen(false);
    setOpenUpd(false);
    setLoading(true);
    setIsViewModes({
      ...isViewModes,
      isViewMode_supplier: false,
    });
    setSupplierInputField({
      dashId: [{ dashIDs: '' }],
      role: 'supplier',
      logo_img: '',
      status: 'inactive',
      id: '',
      short_name: '',
      full_company_name: '',
      abn_no: '',
      mob_number: '',
      mini_ordr_value: '',
      business_email: '',
      contract_type: '',
      address: '',
      state: '',
      country: '',
      record_time: 'no',
      username: '',
      notes: '',
      contactInfo: [
        {
          first_name: '',
          last_name: '',
          email: '',
          ph_number: '',
        },
      ],
      supplierDoc: [
        {
          document_type: '',
          document: '',
        },
      ],
      call_cycle: [
        {
          callcycle_id: '',
        },
      ],
    });
    setmobNums({
      manager_mob_number: '',
      supplier_ph_number: [],
    });
    setIsViewModes({
      ...isViewModes,
      isViewMode_supplier: false,
    });
    setFieldDisabled({
      ...fieldDisabled,
      fieldDisabled_supplier: false,
    });
  };

  const renderRetailerOptions = () => {
    return retailerList.map(item => (
      <option value={item.full_company_name}>{item.full_company_name}</option>
    ));
  };

  useEffect(() => {
    const disabledVal = props.fieldDisabled.fieldDisabled_supplier;

    const supplier_form_inputs = document.querySelectorAll(
      '#supplier_form input',
    );
    const supplier_form_selects = document.querySelectorAll(
      '#supplier_form select',
    );
    const supplier_form_textarea = document.querySelectorAll(
      '#supplier_form textarea',
    );
    const supplier_action_btns = document.querySelectorAll('.action_btn');
    // console.log(manager_form_inputs);
    for (let i = 0; i < supplier_form_inputs.length; i++) {
      // store_id should not change
      if (
        supplier_form_inputs[i].name !== 'id' &&
        supplier_form_inputs[i].name !== 'username' &&
        supplier_form_inputs[i].name !== 'business_email' &&
        supplier_form_inputs[i].name !== 'full_company_name'
      ) {
        supplier_form_inputs[i].disabled = disabledVal;
      }
    }
    for (let i = 0; i < supplier_form_selects.length; i++) {
      supplier_form_selects[i].disabled = disabledVal;
    }
    for (let i = 0; i < supplier_form_textarea.length; i++) {
      supplier_form_textarea[i].disabled = disabledVal;
    }
    for (let i = 0; i < supplier_action_btns.length; i++) {
      supplier_action_btns[i].style.pointerEvents = disabledVal
        ? 'none'
        : 'auto';
    }
  }, [props.fieldDisabled]);
  const [newCallList, setNewCallList] = useState([]);
  useEffect(() => {
    var callList;
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE +
        'call-cycle/list-by-supplier?supplier_username=' +
        supplierInputField.suppUsern,
    })
      .then(response => {
        console.log('callCycle', response);
        callList = response.data.body;
        setNewCallList(callList);
        // if (Array.isArray(callList) && callList.length > 0) {
        //   setNewCallList(callList);
        // } else {
        //   console.log('callCycle not an array or empty', response);
        // }

      })
      .catch(error => {
        console.log(error.response);
        // console.log(error.response.status);
        if (error && error.response &&
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  }, []);

  const beforeUploadHandler = file => {
    console.log('>>> beforeUpload', file);
    // check if has that file already
    const curFileName = file.name;
    console.log(curFileName);
    setSupplierInputField({
      ...supplierInputField,
      logo_img: file,
    });
    console.log('logo_img', supplierInputField.logo_img);
  };

  const renderStateOptions = () => {
    console.log(
      'renderStateOptions',
      supplierInputField,
      supplierInputField.country.length == 0,
    );
    return (
      <Input
        type="select"
        name="state"
        className="admin-input"
        placeholder="State"
        onChange={supplierHandler}
        value={supplierInputField.state}
        required
      >
        {supplierInputField.country.length == 0 ? (
          <option selected value="">
            Please Select Country First
          </option>
        ) : supplierInputField.country.toLowerCase() == 'australia' ? (
          <>
            <option selected value="">
              Select State
            </option>
            <option value="ACT">Australian Capital Territory</option>
            <option value="NSW">New South Wales</option>
            <option value="NT">Northern Territory</option>
            <option value="SA">South Australia</option>
            <option value="QLD">Queensland</option>
            <option value="TAS">Tasmania</option>
            <option value="VIC">Victoria</option>
            <option value="WA">Western Australia</option>
          </>
        ) : (
          <>
            <option selected value="">
              Select State
            </option>
            <option value="NI">North Island</option>
            <option value="SI">South Island</option>
          </>
        )}
      </Input>
    );
  };

  return (
    <>
      <Dialog open={openSupp}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Supplier details successfully added
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Supplier details successfully updated
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openError}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Sorry</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>{errorM}</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleCloses} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Page>
        <Row>
          <div className="supplier-main-container">
            <Form id="supplier_form">
              <FormGroup>
                <Row>
                  <Col className="left-supplier-container hide-scrollbar">
                    <Row className="upload-logo">
                      <Col>
                        <Upload
                          accept=".png, .jpg, .jpeg, .pdf"
                          beforeUpload={beforeUploadHandler}
                        >
                          Upload Logo <BiUpload />
                        </Upload>
                        {isViewModes.isViewMode_supplier == true ? (
                          <>
                            {supplierInputField.logo_img &&
                              supplierInputField.logo_img ? (
                              <div>
                                <p>
                                  <a
                                    title="Click to download"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${process.env.REACT_APP_CDN}supplier-documents/logo/${supplierInputField.id}/${supplierInputField.logo_img}`}
                                  >
                                    {typeof supplierInputField.logo_img ==
                                      'string'
                                      ? supplierInputField.logo_img.length > 0
                                        ? supplierInputField.logo_img
                                        : ''
                                      : supplierInputField.logo_img.name}
                                  </a>
                                </p>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <p>
                            {typeof supplierInputField.logo_img == 'string'
                              ? supplierInputField.logo_img
                              : supplierInputField.logo_img.name}
                          </p>
                        )}

                        {/* <Upload style={{ cursor: 'pointer' }}>Upload Logo
                      {isViewModes.isViewMode_supplier == true ? (
                        <>
                          {supplierInputField.logo_img ?
                            (
                              <div>
                                <p><a
                                  title="Click to download"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`${process.env.REACT_APP_CDN}supplier-documents/logo/${supplierInputField.id}/${supplierInputField.logo_img}`}
                                >
                                  {supplierInputField.logo_img}
                                </a></p>
                              </div>
                            ) : null}
                        </>
                      ) : null}
                      <BiUpload style={{ fontSize: '23px', marginLeft: '2%' }}
                        name="logo_img"
                        accept=".png,.jpeg,.jpg,.pdf"
                        onChange={e => {
                          console.log('logo_img', e);
                          supplierInputField.logo_img = e.target.files[0].name;
                          console.log(supplierInputField.logo_img)
                          console.log(e.target.files[0])
                          setSupplierInputField({
                            ...supplierInputField,
                          });
                        }} />
                    </Upload> */}
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: '20px',
                      }}
                    >
                      <Col>
                        <b
                          style={{
                            fontFamily: 'Arial',
                            fontSize: '1rem',
                          }}
                        >
                          Supplier Details{' '}
                        </b>
                      </Col>
                    </Row>
                    <div className="supplier-details">
                      <div className="rep-container-1-inner">
                        <Col>
                          <Row className="admin-row">
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Supplier ID
                                <span style={{ color: 'red' }}> *</span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="id"
                                value={supplierInputField.id}
                                onChange={supplierHandler}
                                disabled={isViewModes.isViewMode_supplier}
                                className="admin-input"
                                style={{ textTransform: 'uppercase' }}
                                placeholder="Supplier ID"
                              />
                              <p style={{ color: 'red', fontSize: '12px' }}>
                                {requiredError.supplierID}
                                {props.idError}
                              </p>
                            </Col>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Username<span style={{ color: 'red' }}> *</span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="username"
                                value={supplierInputField.username}
                                onChange={supplierHandler}
                                disabled={isViewModes.isViewMode_supplier}
                                className="admin-input"
                                placeholder="Username"
                              />
                              <p style={{ color: 'red', fontSize: '12px' }}>
                                {requiredError.supplierUser}
                              </p>
                            </Col>
                          </Row>
                          <Row className="admin-row">
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Short Name{' '}
                              </label>
                              <Input
                                type="text"
                                name="short_name"
                                value={supplierInputField.short_name}
                                onChange={supplierHandler}
                                className="admin-input"
                                placeholder="Short Name"
                              />
                            </Col>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Full Company Name
                                <span style={{ color: 'red' }}> *</span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="full_company_name"
                                disabled={isViewModes.isViewMode_supplier}
                                value={supplierInputField.full_company_name}
                                onChange={supplierHandler}
                                className="admin-input"
                                placeholder="Full Company Name"
                              />
                              <p style={{ color: 'red', fontSize: '12px' }}>
                                {requiredError.supplierFullComapany}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <label style={{ fontSize: '1vw' }}>
                                Email
                                <span style={{ color: 'red' }}> *</span>{' '}
                              </label>
                              <Input
                                type="email"
                                name="business_email"
                                onChange={supplierHandler}
                                disabled={isViewModes.isViewMode_supplier}
                                value={supplierInputField.business_email}
                                className="admin-input"
                                placeholder="Email"
                              />
                              <p style={{ color: 'red', fontSize: '12px' }}>
                                {requiredError.supplierEmail}
                              </p>
                            </Col>
                          </Row>
                          <Row className="admin-row">
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>ABN/ACN</label>
                              <Input
                                type="text"
                                name="abn_no"
                                value={supplierInputField.abn_no}
                                onChange={supplierHandler}
                                maxlength="15"
                                className="admin-input"
                                placeholder="ABN/ACN"
                              />
                            </Col>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Mob Number{' '}
                              </label>
                              <PhoneInput
                                className="phnclass admin-input PhoneInput"
                                name="mob_number"
                                onChange={val =>
                                  handleMobChange(val, 'supplier_mob_number')
                                }
                                value={mobNums.supplier_mob_number}
                                placeholder="Mobile Number"
                              />
                            </Col>
                          </Row>
                          <Row className="admin-row">
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Minimum Order Value
                              </label>
                              <Input
                                type="text"
                                name="mini_ordr_value"
                                value={supplierInputField.mini_ordr_value}
                                onChange={supplierHandler}
                                className="admin-input"
                                placeholder="Minimum Order Value"
                              />
                            </Col>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Contract Type
                              </label>
                              <Input
                                type="select"
                                name="contract_type"
                                value={supplierInputField.contract_type}
                                required
                                onChange={supplierHandler}
                                className="admin-input"
                              >
                                <option selected value="">
                                  Select Contract Type
                                </option>
                                <option value="Per Call">Per Call</option>
                                <option value="Percentage">Percentage</option>
                              </Input>
                            </Col>
                          </Row>
                          <Row className="admin-row">
                            <Col md="12">
                              <label style={{ fontSize: '1vw' }}>Address</label>
                              <Input
                                type="text"
                                name="address"
                                value={supplierInputField.address}
                                onChange={supplierHandler}
                                className="admin-input"
                                placeholder="Address"
                              />
                            </Col>
                          </Row>
                          <Row className="admin-row">
                            <Col md="6">
                              <label>
                                Country
                                <span style={{ color: 'red' }}> *</span>
                              </label>
                              <Input
                                type="select"
                                name="country"
                                className="admin-input"
                                placeholder="country"
                                onChange={supplierHandler}
                                value={supplierInputField.country}
                                required
                              >
                                <option selected value="">
                                  Select country
                                </option>
                                <option value="Australia">Australia</option>
                                <option value="New Zealand">New Zealand</option>
                              </Input>
                              <p style={{ color: 'red', fontSize: '12px' }}>
                                {requiredError.country}
                              </p>
                            </Col>
                            <Col md="6">
                              <label>
                                State<span style={{ color: 'red' }}>*</span>
                                {/* <span style={{ color: 'red' }}>
                                {' '}
                                *
                              </span>{' '} */}
                              </label>
                              {renderStateOptions()}
                              {/* <Input
                                type="select"
                                name="state"
                                className="admin-input"
                                placeholder="State"
                                onChange={supplierHandler}
                                value={supplierInputField.state}
                                required
                              >
                                <option selected value="">Select State</option>
                                <option value="ACT">
                                  Australian Capital Territory
                                </option>
                                <option value="NSW">New South Wales</option>
                                <option value="NT">
                                  Northern Territory
                                </option>
                                <option value="SA">South Australia</option>
                                <option value="QLD">Queensland</option>
                                <option value="TAS">Tasmania</option>
                                <option value="VIC">Victoria</option>
                                <option value="WA">
                                  Western Australia
                                </option>
                                <option value="NI">North Island</option>
                                <option value="SI">South Island</option>
                              </Input> */}
                              <p style={{ color: 'red', fontSize: '12px' }}>
                                {requiredError.state}
                              </p>
                            </Col>
                            {/* 
                          <Col>
                            <label style={{ fontSize: '1vw' }}>State</label>
                            <Input type="text" name="state"
                              value={supplierInputField.state}
                              onChange={supplierHandler}
                              className='admin-input'
                              placeholder='State' />
                          </Col>
                          <Col>
                            <label style={{ fontSize: '1vw' }}>Country</label>
                            <Input type="text" name="country"
                              value={supplierInputField.country}
                              onChange={supplierHandler}
                              className='admin-input'
                              placeholder='Country' />
                          </Col> */}
                          </Row>
                          <Row className="admin-row">
                            <Col md="12">
                              <label style={{ fontSize: '1vw' }}>Notes</label>
                              <Input
                                type="textarea"
                                name="notes"
                                value={supplierInputField.notes}
                                onChange={supplierHandler}
                                className="admin-input"
                                placeholder="Notes"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                    </div>
                  </Col>
                  <Col className="right-supplier-container">
                    <Row>
                      <label
                        style={{
                          padding: '0px 0px 0px 40px',
                          margin: '15px 0px 0px 0px',
                        }}
                      >
                        {' '}
                        <strong>Reporting</strong>
                      </label>
                    </Row>
                    <Row
                      style={{ overflowY: 'scroll' }}
                      className="supplier-contact hide-scrollbar"
                    >
                      <Col>
                        <Row>
                          <Col md="6">
                            <label style={{ fontSize: '1vw' }}>
                              User Type <span style={{ color: 'red' }}> *</span>{' '}
                            </label>
                            <Input
                              type="select"
                              name="user-type"
                              value={supplierInputField['user-type']}
                              onChange={supplierHandler}
                              required
                              className="admin-input"
                            >
                              <option selected value="">
                                Select User Type
                              </option>
                              <option value="platinum">Platinum</option>
                              <option value="gold">Gold</option>
                              <option value="silver">Silver</option>
                              <option value="no">No</option>
                            </Input>
                            <p style={{ color: 'red', fontSize: '12px' }}>
                              {requiredError.supplierType}
                            </p>
                          </Col>
                          <Col md="6">
                            <label style={{ fontSize: '1vw' }}>
                              Supplier Reporting IDs
                              {/* <span style={{ color: 'red' }}> *</span>{' '} */}
                            </label>
                            {supplierInputField.dashId &&
                              supplierInputField.dashId.map((rec3, index) => (
                                <>
                                  <Input
                                    type="text"
                                    name="dashIDs"
                                    value={
                                      supplierInputField.dashId[index].dashIDs
                                    }
                                    onChange={e => {
                                      supplierInputField.dashId[index].dashIDs =
                                        e.target.value;
                                      setDashID([...dashId]);
                                      setSupplierInputField({
                                        ...supplierInputField,
                                        dashId: [...supplierInputField.dashId],
                                      });
                                      // setRequiredError({ reportId: '' });
                                    }}
                                    className="admin-input"
                                  />

                                  {/* <p style={{ color: 'red', fontSize: '12px' }}>
                                  {requiredError.reportId}
                                </p> */}
                                  <Row
                                    style={{
                                      width: '70px',
                                      float: 'right',
                                      height: '50px',
                                    }}
                                  >
                                    {index ==
                                      supplierInputField.dashId.length - 1 &&
                                      supplierInputField['user-type'] != 'no' ? (
                                      <Col md="1" style={{ margin: '0px' }}>
                                        {/* <label style={{ color: 'transparent' }}>Add</label><br /> */}
                                        <div
                                          class="action_btn"
                                          onClick={e => {
                                            adddashMore(e);
                                          }}
                                          style={{
                                            width: '50px',
                                          }}
                                        >
                                          <b
                                            style={{
                                              fontSize: '34px',
                                              cursor: 'pointer',
                                              color: '#D71920',
                                            }}
                                          >
                                            +
                                          </b>
                                        </div>
                                      </Col>
                                    ) : null}
                                    {index > 0 &&
                                      index ==
                                      supplierInputField.dashId.length - 1 ? (
                                      <Col md="1" style={{ margin: '0px' }}>
                                        {/* <label style={{ color: 'transparent' }}>Remove</label><br /> */}
                                        <div
                                          style={{ width: '50px' }}
                                          class="action_btn"
                                          onClick={e => {
                                            supplierInputField.dashId.splice(
                                              index,
                                              1,
                                            );
                                            // mobNums.supplier_ph_number.splice(index, 1);
                                            // setmobNums({ ...mobNums });
                                            setSupplierInputField({
                                              ...supplierInputField,
                                              dashId: [
                                                ...supplierInputField.dashId,
                                              ],
                                            });
                                          }}
                                        >
                                          <b
                                            style={{
                                              fontSize: '34px',
                                              cursor: 'pointer',
                                              color: '#D71920',
                                            }}
                                          >
                                            -
                                          </b>{' '}
                                        </div>
                                      </Col>
                                    ) : null}
                                  </Row>
                                </>
                              ))}
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {/* {isViewModes.isViewMode_supplier == true ? (
                        <Col style={{
                          font: 'Arial',
                          fontSize: '1.1vw',
                          marginLeft: '2.5%'
                        }}>Supplier Call Cycle</Col>
                      ) : null} */}
                    {/* </Row> */}
                    <Row className="supplier-call-cycle-headings">
                      <Col>
                        <label
                          style={{
                            padding: '0px 0px 0px 20px',
                            margin: '15px 0px 0px 0px',
                          }}
                        >
                          {' '}
                          <strong>Record Time for Task</strong>
                        </label>
                      </Col>
                      {newCallList.length > 0 ? (
                        <Col
                          style={{
                            font: 'Arial',
                            fontSize: '1.1vw',
                            marginLeft: '7%',
                          }}
                        >
                          <label style={{ margin: '15px 0px 0px 0px' }}>
                            <strong>Supplier Call Cycle</strong>
                          </label>
                        </Col>
                      ) : null}
                    </Row>
                    <Row className="supplier-call-cycle">
                      <Col>
                        <Row>
                          <Col>
                            <label className="switch">
                              <Input
                                type="checkbox"
                                name="record_time"
                                onChange={supplierHandler}
                                checked={
                                  supplierInputField.record_time == 'no'
                                    ? false
                                    : true
                                }
                              />
                              <span className="slider round"></span>
                            </label>
                            <p style={{ fontSize: '14px' }}>
                              {supplierInputField &&
                                supplierInputField.record_time == 'no'
                                ? 'No'
                                : 'Yes'}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          {isViewModes.isViewMode_supplier == true ? (
                            <Col
                              style={{
                                marginLeft: '12%',
                                overflowY: 'auto',
                                height: '100px',
                              }}
                            >
                              {newCallList &&
                                newCallList.map(item => (
                                  <p style={{ marginBottom: '0px' }}>
                                    <label>{item.call_cycle_name}</label>
                                  </p>
                                ))}
                            </Col>
                          ) : null}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <label
                        style={{
                          padding: '0px 0px 0px 40px',
                          margin: '15px 0px 0px 0px',
                        }}
                      >
                        {' '}
                        <strong>Contact Information</strong>
                      </label>
                    </Row>
                    <Row
                      style={{ overflowY: 'scroll' }}
                      className="supplier-contact"
                    >
                      <Col>
                        {supplierInputField.contactInfo &&
                          supplierInputField.contactInfo.map((rec, index) => {
                            return (
                              <Row key={index}>
                                <Col style={{ margin: '0px' }}>
                                  <Row>
                                    <Col md="6">
                                      <label style={{ fontSize: '1vw' }}>
                                        First Name{' '}
                                      </label>
                                      <Input
                                        type="text"
                                        name="first_name"
                                        value={rec.first_name}
                                        onChange={e => {
                                          supplierInputField.contactInfo[
                                            index
                                          ].first_name = e.target.value;
                                          setSupplierInputField({
                                            ...supplierInputField,
                                            contactInfo: [
                                              ...supplierInputField.contactInfo,
                                            ],
                                          });
                                          // setContactInfo([...contactInfo]);
                                        }}
                                        className="admin-input"
                                        placeholder="First Name"
                                      />
                                    </Col>
                                    <Col md="6">
                                      <label style={{ fontSize: '1vw' }}>
                                        Last Name{' '}
                                      </label>
                                      <Input
                                        type="text"
                                        name="last_name"
                                        value={rec.last_name}
                                        onChange={e => {
                                          supplierInputField.contactInfo[
                                            index
                                          ].last_name = e.target.value;
                                          setSupplierInputField({
                                            ...supplierInputField,
                                            contactInfo: [
                                              ...supplierInputField.contactInfo,
                                            ],
                                          });
                                          // setContactInfo([...contactInfo]);
                                        }}
                                        className="admin-input"
                                        placeholder="Last Name"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="6" style={{ margin: '0px' }}>
                                      <label style={{ fontSize: '1vw' }}>
                                        Email
                                      </label>
                                      <Input
                                        type="text"
                                        name="email"
                                        value={rec.email}
                                        onChange={e => {
                                          supplierInputField.contactInfo[
                                            index
                                          ].email = e.target.value;
                                          setSupplierInputField({
                                            ...supplierInputField,
                                            contactInfo: [
                                              ...supplierInputField.contactInfo,
                                            ],
                                          });
                                          // setContactInfo([...contactInfo]);
                                        }}
                                        className="admin-input"
                                        placeholder="Email"
                                      />
                                    </Col>
                                    <Col md="6" style={{ margin: '0px' }}>
                                      <label style={{ fontSize: '1vw' }}>
                                        Phone Number
                                      </label>
                                      <PhoneInput
                                        className="phnclass admin-input PhoneInput"
                                        name="ph_number"
                                        value={
                                          supplierInputField.contactInfo[index]
                                            .ph_number
                                        }
                                        placeholder="Mobile Number"
                                        onChange={val => {
                                          handleMobChange(
                                            val,
                                            'supplier_ph_number',
                                          );
                                          supplierInputField.contactInfo[
                                            index
                                          ].ph_number = val;
                                          console.log(
                                            supplierInputField.contactInfo,
                                            supplierInputField.contactInfo[
                                              index
                                            ].ph_number,
                                          );
                                          setSupplierInputField({
                                            ...supplierInputField,
                                            contactInfo: [
                                              ...supplierInputField.contactInfo,
                                            ],
                                          });
                                          // setmobNums({...mobNums,
                                          // supplier_ph_number: [...mobNums.supplier_ph_number.push(val)]})
                                          // setContactInfo([...contactInfo]);
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row
                                    style={{
                                      width: '70px',
                                      float: 'right',
                                      height: '50px',
                                    }}
                                  >
                                    {index ==
                                      supplierInputField.contactInfo.length -
                                      1 ? (
                                      <Col md="1" style={{ margin: '0px' }}>
                                        {/* <label style={{ color: 'transparent' }}>Add</label><br /> */}
                                        <div
                                          class="action_btn"
                                          onClick={e => {
                                            addContInfo(e);
                                          }}
                                          style={{
                                            width: '50px',
                                          }}
                                        >
                                          <b
                                            style={{
                                              fontSize: '34px',
                                              cursor: 'pointer',
                                              color: '#D71920',
                                            }}
                                          >
                                            +
                                          </b>
                                        </div>
                                      </Col>
                                    ) : null}
                                    {index > 0 &&
                                      index ==
                                      supplierInputField.contactInfo.length -
                                      1 ? (
                                      <Col md="1" style={{ margin: '0px' }}>
                                        {/* <label style={{ color: 'transparent' }}>Remove</label><br /> */}
                                        <div
                                          style={{ width: '50px' }}
                                          class="action_btn"
                                          onClick={e => {
                                            supplierInputField.contactInfo.splice(
                                              index,
                                              1,
                                            );
                                            // mobNums.supplier_ph_number.splice(index, 1);
                                            // setmobNums({ ...mobNums });
                                            setSupplierInputField({
                                              ...supplierInputField,
                                              contactInfo: [
                                                ...supplierInputField.contactInfo,
                                              ],
                                            });
                                          }}
                                        >
                                          <b
                                            style={{
                                              fontSize: '34px',
                                              cursor: 'pointer',
                                              color: '#D71920',
                                            }}
                                          >
                                            -
                                          </b>{' '}
                                        </div>
                                      </Col>
                                    ) : null}
                                  </Row>
                                </Col>
                              </Row>
                            );
                          })}
                      </Col>
                    </Row>
                    <Row>
                      <label
                        style={{
                          padding: '0px 0px 0px 40px',
                          margin: '15px 0px 0px 0px',
                        }}
                      >
                        {' '}
                        <strong>Document Uploads</strong>{' '}
                        <span style={{ color: 'gray' }}>
                          (Please only upload PDF or Office files here.)
                        </span>
                      </label>
                    </Row>
                    <Row
                      style={{ overflowY: 'scroll' }}
                      className="document-upload"
                    >
                      <Col>
                        {Array.isArray(supplierInputField.supplierDoc) &&
                          supplierInputField.supplierDoc.map((rec1, index) => (
                            <Row key={index}>
                              <Col style={{ margin: '0px' }}>
                                <Row>
                                  <Col md="6" style={{ margin: '0px' }}>
                                    <label style={{ fontSize: '1vw' }}>
                                      Document {index + 1}
                                    </label>
                                    {isViewModes.isViewMode_supplier == true ? (
                                      <>
                                        {supplierInputField.supplierDoc &&
                                          supplierInputField.supplierDoc[index]
                                            .document ? (
                                          <div>
                                            <p>
                                              <a
                                                title="Click to download"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`${process.env.REACT_APP_CDN}admin-documents/supplier-docs/${supplierInputField.id}/documents/${supplierInputField.supplierDoc[index].document}`}
                                              >
                                                {typeof supplierInputField
                                                  .supplierDoc[index]
                                                  .document == 'string'
                                                  ? supplierInputField
                                                    .supplierDoc[index]
                                                    .document
                                                  : supplierInputField
                                                    .supplierDoc[index]
                                                    .document.name}
                                              </a>
                                            </p>
                                          </div>
                                        ) : null}
                                      </>
                                    ) : null}
                                    <Input
                                      type="file"
                                      id="file-input"
                                      name="document"
                                      onChange={e => {
                                        console.log('doc change', e);
                                        supplierInputField.supplierDoc[
                                          index
                                        ].document = e.target.files[0];
                                        setSupplierInputField({
                                          ...supplierInputField,
                                          supplierDoc: [
                                            ...supplierInputField.supplierDoc,
                                          ],
                                        });
                                      }}
                                    // className='admin-input'
                                    />
                                  </Col>
                                  <Col md="6" style={{ margin: '0px' }}>
                                    <label style={{ fontSize: '1vw' }}>
                                      Document Type
                                    </label>
                                    <Input
                                      type="text"
                                      name="document_type"
                                      value={rec1.document_type}
                                      onChange={e => {
                                        supplierInputField.supplierDoc[
                                          index
                                        ].document_type = e.target.value;
                                        setSupplierInputField({
                                          ...supplierInputField,
                                          supplierDoc: [
                                            ...supplierInputField.supplierDoc,
                                          ],
                                        });
                                      }}
                                      placeholder="Document Type"
                                      className="admin-input"
                                    />
                                  </Col>
                                </Row>
                                <Row
                                  style={{
                                    width: '100px',
                                    height: '25px',
                                    float: 'right',
                                  }}
                                >
                                  {index ==
                                    supplierInputField.supplierDoc.length - 1 ? (
                                    <Col>
                                      {/* <label style={{ color: 'transparent' }}>
                                    Add{' '}
                                  </label> */}
                                      <br />
                                      <div
                                        class="action_btn"
                                        onClick={e => {
                                          addDoc(e);
                                        }}
                                      >
                                        <b
                                          style={{
                                            fontSize: '34px',
                                            cursor: 'pointer',
                                            color: '#D71920',
                                          }}
                                        >
                                          +
                                        </b>
                                      </div>
                                    </Col>
                                  ) : null}
                                  {index > 0 &&
                                    index ==
                                    supplierInputField.supplierDoc.length -
                                    1 ? (
                                    <Col>
                                      {/* <label style={{ color: 'transparent' }}>
                                    Remove
                                  </label> */}
                                      <br />
                                      <div
                                        class="action_btn"
                                        onClick={e => {
                                          supplierInputField.supplierDoc.splice(
                                            index,
                                            1,
                                          );
                                          setSupplierInputField({
                                            ...supplierInputField,
                                            supplierDoc: [
                                              ...supplierInputField.supplierDoc,
                                            ],
                                          });
                                        }}
                                      >
                                        {' '}
                                        <b
                                          style={{
                                            fontSize: '34px',
                                            cursor: 'pointer',
                                            color: '#D71920',
                                          }}
                                        >
                                          -
                                        </b>{' '}
                                      </div>
                                    </Col>
                                  ) : null}
                                </Row>
                              </Col>
                            </Row>
                          ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row
                  style={{
                    paddingTop: '20px',
                    paddingLeft: '40px',
                  }}
                >
                  {isViewModes.isViewMode_supplier == false ? (
                    <Col md={12} className="martop">
                      <Button
                        onClick={addsupplier}
                        variant="outlined"
                        color="primary"
                      >
                        <span>
                          {' '}
                          <b> Add Supplier</b>{' '}
                        </span>
                      </Button>
                      <Button
                        onClick={cancelInputField}
                        variant="outlined"
                        color="primary"
                      >
                        <span>
                          {' '}
                          <b>Cancel</b>{' '}
                        </span>
                      </Button>
                    </Col>
                  ) : (
                    <Col md={12} className="martop">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={updateSupplier}
                      >
                        <span>
                          {' '}
                          <b> Update</b>{' '}
                        </span>
                      </Button>
                      <Button
                        onClick={cancelInputField}
                        variant="outlined"
                        color="primary"
                      >
                        <span>
                          {' '}
                          <b>Cancel</b>{' '}
                        </span>
                      </Button>
                    </Col>
                  )}
                </Row>
              </FormGroup>
            </Form>
          </div>
        </Row>
      </Page>
    </>
  );
};

export default Supplier;
