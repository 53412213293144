import React from 'react';
import { Bar, Line } from 'react-chartjs-2';

import { Row } from 'reactstrap';
import axios from 'axios';
import { useEffect } from 'react';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Helmet } from 'react-helmet';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';

// import QuicksightEmbed from './QuicksightEmbed'
const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;
const DashboardPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const [quickSightUrl, setQuickSightUrl] = React.useState('');
  // this is needed, because InfiniteCalendar forces window scroll
  window.scrollTo(0, 0);
  const script = document.createElement('script');

  const embedDashboard = () => {
    setTimeout(() => {
      var dashboard;

      const tokenString = sessionStorage.getItem('idtoken');

      console.log('in string', tokenString);
      if (tokenString) {
        setLoading(true);
        if (localStorage.getItem('userRole') != 'admin') {
          // var url1;
          axios({
            method: 'GET',
            headers: {
              Authorization: sessionStorage.getItem('idtoken'),
            },
            url: process.env.REACT_APP_API_BASE + 'admin-portal',
          }).then(response => {
            const admindata = response.data.body;
            var supplierList;
            admindata.map(rec => {
              // if (rec.role == 'supplier') {
              if (rec.username == sessionStorage.getItem('username')) {
                console.log(rec);
                console.log(sessionStorage.getItem('username'));
                supplierList = rec.dashIDs;
                // }
              }
            });
            if (supplierList && supplierList.length == 1) {
              supplierList.map(rec => {
                return (supplierList = rec);
              });
            }
            console.log(supplierList);
            axios({
              method: 'POST',
              // headers: {
              //   Authorization: sessionStorage.getItem('idtoken'),
              // },
              url:
                process.env.REACT_APP_SUPPLIER_API_QUICKSIGHT +
                'dashboard-embed',
              data: { id_token: tokenString },
            }).then(res => {
              console.log('res.toString()', res.data.EmbedUrl);
              setQuickSightUrl(res.data.EmbedUrl);
              const url1 = res.data.EmbedUrl;
              setLoading(false);
              var containerDiv = document.getElementById('embeddingContainer');
              var options = {
                // URL From API
                url: url1,
                container: containerDiv,
                scrolling: 'yes',
                // height: "700px",
                width: '100%',
                footerPaddingEnabled: true,
                parameters: {
                  Supplier: supplierList, // LOGGED IN SUPPLIER ID !?
                },

                //Export to PDF
                printEnabled: true,
              };
              console.log(QuickSightEmbedding);
              dashboard = QuickSightEmbedding.embedDashboard(options);

              setTimeout(() => {
                dashboard.setParameters({ Supplier: supplierList });
                console.log(dashboard.getParameters());
                console.log({ Supplier: supplierList });
              }, 1500);
            });
          });
        } else {
          axios({
            method: 'POST',
            url: process.env.REACT_APP_ADMIN_API_QUICKSIGHT + `dashboard-embed`,
            data: { id_token: tokenString },
          }).then(res => {
            console.log('res.toString()', res.data.EmbedUrl);
            setQuickSightUrl(res.data.EmbedUrl);
            setLoading(false);
            const url1 = res.data.EmbedUrl;
            // sessionStorage.setItem('dashboardurl', url1);
            var containerDiv = document.getElementById('embeddingContainer');
            var options = {
              // URL From API
              url: url1,
              container: containerDiv,
              scrolling: 'yes',
              // height: "700px",
              width: '100%',
              footerPaddingEnabled: true,

              //Export to PDF
              printEnabled: true,
            };
            console.log(QuickSightEmbedding);
            dashboard = QuickSightEmbedding.embedDashboard(options);
            // dashboard.initiatePrint();
          });
        }
      }
      var type = '';
      if (localStorage.getItem('userRole') == 'supplier') {
        type = 'adhoc';
      } else {
        type = 'task';
      }
      axios({
        method: 'GET',
        headers: {
          Authorization: sessionStorage.getItem('idtoken'),
        },
        url: process.env.REACT_APP_API_BASE + 'id-gen?type=' + type,
      }).then(response => {
        const id = response.data.body;
        console.log(response.data.body);
        if (localStorage.getItem('userRole') == 'supplier') {
          localStorage.setItem('adhocID', id);
        } else {
          localStorage.setItem('taskID', id);
        }
      });
    }, 2500);
  };
  // const generateTokens = () => {
  //   const accessToken = sessionStorage.getItem('accessToken');
  //   console.log('generateTokens', accessToken);
  //   axios({
  //     method: 'POST',
  //     url: process.env.REACT_APP_API_BASE + 'auth',
  //     data: { access_token: accessToken },
  //     headers: {
  //       Authorization: sessionStorage.getItem('idtoken'),
  //     },
  //   }).then(response => {
  //     console.log(response.data);
  //     // setAccessKeyId(response.AccessKeyId)
  //     // sessionStorage.setItem('AccessKeyId', response.data.AccessKeyId);
  //     sessionStorage.setItem('apikey', response.data.ApiKey);
  //     // sessionStorage.setItem('AccessKeySec', response.data.SecretAccessKey);
  //     // sessionStorage.setItem('sessionToken', response.data.SessionToken);

  //     // setAccessKeySec(response.SecretAccessKey)
  //   });
  // };

  useEffect(() => {
    // generateTokens();
    embedDashboard();
  }, []);

  return (
    <div
      className={loading ? 'parentDisable' : ''}
      style={{ overflow: 'hidden' }}
    >
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Row
        onLoad={embedDashboard}
        style={{ marginLeft: '5px', marginRight: '-10px', overflow: 'hidden' }}
      >
        <div
          style={{ width: '100%', height: '100vh' }}
          id="embeddingContainer"
        ></div>
      </Row>
      {/* <Row style={{ marginLeft: '5px', marginRight: '-10px' }}> */}
      {/* <iframe
          width="100%"
          className="quickfrmae"
          src={quickSightUrl}
          // printEnabled={true}
          // undoRedoDisabled={false}
          // URL From API


          footerPaddingEnabled={true}

          //Export to PDF
          printEnabled={true}
        ></iframe> */}
      {/* </Row> */}
    </div>
  );
};
export default DashboardPage;
