import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Row, Col, Button } from 'reactstrap';
import { Table, Tag, Space, Popconfirm } from 'antd';
import 'antd/dist/antd.css';
import { Input } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import { red } from '@material-ui/core/colors';
import moment from 'moment';
import { ConsoleLogger } from '@aws-amplify/core';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Pagination } from 'antd';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const StoreTable = props => {
  console.log('manager table', props);
  console.log(props);
  const [tableData, setTableData] = useState();
  const [filteredTableData, setFilteredTableData] = useState();
  const [filteredExcelData, setFilteredExcelData] = useState();
  const [templateExcelData, setTemplateExcelData] = useState([]);
  const [storeDataSearch, setStoreDataSearch] = React.useState();
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const [filteredData, setFilteredData] = useState([]);
  const newFil = [];
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
  };

  const userRole = localStorage.getItem('userRole');
  let userState = '';
  if (userRole == 'manager') {
    userState = localStorage.getItem('userState');
  }

  const getStoreList = () => {
    console.log(props);

    setLoading(true);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal/store',
    })
      .then(response => {
        if (response.data.statusCode == 200) {
          const roless = response.data.body.Items;
          console.log('get StoreItems', roless, roless.length);
          let newroles = [];
          if (userRole == 'manager') {
            console.log('userState==>', userState, 'userRole', userRole);
            roless.filter(item => {
              if (item.state == userState) {
                newroles.push(item);
              }
            });
          } else {
            newroles = roless;
          }
          const storeRoles = newroles.filter(
            item =>
              typeof item.store_name == 'string' && item.store_name.length > 0,
          );
          const roles = storeRoles.sort((a, b) =>
            a.store_name
              .toLowerCase()
              .localeCompare(b.store_name.toLowerCase()),
          );
          var storeList = [];
          var rar = [];
          var rar1 = {};
          console.log(props.repList);
          console.log('roles3252135>', roles);
          roles.map(rec => {
            props.repList.filter(item => {
              if (item.username == rec.senior_rep_username) {
                rec['mobile'] = item.mobile;
              }
            });
            console.log(rec);
          });

          setFilteredExcelData(storeList);
          console.log('store', rar);
          setTableData(roles);
          filterD(roles);
          setFilteredTableData(roles);
        } else {
          console.log('not 200 status', response);
        }
        console.log(storeList);
        // TODO: remove static when api ready

        // setTableData(stores);
        // setFilteredTableData(stores);
        setLoading(false);
      })
      .catch(error => {
        // PLEASE set loading as false even there is an error
        setLoading(false);
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };
  const filterD = data => {
    var storeList = [];
    var rar = [];
    var rar1 = {};
    data.map(rec => {
      console.log(rec);
      const visitDays = rec.visit_days;

      var visitD = [];
      rec.visit_days &&
        rec.visit_days.map(rec2 => {
          if (rec2.checked == true) {
            console.log(rec2.name);
            visitD.push(rec2.name);
          }
        });
      const daysString = visitD.join(',');

      // storeList.map(rec3 => {
      //   rec3.StoreVisitDays = daysString
      // })

      rec.additionalRep &&
        rec.additionalRep.map((rec4, index) => {
          console.log(rec4);
          rar.push({
            ['Rep Cover' + [index + 1]]: rec4.rep_cover,
            ['Rep Cover Name' + [index + 1]]: rec4.rep_cover_name,
            // ['Rep Start Date From' + [index + 1]]: moment(rec4.from_date).format('DD/MM/YYYY'),
            // ['Rep End Date' + [index + 1]]: moment(rec4.to_date).format('DD/MM/YYYY')
          });
        });
      var frq1 = '';
      if (rec.visit_freq == '14odd') {
        frq1 = '14O';
      } else if (rec.visit_freq == '14even') {
        frq1 = '14E';
      } else {
        frq1 = rec.visit_freq;
      }
      const aa = {
        'Store ID*': rec.store_id,
        'Store Name*': rec.store_name,
        'Retailer Name*': rec.retailer_name,
        'Store Size': rec.store_size,
        'Store Grade': rec.store_grade,
        Address: rec.address,
        'State*': rec.state,
        'Country*': rec.country,
        Region: rec.region,
        'Store Manager': rec.store_manager,
        'Store Manager Email': rec.store_manager_email,
        'Store Manager Phone': rec.mob_number,
        Territory: rec.territory,
        'Trade Drive Through': rec.trade_drive_trough,
        'Warehouse or SFS': rec.whare_sfs,
        'Frequency*': frq1,
        'Preferred Visit Time': rec.visit_time,
        'If Monthly, Select Week*': rec.visit_start_week_no,
        'Senior/Primary Rep Username*': rec.senior_rep_username,
        'Primary Rep Status': rec.senior_status,
        'Support Rep Username': rec.support_rep_username,
        'Support Rep Status': rec.support_status,
        'Store Visit Days': daysString,
        // Contact: rec.contact,
      };

      rar.map((rec1, index) => {
        Object.keys(rec1).map(key => {
          console.log(key);
          aa[key] = rec1[key];
          rar = [];
        });
        rar = [];
      });
      rar = [];
      // })
      rar = [];
      storeList.push(aa);
    });

    setFilteredExcelData(storeList);

    setFilteredData(storeList);
  };
  // const getRetailerList = () => {
  //   axios({
  //     method: 'GET',
  //     url: process.env.REACT_APP_API_BASE + 'admin-portal',
  //   }).then(response => {
  //     const admindata = response.data.body.Items;
  //     const roles = admindata.filter(item => {
  //       if (item.role && item.role == 'store') {
  //         return item;
  //       }
  //     });
  //     console.log('store', roles);
  //     // setTableData(roles);
  //     // TODO: remove static when api ready
  //     const list = [
  //       {
  //         retailer_id: 'RE001',
  //         retailer_name: 'Bunnings',
  //       },
  //       {
  //         retailer_id: 'RE002',
  //         retailer_name: 'Mitre 10',
  //       },
  //     ];
  //     setStoreDataSearch(list);
  //   });
  // };

  useEffect(() => {
    getStoreList();
    const head = {
      'Store ID*': '',
      'Store Name*': '',
      'Retailer Name*': '',
      'Store Size': '',
      'Store Grade': '',
      Address: '',
      'State*': '',
      'Country*': '',
      Region: '',
      'Store Manager': '',
      'Store Manager Email': '',
      'Store Manager Phone': '',
      Territory: '',
      'Trade Drive Through': '',
      'Warehouse or SFS': '',
      'Frequency*': '',
      'If Monthly, Select Week*': '',
      'Preferred Visit Time': '',
      'Senior/Primary Rep Username*': '',
      'Primary Rep Status': '',
      'Support Rep Username': '',
      'Support Rep Status': '',
      'Store Visit Days': '',
      // 'Rep Cover1': '',
      // 'Rep Cover2': '',
      // 'Rep Cover3': '',
      // 'Rep Cover4': '',
      // 'Rep Cover5': '',
      // 'Rep Cover Name1': '',
      // 'Rep Cover Name2': '',
      // 'Rep Cover Name3': '',
      // 'Rep Cover Name4': '',
      // 'Rep Cover Name5': '',
      // "Rep Start Date From1": "",
      // "Rep Start Date From2": "",
      // "Rep Start Date From3": "",
      // "Rep Start Date From4": "",
      // "Rep Start Date From5": "",
      // 'Rep End Date1': '',
      // 'Rep End Date2': '',
      // 'Rep End Date3': '',
      // 'Rep End Date4': '',
      // 'Rep End Date5': '',
    };
    // var head1 = {}
    // storeList.map((rec, i) => {
    //   console.log(rec)
    //   Object.keys(rec).map((key, val) => {
    //     console.log(key)
    //     console.log(val)
    //     head.push(key)
    //   }
    //   )
    //   head1 = head;
    //   head = []
    // })
    console.log(head);
    setTemplateExcelData([head]);
    // getRetailerList();
  }, []);
  // store_namestore_id

  const handleClose = () => {
    setOpenUpd(false);
    // setBtnUpdate(false);
  };
  const handleView = (record, e) => {
    console.log('recccc', record);

    // props.setStoreInputField.visit_days(record.visit_days);
    const { additionalRep } = record;
    if (record.store_status == 'active') {
      console.log('true active');
      props.setStatusValue(true);
      props.setToggleText('Active');
    } else if (record.store_status == 'inactive') {
      props.setStatusValue(false);
      props.setToggleText('Inactive');
    }

    let newRepsList = [];
    var fromDate;
    var toDate;
    if (additionalRep.length > 0) {
      newRepsList = additionalRep.map(item => {
        if (item.from_date) {
          fromDate = new Date(item.from_date);
          console.log(fromDate);
        } else {
          fromDate = '';
          console.log('nothing', fromDate);
        }
        if (item.to_date) {
          toDate = new Date(item.to_date);
          console.log(toDate);
        } else {
          toDate = '';
          console.log('nothing', toDate);
        }
        return {
          ...item,
          from_date: fromDate,
          to_date: toDate,
        };
      });
    }
    const newRec = {
      ...record,
      additionalRep: newRepsList,
    };

    console.log(newRepsList);
    console.log('new obj list', newRec.additionalRep);
    props.setValue('1');
    var additionalRepData;
    if (
      newRec.additionalRep == '' ||
      newRec.additionalRep == undefined ||
      newRec.additionalRep.length == 0
    ) {
      additionalRepData = [
        {
          rep_cover: 'Primary Rep',
          rep_cover_username: '',
          from_date: '',
          to_date: '',
        },
      ];
    } else {
      additionalRepData = newRec.additionalRep;
    }
    console.log('additionalRepData', additionalRepData);

    // if(newRec.visit_days){
    const new_visit_days = [
      { checked: false, name: 'Monday' },
      { checked: false, name: 'Tuesday' },
      { checked: false, name: 'Wednesday' },
      { checked: false, name: 'Thursday' },
      { checked: false, name: 'Friday' },
      { checked: false, name: 'Saturday' },
      { checked: false, name: 'Sunday' },
    ].map(item => {
      return {
        ...item,
        checked: newRec.visit_days.filter(item1 => item1.name == item.name)[0]
          ? newRec.visit_days.filter(item1 => item1.name == item.name)[0]
              .checked
          : false,
      };
    });
    console.log('new isfdkl', new_visit_days);
    // props.setStoreInputField(newRec.visit_days = new_visit_days)
    // };
    var list = [];

    props.setStoreInputField({
      ...newRec,
      visit_days: new_visit_days,
      additionalRep: additionalRepData,
    });
    props.setmobNums({ store_mob_number: record.mob_number });
    props.selectedRole({ value: 'Store', label: 'Store' });

    props.setIsViewModes({
      ...props.isViewModes,
      isViewMode_store: true,
    });
    props.setFieldDisabled({
      ...props.fieldDisabled,
      fieldDisabled_store: true,
    });
  };

  const deleteStore = (record, e) => {
    e.preventDefault();
    console.log(record);
    const adminID = record.store_id;

    // setLoading(true);
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios({
              method: 'DELETE',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url:
                process.env.REACT_APP_API_BASE +
                'admin-portal/store?store_id=' +
                adminID,
            })
              .then(response => {
                console.log('store deleted !', response);
                getStoreList();
              })
              .catch(error => {
                console.log(error.response);
                console.log(error.response.status);
                if (
                  error.response.status == 401 &&
                  error.response.data.message ==
                    'The incoming token has expired'
                ) {
                  alert('Session timed out, please login again.');
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.clear();
                  // localStorage.removeItem( "accessLevel" );
                  // reset page location
                  console.log('logout succesfully');
                  document.location.replace('/');
                }
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const searchId = (e, details) => {
    console.log('details', tableData);
    if (details != null) {
      const newTableData = tableData.filter(
        item => item.retailer_name == details.full_company_name,
      );
      setFilteredTableData(newTableData);
    } else {
      setFilteredTableData(tableData);
    }
  };

  const bulkDelete = e => {
    console.log('selectedList==>', props.selectedList);
    var arrData = [];
    props.selectedList.forEach((item, index) => {
      const obj = {};
      if (item) {
        obj['store_id'] = item.store_id;
        arrData.push(obj);
        console.log('arrData==>', arrData);
        confirmAlert({
          title: 'Confirm to Delete',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'yes',
              onClick: () => {
                axios({
                  method: 'PUT',
                  headers: {
                    Authorization: sessionStorage.getItem('idtoken'),
                  },
                  url:
                    process.env.REACT_APP_API_BASE +
                    'admin-portal/store/bulk-delete',
                  data: arrData,
                })
                  .then(response => {
                    console.log(response);
                    console.log('bulk store deleted !', response);
                    getStoreList();
                    props.setSelectedList([]);
                    arrData = [];
                  })
                  .catch(error => {
                    console.log(error.response);
                    console.log(error.response.status);
                    if (
                      error.response.status == 401 &&
                      error.response.data.message ==
                        'The incoming token has expired'
                    ) {
                      alert('Session timed out, please login again.');
                      localStorage.clear();
                      sessionStorage.clear();
                      localStorage.clear();
                      // localStorage.removeItem( "accessLevel" );
                      // reset page location
                      console.log('logout succesfully');
                      document.location.replace('/');
                    }
                  });
              },
            },
            {
              label: 'No',
              onClick: () => {
                return;
              },
            },
          ],
        });
      }
    });
  };

  const columns = [
    {
      title: 'Store ID',
      dataIndex: 'store_id',
      align: 'center',
      ...getColumnSearchProps('store_id'),
    },
    {
      title: 'State',
      dataIndex: 'state',
      align: 'center',
      key: 'state',
      filters:
        userRole == 'manager'
          ? null
          : [
              { text: 'VIC', value: 'VIC' },
              { text: 'ACT', value: 'ACT' },
              { text: 'NSW', value: 'NSW' },
              { text: 'NT', value: 'NT' },
              { text: 'QLD', value: 'QLD' },
              { text: 'SA', value: 'SA' },
              { text: 'TAS', value: 'TAS' },
              { text: 'WA', value: 'WA' },
              { text: 'NI', value: 'NI' },
              { text: 'SI', value: 'SI' },
            ],
      onFilter: (value, record) => {
        console.log('vvvvv', record['state'] == value);
        setFilteredExcelData(record['state'] == value);
        return record['state'] == value;
      },
    },
    {
      title: 'Store Name',
      dataIndex: 'store_name',
      align: 'center',
      ...getColumnSearchProps('store_name'),
    },
    {
      title: 'Retailer Name',
      dataIndex: 'retailer_name',
      align: 'center',
      ...getColumnSearchProps('retailer_name'),
    },
    {
      title: 'Frequency',
      dataIndex: 'visit_freq',
      align: 'center',
      key: 'visit_freq',
      filters: [
        { text: '7', value: '7' },
        { text: '14Odd', value: '14odd' },
        { text: '14Even', value: '14even' },
        { text: '28', value: '28' },
      ],
      onFilter: (value, record) => {
        console.log('record', record);
        console.log(
          'vvvvv',
          value == record.visit_freq,
          value,
          record['visit_freq'],
        );
        // return record['visit_freq'].toLowerCase().includes(value.toLowerCase());
        return record.visit_freq == value;

        // if (record['visit_freq'] == value) {
        //   newFil.push(record)
        // }
      },
      render: (text, record) => {
        if (record['visit_freq'] == '14odd' || record['visit_freq'] == '14O') {
          return <p>14 Odd</p>;
        } else if (
          record['visit_freq'] == '14even' ||
          record['visit_freq'] == '14E'
        ) {
          return <p>14 Even</p>;
        } else {
          return record['visit_freq'];
        }
      },
    },
    {
      title: 'Senior/Primary Rep',
      dataIndex: 'senior_rep_username',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <p>
              {' '}
              <b>Name:</b> {record.senior_rep_username}
            </p>
            <p>
              {' '}
              <b>Status:</b> {record.senior_status}
            </p>
          </>
        );
      },
    },
    // {
    //   title: 'Support Rep',
    //   dataIndex: 'support_rep_username',
    //   align: 'center',
    //   render: (text, record) => {
    //     return (
    //       <>
    //         <p> <b>Name:</b> {record.support_rep_username}</p>
    //         <p> <b>Status:</b> {record.support_status}</p>
    //       </>
    //     )
    //   },
    // },
    // {
    //   title: 'Rep Cover',
    //   dataIndex: 'rep_cover',
    //   align: 'center',
    //   render: (text, record) => {
    //     return (
    //       <>
    //         {(record.additionalRep) && record.additionalRep.map(rec => (
    //           <>
    //             <p> <b>Rep Cover: </b> {rec.rep_cover}</p>
    //             <p> <b>Rep Cover Name: </b> {rec.rep_cover_username}</p>
    //             {/* <p> <b>From Date: </b> {moment(rec.from_date).format('DD/MM/YYYY')}</p>
    //             <p> <b>To Date: </b>  {moment(rec.to_date).format('DD/MM/YYYY')}</p> */}
    //           </>

    //         ))
    //         }
    //       </>
    //     )
    //   },
    // },
    {
      title: 'Rep Contact',
      dataIndex: 'mobile',
      align: 'center',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <button
              style={{
                marginRight: '0px',
                marginLeft: '5px',
                backgroundColor: 'transparent',
              }}
              onClick={e => handleView(record, e)}
            >
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>{' '}
            </button>
            <button
              style={{ marginRight: '0', backgroundColor: 'transparent' }}
              onClick={e => deleteStore(record, e)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </button>
          </>
        );
      },
    },
  ];
  const [openUpd, setOpenUpd] = React.useState(false);
  const [errorMsgBulk, setErrorMsgBulk] = React.useState('');
  const handleUpload = e => {
    e.preventDefault();
    console.log('okkkkk');
    var file = e.target.files,
      f = file[0]; //file info, get file
    console.log(f.name);
    const newArr = [];
    // setFileName(f.name);
    var reader = new FileReader();
    reader.onload = function (e) {
      // console.log(e.target.result)
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' }); // convert to binary, make readed data
      const wsname = readedData.SheetNames[0]; // to find sheet name
      const ws = readedData.Sheets[wsname]; // To get first sheet

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: '',
        blankrows: false,
      }); // to get the header of sheet
      // setFileUploaded( dataParse );
      // console.log(dataParse)
      const headers = dataParse[0];
      console.log('data paser', typeof dataParse, dataParse);

      for (let i = 1; i < dataParse.length; i++) {
        newArr.push({ ...dataParse[i], checked: false });
      }
      var arr = [];
      var arr1 = {};
      newArr.map((rec, i) => {
        headers.map((rec1, index) => {
          arr1[rec1] = rec[index];
        });
        arr.push(arr1);
        arr1 = {};
      });
      var data = [];
      var frq = '';
      var contactD = [];
      arr.map((rec, i) => {
        if (rec['Frequency*'] == '14O' || rec['Frequency*'] == '14o') {
          frq = '14odd';
        } else if (rec['Frequency*'] == '14E' || rec['Frequency*'] == '14e') {
          frq = '14even';
        } else {
          console.log(rec['Frequency*']);
          frq = rec['Frequency*'] != '' ? rec['Frequency*'].toString() : '';
        }
        var aa2 = {
          store_id:
            rec['Store ID*'] != ''
              ? rec['Store ID*'].toString().toUpperCase()
              : '',
          store_name: rec['Store Name*'],
          retailer_name: rec['Retailer Name*'],
          store_size:
            rec['Store Size'] != '' ? rec['Store Size'].toString() : '',
          store_grade: rec['Store Grade'],
          address: rec['Address'],
          state: rec['State*'] == '' ? '' : rec['State*'].toUpperCase(),
          country:
            rec['Country*'] == ''
              ? ''
              : rec['Country*'].charAt(0).toUpperCase() +
                rec['Country*'].slice(1).toLowerCase(),
          region: rec['Region'],
          store_manager: rec['Store Manager'],
          store_manager_email: rec['Store Manager Email'],
          // status: 'active',
          mob_number:
            rec['Store Manager Phone'] != ''
              ? rec['Store Manager Phone'].toString()
              : '',
          territory: rec['Territory'],
          trade_drive_trough: rec['Trade Drive Through'],
          whare_sfs: rec['Warehouse or SFS'],
          visit_freq: frq,
          visit_time: rec['Preferred Visit Time'],
          visit_start_week_no: rec['If Monthly, Select Week*'].toString(),
          senior_rep_username: rec['Senior/Primary Rep Username*'].toString(),
          senior_status: rec['Primary Rep Status'],
          support_rep_username: rec['Support Rep Username'],
          support_status: rec['Support Rep Status'],
          visit_days: rec['Store Visit Days'],
          additionalRep: [],
          // contact: rec['Contact'],
        };

        const dayss = aa2.visit_days && aa2.visit_days.split(',');

        var day = [];
        var additionRep = [];
        dayss &&
          dayss.map(rec3 => {
            console.log(rec3);
            const lower = rec3.toLowerCase();
            const dayy = rec3.charAt(0).toUpperCase() + lower.slice(1);
            console.log(dayy);
            day.push({ name: rec3 != '' ? dayy : '', checked: true });
          });
        console.log(day);
        aa2.visit_days = day;

        filteredTableData.map(rec3 => {
          if (rec3.store_id == aa2.store_id) {
            rec3.additionalRep &&
              rec3.additionalRep.map((rec4, index) => {
                var dateObject;
                var fromdate;
                var dateObjectto;
                var todate;
                // console.log(rec.['Rep Start Date From' + [index + 1]])
                // if (rec.['Rep Start Date From' + [index + 1]] != "") {
                //   fromdate = (rec.['Rep Start Date From' + [index + 1]].split('/'))
                //   dateObject = new Date(+fromdate[2], fromdate[1] - 1, +fromdate[0]);
                //   console.log(dateObject)
                // }
                // else {
                //   dateObject = ''
                // }
                // if (rec.['Rep End Date' + [index + 1]] != "") {
                //   todate = (rec.['Rep End Date' + [index + 1]].split('/'))
                //   dateObjectto = new Date(+todate[2], todate[1] - 1, +todate[0]);
                //   console.log(dateObjectto)
                // }
                // else {
                //   dateObject = ''
                // }
                additionRep.push({
                  rep_cover: rec['Rep Cover' + [index + 1]],
                  rep_cover: rec['Rep Cover Name' + [index + 1]],
                  // 'from_date': dateObject,
                  // 'to_date': dateObjectto
                });
              });
            if (rec3.contact.length > 0) {
              contactD = rec3.contact;
            } else {
              contactD = [];
            }
          }
        });
        console.log('contactD', contactD);
        console.log(additionRep);
        aa2.additionalRep = additionRep;
        aa2.contact = contactD;
        data.push(aa2);
      });
      console.log('contactD', contactD);

      // return;
      console.log(data);
      // return;
      var error = '';
      data.map((rec, i) => {
        console.log(rec);

        if (rec.store_id == '') {
          console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please Enter Store ID';
          setErrorMsgBulk(
            'Error in ' + (i + 2) + ' record. Please Enter Store ID',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.store_name == '') {
          console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please Enter Store ID';

          setErrorMsgBulk(
            'Error in ' + (i + 2) + ' record. Please Enter Store Name',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.retailer_name == '') {
          console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please Enter Store ID';

          setErrorMsgBulk(
            'Error in ' + (i + 2) + ' record. Please Enter Retailer Name',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.state == '') {
          console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please Enter State';

          setErrorMsgBulk(
            'Error in ' + (i + 2) + ' record. Please Enter State',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.country == '') {
          console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please Enter Store ID';

          setErrorMsgBulk(
            'Error in ' + (i + 2) + ' record. Please Enter Country',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.visit_freq == '') {
          console.log('error in' + i + 'record');

          setErrorMsgBulk(
            'Error in ' + (i + 2) + ' record. Please Enter Frequency',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.visit_freq == '28' && rec.visit_start_week_no == '') {
          console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please Enter Store ID';

          setErrorMsgBulk(
            'Error in ' +
              (i + 2) +
              ' record. Please Enter If Monthly, Select Week ',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.senior_rep_username == '') {
          console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please Enter Store ID';

          setErrorMsgBulk(
            'Error in ' + (i + 2) + ' record. Please Enter Senior Rep',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.visit_days.length == 0) {
          console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 2) + ' record. Please Enter visit Days';

          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please Enter visit Days',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
      });
      // return;
      if (error == '') {
        setLoading(true);
        axios({
          method: 'PUT',
          headers: {
            Authorization: sessionStorage.getItem('idtoken'),
          },
          url: process.env.REACT_APP_API_BASE + 'admin-portal/store',
          data: data,
        })
          .then(response => {
            setLoading(false);
            console.log(response);
            setOpenUpd(true);
            setTimeout(() => {
              setOpenUpd(false);
            }, 3000);
            getStoreList();
          })
          .catch(error => {
            console.log(error.response);
            console.log(error.response.status);
            if (
              error.response.status == 401 &&
              error.response.data.message == 'The incoming token has expired'
            ) {
              alert('Session timed out, please login again.');
              localStorage.clear();
              sessionStorage.clear();
              localStorage.clear();
              // localStorage.removeItem( "accessLevel" );
              // reset page location
              console.log('logout succesfully');
              document.location.replace('/');
            }
            if (error.response.status == 504) {
              setLoading(false);
              setOpenUpd(true);
              setTimeout(() => {
                setOpenUpd(false);
              }, 3000);
            }
          });
      }
    };

    reader.readAsBinaryString(f);
    e.target.value = '';
    setErrorMsgBulk('');
  };
  console.log('newFil', newFil);
  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Dialog open={openUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                {errorMsgBulk == '' ? (
                  <>
                    <b style={{ color: 'red' }}>Thank you</b>
                    <br />
                    <br />
                    <label style={{ color: 'red' }}>
                      Store details successfully updated
                    </label>
                  </>
                ) : (
                  <>
                    {' '}
                    <br />
                    <br />
                    <label style={{ color: 'red' }}>{errorMsgBulk}</label>
                  </>
                )}
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Row style={{ margin: '10px 0' }}>
        <Col md={3} className="autoComp" style={{ float: 'left' }}>
          {/* <Autocomplete
            id="combo-box-demo"
            options={props.retailerList}
            onChange={(e, rec) => {
              searchId(e, rec);
            }}
            getOptionLabel={option => option.full_company_name}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Search Retailer Name"
                variant="outlined"
              />
            )}
          /> */}
        </Col>
        <Col
          md={9}
          style={{
            float: 'right',
            textAlign: 'right',
            paddingRight: '25px',
            marginBottom: '-5px',
          }}
          className="uploadExcel"
        >
          <button
            title="Select records to delete!"
            type="button"
            variant="outlined"
            class="btn btn-primary"
            onClick={e => {
              bulkDelete(e);
            }}
            disabled={props.selectedList.length == 0}
          >
            {' '}
            Delete
          </button>
          <input
            type="file"
            id="importFile"
            accept=" .csv, .xlsx"
            style={{ display: 'none' }}
            onChange={handleUpload}
          />
          <label
            htmlFor="importFile"
            class="btn btn-primary"
            style={{ verticalAlign: 'sub' }}
          >
            Import
          </label>
          {/* <button type="button" variant="outlined" class="btn btn-primary" onClick={(e) => exportToCSV(filteredTableData, 'fileName')}>Import</button> */}
          <button
            type="button"
            variant="outlined"
            class="btn btn-primary"
            onClick={e => exportToCSV(filteredData, 'store_listing')}
          >
            Export
          </button>
          <button
            type="button"
            variant="outlined"
            class="btn btn-primary"
            onClick={e => exportToCSV(templateExcelData, 'store_template')}
          >
            Export Template
          </button>
        </Col>
      </Row>
      {/* <br /> */}
      <div className="antdTable">
        {/* {filteredTableData ? ( */}
        <Table
          columns={columns}
          dataSource={filteredTableData}
          rowSelection={{ ...props.rowSelection }}
          rowKey={record => record.store_id}
          onChange={(pagination, filters, sorter, currentPageData) =>
            filterD(currentPageData.currentDataSource)
          }
          pagination={{
            total: filteredData.length,
            showSizeChanger: true,
            showTotal: total => `Total ${total} items`,
            pageSizeOptions: ['10', '50', '100', '200', '500'],
          }}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

export default StoreTable;
