import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tag, Space, Popconfirm } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import 'antd/dist/antd.css';
import axios from 'axios';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Button, Input, Col } from 'reactstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const ManagerTable = props => {
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  let userState = '';
  const userRole = localStorage.getItem('userRole');
  // if (userRole == 'manager') {

  //   userState = localStorage.getItem('userState');
  // }
  console.log('manager table', props);

  const listAdmin = () => {
    setLoading(true);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    })
      .then(response => {
        const admindata = response.data.body;
        // const roles = admindata.filter(item => {
        //   if (
        //     localStorage.getItem('userRole') == 'manager' &&
        //     item.role == 'manager' &&
        //     localStorage.getItem('username') == item.username &&
        //     item.state == localStorage.getItem('userState')
        //   ) {
        //     console.log('userState', userState, item);
        //     return item;
        //   } else if (item.role == 'manager') {
        //     return item;
        //   }
        // });

        const roles = admindata.filter(item => {
          if (
            localStorage.getItem('userRole') == 'manager' &&
            item.role == 'manager' &&
            item.state == localStorage.getItem('userState')
          ) {
            console.log('userState', userState, item);

            return item;
          } else if (
            item.role == 'manager' &&
            localStorage.getItem('userRole') !== 'manager'
          ) {
            return item;
          }
        });

        console.log('managerRoles', roles);
        setFilteredData(roles);
        setTableData(roles);
        setLoading(false);
      })
      .catch(error => {
        console.log('error...', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    }).then(response => {
      const admindata = response.data.body;
      const roles = admindata.filter(item => {
        if (
          item.role == 'manager' &&
          localStorage.getItem('username') == item.username
        ) {
          localStorage.setItem('userState', item.state);
          console.log('userState', userState);
        }
      });
    });
    listAdmin();
  }, []);

  const deleteManager = (record, e) => {
    e.preventDefault();
    console.log(record);
    const adminID = record.id;
    const usrName = record.username;
    console.log(usrName);
    // setLoading(true);
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios({
              method: 'DELETE',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url:
                process.env.REACT_APP_API_BASE +
                'admin-portal?id=' +
                adminID +
                '&username=' +
                usrName,
            })
              .then(response => {
                console.log('manager deleted !', response);
                listAdmin();
              })
              .catch(error => {
                console.log(error.response);
                console.log(error.response.status);
                if (
                  error.response.status == 401 &&
                  error.response.data.message ==
                    'The incoming token has expired'
                ) {
                  alert('Session timed out, please login again.');
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.clear();
                  // localStorage.removeItem( "accessLevel" );
                  // reset page location
                  console.log('logout succesfully');
                  document.location.replace('/');
                }
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleView = (record, e) => {
    console.log('recccc', record);
    console.log('record.mob_number', record.mob_number);
    props.setValue('1');
    props.setManagerInputField(record);
    props.setmobNums({ manager_mob_number: record.mob_number });
    props.selectedRole({ value: 'Manager', label: 'Manager' });
    props.setIsViewModes({
      ...props.isViewModes,
      isViewMode_manager: true,
    });
    props.setFieldDisabled({
      ...props.fieldDisabled,
      fieldDisabled_manager: true,
    });
    if (record.status == 'active') {
      console.log('true active');
      props.setStatusValue(true);
      props.setToggleText('Active');
    } else if (record.status == 'inactive') {
      props.setStatusValue(false);
      props.setToggleText('Inactive');
    }
  };

  const rec =
    tableData &&
    tableData
      .sort((a, b) => a.first_name.localeCompare(b.first_name))
      .map(({ ...item }) => ({
        ...item,
      }));
  console.log('modifyManager', rec);

  const filter = data => {
    data.map(rec => {
      console.log('rec====>', rec);
      setFilteredData(rec);
    });
  };

  const bulkDelete = e => {
    console.log('selectedList==>', props.selectedList);
    var arrData = [];
    props.selectedList.forEach(item => {
      var obj = {};
      if (item) {
        obj['id'] = item.id;
        obj['username'] = item.username;
        arrData.push(obj);
        console.log('arrData==>', arrData);
        confirmAlert({
          title: 'Confirm to Delete',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'yes',
              onClick: () => {
                axios({
                  method: 'PUT',
                  headers: {
                    Authorization: sessionStorage.getItem('idtoken'),
                  },
                  url:
                    process.env.REACT_APP_API_BASE + 'admin-portal/bulk-delete',
                  data: arrData,
                })
                  .then(response => {
                    console.log(response);
                    console.log('bulk manager deleted !', response);
                    listAdmin();
                    props.setSelectedList([]);
                    arrData = [];
                  })
                  .catch(error => {
                    console.log(error.response);
                    console.log(error.response.status);
                    if (
                      error.response.status == 401 &&
                      error.response.data.message ==
                        'The incoming token has expired'
                    ) {
                      alert('Session timed out, please login again.');
                      localStorage.clear();
                      sessionStorage.clear();
                      localStorage.clear();
                      // localStorage.removeItem( "accessLevel" );
                      // reset page location
                      console.log('logout succesfully');
                      document.location.replace('/');
                    }
                  });
              },
            },
            {
              label: 'No',
              onClick: () => {
                return;
              },
            },
          ],
        });
      }
    });
  };

  const columns = [
    {
      title: 'Manager ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Manager',
      dataIndex: 'first_name',
      key: 'first_name',
      align: 'center',
      ...getColumnSearchProps('first_name'),
    },
    {
      title: 'Mob No.',
      dataIndex: 'mob_number',
      align: 'center',
      key: 'mob_number',
      editable: true,
    },
    {
      title: 'State',
      dataIndex: 'state',
      align: 'center',
      key: 'state',
      filters:
        userRole == 'manager'
          ? null
          : [
              { text: 'VIC', value: 'VIC' },
              { text: 'ACT', value: 'ACT' },
              { text: 'NSW', value: 'NSW' },
              { text: 'NT', value: 'NT' },
              { text: 'QLD', value: 'QLD' },
              { text: 'SA', value: 'SA' },
              { text: 'TAS', value: 'TAS' },
              { text: 'WA', value: 'WA' },
              { text: 'NI', value: 'NI' },
              { text: 'SI', value: 'SI' },
            ],
      onFilter: (value, record) => {
        console.log('vvvvv', value == record.state, value, record.state);
        return record['state'] == value;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center',
      key: 'email',
      render: (text, record) => {
        return <p style={{ textTransform: 'lowercase' }}>{record.email}</p>;
      },
      editable: true,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      width: '100px',
      render: (text, record) => {
        return (
          <>
            <button
              style={{
                marginRight: '0px',
                marginLeft: '5px',
                backgroundColor: 'transparent',
              }}
              onClick={e => handleView(record, e)}
            >
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>{' '}
            </button>
            <button
              style={{ marginRight: '0', backgroundColor: 'transparent' }}
              onClick={e => deleteManager(record, e)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Col
        md={9}
        style={{
          float: 'right',
          textAlign: 'right',
          marginBottom: '0px',
          marginTop: '10px',
          paddingRight: '20px',
        }}
        className="uploadExcel"
      >
        <button
          title="Select records to delete!"
          type="button"
          variant="outlined"
          class="btn btn-primary"
          onClick={e => {
            bulkDelete(e);
          }}
          disabled={props.selectedList.length == 0}
        >
          {' '}
          Delete
        </button>
      </Col>
      <div style={{ clear: 'both' }}></div>
      <div className="antdTable">
        {/* {rec ? */}
        <Table
          columns={columns}
          dataSource={rec}
          rowSelection={{ ...props.rowSelection }}
          rowKey={record => record.id + record.username}
          onChange={(pagination, filters, sorter, currentPageData) =>
            filter(currentPageData.currentDataSource)
          }
          pagination={{
            total: filteredData.length,
            showSizeChanger: true,
            showTotal: total => `Total ${total} items`,
            pageSizeOptions: ['10', '50', '100', '200', '500'],
          }}
        />
        {/* : null} */}
      </div>
    </>
  );
};

export default ManagerTable;
