import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tag, Space, Popconfirm } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import 'antd/dist/antd.css';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import axios from 'axios';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const RepTable = props => {
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const [templateExcelData, setTemplateExcelData] = React.useState([]);
  const [listingExcelData, setListingExcelData] = React.useState([]);
  const [openUpd, setOpenUpd] = React.useState(false);
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const userRole = localStorage.getItem('userRole');
  let userState = '';
  if (userRole == 'manager') {
    userState = localStorage.getItem('userState');
  }

  console.log('rep table', props);
  const handleClose = () => {
    setOpenUpd(false);
  };

  const listAdmin = () => {
    setLoading(true);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal/rep-with-stores',
    })
      .then(response => {
        const admindata = response.data.body;

        console.log('admindata', admindata);
        var rar = [];
        var storeList = [];
        const roles = admindata.filter(item => {
          console.log('rep item listing', item);
          if (userRole == 'manager') {
            if (item.role && item.role == 'rep' && item.state == userState) {
              return item;
            }
          } else {
            if (item.role && item.role == 'rep') {
              return item;
            }
          }
        });
        console.log('repRoles', roles);
        filterD(roles);
        setTableData(roles);
        setLoading(false);
      })
      .catch(error => {
        console.log('error...', error);
        setLoading(false);
      });
  };
  const filterD = data => {
    console.log(data);
    var rar = [];
    var storeList = [];
    data &&
      data.map(rec => {
        console.log(rec);
        const vendorDet = rec.venderDetails ? rec.venderDetails : [];
        console.log('vendorDet', vendorDet);
        Array.isArray(vendorDet) &&
          vendorDet.map((rec1, index) => {
            rar.push({
              ['Retailer Name' + [index + 1]]: rec1.retailer_name,
              ['Vendor ID' + [index + 1]]: rec1.vendor_id,
            });
          });
        console.log('exactly the same', rar);

        const aa = {
          'Rep ID': rec.id.toString(),
          Username: rec.username,
          'First Name': rec.first_name,
          'Last Name': rec.last_name,
          'Personal Email': rec.personal_email,
          Email: rec.email,
          State: rec.state,
          'Rep Type': rec.rep_type,
          'Mob Number': rec.mobile,
          Address: rec.address,
          Status: rec.status,
          'Employment Type': rec.employement_type,
          'Employment Status': rec.employment_status,
          'Line Manager': rec.line_manager,
          'Rep Region': rec.rep_region,
          'Rep Territory': rec.rep_territory,
          Notes: rec.notes,
          store: rec.stores.toString(),
        };
        rar.map((rec3, index) => {
          Object.keys(rec3).map((key, index) => {
            console.log(key);
            aa[key] = rec3[key];
            rar = [];
          });
          rar = [];
        });
        rar = [];
        // })
        rar = [];

        storeList.push(aa);
        rec.availabeInfo.map(days => {
          aa[days.availablity] =
            days.avail_val == true
              ? days.start_time + '-' + days.end_time + '=' + days.hours + 'hrs'
              : '0hrs';
        });
      });
    console.log(storeList);

    setListingExcelData(storeList);
  };
  useEffect(() => {
    listAdmin();
    const head = {
      Role: '',
      Status: '',
      ID: '',
      'First Name': '',
      'Last Name': '',
      Username: '',
      'Employement Type': '',
      'Employment Status': '',
      'Line Manager': '',
      Address: '',
      State: '',
      'Rep Region': '',
      'Rep Territory': '',
      Email: '',
      'Personal Email': '',
      'Mob Number': '',
      'Retailer Name1': '',
      'Vendor ID1': '',
      'Retailer Name2': '',
      'Vendor ID2': '',
      'Retailer Name3': '',
      'Vendor ID3': '',
      'Retailer Name4': '',
      'Vendor ID4': '',
      'Retailer Name5': '',
      'Vendor ID5': '',
    };
    setTemplateExcelData([head]);
  }, []);

  const deleteRep = (record, e) => {
    e.preventDefault();
    console.log(record);
    const repID = record.id;
    const usrName = record.username;
    console.log(usrName);
    // setLoading(true);
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios({
              method: 'DELETE',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url:
                process.env.REACT_APP_API_BASE +
                'admin-portal?id=' +
                repID +
                '&username=' +
                usrName,
            })
              .then(response => {
                console.log('manager deleted !', response);
                listAdmin();
              })
              .catch(error => {
                console.log(error.response);
                console.log(error.response.status);
                if (
                  error.response.status == 401 &&
                  error.response.data.message ==
                    'The incoming token has expired'
                ) {
                  alert('Session timed out, please login again.');
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.clear();
                  // localStorage.removeItem( "accessLevel" );
                  // reset page location
                  console.log('logout succesfully');
                  document.location.replace('/');
                }
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const handleView = (record, e) => {
    console.log('recccc', record.availabeInfo.length);
    // console.log('record.availabeInfo', record.availabeInfo[0].start_time);
    var availaData;
    props.setCurrentRepType(record.rep_type);
    if (
      record.availabeInfo == undefined ||
      record.availabeInfo == '' ||
      record.availabeInfo.length == 1 ||
      record.availabeInfo.length == 0
    ) {
      availaData = [
        {
          availablity: 'Monday',
          avail_val: false,
          start_time: '00:00',
          end_time: '00:00',
          hours: '24:00',
        },
        {
          availablity: 'Tuesday',
          avail_val: false,
          start_time: '00:00',
          end_time: '00:00',
          hours: '24:00',
        },
        {
          availablity: 'Wednesday',
          avail_val: false,
          start_time: '00:00',
          end_time: '00:00',
          hours: '24:00',
        },
        {
          availablity: 'Thursday',
          avail_val: false,
          start_time: '00:00',
          end_time: '00:00',
          hours: '24:00',
        },
        {
          availablity: 'Friday',
          avail_val: false,
          start_time: '00:00',
          end_time: '00:00',
          hours: '24:00',
        },
        {
          availablity: 'Saturday',
          avail_val: false,
          start_time: '00:00',
          end_time: '00:00',
          hours: '24:00',
        },
        {
          availablity: 'Sunday',
          avail_val: false,
          start_time: '00:00',
          end_time: '00:00',
          hours: '24:00',
        },
      ];
    } else {
      props.setAvailable(record.availabeInfo);
      availaData = record.availabeInfo;
      var startData;
      var endData;
      {
        record.availabeInfo &&
          record.availabeInfo.map(item => {
            console.log('item===>', item);
            // startData = item.start_time,
            // endData = item.end_time
          });
      }
    }
    // let availData = [];

    console.log('startData==>', startData);
    console.log('endData==>', endData);
    props.setAvailable(availaData);
    // props.setAvailable(record.availabeInfo);
    props.setValue('1');
    props.setRepFields(record);
    props.setmobNums({ rep_mob_number: record.mobile });
    props.setVendorDetails(record.venderDetails);
    props.selectedRole({ value: 'Rep', label: 'Rep' });
    props.setIsViewModes({
      ...props.isViewModes,
      isViewMode_rep: true,
    });
    props.setFieldDisabled({
      ...props.fieldDisabled,
      fieldDisabled_rep: true,
    });
    if (record.status == 'active') {
      console.log('true active');
      props.setStatusValue(true);
      props.setToggleText('Active');
    } else if (record.status == 'inactive') {
      props.setStatusValue(false);
      props.setToggleText('Inactive');
    }
  };

  const rec =
    tableData &&
    tableData
      .sort((a, b) => a.first_name.localeCompare(b.first_name))
      .map(({ ...item }) => ({
        ...item,
      }));
  console.log('modifyRep', rec);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const columns = [
    {
      title: 'Rep ID',
      dataIndex: 'id',
      align: 'center',
      key: 'id',
      // fixed: 'left',
      // width: 100,
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Rep',
      dataIndex: 'first_name',
      align: 'center',
      key: 'first_name',
      // fixed: 'left',
      // width: 100,
      ...getColumnSearchProps('first_name'),
    },
    {
      title: 'Mob No.',
      dataIndex: 'mobile',
      align: 'center',
      key: 'mobile',
    },
    {
      title: 'State',
      dataIndex: 'state',
      align: 'center',
      key: 'state',
      filters:
        userRole == 'manager'
          ? null
          : [
              { text: 'VIC', value: 'VIC' },
              { text: 'ACT', value: 'ACT' },
              { text: 'NSW', value: 'NSW' },
              { text: 'NT', value: 'NT' },
              { text: 'QLD', value: 'QLD' },
              { text: 'SA', value: 'SA' },
              { text: 'TAS', value: 'TAS' },
              { text: 'WA', value: 'WA' },
              { text: 'NI', value: 'NI' },
              { text: 'SI', value: 'SI' },
            ],
      onFilter: (value, record) => {
        console.log('vvvvv', value == record.state, value, record.state);
        return record['state'] == value;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center',
      key: 'email',
      render: (text, record) => {
        return <p style={{ textTransform: 'lowercase' }}>{record.email}</p>;
      },
    },
    {
      title: 'Stores',
      dataIndex: 'stores',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            {record.stores &&
              record.stores.map(rec => (
                <>
                  {record.stores && record.stores.length == 1 ? (
                    <span>{rec}</span>
                  ) : (
                    <span>{rec + ', '}</span>
                  )}
                </>
              ))}
          </>
        );
      },
    },
    {
      title: 'No. of Stores',
      dataIndex: 'Nostore',
      align: 'center',
      key: 'Nostore',
      render: (text, record) => {
        return (
          <>
            {record.stores && record.stores.length > 0 ? (
              <span>{record.stores && record.stores.length}</span>
            ) : null}
          </>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      // fixed: 'right',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <button
              style={{
                marginRight: '0px',
                marginLeft: '5px',
                backgroundColor: 'transparent',
              }}
              onClick={e => handleView(record, e)}
            >
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>{' '}
            </button>
            <button
              style={{ marginRight: '0', backgroundColor: 'transparent' }}
              onClick={e => deleteRep(record, e)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Dialog open={openUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Rep details successfully updated
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Col
        md={9}
        style={{ float: 'right', textAlign: 'right' }}
        className="uploadExcel"
      >
        <button
          style={{ marginTop: '10px' }}
          type="button"
          variant="outlined"
          class="btn btn-primary"
          onClick={e => exportToCSV(listingExcelData, 'rep_listing')}
        >
          Export
        </button>
      </Col>
      {/* <Col md={9} style={{ float: 'right', textAlign: 'right' }} className="uploadExcel">
        <input type='file' id='importFile' style={{ display: 'none' }} onChange={handleUpload} />
        <label htmlFor='importFile' class="btn btn-primary" style={{ verticalAlign: 'sub' }} >Import</label> */}
      {/* <button type="button" variant="outlined" class="btn btn-primary" onClick={(e) => exportToCSV(filteredTableData, 'fileName')}>Import</button> */}
      {/* <button type="button" variant="outlined" class="btn btn-primary" onClick={(e) => exportToCSV(listingExcelData, 'rep_listing')} >Export</button>
        <button type="button" variant="outlined" class="btn btn-primary" onClick={(e) => exportToCSV(templateExcelData, 'rep_template')}>Export Template</button>
      </Col> */}
      <div style={{ clear: 'both' }}></div>
      <div className="antdTable">
        {/* {rec ? ( */}
        <Table
          columns={columns}
          dataSource={rec}
          scroll={{ x: 1300 }}
          onChange={(pagination, filters, sorter, currentPageData) =>
            filterD(currentPageData.currentDataSource)
          }
          pagination={{
            total: listingExcelData.length,
            showSizeChanger: true,
            showTotal: total => `Total ${total} items`,
            pageSizeOptions: ['10', '50', '100', '200', '500'],
          }}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

export default RepTable;
