import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import {
  EmptyLayout,
  LayoutRoute,
  MainLayout,
  MainLoginLayout,
} from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import DashboardPage from 'pages/DashboardPage';
import TaskManagement from 'pages/AdminPages/TaskManagement';
import Admin from 'pages/AdminPages/Admin';
import AddAdmin from 'pages/AdminPages/AddAdmin';
import Manager from 'pages/AdminPages/Manager';
import Rep from 'pages/AdminPages/Rep';
import Reports from 'pages/AdminPages/Reports';
import Retailer from 'pages/AdminPages/Retailer';
import RequestManagement from 'pages/AdminPages/RequestManagement';
import Store from 'pages/AdminPages/Store';
import Supplier from './pages/AdminPages/Supplier';
import AdhocTask from './pages/SupplierPages/AdhocTask';
import Invoice from './pages/SupplierPages/Invoice';
import ProductCatalogue from './pages/SupplierPages/ProductCatalogue';
import Administration from './pages/SupplierPages/Administration';
import ProgressTaskSupplierTable from './pages/Tables/ProgressTaskSupplierTable';
import TableViewAdmin from './pages/TableViewAdmin';
import RequestAdhocTask from 'pages/AdminPages/RequestAdhocTask';
import TableTaskView from './pages/Tables/TableTaskView';
import AdminProductCatalogue from './pages/AdminPages/AdminProductCatalogue';

class App extends React.Component {
  // automaticTimeout = time => {
  componentDidMount() {
    const espiryDate = localStorage.getItem('expiry');
    console.log('espiryDate', espiryDate);
    setTimeout(() => {
      if (localStorage.getItem('username')) {
        console.log('espiryDate', espiryDate - 60 * 60 * 4000);
        // eslint-disable-next-line no-alert
        alert('Session time out, please sign in again');
        sessionStorage.clear();
        localStorage.clear();

        document.location.replace('/');
      }
    }, espiryDate - 60 * 60 * 4000);
  }
  // }, time - 240000);
  // };
  render() {
    return (
      <BrowserRouter>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />

            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                <PrivateRoute
                  exact
                  path="/dashboard"
                  component={DashboardPage}
                />
                <PrivateRoute exact path="/task" component={TaskManagement} />
                <PrivateRoute exact path="/addadmin" component={AddAdmin} />
                <PrivateRoute exact path="/admin" component={Admin} />
                <PrivateRoute exact path="/manager" component={Manager} />
                <PrivateRoute exact path="/rep" component={Rep} />
                <PrivateRoute exact path="/reports" component={Reports} />
                <PrivateRoute
                  exact
                  path="/runList"
                  component={RequestManagement}
                />
                <PrivateRoute exact path="/store" component={Store} />
                <PrivateRoute exact path="/retailer" component={Retailer} />
                <PrivateRoute exact path="/supplier" component={Supplier} />

                <PrivateRoute exact path="/adhoc" component={AdhocTask} />
                <PrivateRoute exact path="/invoice" component={Invoice} />
                <PrivateRoute
                  exact
                  path="/progressTask"
                  component={ProgressTaskSupplierTable}
                />

                <PrivateRoute
                  exact
                  path="/product-catalogue"
                  component={ProductCatalogue}
                />
                <PrivateRoute
                  exact
                  path="/tableView"
                  component={TableViewAdmin}
                />
                <PrivateRoute
                  exact
                  path="/administration"
                  component={Administration}
                />
                <PrivateRoute
                  exact
                  path="/requestadhoctask"
                  component={RequestAdhocTask}
                />
                <PrivateRoute
                  exact
                  path="/tasktableview"
                  component={TableTaskView}
                />
                <PrivateRoute
                  exact
                  path="/ProductCatalogue"
                  component={AdminProductCatalogue}
                />
              </React.Suspense>
            </MainLayout>
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const currentUser = sessionStorage.getItem('username');

  return (
    <Route
      {...rest}
      render={props =>
        authenticate(currentUser) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  );
};

const authenticate = currentUser => {
  console.log('currentUser', currentUser);
  if (currentUser !== null && currentUser !== undefined) {
    return true;
  }
  return false;
};

export default componentQueries(query)(App);
