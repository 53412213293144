import Page from 'components/Page';
import React, { useState } from 'react';
import { useEffect } from 'react';
import greyBanner from 'assets/img/logo/Banner Grey.png';
import workImage from 'assets/img/work.png';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import validator from 'validator';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import { isValid } from 'date-fns';
import {
  MAX_ID_LENGTH,
  MAX_TASK_NAME_LENGTH,
  MAX_USERNAME_LENGTH,
} from '../../utils/constants.js';

export const isValidID = id => {
  let isValid = true;
  var regex = /^[^!@#$%^&*()+=~<>,?/`+=]+$/;
  isValid = regex.test(id);
  console.log('input ID', id, isValid);
  return isValid;
};
// TODO: should check if allow space
export const isInvalidID = id => {
  let isInvalid = false;
  var regex = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;
  if (id.length > MAX_ID_LENGTH) {
    return true;
  }
  isInvalid = regex.test(id);
  console.log('input ID', id, isValid);
  return isInvalid;
};
export const isInvalidUsername = (username, allowSpace = false) => {
  let isInvalid = false;
  if (username.length > MAX_USERNAME_LENGTH) {
    return true;
  }

  var regex = allowSpace
    ? /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/
    : /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~\s]/;
  isInvalid = regex.test(username);
  console.log('input username', username, isInvalid);
  return isInvalid;
};

export const isInvalidTaskName = taskname => {
  let isInvalid = false;
  if (taskname.length > MAX_TASK_NAME_LENGTH) {
    return true;
  }

  var regex = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;
  isInvalid = regex.test(taskname);
  console.log('input taskname', taskname, isInvalid);
  return isInvalid;
};

const AdminSetup = props => {
  console.log(props);
  // console.log(props.statusValue, props.toggleText);
  const {
    statusValue,
    adminInputHandler,
    requiredError,
    setRequiredError,
    setAdminInputField,
    adminInputField,
    setStatusValue,
    toggleText,
    setToggleText,
    isDisabled,
    setIsDisabled,
    fieldDisabled,
    setmobValue,
    mobValue,
  } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  ////// toggle checkbox function show content
  // const [toggleText, setToggleText] = React.useState('Inactive');
  // const [isDisabled, setIsDisabled] = useState(false);
  const [btnUpdate, setBtnUpdate] = React.useState(false);
  // const [statusValue, setStatusValue] = useState(props.statusValue);
  const [open, setOpen] = React.useState(false);
  const [openUpd, setOpenUpd] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [adminData, setAdmindata] = useState([]);
  // const [adminInputField, setAdminInputField] = useState({
  //   role: 'admin',
  //   status: '',
  //   id: '',
  //   first_name: '',
  //   last_name: '',
  //   address: '',
  //   state: '',
  //   mob_number: '',
  //   personal_email: '',
  //   business_email: '',
  //   username: '',
  // });
  // const [mobValue, setmobValue] = useState({
  //   mob_number: '',
  // });

  ////////////// toggle checkbox to show content
  const showContent = () => {
    var checkBox = document.getElementById('myCheck');
    console.log('checkbox ', checkBox.checked);
    // setStatusValue(checkBox.checked)
    if (checkBox.checked == true) {
      // props.setStatusValue(true);
      props.setToggleText('Active');
    } else {
      // props.setStatusValue('inactive');
      props.setToggleText('Inactive');
    }
    props.setStatusValue(checkBox.checked);
  };

  /////////set phone value
  const mobileValue = e => {
    props.setmobValue({ ...props.mobValue, mob_number: e });
  };

  // const [requiredError, setRequiredError] = React.useState({
  //   adminId: '',
  //   personalEmail: '',
  //   businessEmail: '',
  //   adminUsername: '',
  // });

  //////// onchange input handler function
  // const adminInputHandler = e => {
  //   props.setAdminInputField({
  //     ...props.adminInputField,
  //     [e.target.name]: e.target.value,
  //   });
  //   if (e.target.name == 'id') {
  //     setRequiredError({ id: '' });
  //   }
  //   if (e.target.name == 'username') {
  //     setRequiredError({ username: '' });
  //   }
  //   if (e.target.name == 'personal_email') {
  //     setRequiredError({ personal_email: '' });
  //   }
  //   if (e.target.name == 'business_email') {
  //     setRequiredError({ business_email: '' });
  //   }
  // };

  //// Add Admin Data
  const addAdmin = () => {
    console.log('apidata', adminInputField);
    if (adminInputField.id == '' || adminInputField.id == null) {
      setRequiredError({ adminId: 'Please Enter ID' });
      return;
    }
    if (
      adminInputField.id != '' &&
      adminInputField.id != null &&
      isInvalidID(adminInputField.id)
    ) {
      setRequiredError({
        adminId:
          'Invalid ID, please remove special characters or shorten the ID',
      });
      return;
    }

    if (props.idError != '') {
      return;
    }
    if (adminInputField.username == '' || adminInputField.username == null) {
      setRequiredError({ adminUsername: 'Please Enter Username' });
      return;
    }
    if (
      adminInputField.username != '' &&
      adminInputField.username != null &&
      isInvalidUsername(adminInputField.username)
    ) {
      setRequiredError({
        adminUsername:
          'Invalid username, please remove special characters, space or shorten the username',
      });
      return;
    }
    // if (!validator.isEmail(adminInputField.personal_email)) {
    //   setRequiredError({ personalEmail: 'Enter Valid Personal Email' });
    //   return;
    // }
    if (!validator.isEmail(adminInputField.business_email)) {
      setRequiredError({ businessEmail: 'Enter Valid Business Email' });
      return;
    }
    if (adminInputField.username == '' || adminInputField.username == null) {
      setRequiredError({ usrname: 'Enter Username' });
      return;
    }
    console.log({
      ...adminInputField,
      mob_number: props.mobValue.mob_number,
      id: adminInputField.id.toUpperCase(),
      status: props.statusValue,
    });
    const data = {
      ...adminInputField,
      id: adminInputField.id.toUpperCase(),

      mob_number: props.mobValue.mob_number,
      status: props.statusValue ? 'active' : 'inactive',
    };
    axios({
      method: 'POST',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
      data: data,
    })
      .then(response => {
        console.log(response);
        if (response.data.ResponseMetadata) {
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
          props.setBtnUpdate(false);
          props.setmobValue({
            mob_number: '',
          });
          // props.setStatusValue('active');
          props.setStatusValue(true);
          props.setToggleText('Active');
          setAdminInputField({
            status: 'inactive',
            id: '',
            first_name: '',
            last_name: '',
            address: '',
            state: '',
            mob_number: '',
            personal_email: '',
            business_email: '',
            username: '',
          });
        }
      })
      .catch(error => {
        console.log('error.Response', error.response.data);
        if ((error = 'Email already in use')) {
          setOpenError(true);
          setAdminInputField({
            ...adminInputField,
            business_email: '',
          });
        }
      });
  };

  ////////// cancel input Field handler
  const cancelInputField = () => {
    props.setStatusValue(true);
    props.setToggleText('Active');
    props.setIsDisabled(false);
    props.setBtnUpdate(false);
    setAdminInputField({
      role: 'admin',
      status: 'inactive',
      id: '',
      first_name: '',
      last_name: '',
      address: '',
      state: '',
      mob_number: '',
      personal_email: '',
      business_email: '',
      username: '',
    });
    props.setmobValue({
      mob_number: '',
    });
    // props.setIsViewModes({
    //   ...isViewModes,
    //   isViewMode_admin: false,
    // });
    props.setFieldDisabled({
      ...fieldDisabled,
      fieldDisabled_admin: false,
    });
    setRequiredError('');
  };
  ///// Update Admin
  const updateAdmin = () => {
    props.setIsDisabled(false);
    setOpenUpd(true);
    const data = {
      ...adminInputField,
      mob_number: props.mobValue.mob_number,
      id: adminInputField.id.toUpperCase(),
      status: props.statusValue ? 'active' : 'inactive',
    };
    axios({
      method: 'PUT',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
      data: data,
    }).then(response => {
      console.log(response);
      console.log(response.data);
      if (response.data) {
        setOpenUpd(true);
        setTimeout(() => {
          setOpenUpd(false);
          listAdmin();
        }, 3000);
        props.setBtnUpdate(false);
        props.setmobValue({
          mob_number: '',
        });
        // props.setStatusValue(false);
        props.setStatusValue(true);
        props.setToggleText('Active');
        setAdminInputField({
          status: 'inactive',
          id: '',
          first_name: '',
          last_name: '',
          address: '',
          state: '',
          mob_number: '',
          personal_email: '',
          business_email: '',
          username: '',
        });
      }
    });
  };
  const listAdmin = () => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    }).then(response => {
      const admindata = response.data.Items;
      setAdmindata(admindata);
    });
  };

  // useEffect(() => {
  //   listAdmin();
  // }, []);

  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloses = () => {
    setOpenError(false);
    props.setStatusValue(true);
    props.setToggleText('Active');
  };

  const handleClose = () => {
    props.setStatusValue(true);
    props.setToggleText('Active');
    setOpenError(false);
    setOpen(false);
    setOpenUpd(false);
    props.setAdminInputField({
      role: 'admin',
      status: '',
      id: '',
      first_name: '',
      last_name: '',
      address: '',
      state: '',
      mob_number: '',
      personal_email: '',
      business_email: '',
      username: '',
      password: '',
    });
    setmobValue({
      mob_number: '',
    });
    setRequiredError('');
  };

  useEffect(() => {
    const disabledVal = props.fieldDisabled.fieldDisabled_admin;

    const admin_form_inputs = document.querySelectorAll('#admin_form input');
    // console.log(admin_form_inputs);
    for (let i = 0; i < admin_form_inputs.length; i++) {
      // store_id should not change
      if (
        admin_form_inputs[i].name !== 'id' &&
        admin_form_inputs[i].name !== 'username' &&
        admin_form_inputs[i].name !== 'business_email'
      ) {
        admin_form_inputs[i].disabled = disabledVal;
      }
    }
  }, [props.fieldDisabled]);

  return (
    <>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Admin details successfully added
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Admin details successfully updated
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openError}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Sorry</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>Email already in use</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleCloses} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Page>
        <Row style={{ height: '100%' }}>
          <div
            className="rep-container-1"
            style={{ width: '40rem', maxWidth: '40rem' }}
          >
            <div className="rep-container-1-inner">
              <Col xl={12} lg={12} md={12} style={{ paddingLeft: '0' }}>
                <Card id="userManagement">
                  <CardBody
                    style={{ background: '#fff', padding: '0px 18px 18px 0px' }}
                  >
                    <div id="tabCol" style={{ paddingTop: '0%' }}>
                      {/* <Row>
                    <Col md="6">
                      <b style={{ fontFamily: 'Arial Bold' }}>ADMIN - SETUP </b>
                    </Col>
                  </Row> */}
                      <Form id="admin_form">
                        <FormGroup>
                          {/* <Row>
                          <Col md="3">
                            <label className="switch">
                              <Input
                                type="checkbox"
                                name="status"
                                id="myCheck"
                                // onClick={showContent}
                                onChange={e => showContent(e)}
                                // value={statusValue}
                                checked={props.statusValue}
                              />
                              <span className="slider round"></span>
                            </label>
                            <p style={{ fontSize: '14px' }}>{props.toggleText}</p>
                          </Col>
                        </Row> */}
                          <Row md="12">
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Admin ID<span style={{ color: 'red' }}> *</span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="id"
                                style={{ textTransform: 'uppercase' }}
                                onChange={adminInputHandler}
                                disabled={isDisabled}
                                value={adminInputField.id}
                                placeholder="Admin ID"
                                className="admin-input"
                              />
                              <p style={{ color: 'red', fontSize: '12px' }}>
                                {requiredError.adminId}
                                {props.idError}
                              </p>
                            </Col>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Username<span style={{ color: 'red' }}> *</span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="username"
                                disabled={isDisabled}
                                onChange={adminInputHandler}
                                value={adminInputField.username}
                                placeholder="Username"
                                className="admin-input"
                              />
                              <p style={{ color: 'red', fontSize: '12px' }}>
                                {requiredError.adminUsername}
                              </p>
                            </Col>
                          </Row>
                          <Row md="12">
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                First Name<span style={{ color: 'red' }}></span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="first_name"
                                onChange={adminInputHandler}
                                value={adminInputField.first_name}
                                disabled={fieldDisabled['fieldDisabled_admin']}
                                placeholder="First Name"
                                className="admin-input"
                              />
                            </Col>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Last Name<span style={{ color: 'red' }}></span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="last_name"
                                onChange={adminInputHandler}
                                value={adminInputField.last_name}
                                placeholder="Last Name"
                                className="admin-input"
                              />
                            </Col>
                          </Row>
                          <Row md="12">
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Personal Email
                                {/* <span style={{ color: 'red' }}> *</span>{' '} */}
                              </label>
                              <Input
                                type="text"
                                name="personal_email"
                                onChange={adminInputHandler}
                                value={adminInputField.personal_email}
                                placeholder="Personal Email"
                                className="admin-input"
                              />{' '}
                              {/* <p style={{ color: 'red', fontSize: '12px' }}>
                              {requiredError.personalEmail}
                            </p> */}
                            </Col>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Business Email
                                <span style={{ color: 'red' }}> *</span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="business_email"
                                onChange={adminInputHandler}
                                disabled={isDisabled}
                                value={adminInputField.business_email}
                                placeholder="Business Email"
                                className="admin-input"
                              />{' '}
                              <p style={{ color: 'red', fontSize: '12px' }}>
                                {requiredError.businessEmail}
                              </p>
                            </Col>
                          </Row>
                          <Row md="12">
                            <Col md="12">
                              <label style={{ fontSize: '1vw' }}>
                                Address<span style={{ color: 'red' }}></span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="address"
                                onChange={adminInputHandler}
                                value={adminInputField.address}
                                placeholder="Full Address"
                                className="admin-input"
                              />
                            </Col>
                          </Row>
                          <Row md="12">
                            <Col>
                              <label>State</label>
                              <Input
                                type="select"
                                name="state"
                                className="admin-input"
                                placeholder="State"
                                onChange={adminInputHandler}
                                value={adminInputField.state}
                                required
                                style={{
                                  background:
                                    '#E6E6E6 0% 0% no-repeat padding-box',
                                  font: 'normal normal 16px/32px Arial',
                                  letterSpacing: '0px',
                                  color: '#000000',
                                  borderRadius: '32px',
                                  marginTop: '0px',
                                }}
                              >
                                <option selected value="">
                                  Select State
                                </option>
                                <option value="ACT">
                                  Australian Capital Territory
                                </option>
                                <option value="NSW">New South Wales</option>
                                <option value="NT">Northern Territory</option>
                                <option value="SA">South Australia</option>
                                <option value="QLD">Queensland</option>
                                <option value="TAS">Tasmania</option>
                                <option value="VIC">Victoria</option>
                                <option value="WA">Western Australia</option>
                                <option value="NI">North Island</option>
                                <option value="SI">South Island</option>
                              </Input>
                            </Col>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Mobile Number
                                <span style={{ color: 'red' }}></span>{' '}
                              </label>
                              <PhoneInput
                                className="admin-input" //className="phnclass"
                                name="mob_number"
                                onChange={mobileValue}
                                value={mobValue.mob_number}
                                placeholder="Moblile Number"
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            {props.btnUpdate == false ? (
                              <Col md={12} className="martop">
                                <Button
                                  onClick={addAdmin}
                                  variant="outlined"
                                  color="primary"
                                >
                                  <span>
                                    {' '}
                                    <b> Add Admin</b>{' '}
                                  </span>
                                </Button>
                                <Button
                                  onClick={cancelInputField}
                                  variant="outlined"
                                  color="primary"
                                >
                                  <span>
                                    {' '}
                                    <b>Cancel</b>{' '}
                                  </span>
                                </Button>
                              </Col>
                            ) : (
                              <Col md={12} className="martop">
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={updateAdmin}
                                >
                                  <span>
                                    {' '}
                                    <b> Update</b>{' '}
                                  </span>
                                </Button>
                                <Button
                                  onClick={cancelInputField}
                                  variant="outlined"
                                  color="primary"
                                >
                                  <span>
                                    {' '}
                                    <b>Cancel</b>{' '}
                                  </span>
                                </Button>
                              </Col>
                            )}
                          </Row>
                        </FormGroup>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
          <Col
            className="admin-svg"
            style={{ marginBottom: '0', maxWidth: '50rem' }}
          >
            <div
              className="rep-container-2 hide-scrollbar"
              style={{ position: 'relative', height: '100%' }}
            >
              <img
                src={workImage}
                style={{
                  width: '15rem',
                  position: 'absolute',
                  bottom: '5rem',
                  right: '5rem',
                }}
              ></img>

              <svg
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '3px',
                  width: '100%',
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="1255.939"
                height="258.104"
                viewBox="0 0 1255.939 258.104"
              >
                <path
                  id="Path_238"
                  data-name="Path 238"
                  d="M7503.517,1717.894c-154.665,393.656-1139.565-31.267-1139.565-31.267l-115.683-58.649"
                  transform="translate(-6248.043 -1627.531)"
                  fill="none"
                  stroke="#ff1313"
                  stroke-width="1"
                />
              </svg>
            </div>
          </Col>
        </Row>
      </Page>
    </>
  );
};

export default AdminSetup;
