import React, { useEffect, useState, useRef } from 'react';
import { BiDownload } from 'react-icons/bi';
import { Table, Space } from 'antd';
import 'antd/dist/antd.css';
import { Input, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import AWS from 'aws-sdk';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import { confirmAlert } from 'react-confirm-alert';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import moment from 'moment';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const viewRangelist = props => {
  const { getAllProductsRangeList } = props;
  const [tableData, setTableData] = React.useState([]);
  const [filteredTableData, setFilteredTableData] = React.useState([]);
  const [supplierList, setSupplierList] = React.useState([]);
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');

  const [actionHint, setActionHint] = useState({
    open: false,
    text: '',
    title: 'Thank you',
  });

  const getTableData = () => {
    setLoading(true);
    props.setSelectedList([]);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'rangelist' + '?status=Approved',
    })
      .then(response => {
        // console.log('getAllProductsRangeList ', response);
        const result = response.data.body.Items;
        if (Array.isArray(result) && result.length > 0) {
          console.log('getAll RangeList ', result);
          const filteredList = result.filter(
            item => item.status.toLowerCase() == 'approved',
          );
          // const list = [];

          // const list = result.reduce((prevVal, curItem, curIdx) => {
          //   const lastIdx = prevVal.length - 1;

          //   if (
          //     lastIdx >= 0 &&
          //     // prevVal[lastIdx].supplier_name == curItem.supplier_name
          //     prevVal[lastIdx].supplier_id == curItem.supplier_id
          //   ) {
          //     return prevVal;
          //   } else {
          //     prevVal.push({
          //       supplier_name: curItem.supplier_name,
          //       supplier_id: curItem.supplier_id,
          //       rangelist_name: curItem.rangelist_name,
          //     });
          //     return prevVal;
          //   }
          // }, []);
          // console.log('lll', list);
          setTableData(filteredList);
          setFilteredTableData(filteredList);
          filter(filteredList);
          setFilteredData(filteredList);

          // setFilteredTableData(uploadData);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log('doc for supplier fail...', error);
        setLoading(false);
      });
  };

  const getSupplierList = () => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    }).then(response => {
      const admindata = response.data.body;
      // const admindata = response.data.body.Items;
      const roles = admindata.filter(item => {
        if (item.role && item.role == 'supplier') {
          return item;
        }
      });
      console.log('sup Roles', roles);
      setSupplierList(roles);
    });
  };

  useEffect(() => {
    getTableData();
    getSupplierList();
  }, []);

  const deleteDocFromS3 = record => {
    // delete doc in S3
    const formatedUploadDate = moment(record.datetime).format(
      'DDMMYYYY-HHmmss',
    );
    const curKey = `admin-documents/range-lists/${record.supplier_id}/${formatedUploadDate}/${record.rangelist_name}`;
    // const curKey = `admin-documents/range-lists/${record.supplier_id}/${record.rangelist_name}`;
    console.log('delete range list key', curKey);

    var params = { Bucket: process.env.REACT_APP_BUCKET_NAME, Key: curKey };
    AWS.config.update({
      accessKeyId: sessionStorage.getItem('AccessKeyId'),
      secretAccessKey: sessionStorage.getItem('AccessKeySec'),
      sessionToken: sessionStorage.getItem('sessionToken'),
    });
    const myBucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
      region: 'ap-southeast-2',
    });

    myBucket.deleteObject(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
        setActionHint({
          ...actionHint,
          open: true,
          text: err.mes,
          title: 'Sorry',
        });
        setTimeout(() => {
          setActionHint({
            open: false,
            text: err.mes,
            title: 'Sorry',
          });
        }, 3000);
      } else {
        console.log('rangelist deleted from S3', data);
        setActionHint({
          ...actionHint,
          open: true,
          text: 'Range list deleted successfully!',
          title: 'Thank you',
        });
        setTimeout(() => {
          setActionHint({
            ...actionHint,
            open: false,
          });
        }, 3000);
        getTableData();
        getAllProductsRangeList && getAllProductsRangeList();
      }
    });
  };
  const deleteDocFromDB = record => {
    confirmAlert({
      style: { position: 'relative', zIndex: '9999' },
      message: 'Are you sure to delete current rangelist?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios({
              method: 'DELETE',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url:
                process.env.REACT_APP_API_BASE +
                'rangelist?supplier_id=' +
                record.supplier_id +
                '&datetime=' +
                record.datetime,
              // url:
              //   process.env.REACT_APP_API_BASE +
              //   'rangelist?supplier_id=' +
              //   record.supplier_id +
              //   '&rangelist_name=' +
              //   encodeURIComponent(record.rangelist_name),
            })
              .then(response => {
                console.log('delete rangelist res ', response);
                if (response) {
                  // const result = response.data.body.Items;

                  deleteDocFromS3(record);
                  // setActionHint({
                  //   ...actionHint,
                  //   open: true,
                  //   text: 'Range list deleted successfully!',
                  //   title: 'Thank you',
                  // });
                  // setTimeout(() => {
                  //   setActionHint({
                  //     ...actionHint,
                  //     open: false,
                  //   });
                  // }, 3000);
                  // getTableData();
                } else {
                  console.log('not 200 status deleteDocFromDB', response);
                }
              })
              .catch(err => {
                console.log('deleteDocFromDB  fail...', err);
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };
  const deleteDoc = (e, record) => {
    console.log('Delete rangelist for a supplier', record);
    e.preventDefault();

    // Delete from DB first, then delete from S3
    deleteDocFromDB(record);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => { }, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const modifyFilteredTableData = filteredTableData
    .sort((a, b) => a.supplier_name.localeCompare(b.supplier_name))
    .map(({ ...item }) => ({
      ...item,
    }));

  const filter = data => {
    data.map(rec => {
      // console.log('rec====>', rec)
      setFilteredData(rec);
    });
  };

  const bulkDelete = e => {
    e.preventDefault();
    console.log('selectedList==>', props.selectedList);
    var arrData = [];
    var keyArray = [];
    props.selectedList.forEach(item => {
      if (item) {
        var formatedUploadDate = moment(item.datetime).format(
          'DDMMYYYY-HHmmss',
        );
        const obj = {};
        obj['datetime'] = item.datetime;
        obj['supplier_id'] = item.supplier_id;
        var curKey = `admin-documents/range-lists/${item.supplier_id}/${formatedUploadDate}/${item.rangelist_name}`;
        arrData.push(obj);
        keyArray.push(curKey);
        console.log('arrData==>', arrData, keyArray);
        confirmAlert({
          title: 'Confirm to Delete',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'yes',
              onClick: () => {
                axios({
                  method: 'POST',
                  headers: {
                    Authorization: sessionStorage.getItem('idtoken'),
                  },
                  url: process.env.REACT_APP_API_BASE + 'rangelist/bulk-delete',
                  data: arrData,
                })
                  .then(response => {
                    console.log(response);
                    console.log('bulk range list deleted !', response);
                    getTableData();
                    props.setSelectedList([]);
                    bulkDeleteS3(keyArray);
                    props.setSelectedList([]);
                  })
                  .catch(error => {
                    console.log(error.response);
                    console.log(error.response.status);
                    if (
                      error.response.status == 401 &&
                      error.response.data.message ==
                      'The incoming token has expired'
                    ) {
                      alert('Session timed out, please login again.');
                      localStorage.clear();
                      sessionStorage.clear();
                      localStorage.clear();
                      // localStorage.removeItem( "accessLevel" );
                      // reset page location
                      console.log('logout succesfully');
                      document.location.replace('/');
                    }
                  });
              },
            },
            {
              label: 'No',
              onClick: () => {
                return;
              },
            },
          ],
        });
      }
    });
  };

  const bulkDeleteS3 = allKeys => {
    console.log('allkeys', allKeys);
    Array.isArray(allKeys) &&
      allKeys.length > 0 &&
      allKeys.forEach(curKey => {
        var params = { Bucket: process.env.REACT_APP_BUCKET_NAME, Key: curKey };
        AWS.config.update({
          accessKeyId: sessionStorage.getItem('AccessKeyId'),
          secretAccessKey: sessionStorage.getItem('AccessKeySec'),
          sessionToken: sessionStorage.getItem('sessionToken'),
        });
        const myBucket = new AWS.S3({
          params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
          region: 'ap-southeast-2',
        });

        myBucket.deleteObject(params, function (err, data) {
          if (err) {
            console.log(err, err.stack);
          } else {
            console.log('file deleted from S3', data);
            getTableData();
          }
        });
      });
  };

  const columns = [
    {
      title: 'Seq',
      dataIndex: 'seq',
      align: 'center',
      render: (text, rec, index) => <>{index + 1}</>,
    },
    {
      title: 'Supplier ID',
      dataIndex: 'supplier_id',
      align: 'center',
      ...getColumnSearchProps('supplier_id'),
    },
    {
      title: 'Supplier Name',
      dataIndex: 'supplier_name',
      align: 'center',
      filters:
        Array.isArray(supplierList) &&
        supplierList.map(item => ({
          text: item.full_company_name,
          value: item.full_company_name,
        })),
      // filteredValue: (filterInfo && filterInfo.supplier_name) || null,
      onFilter: (value, record) => {
        console.log('vvv', value, record);
        return record.supplier_name == value;
      },
      filterSearch: true,
    },
    {
      title: 'Rangelist Name',
      dataIndex: 'doc_name',
      align: 'center',
      dataIndex: 'rangelist_name',
    },
    {
      title: 'Uploaded Datetime',
      dataIndex: 'datetime',
      align: 'center',
      render: (text, record) => {
        return <>{moment(record.datetime).format('DD/MM/YYYY HH:mm:ss')}</>;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      render: (text, record) => {
        const formatedUploadDate = moment(record.datetime).format(
          'DDMMYYYY-HHmmss',
        );
        return (
          <>
            <button
              style={{
                padding: '0',
                marginLeft: '6px',
                backgroundColor: 'transparent',
              }}
            // onClick={e => downloadDoc(e, record)}
            >
              {/* TODO: need to figure out how  */}
              <a
                href={`${process.env.REACT_APP_CDN}${record.upload_by
                  }-documents/range-lists/${record.supplier_id
                  }/${formatedUploadDate}/${encodeURIComponent(
                    record.rangelist_name,
                  )}`}
              >
                <BiDownload
                  style={{ fontSize: '20px', color: 'rgb(35, 34, 36)' }}
                />
              </a>
            </button>
            <button
              style={{ marginRight: '0', backgroundColor: 'transparent' }}
              onClick={e => deleteDoc(e, record)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </button>
          </>
        );
      },
    },
  ];

  const handleClose = () => {
    setActionHint({
      open: false,
      text: 'File deleted successfully!',
      title: 'Thank you',
    });
  };

  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Dialog open={actionHint.open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>{actionHint.title}</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>{actionHint.text}</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Col
        md={9}
        style={{ float: 'right', textAlign: 'right', marginBottom: '4px' }}
        className="uploadExcel"
      >
        <button
          title="Select records to delete!"
          type="button"
          variant="outlined"
          class="btn btn-primary"
          onClick={e => {
            bulkDelete(e);
          }}
          disabled={props.selectedList.length == 0}
        >
          {' '}
          Delete
        </button>
      </Col>
      <div style={{ clear: 'both' }}></div>
      <div className="antdTable">
        {/* {modifyFilteredTableData ? */}
        <Table
          columns={columns}
          // scroll={{ x: 1500 }}
          rowSelection={{ ...props.rowSelection }}
          rowKey={record => record.supplier_id + record.rangelist_name}
          onChange={(pagination, filters, sorter, currentPageData) =>
            filter(currentPageData.currentDataSource)
          }
          dataSource={modifyFilteredTableData}
          pagination={{
            total: filteredData.length,
            showTotal: total => `Total ${total} items`,
            pageSizeOptions: ['10', '50', '100', '200', '500'],
          }}
        />
        {/* : null
        } */}
      </div>
    </>
  );
};

export default viewRangelist;
