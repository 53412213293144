import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tag, Space } from 'antd';
import 'antd/dist/antd.css';
import {
  Button,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';

import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Multiselect from 'multiselect-react-dropdown';
import { DialogTitle } from '@material-ui/core';
import greyBanner from 'assets/img/logo/Banner Grey.png';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const TaskManagementArchived = props => {
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchColText, setSearchColText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const searchInput = useRef(null);
  let [filteredData] = useState();
  console.log(props);
  const taskManagData = [
    {
      // 'date': '02/06/2022',
      // 'supplier': 'Supp 1',
      // 'country': 'AU',
      // 'state': 'VIC',
      // 'stores': 'Store 1',
      // 'frequency': 'One Off',
      // 'task_id': 'Task 1',
      // 'task_name': 'Name of task',
      // 'task_type': 'Task type',
      // 'task_priority': 'Priority 1',
      // 'task_description': 'desc',
    },
  ];

  console.log(taskManagData);

  const handleRowClick = e => {
    e.preventDefault();
    console.log('You clicked.');
  };
  const rec = taskManagData.map(({ ...item }) => ({
    ...item,
  }));

  const [taskData, setTaskData] = React.useState([]);
  const [reassignOpen, setReassignOpen] = React.useState(false);
  const [reAssignPayload, setReassignPayload] = useState({});

  const getTasks = () => {
    setLoading(true);
    const curUserRole = localStorage.getItem('userRole');
    // let url = process.env.REACT_APP_API_BASE + 'task-management/archived';
    // let url = process.env.REACT_APP_API_BASE + 'archived';
    // let url = process.env.REACT_APP_TASKS_BASE + 'archived';
    // let url = process.env.REACT_APP_TASKS_BASE + 'archived';
    let url = process.env.REACT_APP_CDN + 'archived_tasks/tasks.json';
    if (curUserRole == 'manager') {
      const curManagerState = localStorage.getItem('userState');
      console.log(' manager login , get archived only for that state task');

      // url = `${process.env.REACT_APP_API_BASE}task-management/manager/archive/${curManagerState}`;
      // url = `${process.env.REACT_APP_API_BASE}archived/${curManagerState}`;
      // url = `${process.env.REACT_APP_TASKS_BASE}archived?state=${curManagerState}`;
      url = `${process.env.REACT_APP_CDN}tasks/archived/${curManagerState}/tasks.json`;
    }

    axios({
      method: 'GET',
      // headers: {
      //   Authorization: sessionStorage.getItem('idtoken'),
      // },
      url: url,
    })
      .then(response => {

        console.log('data===>', response);
        const data = response.data || [];

        const modifyData = data.sort((a, b) =>
          a.task_name.localeCompare(b.task_name),
        );
        // setTaskData(modifyData)
        var suppData = modifyData;

        console.log(suppData);
        setTaskData(suppData);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
        console.log(error.response);
        // console.log(error.response.status);
        if (
          error &&
          error.response &&
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };

  useEffect(() => {
    getTasks();
  }, []);
  console.log('modifyTaskManage', rec);
  // const getColumnSearchProps = (dataIndex) => ({
  //   // filterDropdown: ({
  //   //   setSelectedkeys,
  //   //   selectedKeys,
  //   //   confirm,
  //   //   clearFilters,
  //   // }) => (
  //   //   <div style={{ padding: 0 }}>
  //   //     <br />
  //   //     <Input
  //   //       placeholder={`Search ${dataIndex}`}
  //   //       value={selectedKeys[0]}
  //   //       onChange={(e) =>
  //   //         setSelectedkeys(e.target.value ? [e.target.value] : [])}
  //   //       onPressEnter={() => handleSearchCol(selectedKeys, confirm, dataIndex)}
  //   //       style={{ width: 167, marginLeft: '7px', display: "block", marginTop: '-10px', height: '35px' }}
  //   //     />
  //   //     <Space className='btnGap'>
  //   //       <Button onClick={() => handleSearchCol(selectedKeys, confirm, dataIndex)}
  //   //         icon={<SearchOutlined />}
  //   //         size="small"
  //   //         style={{ width: 82, height: 36 }}
  //   //       >
  //   //         Search
  //   //       </Button>
  //   //       <Button onClick={() => handleResetCol(clearFilters)}
  //   //         size="small"
  //   //         style={{ width: 82, height: 36 }}
  //   //       >
  //   //         Reset
  //   //       </Button>
  //   //     </Space>
  //   //   </div>
  //   // ),
  //   // filterIcon: (filtered) => (
  //   //   <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  //   // ),
  //   // onFilter: (value, record) =>
  //   //   record[dataIndex]
  //   //     ? record[dataIndex]
  //   //       .toString()
  //   //       .toLowerCase()
  //   //       .includes(value.toLowerCase())
  //   //     : "",
  //   // render: (text) =>
  //   //   searchedColumn === dataIndex ? (
  //   //     <Highlighter
  //   //       highlightStyle={{ backgroundColor: "#ffc69", padding: 0 }}
  //   //       searchWords={[searchColText]}
  //   //       autoEscape
  //   //       textToHighlight={text ? text.toString() : ""}
  //   //     />
  //   //   ) : (
  //   //     text
  //   //   ),
  // });

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => { }, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // const handleSearchCol = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setSearchColText(selectedKeys[0]);
  //   setSearchedCol(dataIndex);
  // };

  // const handleResetCol = (clearFilters) => {
  //   clearFilters();
  //   setSearchText("");
  // };

  const handleChange = (...sorter) => {
    console.log('sorter', sorter);
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
    const currentDataSource = sorter[3].currentDataSource;
    setTaskData(currentDataSource);
  };
  // const clearAll = () => {
  //   setSortedInfo({});
  //   setSearchText("");
  //   // taskManagData();
  // };

  // const handleSearch = (e) => {
  //   setSearchText(e.target.value);
  //   if (e.target.value === "") {
  //     //  loadData();
  //   }
  // }

  const globalSearch = () => {
    filteredData = rec.filter(value => {
      return (
        value.supplier.toLowerCase().includes(searchText.toLowerCase()) ||
        value.country.toLowerCase().includes(searchText.toLowerCase()) ||
        value.state.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    // setTaskManageData(filteredData);
  };
  const approveTask = (e, data) => {
    console.log(data);
    const taskInfo = [{ ...data, task_status: 'approved' }];

    console.log(taskInfo);

    confirmAlert({
      title: 'Confirm to approve the task',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios({
              method: 'PUT',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url: process.env.REACT_APP_API_BASE + 'task-management',
              data: taskInfo,
            })
              .then(response => {
                console.log(response);
                getTasks();
              })
              .catch(error => {
                console.log(error.response);
                console.log(error.response.status);
                if (
                  error.response.status == 401 &&
                  error.response.data.message ==
                  'The incoming token has expired'
                ) {
                  alert('Session timed out, please login again.');
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.clear();
                  // localStorage.removeItem( "accessLevel" );
                  // reset page location
                  console.log('logout succesfully');
                  document.location.replace('/');
                }
                if (error.response.status == 504) {
                  getTasks();
                }
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };
  const handleCloseRe = () => {
    setReassignOpen(false);
  };
  const handleReassign = () => {
    console.log('reassign data', reAssignPayload);
    setLoading(true);
    axios({
      method: 'PUT',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'task-management',
      data: [{ ...reAssignPayload, task_status: 'reassigned' }],
    })
      .then(response => {
        console.log(response);
        getTasks();
        setLoading(false);

        setReassignOpen(false);
        setReassignPayload({});
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };
  const editTask = (e, data) => {
    console.log(data);
    const taskDat = props.taskInfo;
    // return;
    var taskDte = data.taskDate && data.taskDate.split('/');

    var d1 = new Date(
      Number(taskDte[2]),
      Number(taskDte[1]) - 1,
      Number(taskDte[0]),
    );
    data.documentAdd &&
      data.documentAdd.map(rec => {
        if (rec.document != '') {
          props.setEditView({ edit_adhoc: true, edit_task: true });
        } else {
          props.setEditView({ edit_adhoc: false, edit_task: true });
        }
      });
    // data.task_photos && (data.task_photos).map(rec => {
    //   props.setTaskPhotos([{
    //     ...rec,
    //     photo_name: rec.photo_name,
    //     task_photos_notes: rec.task_photos_notes,
    //     client_photos_shareable: rec.client_photos_shareable == 'yes' ? true : false
    //   }])
    // })
    // props.setEditView({ 'edit_task': true });
    console.log(data);
    // return;
    var caalc = [];
    caalc.push(data.callCycle);
    console.log(caalc);
    var statee = [];
    data.country.map(rec => {
      if (rec.value == 'Australia') {
        statee.push(
          { label: 'ACT', value: 'ACT' },
          { label: 'NSW', value: 'NSW' },
          { label: 'NT', value: 'NT' },
          { label: 'QLD', value: 'QLD' },
          { label: 'SA', value: 'SA' },
          { label: 'TAS', value: 'TAS' },
          { label: 'VIC', value: 'VIC' },
          { label: 'WA', value: 'WA' },
        );
      } else if (rec.value == 'New Zealand') {
        statee.push({ label: 'NI', value: 'NI' }, { label: 'SI', value: 'SI' });
      }
    });
    console.log(props.filteredRetailer);
    var retailer = [];
    var suppliere = [];
    data.state.map(rec1 => {
      props.retailerList.map(rec => {
        if (rec.state == rec1.value && rec.status == 'active') {
          retailer.push(rec);
        }
      });
      props.supplierListing.map(rec => {
        if (rec.state == rec1.value) {
          suppliere.push(rec);
        }
      });
    });
    console.log(retailer);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE +
        'call-cycle/list-by-supplier?supplier_username=' +
        data.supplier_name,
    }).then(response => {
      console.log(response.data);
      if (response.data != null) {
        const admindata = response.data.body;

        admindata.map(rec => {
          return (rec.checked = true);
        });
        console.log(admindata);

        // admindata.map(rec3 => {
        //   if (rec3.retailer == data.retailer_name) {
        //     rec3.storeList && rec3.storeList.map(rec2 => {
        //       data.state && (data.state).map(rec1 => {
        //         // taskInfo[i].storeOp && (taskInfo[i].storeOp).map(rec => {
        //           if (rec1.state == rec1.value && rec2.store_id == rec.store_id) {
        //             storess.push({ value: rec2.store_id, label: rec2.store_id, checked: true, store_id: rec2.store_id, store_name: rec2.store_name })
        //           }
        //         // })
        //       })
        //     })
        //   }
        // })

        //
      }
    });
    taskDat.map(rec => {
      props.setTaskInfo([
        {
          ...rec,
          PK: data.PK,
          SK: data.SK,
          created_date: data.created_date ? new Date(data.created_date) : '',
          action: data.action,
          task_id: data.task_id,
          appsync_id: data.appsync_id,

          task_description: data.task_description,
          task_name: data.task_name,
          callCycle: caalc[0],
          country: data.country,
          documentAdd: data.documentAdd,
          retailer_name: data.retailer_name,
          state: data.state,
          stateOptions: statee,
          store_id: data.store_id,
          store_name: data.store_name,
          fine_line: data.fine_line,
          supplier_id: data.supplier_id,
          supplier_name: data.supplier_name,
          supplier_name: data.supplier_name,
          taskDate: d1,
          task_approval: data.task_approval,
          task_approval_notes: data.task_approval_notes,
          task_priority: data.task_priority,
          task_type: data.task_type,
          dayOffCheck: data.oneOff == true ? true : false,
          recurringCheck: data.recurring == true ? true : false,
          recurring: data.recurring == true ? true : false,
          recurringValue: data.recurringValue ? data.recurringValue : '',
          startDate: data.startDate ? new Date(data.startDate) : '',
          endDate: data.endDate ? new Date(data.endDate) : '',
          week_startDate: data.week_startDate
            ? new Date(data.week_startDate)
            : '',
          questions: data.questions,
          stores: [data.stores],
          // storeOptions: [data.stores]
          task_photos: [
            {
              photo_name: data.task_photos[0].photo_name,
              task_photos_notes: data.task_photos[0].task_photos_notes,
              client_photos_shareable:
                data.task_photos[0].client_photos_shareable == 'yes'
                  ? true
                  : false,
            },
          ],
          task_comments: [
            {
              comment: data.task_photos[0].comment,
              task_comments_notes: data.task_photos[0].task_comments_notes,
              client_comments_shareable:
                data.task_photos[0].client_comments_shareable == 'yes'
                  ? true
                  : false,
            },
          ],
        },
      ]);
      // props.setCallCycleList(caalc[0])
      console.log(props.setRetailerList);
      props.setFilteredRetailer(retailer);
      props.setFilteredSupplier(suppliere);
      console.log(props.retailerList);
      props.setCallCycleList([{ callCycleOptions: caalc[0] }]);
    });

    console.log(props.taskInfo);
    props.setValue('1');

    // console.log({
    //   ...data,
    //   taskDate: new Date(data.taskDate), endDate: new Date(data.endDate), created_date: new Date(data.created_date), startDate: new Date(data.startDate),
    //   'taskDate': new Date(d1)
    // })
  };
  const [viewData, setViewData] = React.useState([]);
  const detailsTask = (e, record) => {
    console.log(record);
    var hours = record.time_spent / (1000 * 60 * 60);
    var absoluteHours = Math.floor(hours);
    var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

    //Get remainder from hours and convert to minutes
    var minutes = (hours - absoluteHours) * 60;
    var absoluteMinutes = Math.floor(minutes);
    var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

    //Get remainder from minutes and convert to seconds
    var seconds = (minutes - absoluteMinutes) * 60;
    var absoluteSeconds = Math.floor(seconds);
    var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

    const timmee = h + ':' + m + ':' + s;
    console.log(h + ':' + m + ':' + s);
    // }
    setViewData({ ...record, time_spent: timmee });
    setOpen(true);
  };
  const columns = [
    {
      title: 'Task ID',
      dataIndex: 'task_id',
      align: 'left',
      editable: true,
      key: 'task_id',
      ...getColumnSearchProps('task_id'),
    },
    {
      title: 'Task Name',
      dataIndex: 'task_name',
      align: 'left',
      editable: true,
      ...getColumnSearchProps('task_name'),
    },
    // {
    //   title: "Task Type",
    //   dataIndex: "task_type",
    //   align: "left",
    //   editable: true,
    //   filters: [
    //     { text: 'Order Task', value: 'Order Task' },
    //     { text: 'Merch Task', value: 'Merch Task' },
    //     { text: 'Yes/No Task', value: 'Yes/No Task' },
    //   ],
    //   onFilter: (value, record) => record.task_type === value
    // },
    {
      title: 'Task Priority',
      dataIndex: 'task_priority',
      align: 'left',
      editable: true,
      filters: [
        { text: 'Critical', value: 'Critical' },
        { text: 'High', value: 'High' },
        { text: 'Medium', value: 'Medium' },
        { text: 'Low', value: 'Low' },
      ],
      onFilter: (value, record) => record.task_priority === value,
      // sorter: (a, b) => {
      //   const order = {'Critical': 4, 'High': 3, 'Medium': 2, 'Low': 1};
      //   return order[a.task_priority] - order[b.task_priority]
      // },
      // sortOrder: sortedInfo.columnKey === "task_priority" && sortedInfo.order,
    },
    {
      title: 'Task Status',
      dataIndex: 'task_status',
      align: 'left',
      editable: true,
      filters: [
        { text: 'Created', value: 'created' },
        { text: 'In Progress', value: 'in_progress' },
        { text: 'Cannot complete', value: 'cannot_complete' },
        { text: 'Done', value: 'done' },
        { text: 'Voided', value: 'avoided' },
        { text: 'Paused', value: 'paused' },
        { text: 'Reassigned', value: 'reassigned' },
        { text: 'Approved', value: 'approved' },
        { text: 'Rolled Over', value: 'rolled_over' },
      ],
      onFilter: (value, record) => record.task_status === value,
      render: (text, record) => {
        return (
          <>
            {record.task_status == 'created' ? <p>Created</p> : null}
            {record.task_status == 'in_progress' ? <p>In Progress</p> : null}
            {record.task_status == 'cannot_complete' ? (
              <p>Cannot complete</p>
            ) : null}
            {record.task_status == 'done' ? <p> Done</p> : null}
            {/* {record.task_status == 'pushed' ? <p> Pushed</p> : null} */}
            {record.task_status == 'avoided' ? <p> voided</p> : null}
            {record.task_status == 'paused' ? <p> Paused</p> : null}
            {record.task_status == 'reassigned' ? <p> Reassigned</p> : null}
            {record.task_status == 'approved' ? <p> Approved</p> : null}
            {record.task_status == 'rolled_over' ? <p> Rolled Over</p> : null}
          </>
        );
      },
    },
    {
      title: 'Task Description',
      dataIndex: 'task_description',
      align: 'left',
      editable: true,
      width: 300,
      render: (text, record) => (
        <div dangerouslySetInnerHTML={{ __html: record.task_description }} />
      ),
    },
    {
      title: 'Task Date',
      dataIndex: 'taskdate',
      align: 'left',
      editable: true,
      key: 'taskDate',
      sorter: (a, b) => {
        const newA = a.taskdate.split('/').reverse().join('-');
        const newB = b.taskdate.split('/').reverse().join('-');
        return +new Date(newA) - +new Date(newB);
      },
      sortOrder: sortedInfo.columnKey === 'taskDate' && sortedInfo.order,
    },
    {
      title: 'Store ID',
      dataIndex: 'store_id',
      align: 'left',
      editable: true,
      ...getColumnSearchProps('store_id'),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) => {
        console.log('record', record);
        return (
          <>
            {/* <Link to={{ pathname: '/tasktableview', state: { rec: record, tabValue: props.value }, page: '/task' }}><svg height='18' width='18' fill="#000000" xmlns="http://www.w3.org/2000/svg" data-name="Layer 3" viewBox="0 0 24 24" x="0px" y="0px"><path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
              <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path></svg></Link> */}
            {record.task_status == 'created' ? (
              <button
                style={{
                  marginRight: '0px',
                  marginLeft: '5px',
                  backgroundColor: 'transparent',
                }}
                onClick={e => editTask(e, record)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#000"
                  className="bi bi-pencil"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>{' '}
              </button>
            ) : null}
            {/* {record.task_status ? ( */}
            <button
              onClick={e => {
                detailsTask(e, record);
              }}
              style={{
                marginRight: '0px',
                marginLeft: '5px',
                backgroundColor: 'transparent',
                float: 'right',
              }}
            >
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>{' '}
            </button>
            {record.task_status == 'done' &&
              (record.task_approval == 'Required' ||
                record.task_approval == 'required' ||
                record.task_approval == '') ? (
              <>
                <Button
                  style={{ marginRight: '5px', marginLeft: '5px' }}
                  onClick={e => {
                    approveTask(e, record);
                  }}
                >
                  Approve Task{' '}
                </Button>
                <Button
                  style={{ marginRight: '0' }}
                  onClick={() => {
                    setReassignOpen(true);
                    console.log(record);
                    setReassignPayload({
                      ...reAssignPayload,
                      PK: record.PK,
                      SK: record.SK,
                      store_name: record.store_name,
                      store_id: record.store_id,
                      task_id: record.task_id,
                      id: record.id,
                    });
                    console.log('Record', record);
                  }}
                >
                  Re assign Task{' '}
                </Button>
              </>
            ) : null}
            {/* ) : null} */}
          </>
        );
      },
    },
  ];
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Modal isOpen={reassignOpen} className="plan-modal-dialog">
        <ModalHeader style={{ justifyContent: 'center' }}>
          Task Details
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <label>Store Name</label>
              <Input
                type="text"
                name="store_name"
                disabled
                value={reAssignPayload.store_name}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label> Feedback</label>
              <Input
                type="textarea"
                name="task_name"
                // disabled
                value={reAssignPayload.feedback_reassign}
                className="admin-input"
                onChange={e => {
                  setReassignPayload({
                    ...reAssignPayload,
                    feedback_reassign: e.target.value,
                  });
                }}
              ></Input>
            </Col>
          </Row>

          <Row className="modalRow">
            <Col>
              <Button
                onClick={handleReassign}
                variant="outlined"
                color="primary"
              >
                Submit
              </Button>
              <Button
                onClick={handleCloseRe}
                variant="outlined"
                color="primary"
              >
                Close
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={open} className="plan-modal-dialog">
        <ModalHeader style={{ justifyContent: 'center' }}>
          Task Details
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <label>Created Date</label>
              <Input
                type="text"
                name="created_date"
                disabled
                value={moment(viewData.created_date).format('DD/MM/YYYY')}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label>Task ID</label>
              <Input
                type="text"
                name="task_id"
                disabled
                value={viewData.task_id}
                className="admin-input"
              ></Input>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <label>Task Name</label>
              <Input
                type="text"
                name="task_name"
                disabled
                value={viewData.task_name}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label>Task Description</label>
              <div
                className="admin-input form-control task-detail-desc"
                style={{ backgroundColor: '#e9ecef', height: 'auto' }}
                dangerouslySetInnerHTML={{ __html: viewData.task_description }}
              />
              {/* <Input
                type="text"
                name="task_description"
                disabled
                value={viewData.task_description}
                className="admin-input"
              >

              </Input> */}
            </Col>
          </Row>

          <Row>
            {/* <Col md={6}>
              <label>Task Type</label>
              <Input
                type="text"
                name="task_type"
                disabled

                value={viewData.task_type}
                className="admin-input"
              >

              </Input>
            </Col> */}
            <Col md={6}>
              <label>Task Priority</label>
              <Input
                type="text"
                name="task_name"
                disabled
                value={viewData.task_priority}
                className="admin-input"
              ></Input>
            </Col>
            {/* <Col md={6}>
              <label>Rep Name</label>
              <Input
                type="text"
                name="rep_name"
                disabled

                value={viewData.rep_name}
                className="admin-input"
              >

              </Input>
            </Col> */}
            {/* </Row>

          <Row> */}
            <Col md={6}>
              <label>Store Name</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={viewData.store_name}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label>Supplier Name</label>
              <Input
                type="text"
                name="task_name"
                disabled
                value={viewData.supplier_name}
                className="admin-input"
              ></Input>
            </Col>
            {/* </Row>

          <Row> */}
            <Col md={6}>
              <label>Task Status</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={viewData.task_status}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label>Time Spent</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={viewData.time_spent}
                className="admin-input"
              ></Input>
            </Col>
            {/* </Row>
          <Row> */}
            <Col md={6}>
              <label>Rep's Comment</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={viewData.comments_from_rep}
                className="admin-input"
              ></Input>
            </Col>

            {viewData.rep_images && viewData.rep_images.length > 0 ? (
              <Col md={6}>
                <label>Photos From Rep</label>
                <br />
                {/* <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + viewData.task_id + "/rep/" + rec} >
     <img style={{ width: '60px' }} src={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/0306/rep/Image0.jpg'} />
   </a></> */}
                {viewData.rep_images &&
                  viewData.rep_images.map(rec => rec && rec.key && rec.key.length > 0 && (
                    <a
                      style={{ marginRight: '10px' }}
                      target="_blank"
                      href={
                        process.env.REACT_APP_CDN + rec.key
                        // 'admin-documents/taskDocuments/' +
                        // viewData.task_id +
                        // '/rep/images/' +
                        // viewData.taskDate.replace(/[^\d]/g, '') +
                        // '/' +
                        // rec
                      }
                    >
                      <img
                        style={{ width: '60px' }}
                        alt='rep photos'
                        src={
                          process.env.REACT_APP_CDN + rec.key
                          // 'admin-documents/taskDocuments/' +
                          // viewData.task_id +
                          // '/rep/images/' +
                          // viewData.taskDate.replace(/[^\d]/g, '') +
                          // '/' +
                          // rec
                        }
                      />
                    </a>
                  ))}
              </Col>
            ) : null}
            {/* {viewData.documentAdd && viewData.documentAdd.length > 0 ? ( */}
            {viewData.documentAdd &&
              viewData.documentAdd.length > 0 &&
              viewData.documentAdd[0].document &&
              viewData.documentAdd[0].document.length > 0 && (
                <Col md={6}>
                  <label>Admin Document</label>
                  <br />
                  {/* <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + viewData.task_id + "/rep/" + rec} >
     <img style={{ width: '60px' }} src={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/0306/rep/Image0.jpg'} />
   </a></> */}
                  {viewData.documentAdd &&
                    viewData.documentAdd.length > 0 &&
                    viewData.documentAdd[0].document &&
                    viewData.documentAdd[0].document.length > 0 &&
                    viewData.documentAdd.map(rec => (
                      <a
                        style={{ marginRight: '10px' }}
                        // documents have different cdn link based on if it is signed or not
                        href={
                          rec.signed && rec.signed == true
                            ? process.env.REACT_APP_CDN +
                            'admin-documents/taskDocuments/' +
                            viewData.task_id +
                            '/signedDoc/' +
                            viewData.taskDate.split('/')[0] +
                            viewData.taskDate.split('/')[1] +
                            viewData.taskDate.split('/')[2] +
                            '/' +
                            viewData.store_id +
                            '/' +
                            rec.document
                            : process.env.REACT_APP_CDN +
                            'admin-documents/taskDocuments/' +
                            viewData.task_id +
                            '/' +
                            rec.document
                        }
                      >
                        <span style={{ display: 'inline-block' }}>
                          {rec.document}
                        </span>
                        {/* <img
                        style={{ width: '60px' }}
                        alt="document icon"
                        src={rec.signed && rec.signed == true ? (process.env.REACT_APP_CDN +
                          'admin-documents/taskDocuments/' +
                          viewData.task_id +
                          '/signedDoc/' +
                          viewData.taskDate.split('/')[0] +
                          viewData.taskDate.split('/')[1] +
                          viewData.taskDate.split('/')[2] +
                          '/' +
                          viewData.store_id +
                          '/' +
                          rec.document) :
                          (process.env.REACT_APP_CDN +
                            'admin-documents/taskDocuments/' +
                            viewData.task_id +
                            '/rep/' +
                            rec.document)
                        }
                      /> */}
                      </a>
                    ))}
                </Col>
              )}

            {/* ) : null} */}
            {viewData.docs_from_rep && viewData.docs_from_rep.length > 0 ? (
              <>
                {/* <Col md={6}>
                  <label>Rep's Documents </label><br />

                  {viewData.docs_from_rep && ( viewData.docs_from_rep ).map( rec => (
                    <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + viewData.task_id + "/rep/" + rec.document} >
                      {rec.document}
                    </a>

                  ) )}

                </Col> */}

                <Col md={6}>
                  <label>Document Description </label>
                  <br />

                  {viewData.docs_from_rep &&
                    viewData.docs_from_rep.map(rec => (
                      <Input
                        type="text"
                        name="task_type"
                        disabled
                        value={rec.description}
                        className="admin-input"
                      ></Input>
                    ))}
                </Col>
              </>
            ) : null}
            {viewData.rep_images_cannot_complete &&
              viewData.rep_images_cannot_complete.length > 0 ? (
              <Col md={6}>
                <label>Can not complete Photos</label>
                <br />
                {/* <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + viewData.task_id + "/rep/" + rec} >
     <img style={{ width: '60px' }} src={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/0306/rep/Image0.jpg'} />
   </a></> */}
                {viewData.rep_images_cannot_complete &&
                  viewData.rep_images_cannot_complete.map(rec => rec && rec.key && rec.key.length > 0 && (
                    <a
                      style={{ marginRight: '10px' }}
                      target="_blank"
                      href={
                        process.env.REACT_APP_CDN + rec.key
                        // 'admin-documents/taskDocuments/' +
                        // viewData.task_id +
                        // '/rep/cannot-complete/images/' +
                        // viewData.taskDate.replace(/[^\d]/g, '') +
                        // '/' +
                        // rec
                      }
                    >
                      <img
                        style={{ width: '60px' }}
                        alt='roll over photos'
                        src={
                          process.env.REACT_APP_CDN + rec.key
                          // 'admin-documents/taskDocuments/' +
                          // viewData.task_id +
                          // '/rep/cannot-complete/images/' +
                          // viewData.taskDate.replace(/[^\d]/g, '') +
                          // '/' +
                          // rec
                        }
                      />
                    </a>
                  ))}
              </Col>
            ) : null}
            {viewData.cannot_complete_comments != '' ? (
              <Col md={6}>
                <label>Can not complete comments</label>
                <br />
                {/* <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + viewData.task_id + "/rep/" + rec} >
     <img style={{ width: '60px' }} src={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/0306/rep/Image0.jpg'} />
   </a></> */}
                <Input
                  type="text"
                  name="task_type"
                  disabled
                  value={viewData.cannot_complete_comments}
                  className="admin-input"
                ></Input>
              </Col>
            ) : null}
            {viewData.cannot_complete_reason != '' ? (
              <Col md={6}>
                <label>Can not complete reason</label>
                <br />
                {/* <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + viewData.task_id + "/rep/" + rec} >
     <img style={{ width: '60px' }} src={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/0306/rep/Image0.jpg'} />
   </a></> */}
                <Input
                  type="text"
                  name="task_type"
                  disabled
                  value={viewData.cannot_complete_reason}
                  className="admin-input"
                ></Input>
              </Col>
            ) : null}

            {/* </> */}

            <Col md={6}>
              <label>Delegated</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={viewData.delegated ? 'Yes' : 'No'}
                className="admin-input"
              ></Input>
            </Col>
            {viewData.delegated ? (
              <>
                <Col md={6}>
                  <label>Delegated Support rep</label>
                  <Input
                    type="text"
                    name="task_type"
                    disabled
                    value={viewData.support_rep_username}
                    className="admin-input"
                  ></Input>
                </Col>

                <Col md={6}>
                  <label>Delegated Comments</label>
                  <Input
                    type="text"
                    name="task_type"
                    disabled
                    value={viewData.delegated_comments}
                    className="admin-input"
                  ></Input>
                </Col>
              </>
            ) : null}
            {viewData.push_task_comments != '' ? (
              <Col md={6}>
                <label>Push Task Comments</label>
                <Input
                  type="text"
                  name="task_type"
                  disabled
                  value={viewData.push_task_comments}
                  className="admin-input"
                ></Input>
              </Col>
            ) : null}
            {/* {viewData.task_status == 'cannot_complete' ? (
              <>
              

                <Col md={6}>
                  <label>Can not Complete Photos</label>

                  {viewData.rep_images_cannot_complete &&
                    viewData.rep_images_cannot_complete.map(rec => (
                      <a
                        style={{ marginRight: '10px' }}
                        href={
                          process.env.REACT_APP_CDN +
                          'admin-documents/taskDocuments/' +
                          viewData.task_id +
                          '/rep/cannot-complete/' +
                          rec
                        }
                      >
                        <img
                          src={
                            process.env.REACT_APP_CDN +
                            'admin-documents/taskDocuments/' +
                            viewData.task_id +
                            '/rep/cannot-complete/' +
                            rec
                          }
                        />
                      </a>
                    ))}
                </Col>
              </>
            ) : null} */}
            {viewData.pause_task_reason != '' ? (
              <Col md={6}>
                <label> Paused Reason</label>
                <Input
                  type="text"
                  disabled
                  value={viewData.pause_task_reason}
                  className="admin-input"
                ></Input>
              </Col>
            ) : null}

            {viewData.questions &&
              viewData.questions.map(rec => (
                <>
                  <Col md={6}>
                    <label> Question</label>
                    <Input
                      type="text"
                      disabled
                      value={rec.question}
                      className="admin-input"
                    ></Input>
                  </Col>

                  <Col md={6}>
                    <label> Rep's Answer</label>

                    <Input
                      disabled
                      value={rec.answer_from_rep}
                      className="admin-input"
                    ></Input>
                  </Col>
                </>
              ))}
          </Row>
          <Row className="modalRow">
            <Col>
              <Button onClick={handleClose} variant="outlined" color="primary">
                Close
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* <Space style={{ float: 'right', margin: '15px 0px', padding: '0px 20px 0px 0px' }}>
        <Input
          placeholder="Enter Search Text"
          onChange={handleSearch}
          type="text"
          allowClear
          value={searchText}
          className='admin-input'
        />
        <Button
          type="primary" onClick={globalSearch} style={{ borderRadius: '32px' }}>Search</Button>
        <Button
          onClick={clearAll} variant="outlined" style={{ borderRadius: '32px' }}>Clear All</Button>
      </Space>
      <div style={{ clear: 'both' }}></div> */}
      {/* {taskData && taskData.length > 0 ? ( */}
      <div className="antdTable">
        {console.log('data', taskData)}
        {taskData && taskData.length > 0 ? (
          <Table
            columns={columns}
            // scroll={{ x: 1500 }}
            dataSource={taskData && taskData.length ? taskData : rec}
            // bordered
            onChange={handleChange}
            pagination={{
              total: taskData.length,
              showTotal: total => `Total ${total} items`,
              pageSizeOptions: ['10', '50', '100', '200', '500'],
            }}
          />
        ) : (
          <Table columns={columns} />
        )}
      </div>
      {/* ) : null} */}
      <Row>
        <Col>
          <img src={greyBanner} className="greyBanner"></img>
        </Col>
      </Row>
    </div>
  );
};

export default TaskManagementArchived;
