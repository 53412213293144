import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  // Button,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import _ from 'lodash';
import AWS from 'aws-sdk';
import moment, { Moment } from 'moment';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
// import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Table, Tag, Space, Popconfirm } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import 'antd/dist/antd.css';
import axios from 'axios';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { label } from '@aws-amplify/ui';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import { resetCalls } from 'react-ga';
import { object } from 'prop-types';
// import { call } from 'jodit/types/core/helpers';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const CallCycleTable = props => {
  console.log(props);
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const [templateExcelData, setTemplateExcelData] = React.useState([]);
  const [listingExcelData, setListingExcelData] = React.useState([]);
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  console.log('call cycle table', props);
  AWS.config.update({
    accessKeyId: sessionStorage.getItem('AccessKeyId'),
    secretAccessKey: sessionStorage.getItem('AccessKeySec'),
    sessionToken: sessionStorage.getItem('sessionToken'),
  });
  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
    region: 'ap-southeast-2',
  });

  const listCallCycle = () => {
    console.log('functionCalled', props.selectedList);

    setLoading(true);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'call-cycle/all',
    })
      .then(response => {
        console.log('callCycleq', response.data);

        if (response.data) {
          console.log('callCycleq', response);

          const callCycle = response.data.body.Items;
          console.log('callCycleresponse', callCycle);
          const newCallCycle =
            Array.isArray(callCycle) &&
            callCycle
              .sort((a, b) =>
                a.call_cycle_name.localeCompare(b.call_cycle_name),
              )
              .map(({ ...item }) => {
                return {
                  ...item,
                  start_date: item.start_date && new Date(item.start_date),
                  end_date: item.end_date && new Date(item.end_date),
                };
              });

          console.log('callCycleresponseSorted', newCallCycle);
          setTableData(newCallCycle);
          filter(newCallCycle);
        } else {
          console.log('not 200 status', response);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log('error...', error);
        setLoading(false);
      });
  };

  const filter = data => {
    var rar = [];
    var frq = '';
    var aa;
    var storesData;
    var stateData = [];
    var CountryData;
    var CountryD = [];
    var callCycleLista = [];
    var state = [];
    console.log('all call cycles', data);
    data.map(rec => {
      console.log('stores length', rec.stores);
      if (!rec.stores || rec.stores.length == 0) {
        rec.stores = [
          {
            store_id: '',
            store_name: '',
            frequency: '',
          },
        ];
      }
      const stores = rec.stores;
      console.log(rec);
      rec.stores &&
        rec.stores.map((rec1, index) => {
          rar.push(rec1.value);
        });
      storesData = rar.toString();
      // console.log('after map', rec);
      console.log(rec.country);
      rec.country &&
        rec.country.map(rec1 => {
          // console.log(rec1);
          CountryD.push(rec1.value);
        });
      // console.log('after map', rec);
      CountryData = CountryD.toString();
      console.log(CountryData);
      CountryD = [];
      console.log(CountryData);
      console.log(CountryD);
      rec.state &&
        rec.state.map(rec1 => {
          // console.log(rec1);
          state.push(rec1.value);
        });
      // console.log('after map', rec);
      stateData = state.toString();
      console.log(stateData);

      state = [];
      rar = [];

      // console.log('after map', rec);
      // // console.log(rar);
      if (rec.call_cycle_freq == '14odd') {
        frq = '14O';
      } else if (rec.call_cycle_freq == '14even') {
        frq = '14E';
      } else {
        frq = rec.call_cycle_freq;
      }

      rec.stores &&
        rec.stores.map(rec1 => {
          // console.log(rec1);
          console.log('after map', rec);
          aa = {
            'Call ID*': rec.call_id,
            'Call Cycle Name*': rec.call_cycle_name,
            // 'Call Cycle Type': rec.call_cycle_type,
            // 'Call cycle Frequency*': frq,
            'Supplier Name*': rec.supplier_name,
            'Retailer Name*': rec.retailer,
            // 'Stores': storesData,
            'Supplier Username*': rec.supplier_username,
            'Call Time': rec.call_time,
            'Store ID*': rec1.store_id,
            'Store Name*': rec1.store_name,
            'Store Frequency*': rec1.frequency,
            'Call cycle Frequency*': frq,
            // 'Recurring Task Type': rec.recurring_task_type,
            State: stateData,
            Country: CountryData,
            'Start Date (DD/MM/YYYY)': moment(rec.start_date).format(
              'DD/MM/YYYY',
            ),
            'End Date (DD/MM/YYYY)': moment(rec.end_date).format('DD/MM/YYYY'),
          };
          callCycleLista.push(aa);
          console.log(aa);
        });
      console.log('after map', rec);

      console.log(aa);
    });

    setListingExcelData(callCycleLista);
    // setFilteredData(callCycleLista)
    console.log(callCycleLista);
  };

  useEffect(() => {
    props.listStoreCall();
    listCallCycle();
    const head = {
      'Call ID*': '',
      'Call Cycle Name*': '',
      // 'Call Cycle Type': '',
      'Supplier Name*': '',

      'Retailer Name*': '',
      'Supplier Username*': '',
      'Call Time': '',
      'Store ID*': '',
      'Store Name*': '',
      'Store Frequency*': '',
      'Call cycle Frequency*': '',
      // 'Recurring Task Type': '',

      State: '',
      Country: '',
      'Start Date (DD/MM/YYYY)': '',
      'End Date (DD/MM/YYYY)': '',
    };
    setTemplateExcelData([head]);
  }, []);

  const deleteCallCycle = (record, e) => {
    e.preventDefault();
    console.log(record);
    const callID = record.call_id;
    console.log(callID);
    // setLoading(true);
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setLoading(true);
            axios({
              method: 'DELETE',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url:
                process.env.REACT_APP_API_BASE + 'call-cycle?call_id=' + callID,
            }).then(response => {
              console.log('call Cycle deleted !', response);
              setTimeout(() => {
                setLoading(true);
                listCallCycle();
              }, 3000);
            });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const [callCycleDetails, setCallCycleDetails] = useState([]);

  const listStoreCall = () => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'call-cycle/?assigned_stores=all',
    })
      .then(response => {
        console.log('storesResponse', response);
        const strData = response.data.body;
        console.log('strData', strData);
        var assignedS = [];
        var assignedSt = {};
        strData.map(rec => {
          console.log('rec', rec);
          rec.assignedStore.map(rec1 => {
            assignedS.push({
              ...rec1,
              call_id: rec.call_id,
              supplier_username: rec.supplier_username,
            });
          });
          // console.log(assignedS);
          // assignedSt = assignedS;
          // assignedS = [];
          // assignedStore.push({})
        });
        console.log(assignedS);
        setCallCycleDetails(assignedS);
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };
  // const listAssignedStoreCall = () => {
  //   axios({
  //     method: 'GET',
  //     headers: {
  //       'x-api-key': sessionStorage.getItem('apikey'),
  //     },
  //     url:
  //       process.env.REACT_APP_API_BASE +
  //       'call-cycle/list-stores?assigned_stores=all',
  //   })
  //     .then(response => {
  //       console.log('storesResponse', response);
  //       const strData = response.data;
  //       console.log('strData', strData);
  //       setCallCycleDetails(strData);
  //     })
  //     .catch(error => {
  //       console.log('call-cycle/list-stores fail...', error);
  //     });
  // };

  useEffect(() => {
    listStoreCall();
    // listAssignedStoreCall();
  }, []);

  const [updatedData, setUpdatedData] = useState([]);
  const handleView = (record, e) => {
    props.listStoreCall();
    console.log('record===>', callCycleDetails, record);
    e.preventDefault();
    const callID = record.call_id;
    let isIncluded = false;

    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'call-cycle?call_id=' + callID,
    })
      .then(response => {
        console.log('response====>', response.data.body.Item);
        var details = response.data.body.Item;
        var assignedData = [];

        details.start_date = details.start_date
          ? new Date(details.start_date)
          : '';
        details.end_date = details.end_date ? new Date(details.end_date) : '';
        console.log('dtails===>', details);
        console.log('dtails.state==>', details.state);
        if (details.assignedStore && details.assignedStore.length > 0) {
          props.setStoreCheckbox(true);
        } else {
          props.setStoreCheckbox(false);
        }
        props.setIsViewModes({
          ...props.isViewModes,
          isViewMode_call: true,
        });
        var datleft = [];
        const datRight = [];
        const datStore = [];
        var dataCorrect = [];
        var correctD = {};
        console.log(details.stores);
        console.log('detailss', details);
        console.log('callCycleDetails', callCycleDetails);

        // callCycleDetails.map(itm => {
        var isDisabled = false;
        details.assignedStore &&
          details.assignedStore.map(item => {
            var checkedp = false;
            var isChecked = false;
            callCycleDetails.map(rec => {
              if (
                rec.store_id == item.store_id &&
                rec.supplier_username == record.supplier_username
              ) {
                if (rec.call_id != details.call_id) {
                  checkedp = true;
                  isChecked = rec.checked;
                  // }
                }
              }
              // return [...item];
            });
            // if (rec.call_id == details.call_id) {
            if (checkedp) {
              if (isChecked) {
                datleft.push({
                  'Frequency*': item.frequency,
                  'Store Name*': item.store_name,
                  'Store ID*': item.store_id,
                  'State*': item.state,
                  Supplier: record.supplier_name,
                  call_id: record.call_id,
                  supplier_username: item.supplier_username,
                  checked: false,
                  disabled: true,
                });
              } else {
                datleft.push({
                  'Frequency*': item.frequency,
                  'Store Name*': item.store_name,
                  'Store ID*': item.store_id,
                  'State*': item.state,
                  Supplier: record.supplier_name,
                  call_id: record.call_id,
                  supplier_username: item.supplier_username,
                  checked: item.checked,
                  disabled: false,
                });
              }
            } else {
              datleft.push({
                'Frequency*': item.frequency,
                'Store Name*': item.store_name,
                'Store ID*': item.store_id,
                'State*': item.state,
                Supplier: record.supplier_name,
                call_id: record.call_id,
                supplier_username: item.supplier_username,
                checked: item.checked,
                disabled: false,
              });
            }
            // }
            // }
            // }
          });

        console.log('okp', datleft);
        // datleft.push({
        //   'Frequency*': item.frequency,
        //   'Store Name*': item.store_name,
        //   'Store ID*': item.store_id,
        //   'State*': item.state,
        //   Supplier: record.supplier_name,
        //   checked: item.checked,
        //   call_id: rec.call_id,
        //   supplier_username: rec.supplier_username,
        //   disabled: rec.disabled,
        //   anotherDis: true,
        // });
        // return;
        //       }

        //     });

        //   // correctD = datleft;
        //   // datleft = [];
        // });

        console.log('correctD', datleft);

        const newfinals =
          details.unassignedStore &&
          details.unassignedStore.map(item => {
            datRight.push({
              'Frequency*': item.frequency,
              'Store Name*': item.store_name,
              'Store ID*': item.store_id,
              'State*': item.state,
              Supplier: item.supplier,
              checked: item.checked,
              disabled: item.disabled,
            });
          });
        const newfinalsss =
          details.stores &&
          details.stores.map(item => {
            datStore.push({
              'Frequency*': item.frequency,
              'Store Name*': item.store_name,
              'Store ID*': item.store_id,
              'State*': item.state,
              Supplier: item.supplier,
              checked: item.checked,
              value: item.store_name,
              label: item.store_name,
              disabled: item.disabled,
            });
          });
        console.log(datleft);
        // return;
        props.setFinalData([...datRight, ...datleft]);
        var statea = [];
        if (details.state.length > 0) {
          details.state.map(rec => {
            statea.push({ label: rec.value, value: rec.value });
          });
          props.setSelectedAusState(statea);
        } else {
          props.setSelectedAusState([{ label: '', value: '' }]);
        }
        if (details.country && details.country.length > 0) {
          props.setSelectedCountry(details.country);
        } else {
          props.setSelectedCountry([{ label: '', value: '' }]);
        }
        // props.setSelectedStore(details.stores)

        props.selectedRole({ value: 'Call Cycle', label: 'Call Cycle' });
        props.setCallCycleInput(details);
        props.setFinalLeftData(datleft);
        props.setFinalRightData(datRight);
        props.setStoresValue(datleft);
        props.setunassignedData(datRight);
        console.log(props.value);
        props.setValue('1');
        if (details.call_status == 'active') {
          console.log('true active');
          props.setStatusValue(true);
          props.setToggleText('Active');
        } else if (details.call_status == 'inactive') {
          props.setStatusValue(false);
          props.setToggleText('Inactive');
        }
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
    // });
  };

  // const rec =
  //   tableData &&
  //   tableData
  //     .sort((a, b) => a.call_cycle_name.localeCompare(b.call_cycle_name))
  //     .map(({ ...item }) => ({
  //       ...item,
  //       start_date: item.start_date && new Date(item.start_date),

  //       end_date: item.end_date && new Date(item.end_date),
  //     }));

  // console.log('modifyCallCycle', rec, tableData);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const bulkDelete = e => {
    console.log('selectedList==>', props.selectedList);
    var arrData = [];
    props.selectedList.forEach((item, index) => {
      var obj = {};
      if (item) {
        console.log('item', item);
        obj['call_id'] =
          item && Number(item.call_id) ? item.call_id.toString() : item.call_id;
        arrData.push(obj);
        obj = {};
        console.log('arrData==>', arrData);
        confirmAlert({
          title: 'Confirm to Delete',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'yes',
              onClick: () => {
                setLoading(true);
                axios({
                  method: 'PUT',
                  headers: {
                    Authorization: sessionStorage.getItem('idtoken'),
                  },
                  url:
                    process.env.REACT_APP_API_BASE + 'call-cycle/bulk-delete',
                  data: arrData,
                }).then(response => {
                  console.log(response);
                  console.log('bulk call cycle deleted !', response);
                  props.setSelectedList([]);
                  arrData = [];
                  setTimeout(() => {
                    listCallCycle();
                  }, 3000);
                });
              },
            },
            {
              label: 'No',
              onClick: () => {
                return;
              },
            },
          ],
        });
      }
    });
  };

  const columns = [
    {
      title: 'Call Cycle ID',
      dataIndex: 'call_id',
      align: 'center',
      ...getColumnSearchProps('call_id'),
    },
    {
      title: 'Call Cycle Name',
      dataIndex: 'call_cycle_name',
      align: 'center',
      ...getColumnSearchProps('call_cycle_name'),
    },
    {
      title: 'Supplier Name',
      dataIndex: 'supplier_name',
      align: 'center',
      ...getColumnSearchProps('supplier_name'),
    },
    {
      title: 'Frequency',
      dataIndex: 'call_cycle_freq',
      align: 'center',
      filters: [
        { text: '7', value: '7' },
        { text: '14 Odd', value: '14odd' },
        { text: '14 Even', value: '14even' },
        { text: '28', value: '28' },
      ],
      onFilter: (value, record) => {
        console.log('vvvvv', value == record.call_cycle_freq, value);
        return record['call_cycle_freq']
          .toLowerCase()
          .includes(value.toLowerCase());
        // return record['call_cycle_freq'] == value;
      },
      render: (text, record) => {
        if (record['call_cycle_freq'] == '14odd') {
          return <p>14 Odd</p>;
        } else if (record['call_cycle_freq'] == '14even') {
          return <p>14 Even</p>;
        } else {
          return record['call_cycle_freq'];
        }
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      width: '100px',
      render: (text, record) => {
        return (
          <>
            <button
              style={{
                marginRight: '0px',
                marginLeft: '5px',
                backgroundColor: 'transparent',
              }}
              onClick={e => handleView(record, e)}
            >
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>{' '}
            </button>
            <button
              style={{ marginRight: '0', backgroundColor: 'transparent' }}
              onClick={e => deleteCallCycle(record, e)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </button>
          </>
        );
      },
    },
  ];
  const [openUpd, setOpenUpd] = React.useState(false);
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    console.log(csvData);
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    saveAs(data, fileName + fileExtension);
  };
  const [errorMsgBulk, setErrorMsgBulk] = React.useState('');

  const handleUpload = e => {
    e.preventDefault();
    var file = e.target.files,
      f = file[0]; //file info, get file

    const newArr = [];
    // setFileName(f.name);

    var reader = new FileReader();

    // when started load the file, loading start in case the file is big
    setLoading(true);

    reader.onload = function (e) {
      // console.log(e.target.result)
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' }); // convert to binary, make readed data
      const wsname = readedData.SheetNames[0]; // to find sheet name
      const ws = readedData.Sheets[wsname]; // To get first sheet

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: '',
        blankrows: false,
      }); // to get the header of sheet
      // setFileUploaded( dataParse );
      // console.log(dataParse)
      const headers = dataParse[0];
      console.log('data paser', typeof dataParse, dataParse);
      var arr = [];
      var arr1 = {};
      for (let i = 1; i < dataParse.length; i++) {
        newArr.push({ ...dataParse[i], checked: false });
      }
      console.log(newArr);
      newArr.map((rec, i) => {
        headers.map((rec1, index) => {
          arr1[rec1] = rec[index];
        });
        arr.push(arr1);
        arr1 = {};
      });
      // console.log(arr);
      var startD = '';
      var endD = '';

      var data = [];
      var callC = [];
      var frq;
      var stores = [];
      var callcc = {};
      // tableData.map(rec => {

      // when error happen, stop loading
      var error = '';
      arr.map((rec, i) => {
        // console.log(rec);
        if (rec['Call ID*'] == '') {
          // console.log('error in' + i + 'record');
          setErrorMsgBulk('Error in ' + (i + 1) + ' record. Please enter ID');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setLoading(false);
          return;
        }
        if (rec['Call Cycle Name*'] == '') {
          // console.log('error in' + i + 'record');
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter call cycle name',
          );
          setOpenUpd(true);
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setLoading(false);
          return;
        }
        if (rec['Call cycle Frequency*'] == '') {
          // console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setErrorMsgBulk(
            'Error in ' +
              (i + 1) +
              ' record. Please enter call cycle Frequency',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setLoading(false);
          return;
        }
        if (rec['Supplier Name*'] == '') {
          // console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter supplier name',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setLoading(false);
          return;
        }
        if (rec['Supplier Username*'] == '') {
          // console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter supplier Username',
          );
          setLoading(false);
        }
        if (rec['Retailer Name*'] == '') {
          // console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter Retailer Name',
          );
          setLoading(false);
        }

        if (rec['Store ID*'] == '') {
          // console.log('index for main loop', i, index, rec.call_id);

          // console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter store ID',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setLoading(false);
          return;
        }
        if (rec['Store Name*'] == '') {
          // console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter store name',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setLoading(false);
          return;
        }

        if (rec['Store Frequency*'] == '') {
          // console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter store frequency',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setLoading(false);
          return;
        }
        // setOpenUpd(true);
        // setTimeout(() => {
        //   setOpenUpd(false);
        // }, 3000);
        // return;
        // }
        // else {
        //   setErrorMsgBulk('');
        // }
      });

      arr.map((rec1, i) => {
        // console.log(rec1);
        const testOrderF = _.groupBy(arr, 'Call ID*');
        // console.log('testOrderF', testOrderF);

        // if (rec1['Call ID*'] == rec1['Call ID*']) {
        //   stores.push({
        //     store_id: rec1['Store ID*'],
        //     store_name: rec1['Store Name*'],
        //     frequency: rec1['Store Frequency*'],
        //     call_id: rec1['Call ID*'],
        //   });
        // }
        var startdate1 = '';
        var enddate1 = '';
        // console.log(stores);
        if (rec1['Start Date (DD/MM/YYYY)'] != '') {
          // console.log('startdatefull', typeof rec1['Start Date (DD/MM/YYYY)']);
          if (Number(rec1['Start Date (DD/MM/YYYY)'])) {
            let converted_date = new Date(
              Math.round((rec1['Start Date (DD/MM/YYYY)'] - 25569) * 864e5),
            );
            converted_date = String(converted_date).slice(4, 15);
            // console.log(converted_date);
            let date = converted_date.split(' ');
            let day = date[1];
            let month = date[0];
            month =
              'JanFebMarAprMayJunJulAugSepOctNovDec'.indexOf(month) / 3 + 1;
            if (month.toString().length <= 1) month = '0' + month;
            let year = date[2];
            // console.log('month ===>>>', day, month, year);

            // var dateFor = String(day + '-' + month + '-' + year.slice(2, 4));
            // startdate1 = moment(dateFor).format('DD/MM/yyyy');

            // Changed by qian: change from - to /
            var dateFor = String(day + '/' + month + '/' + year);
            // console.log('dateFor ===>>>', dateFor);
            startdate1 = moment(dateFor, 'DD/MM/YYYY').format('DD/MM/YYYY');
            // console.log(startdate1);
          }
          // else if (Date(rec1['Start Date (DD/MM/YYYY)'])) {
          //   console.log(
          //     'dsate fromat',
          //     rec1['Call ID*'],
          //     rec1['Start Date (DD/MM/YYYY)'],
          //   );
          //   dd = rec1['Start Date (DD/MM/YYYY)'];
          //   console.log(dd);
          //   // startdate1 = rec1['Start Date (DD/MM/YYYY)'];
          //   // startdate1 = rec1['Start Date (DD/MM/YYYY)'];
          //   // const dateDate = rec1['Start Date (DD/MM/YYYY)'];
          //   // dateDate.split;
          // }
          else {
            // console.log(rec1['Start Date (DD/MM/YYYY)']);
            startdate1 = rec1['Start Date (DD/MM/YYYY)'];
          }

          var startdate3 =
            rec1['Start Date (DD/MM/YYYY)'] && startdate1.split('/');
          var startnewDate =
            rec1['Start Date (DD/MM/YYYY)'] &&
            startdate3[1] + '/' + startdate3[0] + '/' + startdate3[2];
          // console.log(startnewDate);

          // console.log(startnewDate);
          var startdate =
            rec1['Start Date (DD/MM/YYYY)'] && new Date(startnewDate);
          // console.log(startdate, rec1['Call ID*']);
          startD = rec1['Start Date (DD/MM/YYYY)'] && startdate.toISOString();
          // console.log(startD);
        }
        if (rec1['End Date (DD/MM/YYYY)'] != '') {
          if (Number(rec1['End Date (DD/MM/YYYY)'])) {
            let converted_date = new Date(
              Math.round((rec1['End Date (DD/MM/YYYY)'] - 25569) * 864e5),
            );
            converted_date = String(converted_date).slice(4, 15);
            // console.log(converted_date);
            let date = converted_date.split(' ');
            let day = date[1];
            let month = date[0];
            month =
              'JanFebMarAprMayJunJulAugSepOctNovDec'.indexOf(month) / 3 + 1;

            if (month.toString().length <= 1) month = '0' + month;
            let year = date[2];
            var enddate1 = String(day + '/' + month + '/' + year.slice(2, 4));
            // console.log(day + '/' + month + '/' + year);
            // console.log(enddate1);
          } else {
            enddate1 = rec1['End Date (DD/MM/YYYY)'];
          }
          var enddate3 = rec1['End Date (DD/MM/YYYY)'] && enddate1.split('/');
          var endnewDate =
            rec1['End Date (DD/MM/YYYY)'] &&
            enddate3[1] + '/' + enddate3[0] + '/' + enddate3[2];
          // console.log(endnewDate);
          var enddate = rec1['End Date (DD/MM/YYYY)'] && new Date(endnewDate);
          endD = rec1['End Date (DD/MM/YYYY)'] && enddate.toISOString();
        }

        // console.log(rec1['State'].split(','));

        var statee = [];
        const stt = rec1['State'] != '' ? rec1['State'].split(',') : [];
        stt &&
          stt.map(rec2 => {
            statee.push({ label: rec2, value: rec2 });
          });
        var countryy = [];
        // console.log(rec1['Country']);
        const ctt = rec1['Country'] != '' ? rec1['Country'].split(',') : [];
        ctt &&
          ctt.map(rec2 => {
            countryy.push({ label: rec2, value: rec2 });
          });
        // }];
        // console.log('countryy', countryy);
        if (
          rec1['Call cycle Frequency*'] == '14O' ||
          rec1['Call cycle Frequency*'] == '14o'
        ) {
          frq = '14odd';
        } else if (
          rec1['Call cycle Frequency*'] == '14E' ||
          rec1['Call cycle Frequency*'] == '14e'
        ) {
          frq = '14even';
        } else {
          frq = rec1['Call cycle Frequency*'];
        }
        var aa2 = {
          call_id:
            rec1['Call ID*'] == Number(rec1['Call ID*'])
              ? rec1['Call ID*'].toString().toUpperCase()
              : rec1['Call ID*'].toUpperCase(),
          call_cycle_name:
            rec1['Call Cycle Name*'] == Number(rec1['Call Cycle Name*'])
              ? rec1['Call Cycle Name*'].toString()
              : rec1['Call Cycle Name*'],
          // call_cycle_type: rec1['Call Cycle Type'],
          call_cycle_freq: frq == Number(frq) ? frq.toString() : frq,
          supplier_name: rec1['Supplier Name*'],
          retailer: rec1['Retailer Name*'],
          supplier_username: rec1['Supplier Username*'],
          stores: {
            store_id:
              rec1['Store ID*'] == Number(rec1['Store ID*'])
                ? rec1['Store ID*'].toString()
                : rec1['Store ID*'],
            store_name: rec1['Store Name*'],
            frequency: rec1['Store Frequency*'],
            // isIncluded = false;
            // checkedInc = true;
            // anotherDis = false;
          },
          call_time: Number(rec1['Call Time'])
            ? rec1['Call Time'].toString()
            : rec1['Call Time'],
          // call_cycle_type: rec1['Recurring Task Type'],
          state: statee,
          country: countryy,
          // country: [{ value: '', label: '' }]
          start_date: rec1['Start Date (DD/MM/YYYY)'] && startD,
          end_date: rec1['End Date (DD/MM/YYYY)'] && endD,
        };
        callC.push(aa2);
      });
      console.log('call cycle data import', callC);
      // callC.map(rec => {
      //   if (rec.stores.store_name == 'KATOOMBA SFS') {
      //     console.log('firsttime import', rec);
      //   }
      // });
      const testOrderF1 = _.groupBy(callC, 'call_id');
      // console.log('testOrderF', testOrderF1);
      var actualArr = {};
      var actualAssArr = [];
      var sto = [];
      var asb = [];
      var Asssto = [];
      var callArray = [];
      var callArray1 = [];
      var uniqueData;

      axios({
        method: 'GET',
        headers: {
          Authorization: sessionStorage.getItem('idtoken'),
        },
        url: process.env.REACT_APP_API_BASE + 'call-cycle/list-stores',
      })
        .then(response => {
          // console.log('storesResponse', response.data);
          const responsee = response.data;
          console.log('responsee', responsee);
          if (responsee.length == 0) {
            console.log('okk', testOrderF1);
            Object.entries(testOrderF1).map(([key, value], index) => {
              // console.log('okvalue', value);
              value.map(rec => {
                sto.push({
                  store_id: rec.stores.store_id,
                  store_name: rec.stores.store_name,
                  frequency: Number(rec.stores.frequency)
                    ? rec.stores.frequency.toString()
                    : rec.stores.frequency,
                  supplier_username: rec.supplier_username,
                  anotherDis: false,
                  checked: true,
                  disabled: false,
                  label: rec.stores.store_id,
                  value: rec.stores.store_name,
                });
                console.log('okvalue', sto);
                asb.push({
                  store_id: rec.stores.store_id,
                  store_name: rec.stores.store_name,
                  frequency: Number(rec.stores.frequency)
                    ? rec.stores.frequency.toString()
                    : rec.stores.frequency,
                  supplier_username: rec.supplier_username,
                  anotherDis: false,
                  checked: true,
                  disabled: false,
                  label: rec.stores.store_id,
                  value: rec.stores.store_name,
                });
                actualAssArr = asb;
                //   // callArray1 = callArray;
                //   // callArray = [];
                //   // console.log(callArray1);
                callArray.push({
                  call_id: rec.call_id,
                  call_cycle_name: rec.call_cycle_name,
                  // call_cycle_type: rec1['Call Cycle Type'],
                  call_cycle_freq: rec.call_cycle_freq,
                  supplier_name: rec.supplier_name,
                  retailer: rec.retailer,
                  supplier_username: rec.supplier_username,
                  stores: sto,
                  // assignedStore:
                  call_status: 'active',
                  call_time: rec.call_time,
                  // call_cycle_type: rec1['Recurring Task Type'],
                  state: rec.state,
                  country: rec.country,
                  // country: [{ value: '', label: '' }]
                  start_date: rec.start_date,
                  assignedStore: asb,
                  end_date: rec.end_date,
                });

                callArray1 = callArray;
                // callArray = [];
              });
              sto = [];
              asb = [];
            });
            console.log('callArray1', callArray1);
          } else {
            // var assignedS=[];
            // responsee.map(rec => {
            //   console.log('rec', rec);
            //   rec.assignedStore.map(rec1 => {
            //     assignedS.push({
            //       ...rec1,
            //       call_id: rec.call_id,
            //       supplier_username: rec.supplier_username,
            //     });
            //   });
            //   // console.log(assignedS);
            //   // assignedSt = assignedS;
            //   // assignedS = [];
            //   // assignedStore.push({})
            // });
            responsee.map(record => {
              console.log('record', record);
              Object.entries(testOrderF1).map(([key, value], index) => {
                // console.log('valueEE', value);
                // console.log('seconf time imort', value);

                // console.log(record);
                var callStatus = '';
                // if (value.length > 0) {
                value.map(rec => {
                  // console.log('recop', rec);
                  // console.log('recordop', record);
                  // console.log(rec.call_id, record.call_id);
                  // console.log(record.store_id, rec.stores.store_id);
                  // return;
                  // if (rec.stores.store_name == 'KATOOMBA SFS') {
                  //   console.log('seconf time imort', rec);
                  // }

                  if (
                    rec.call_id == record.call_id &&
                    record.store_id == rec.stores.store_id &&
                    rec.supplier_username == record.supplier_username
                  ) {
                    callStatus = rec.call_status;
                    // console.log('recordinif', record);
                    sto.push({
                      // store_id: rec.store_id,
                      // store_name: rec.store_name,
                      // frequency: record.frequency,
                      // supplier_username: record.supplier_username,
                      // anotherDis: !record.anotherDis ? false : record.anotherDis,
                      // checked: record.checked,
                      // disabled: record.disabled,
                      // label: record.label,
                      // value: record.value,

                      store_id: rec.stores.store_id,
                      store_name: rec.stores.store_name,
                      frequency: Number(rec.stores.frequency)
                        ? rec.stores.frequency.toString()
                        : rec.stores.frequency,
                      supplier_username: rec.supplier_username,
                      anotherDis: false,
                      checked: true,
                      disabled: false,
                      label: rec.stores.store_id,
                      value: rec.stores.store_name,
                    });
                    tableData.map(reco => {
                      // console.log(reco.stores.store_id);
                      // console.log(reco.call_id);
                      reco.stores.map(data => {
                        // console.log(data.store_id);
                        if (
                          reco.call_id == rec.call_id &&
                          data.store_id == rec.stores.store_id &&
                          reco.supplier_username == rec.supplier_username
                        ) {
                          // console.log(reco);
                          // console.log(reco.assignedStore);
                          reco.assignedStore &&
                            reco.assignedStore.map(item => {
                              Asssto.push({
                                store_id: item.store_id,
                                store_name: item.store_name,
                                frequency: Number(item.frequency)
                                  ? item.frequency.toString()
                                  : item.frequency,
                                supplier_username: rec.supplier_username,
                                anotherDis: !item.anotherDis
                                  ? false
                                  : item.anotherDis,
                                checked: item.checked,
                                disabled: item.disabled,
                                label: item.label,
                                value: item.value,
                              });
                            });
                          actualAssArr = Asssto;
                          // console.log('Asssto', Asssto);
                        }
                      });
                    });

                    // console.log('Asssto', Asssto);
                  } else {
                    // console.log('elseeeee');
                    callStatus = 'active';

                    sto.push({
                      store_id: rec.stores.store_id,
                      store_name: rec.stores.store_name,
                      frequency: Number(rec.stores.frequency)
                        ? rec.stores.frequency.toString()
                        : rec.stores.frequency,
                      supplier_username: rec.supplier_username,
                      anotherDis: false,
                      checked: true,
                      disabled: false,
                      label: rec.stores.store_id,
                      value: rec.stores.store_name,
                    });

                    asb.push({
                      store_id: rec.stores.store_id,
                      store_name: rec.stores.store_name,
                      frequency: Number(rec.stores.frequency)
                        ? rec.stores.frequency.toString()
                        : rec.stores.frequency,
                      supplier_username: rec.supplier_username,
                      anotherDis: false,
                      checked: true,
                      disabled: false,
                      label: rec.stores.store_id,
                      value: rec.stores.store_name,
                    });
                    actualAssArr = asb;
                  }
                  // console.log(sto);

                  // });
                });
                // }

                actualArr = sto;
                Asssto = [];
                asb = [];
                sto = [];
                // value.map(rec => {
                //   if (rec.stores.store_name == 'KATOOMBA SFS') {
                //     console.log('third time imort', rec);
                //   }
                // });
                // console.log('third time imort', value);

                value.map(rec => {
                  callArray.push({
                    call_id: rec.call_id,
                    call_cycle_name: rec.call_cycle_name,
                    // call_cycle_type: rec1['Call Cycle Type'],
                    call_cycle_freq: rec.call_cycle_freq,
                    supplier_name: rec.supplier_name,
                    retailer: rec.retailer,
                    supplier_username: rec.supplier_username,
                    stores: actualArr,
                    // assignedStore:
                    call_status: callStatus,
                    call_time: rec.call_time,
                    // call_cycle_type: rec1['Recurring Task Type'],
                    state: rec.state,
                    country: rec.country,
                    // country: [{ value: '', label: '' }]
                    start_date: rec.start_date,
                    assignedStore: actualAssArr,
                    end_date: rec.end_date,
                  });
                  // console.log(actualArr);
                  // console.log(callArray);
                });

                // }
              });
              callArray1 = callArray;
              callArray = [];
              // }
              // console.log(callArray1);
            });
          }
          console.log(callArray1);

          // actualArr = sto;
          // sto = [];
          // console.log(actualArr);
          // value.map(rec => {
          //   callArray.push({
          //     call_id: rec.call_id,
          //     call_cycle_name: rec.call_cycle_name,
          //     // call_cycle_type: rec1['Call Cycle Type'],
          //     call_cycle_freq: rec.call_cycle_freq,
          //     supplier_name: rec.supplier_name,
          //     retailer: rec.retailer,
          //     supplier_username: rec.supplier_username,
          //     stores: actualArr,
          //     call_time: rec.call_time,
          //     // call_cycle_type: rec1['Recurring Task Type'],
          //     state: rec.state,
          //     country: rec.country,
          //     // country: [{ value: '', label: '' }]
          //     start_date: rec.start_date,
          //     end_date: rec.end_date,
          //   });
          // });
          // callArray1 = callArray;
          // callArray = [];
          // console.log(callArray1);
          const uniqueIds = [];
          // console.log('callArray1', callArray1);
          // callArray1.map(rec => {
          //   if (rec.call_id == 'REPELEC28O') {
          //     console.log('inside call repleca280', rec);
          //   }
          // });
          uniqueData = callArray1.filter(element => {
            const isDuplicate = uniqueIds.includes(element.call_id);

            if (!isDuplicate) {
              uniqueIds.push(element.call_id);

              return true;
            }

            return false;
          });
          console.log('uniqueData', uniqueData);
          uniqueData.map(rec2 => {
            rec2.stores.map(rec3 => {
              if (rec3.store_name == 'KATOOMBA SFS') {
                console.log('import data stre', rec2);
              }
            });
          });
          // return;
          // console.log(callC);

          if (error == '') {
            // set a timer, if the upload tim is greater than 30s, then stop
            // this is because the api gateway is not giving any response after 30 second,
            // so the solution now is considering this as a successful action
            // const startUploadTime = Date.now()

            // }, 180000);

            // loading finish, now show the popup saying data is processing

            setLoading(false);
            setOpenUpd(true);
            setTimeout(() => {
              setOpenUpd(false);
            }, 6000);
            console.log(uniqueData);
            // return;
            axios({
              method: 'PUT',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url: process.env.REACT_APP_API_BASE + 'call-cycle',
              data: uniqueData,
            })
              .then(response => {
                console.log(response);
                // setOpenUpd(true);
                // setTimeout(() => {
                //   setOpenUpd(false);
                // }, 3000);
                // setLoading(false)
                // listCallCycle();
              })
              .catch(error => {
                console.log('other error11', error);

                // const finishUploadTime = Date.now()
                // const delta = finishUploadTime - startUploadTime
                // console.log('delta', startUploadTime, ' delta finishUploadTime', finishUploadTime, delta)

                // setLoading(false)
                // // exceed the gateway response time
                // if (delta > 28500) {
                //   setOpenUpd(true);
                //   setTimeout(() => {
                //     setOpenUpd(false);
                //   }, 3000);
                //   console.log('exceed the gateway response time...',)
                //   listCallCycle();

                // } else {
                //   console.log('other error', error)
                // }
              });
          }
        })
        .catch(err1 => {
          console.log('get error call cycle', err1);
          setLoading(false);
        });

      // return;

      // setErrorMsgBulk('');
    };

    reader.readAsBinaryString(f);
    e.target.value = '';
    setErrorMsgBulk('');
  };

  const handleClose = () => {
    setOpenUpd(false);
  };

  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Dialog open={openUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                {errorMsgBulk == '' ? (
                  <>
                    <b style={{ color: 'red' }}>Thank you</b>
                    <br />
                    <br />
                    <label style={{ color: 'red' }}>
                      Data is processing in background, you will be notified by
                      email once complete.
                    </label>
                  </>
                ) : (
                  <>
                    {' '}
                    <br />
                    <br />
                    <label style={{ color: 'red' }}>{errorMsgBulk}</label>
                  </>
                )}
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Row style={{ margin: '10px 0 -5px 0' }}>
        <Col md={3} className="autoComp" style={{ float: 'left' }}></Col>
        <Col
          md={9}
          style={{ float: 'right', textAlign: 'right', paddingRight: '20px' }}
          className="uploadExcel"
        >
          <button
            title="Select records to delete!"
            type="button"
            variant="outlined"
            class="btn btn-primary"
            onClick={e => {
              bulkDelete(e);
            }}
            disabled={props.selectedList.length == 0}
          >
            {' '}
            Delete
          </button>
          <input
            type="file"
            accept=" .csv, .xlsx"
            id="importFile"
            style={{ display: 'none' }}
            onChange={handleUpload}
          />
          <label
            htmlFor="importFile"
            class="btn btn-primary"
            style={{ verticalAlign: 'sub' }}
          >
            Import
          </label>
          {/* <button type="button" variant="outlined" class="btn btn-primary" onClick={(e) => exportToCSV(filteredTableData, 'fileName')}>Import</button> */}
          <button
            type="button"
            variant="outlined"
            class="btn btn-primary"
            onClick={e => exportToCSV(listingExcelData, 'callCycle_listing')}
          >
            Export
          </button>
          <button
            type="button"
            variant="outlined"
            class="btn btn-primary"
            onClick={e => exportToCSV(templateExcelData, 'callCycle_template')}
          >
            Export Template
          </button>
        </Col>
      </Row>

      {/* <p style={{ color: 'red' }}>  {filteredData.length}</p> */}
      {/* <br /> */}
      <div className="antdTable">
        {/* {rec ? ( */}
        <>
          <Table
            columns={columns}
            dataSource={tableData}
            rowSelection={{ ...props.rowSelection }}
            rowKey={record => record.call_id}
            onChange={(pagination, filters, sorter, currentPageData) =>
              filter(currentPageData.currentDataSource)
            }
            pagination={{
              total: filteredData.length,
              showSizeChanger: true,
              showTotal: total => `Total ${total} items`,
              pageSizeOptions: ['10', '50', '100', '200', '500'],
            }}
          />
        </>
        {/* ) : null} */}
      </div>
    </>
  );
};

export default CallCycleTable;
