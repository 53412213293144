import Page from "components/Page";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

import * as XLSX from "xlsx";

import TaskManagementTable from "../Tables/TaskManagementTable";
import ViewTasksTable from "../Tables/ViewTasksTable";
import ViewArchivedTable from "../Tables/TableTaskArchived";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";

import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { Select } from "antd";
import AWS from "aws-sdk";
import greyBanner from "assets/img/logo/Banner Grey.png";

import { MultiSelect } from "react-multi-select-component";
import { DropzoneArea } from "material-ui-dropzone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker, { registerLocale } from "react-datepicker";
import enGb from "date-fns/locale/en-GB";
import { isInvalidTaskName } from "./AddAdmin.js";
import { MAX_TASK_NAME_LENGTH } from "../../utils/constants.js";
import { css } from "@emotion/react";
registerLocale("en-gb", enGb);
const { Option } = Select;
var updateTask = false;
const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;
const TaskManagement = (props) => {
  const s3 = new AWS.S3();
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState("#ffffff");

  const countryOptions = [
    { label: "Australia", value: "Australia" },
    { label: "New Zealand", value: "New Zealand" },
  ];

  const newStateOptions = [
    { label: "North Island", value: "NI" },
    { label: "South Island", value: "SI" },
  ];

  var ausStateOptions = [];
  const [editView, setEditView] = useState({
    edit_adhoc: false,
    edit_task: false,
  });
  const handleChangeCountry = (e, index) => {
    requiredError.country = "";
    // setSelectedCountry(selectedCount)
    taskInfo[index].country = e;

    e.map((rec) => {
      if (rec.value === "Australia") {
        ausStateOptions.push(
          { label: "ACT", value: "ACT" },
          { label: "NSW", value: "NSW" },
          { label: "NT", value: "NT" },
          { label: "QLD", value: "QLD" },
          { label: "SA", value: "SA" },
          { label: "TAS", value: "TAS" },
          { label: "VIC", value: "VIC" },
          { label: "WA", value: "WA" }
        );
        setStateData(ausStateOptions);
      } else if (rec.value == "New Zealand") {
        //      } else if (rec.value == 'newZealand') {

        ausStateOptions.push(
          { label: "NI", value: "NI" },
          { label: "SI", value: "SI" }
        );
        setStateData(newStateOptions);
      } else {
        setSelectedCountry(rec);
      }
    });
    taskInfo[index].stateOptions = ausStateOptions;

    taskInfo[index].state = [];
    setTaskInfo([...taskInfo]);
    // setStateList(ausStateOptions)
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getRetailerList();

    // setTaskFields(requestTasks)
    // setTaskInfo([{...taskInfo,'task_id':requestTasks.task_id, 'task_name':requestTasks.task_name}])
  }, []);

  const [dataTask, setDataTask] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [stateData, setStateData] = useState([]);

  const [stores, setStores] = React.useState([
    {
      value: "1",
      label: "store 1",
    },
    {
      value: "2",
      label: "store 2",
    },
  ]);

  useEffect(() => {
    setSelectedStores([...stores]);
  }, []);

  const [options, setOptions] = React.useState([
    {
      value: "1",
      label: "Victoria",
    },
    {
      value: "2",
      label: "Queensland",
    },
    {
      value: "3",
      label: "New South Wales",
    },
    {
      value: "4",
      label: "Northern Territory",
    },
    {
      value: "5",
      label: "Western Australia",
    },
    {
      value: "6",
      label: "South Australia",
    },
    {
      value: "7",
      label: "Australian Capital Territory",
    },
  ]);

  useEffect(() => {
    setSelectedOptions([{ label: "All", value: "*" }, ...options]);
  }, []);

  const [callCycleList, setCallCycleList] = React.useState([
    {
      callCycleOptions: [],
    },
  ]);
  const [taskFields, setTaskFields] = React.useState({
    additionTasks: {
      additionInput: "",
      additionShareable: "",
      additionNotes: "",
    },
  });
  const [taskArray, setTaskArray] = useState([
    {
      oneOff: false,
      recurring: false,
    },
  ]);
  const [taskInfo, setTaskInfo] = React.useState([
    {
      task_id: "",
      task_name: "",
      task_priority: "",
      task_type: "",

      callCycleOptions: [],
      task_status: "created",
      task_description: "",
      task_approval: "",
      task_approval_notes: "",
      task_photos: [
        {
          photo_name: "",
          task_photos_notes: "",
          client_photos_shareable: false,
        },
      ],
      task_comments: [
        {
          comment: "",
          task_comments_notes: "",
          client_comments_shareable: false,
        },
      ],

      fine_line: "",
      // task_photos_notes: '',
      // client_photos_shareable: 'no',
      // task_comments: '',
      // task_comments_notes: '',
      // client_comments_shareable: 'no',
      document_1: "",
      action: "",
      oneOff: false,
      recurring: false,
      week_number: "",
      week_startDate: "",
      week_month: "",
      recurringValue: "",
      startDate: "",
      endDate: "",
      documentAdd: [
        {
          document: "",
          notes: "",
        },
      ],
      retailer: "",
      created_date: new Date(),
      supplier_name: "",
      country: [],
      state: [],
      stateOptions: [],
      store: [],
      storeOptions: [],
      storeOp: [],
      csvFile: "",
      week_number: "",
      recurring: "",
      // questions: {
      //   question: '',
      //   Answers: [''],
      // },
      questions: [
        {
          question: "",
          Answers: [""],
          client_shareable: "",
        },
      ],
      state: [],
      stores: [],
      callCycle: [],
      retailer_name: "",
      appsync_id: "",
      comments_from_rep: "",
      photos_from_rep: [],
      docs_from_rep: [],
      push_task_comments: "",
      cannot_complete_reason: "",
      cannot_complete_photos: [],
      cannot_complete_comments: "",
      cannot_complete_documents: [],
      delegated: false,
      delegated_to_sup_rep: "",
      delegated_instruction: "",
      delegated_comments: "",
      feedback_reassign: "",
    },
  ]);
  const [additionTasks, setAdditionTasks] = React.useState([
    {
      additionInput: "",
      additionShareable: "",
      additionNotes: "",
    },
  ]);
  const [taskPhotos, setTaskPhotos] = React.useState([
    {
      photo_name: "",
      task_photos_notes: "",
      client_photos_shareable: false,
    },
  ]);
  const [taskComment, setTaskComment] = React.useState([
    {
      comment: "",
      task_comments_notes: "",
      client_comments_shareable: false,
    },
  ]);
  const [documentAdd, setDocumentAdd] = React.useState([
    {
      document: "",
      notes: "",
    },
  ]);
  const [requiredError, setRequiredError] = React.useState([
    {
      country: "",
      state: "",
      supplier_name: "",
      task_id: "",
    },
  ]);

  //-----Drag and Drop Functionality-----//

  const checkedFrequency = (e, i) => {
    const { value } = e.target;

    if (value === "oneOff") {
      taskInfo[i].dayOff = e.target.checked;
      taskInfo[i].recurring = false;

      taskInfo[i].recurringValue = "";
      taskInfo[i].startDate = "";
      taskInfo[i].endDate = "";
      taskInfo[i].dayOffCheck = true;
      taskInfo[i].recurringCheck = false;
      taskInfo[i].oneOff = true;

      setTaskInfo([...taskInfo]);
      // setDayOffChecked(true);
      // setRecurringChecked(false);
    } else {
      taskInfo[i].recurring = e.target.checked;
      taskInfo[i].oneOff = false;

      taskInfo[i].week_number = "";
      taskInfo[i].week_startDate = "";
      taskInfo[i].dayOffCheck = false;
      taskInfo[i].recurringCheck = true;

      setTaskInfo([...taskInfo]);
      // setDayOffChecked(false);
      // setRecurringChecked(true);
    }
  };

  const addMoreTask = (e) => {
    e.preventDefault();
    var id = "";
    axios({
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("idtoken"),
      },

      url: process.env.REACT_APP_API_BASE + "id-gen?type=task",
    }).then((response) => {
      id = response.data.body;

      let obj = {
        task_id: id,
        retailer_name: "",
        task_name: "",
        task_priority: "",
        task_type: "",
        task_description: "",
        task_approval: "",
        task_approval_notes: "",
        task_status: "created",
        document_1: "",
        action: "",
        questions: [
          {
            question: "",
            Answers: [""],
            additionShareable: "",
          },
        ],
        callCycle: [],
        storeOp: [],
        stores: [],
        storeOptions: [],
        state: [],
        stateOptions: [],
        week_number: "",
        week_startDate: "",
        startDate: "",
        endDate: "",
        dayOff: "",
        recurring: "",
        recurringValue: "",
        country: [],
        supplier_name: "",
        created_date: "",
        dayOffCheck: "",
        recurringCheck: "",
        documentAdd: [
          {
            document: "",
            notes: "",
          },
        ],
        startDateDis: "",
        endDateDis: "",
        comments_from_rep: "",
        photos_from_rep: [],
        push_task_comments: "",
        cannot_complete_reason: "",
        cannot_complete_photos: [],
        cannot_complete_comments: "",
        cannot_complete_documents: [],
        delegated: false,
        delegated_to_sup_rep: "",
        delegated_instruction: "",
        delegated_comments: "",
        callCycleOptions: [],
        task_photos: [
          {
            photo_name: "",
            task_photos_notes: "",
            client_photos_shareable: false,
          },
        ],
        task_comments: [
          {
            comment: "",
            task_comments_notes: "",
            client_comments_shareable: false,
          },
        ],
        storeOptions: [{ label: "", value: "" }],
        appsync_id: "",
        supplier_id: "",
        feedback_reassign: "",
      };

      obj.created_date = taskInfo[0].created_date;
      obj.supplier_id = taskInfo[0].supplier_id;

      if (taskcheckbox.isSamStores) {
        obj.stores = taskInfo[0].stores;
        obj.state = taskInfo[0].state;
        obj.stateOptions = [...taskInfo[0].stateOptions];
        obj.storeOptions = taskInfo[0].storeOptions;

        obj.country = [...taskInfo[0].country];
        obj.retailer_name = taskInfo[0].retailer_name;
      }
      if (taskcheckbox.isCheckFrequecy) {
        obj.dayOff = taskInfo[0].dayOffCheck;
        obj.oneOff = taskInfo[0].oneOff;
        obj.dayOffCheck = taskInfo[0].dayOffCheck;
        obj.startDateDis = taskInfo[0].startDateDis;
        obj.endDateDis = taskInfo[0].endDateDis;
        obj.recurringCheck = taskInfo[0].recurringCheck;
        obj.recurring = taskInfo[0].recurringCheck;
        obj.week_number = taskInfo[0].week_number;
        obj.week_startDate = taskInfo[0].week_startDate;
        obj.recurringValue = taskInfo[0].recurringValue;
        obj.startDate = taskInfo[0].startDate;
        obj.endDate = taskInfo[0].endDate;
      }
      if (taskcheckbox.isChecked) {
        obj.supplier_name = taskInfo[0].supplier_name;
        obj.callCycle = taskInfo[0].callCycle;
        obj.callCycleOptions = taskInfo[0].callCycleOptions;

        // setCallCycleList([callCycleList[0].callCycleOptions])
        setCallCycleList([
          ...callCycleList,
          { callCycleOptions: callCycleList[0].callCycleOptions },
        ]);
      } else {
        setCallCycleList([...callCycleList, { callCycleOptions: [] }]);
      }

      setTaskInfo([...taskInfo, { ...obj }]);

      setTaskcheckboxArray([
        ...taskcheckboxArray,
        { isChecked: true, isCheckFrequecy: true, isSamStores: true },
      ]);
      setTaskArray([...taskArray, { oneOff: false, recurring: false }]);
    });
  };
  const addShare = (e, i, quesIdx) => {
    taskInfo[i].questions[quesIdx].Answers.push("");
    setTaskInfo([...taskInfo]);
    setAdditionTasks([
      ...additionTasks,
      {
        additionInput: "",
        additionShareable: "",
        additionNotes: "",
      },
    ]);
  };
  const addQuestion = (e, i) => {
    taskInfo[i].questions.push({
      question: "",
      Answers: [""],
    });
    setTaskInfo([...taskInfo]);
  };
  const addDoc = (e, i) => {
    taskInfo[i].documentAdd.push({
      document: "",
      notes: "",
      requiredDoc: false,
    });
    setTaskInfo([...taskInfo]);
  };
  const [supplierIdP, setSupplierIdP] = useState("");

  const [errorTask, setErrorTask] = React.useState("");

  // 'required' is to adjust placehoders in select box
  // to make placeholders' styling consistent
  const addRequired = () => {
    const selectInputs = document.querySelectorAll("select");
    selectInputs.forEach((i) => i.setAttribute("required", ""));
  };

  // remove when the create task button is clicked
  const removeRequired = () => {
    const selectInputs = document.querySelectorAll("select");
    selectInputs.forEach((i) => i.removeAttribute("required"));
  };

  useEffect(() => {
    addRequired();
  }, []);

  const successfulAPI = (fileN, myBucket, docData) => {
    // tasks are getting inserted, you will receive an email if it fails
    setEditView({ edit_task: false });
    setOpenFiveError(true);
    setTimeout(() => {
      setOpenFiveError(false);
    }, 3000);

    var id = "";
    axios({
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("idtoken"),
      },
      url: process.env.REACT_APP_API_BASE + "id-gen?type=task",
    }).then((response) => {
      id = response.data.body;

      setTaskInfo([
        {
          task_id: id,
          task_name: "",
          task_priority: "",
          task_type: "",
          task_description: [],
          callCycleOptions: [],
          appsync_id: "",
          task_approval: "",
          task_approval_notes: "",
          task_photos: [
            {
              photo_name: "",
              task_photos_notes: "",
              client_photos_shareable: false,
            },
          ],
          task_comments: [
            {
              comment: "",
              task_comments_notes: "",
              client_comments_shareable: false,
            },
          ],
          fine_line: "",
          // task_photos_notes: '',
          // client_photos_shareable: 'no',
          // task_comments: '',
          // task_comments_notes: '',
          // client_comments_shareable: 'no',
          document_1: "",
          action: "",
          oneOff: false,
          recurring: false,
          week_number: "",
          week_startDate: "",
          week_month: "",
          recurringValue: "",
          startDate: "",
          endDate: "",
          documentAdd: [
            {
              document: "",
              notes: "",
              requiredDoc: false,
            },
          ],
          retailer: "",
          created_date: new Date(),
          supplier_name: "",
          country: [],
          state: [],
          stateOptions: [],
          store: [],
          storeOptions: [],
          csvFile: "",
          week_number: "",
          recurring: "",
          // questions: {
          //   question: '',
          //   Answers: [''],
          // },
          questions: [
            {
              question: "",
              Answers: [""],
              client_shareable: "",
            },
          ],
          state: [],
          stores: [],
          callCycle: [],
          retailer_name: "",
          task_status: "created",
        },
      ]);
      setTaskInfo([
        {
          task_id: id,
          task_name: "",
          task_priority: "",
          task_type: "",
          task_description: "",
          callCycleOptions: [],
          appsync_id: "",
          task_approval: "",
          task_approval_notes: "",
          task_photos: [
            {
              photo_name: "",
              task_photos_notes: "",
              client_photos_shareable: false,
            },
          ],
          task_comments: [
            {
              comment: "",
              task_comments_notes: "",
              client_comments_shareable: false,
            },
          ],
          fine_line: "",
          // task_photos_notes: '',
          // client_photos_shareable: 'no',
          // task_comments: '',
          // task_comments_notes: '',
          // client_comments_shareable: 'no',
          document_1: "",
          action: "",
          oneOff: false,
          recurring: false,
          week_number: "",
          week_startDate: "",
          week_month: "",
          recurringValue: "",
          startDate: "",
          endDate: "",
          documentAdd: [
            {
              document: "",
              notes: "",
              requiredDoc: false,
            },
          ],
          retailer: "",
          created_date: new Date(),
          supplier_name: "",
          country: [],
          state: [],
          stateOptions: [],
          store: [],
          storeOptions: [],
          csvFile: "",
          week_number: "",
          recurring: "",
          // questions: {
          //   question: '',
          //   Answers: [''],
          // },
          questions: [
            {
              question: "",
              Answers: [""],
              client_shareable: "",
            },
          ],
          state: [],
          stores: [],
          callCycle: [],
          retailer_name: "",
          task_status: "created",
        },
      ]);
      setNotMatch([]);
      setAdditionTasks([
        {
          additionInput: "",
          additionShareable: false,
          additionNotes: "",
        },
      ]);
      setDocumentAdd([
        {
          document: "",
          notes: "",
        },
      ]);
      // window.location.reload();
      // document.querySelector('.ck-content').innerHTML = '';
      setUpdateTask();
      // DropzoneArea.forElement(
      //   '#myDropzoneElementID',
      // ).removeAllFiles(true);
      setCallCycleList([{ callCycleOptions: [] }]);
      // editorValue = '';
    });
  };
  const setUpdateTask = () => (updateTask = !updateTask);
  const [errorMsg, setErrorMsg] = useState("");
  const createTask = async (e) => {
    const fileFile1 = document.getElementById("fileUpload");

    var errorM = "";
    // for (var i = 0; i == taskInfo.length; i++) {
    // taskInfo.forEach((rec, i) => {

    taskInfo.map(async (rec, i) => {
      if (taskInfo[i].country.length === 0) {
        // requiredError[i].country = 'Please select country';
        // requiredError[i].country = 'Please select country';
        errorM = "Please select country";
        // addRequired();
        return;
      }
      // return;
      if (taskInfo[i].state.length == 0) {
        // requiredError[i].state = 'Please select state';
        errorM = "Please select state";

        // setRequiredError({ state: 'Please select state' });
        addRequired();
        return;
      }
      // if ((taskInfo[0].stores).length == 0) {
      //   setRequiredError({ store: 'Please select store' })
      //   addRequired()
      //   return;
      // }
      if (
        taskInfo[i].retailer_name == "" ||
        taskInfo[i].retailer_name == null
      ) {
        // setRequiredError({ retailer: 'Please select Retailer' });
        errorM = "Please select  Retailer";

        addRequired();
        return;
      }
      if (
        taskInfo[i].supplier_name == "" ||
        taskInfo[i].supplier_name == null
      ) {
        // setRequiredError({ supplier_name: 'Please select Supplier' });
        errorM = "Please select Supplier";

        addRequired();
        return;
      }

      if (
        (taskInfo[i].dayOffCheck == true && taskInfo[i].week_startDate == "") ||
        taskInfo[i].week_startDate == null
      ) {
        // setRequiredError({ weekDate: 'Please Enter Date' });
        errorM = "Please Enter Date";

        addRequired();
        return;
      }
      if (
        (taskInfo[i].recurring == true && taskInfo[i].recurringValue == "") ||
        taskInfo[i].recurringValue == null
      ) {
        errorM = "Please select recurring";

        // setRequiredError({ recurring: 'Please select recurring' });
        addRequired();
        return;
      }
      if (
        (taskInfo[i].recurring == true && taskInfo[i].startDate == "") ||
        taskInfo[i].startDate == null
      ) {
        // setRequiredError({ weekStartDate: 'Please select Date' });
        errorM = "Please select Date";

        addRequired();
        return;
      }
      if (
        (taskInfo[i].recurring == true && taskInfo[i].endDate == "") ||
        taskInfo[i].endDate == null
      ) {
        errorM = "Please select Date";

        // setRequiredError({ weekEndDate: 'Please select Date' });
        addRequired();
        return;
      }
      if (taskInfo[i].task_id == "" || taskInfo[i].task_id == null) {
        // setRequiredError({ task_id: 'Please Enter Task ID' });
        addRequired();
        return;
      }
      if (
        taskInfo[i].task_priority == "" ||
        taskInfo[i].task_priority == null
      ) {
        setRequiredError({ task_priority: "Please Select Task Priority" });
        errorM = "Please select Task Priority";

        addRequired();
        return;
      }
      if (taskInfo[i].task_name == "" || taskInfo[i].task_name == null) {
        setRequiredError({ task_name: "Please Enter Task Name" });
        errorM = "Please select Task Name";

        addRequired();
        return;
      }

      if (
        taskInfo[i].task_name !== "" &&
        taskInfo[i].task_name !== null &&
        taskInfo[i].task_name.length > MAX_TASK_NAME_LENGTH
      ) {
        errorM = "Max Length Reached, please shorten the task name";

        addRequired();
        return;
      }
      if (
        taskInfo[i].task_name !== "" &&
        taskInfo[i].task_name !== null &&
        isInvalidTaskName(taskInfo[i].task_name)
      ) {
        // setRequiredError({
        //   task_name:
        //     'Invalid Task Name, please remove special characters or shorten the task name',
        // });
        errorM = "Invalid Task Name, please remove special characters";

        addRequired();
        return;
      }

      // check the signature document
      if (taskInfo[i].documentAdd.length > 0) {
        // let stopDocForLoop = false
        for (let item22 of taskInfo[i].documentAdd) {
          // the upload file or reupload file when editing task required signature and type is not pdf
          if (
            item22.requiredDoc == true &&
            typeof item22.document[0] == "object" &&
            item22.document[0].type !== "application/pdf"
          ) {
            errorM = `Document "${item22.document[0].name}" requires signature must be an PDF, please upload correct one`;
            break;
          }
        }
      }
    });

    if (errorM !== "") {
      setErrorMsg(errorM);
      setOpenErrorMsg(true);
      setTimeout(() => {
        setOpenErrorMsg(false);
      }, 3000);
      return;
    }

    // return;
    if (idError === "true") {
      return;
    }
    // remove attribute "required"
    removeRequired();
    e.preventDefault();

    var documentss = [];

    var fileN;
    if (props.location.state && props.location.state.requestValue) {
      const data = props.location.state.requestValue;
      data.documentAdd &&
        data.documentAdd.map(async (rec, i) => {
          const docExtension = rec.document.split(".")[1];
          const url =
            process.env.REACT_APP_CDN +
            "supplier-documents/tasks/" +
            data.task_id +
            "/" +
            data.documentAdd[i].document;

          let file = await fetch(url)
            .then((r) => r.blob())
            .then(
              (blobFile) =>
                new File([blobFile], rec.document, {
                  type: "application/json",
                })
            );

          documentss.push({
            document: file.name,
            notes: rec.notes,
          });

          fileN = file;
        });
    } else {
      taskInfo.map((rec) => {
        if (editView.edit_task == true) {
          rec.documentAdd &&
            rec.documentAdd.map(async (rec1, i) => {
              if (i < rec.documentAdd.length - 1) {
                if (rec1.document != "") {
                  const docExtension =
                    rec1.document && rec1.document.split(".")[1];

                  const url =
                    process.env.REACT_APP_CDN +
                    "admin-documents/taskDocuments/" +
                    rec.task_id +
                    "/" +
                    rec1.document;

                  let file = await fetch(url)
                    .then((r) => r.blob())
                    .then(
                      (blobFile) =>
                        new File([blobFile], rec1.document, {
                          type: "application/json",
                        })
                    );

                  documentss.push({
                    document: file.name,
                    notes: rec1.notes,
                    requiredDoc: rec1.requiredDoc,
                  });

                  fileN = file;
                }
              }
            });
        } else {
          // preet's code
          rec.documentAdd &&
            rec.documentAdd.map(async (rec1) => {
              documentss.push({
                document: rec1.document == "" ? "" : rec1.document[0].name,
                notes: rec1.notes,
                requiredDoc: rec1.requiredDoc,
              });
            });
        }
      });
    }

    setTaskInfo([...taskInfo]);
    var docData = [];
    taskInfo[0].documentAdd.map((rec, i) => {
      if (typeof rec.document == "object") {
        const fileName = rec.document[0].name;
        taskInfo[0].documentAdd[i] = {
          document: rec.document[0].name,
          notes: rec.notes,
          requiredDoc: rec.requiredDoc,
        };
        fileN = rec.document;
        docData.push(rec.document);
      }
    });

    setTaskInfo([...taskInfo]);

    // return;

    var taskData = [];
    taskInfo.forEach((rec) => {
      var taskPhotos = [];
      var taskComment = [];
      rec.task_photos.forEach((rec1) => {
        taskPhotos.push({
          task_id: rec.task_id,
          photo_name: rec1.photo_name,
          task_photos_notes: rec1.task_photos_notes,
          client_photos_shareable: rec1.client_photos_shareable ? "yes" : "no",
        });
      });

      rec.task_comments.forEach((rec1) => {
        taskComment.push({
          task_id: rec.task_id, // Include task_id for each comment
          comment: rec1.comment,
          client_comments_shareable: rec1.client_comments_shareable
            ? "yes"
            : "no",
          task_comments_notes: rec1.task_comments_notes,
        });
      });

      const taskDocAdd = [];
      rec.documentAdd.map(async (rec1) => {
        if (typeof rec1.document[0] == "object") {
          console.log("inside object", rec1.document[0]);
          taskDocAdd.push({
            document: rec1.document[0].name,
            notes: rec1.notes,
            requiredDoc: rec1.requiredDoc === true ? rec1.requiredDoc : false,
          });
        } else {
          taskDocAdd.push({
            document: rec1.document,
            notes: rec1.notes,
            requiredDoc: rec1.requiredDoc == true ? rec1.requiredDoc : false,
          });
        }
      });
      let callMatchRetailers = [];
      rec.callCycle.forEach((call) => {
        if (call.retailer === rec.retailer_name) {
          callMatchRetailers.push(call);
        }
      });
      if (editView.edit_task === false) {
        taskData.push({
          ...rec,
          task_id: rec.task_id.toUpperCase(),
          created_date: rec.created_date,
          task_status: "created",
          PK: rec.PK ? rec.PK : "",
          SK: rec.SK ? rec.SK : "",
          callCycle: callMatchRetailers,
          task_photos: taskPhotos,
          task_comments: taskComment,
          appsync_id: rec.appsync_id !== "" ? rec.appsync_id : "",
          storeOptions: undefined,
          storeOp: undefined,
          documentAdd: taskDocAdd,
        });
      } else {
        taskData.push({
          ...rec,
          task_id: rec.task_id.toUpperCase(),
          created_date: rec.created_date,
          task_status: "created",
          callCycle: callMatchRetailers,
          PK: rec.PK ? rec.PK : "",
          SK: rec.SK ? rec.SK : "",
          task_photos: taskPhotos,
          task_comments: taskComment,
          appsync_id: rec.appsync_id !== "" ? rec.appsync_id : "",
          stores: rec.stores[0],
          documentAdd: taskDocAdd,
          storeOp: undefined,
          storeOptions: undefined,
        });
      }
    });
    AWS.config.update({
      accessKeyId: sessionStorage.getItem("AccessKeyId"),
      secretAccessKey: sessionStorage.getItem("AccessKeySec"),
      sessionToken: sessionStorage.getItem("sessionToken"),
      region: "ap-southeast-2",
    });
    const myBucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
      region: "ap-southeast-2",
    });

    // return;
    // var reocurring = 0;
    setTimeout(async () => {
      setLoading(true);

      await taskInfo.map(async (rec) => {
        (await rec.documentAdd) &&
          rec.documentAdd.map(async (rec1) => {
            console.log("checking documents", rec1);
            // if (reocurring === 0) {
            // reocurring = 1;

            var newFileName;
            var newFile;
            newFile = fileN && fileN[0];

            if (props.location.state && props.location.state.requestValue) {
              if (rec1.document !== undefined && rec1.document !== "") {
                newFileName = rec1.document;
                newFile = fileN;
              }
            } else if (editView.edit_task === true) {
              if (rec1.document !== undefined && rec1.document !== "") {
                newFileName = rec1.document;
                newFile = fileN;
              }
            } else {
              // if (rec1.document !== undefined && rec1.document !== '') {
              //   console.log('in else condition', rec1.document);
              //   newFileName = rec1.document[0].name;
              //   newFile = rec1.document[0];
              // } else
              if (typeof rec1.document[0] == "object") {
                console.log("in else if condition", rec1.document);
                newFileName = rec1.document[0].name;
                newFile = rec1.document[0];
              }
            }
            // return;
            if (newFileName) {
              const params = {
                ACL: "bucket-owner-full-control",
                Body: newFile,
                Bucket: process.env.REACT_APP_BUCKET_NAME,
                Key:
                  "admin-documents/taskDocuments/" +
                  rec.task_id +
                  "/" +
                  newFileName,
              };
              // const uploadToS3 = s3.upload(params).promise();

              setTimeout(async () => {
                const retries = 3;
                const initialDelay = 1000; // 1 second
                const maxDelay = 10000; // 10 seconds
                for (let retryCount = 1; retryCount <= retries; retryCount++) {
                  return new Promise((resolve, reject) => {
                    myBucket.putObject(params, (err, data) => {
                      if (err) {
                        if (err.statusCode == 503) {
                          const delay = Math.min(
                            maxDelay,
                            initialDelay * Math.pow(2, retryCount - 1)
                          );
                          new Promise((resolve) => setTimeout(resolve, delay));
                        }
                        reject(err);
                      } else {
                        resolve(data);
                      }
                    });
                  });
                }
              }, 500);
            }
            if (docData.length > 0) {
              docData.map(async (rec2) => {
                newFileName = rec2[0].name;
                newFile = rec2[0];
                const params = {
                  ACL: "bucket-owner-full-control",
                  Body: newFile,
                  Bucket: process.env.REACT_APP_BUCKET_NAME,
                  Key:
                    "admin-documents/taskDocuments/" +
                    rec.task_id +
                    "/" +
                    newFileName,
                };
                const retries = 4;
                const initialDelay = 1000; // 1 second
                const maxDelay = 10000; // 10 seconds
                for (let retryCount = 1; retryCount <= retries; retryCount++) {
                  // const uploadToS3 = s3.upload(params).promise();
                  return new Promise((resolve, reject) => {
                    myBucket.putObject(params, (err, data) => {
                      if (err) {
                        reject(err);
                        if (err.statusCode == 503) {
                          const delay = Math.min(
                            maxDelay,
                            initialDelay * Math.pow(2, retryCount - 1)
                          );
                          console.log(`Retrying after ${delay} ms...`);
                          new Promise((resolve) => setTimeout(resolve, delay));
                        }
                      } else {
                        console.log("else part");
                        resolve(data);
                      }
                    });
                  });
                }
              });
            } else {
            }
            // }
          });
      });

      axios({
        method: editView.edit_task === true ? "PUT" : "POST",
        headers: {
          Authorization: sessionStorage.getItem("idtoken"),
        },
        url: process.env.REACT_APP_API_BASE + "task-management",
        data: taskData,
      })
        .then((response) => {
          addRequired();
          setLoading(false);
          if (response.data) {
            successfulAPI(fileN, myBucket, docData);
          }
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 504) {
            successfulAPI(fileN, myBucket, docData);
          }

          if (
            error &&
            error.response &&
            error.response.status === 401 &&
            error.response.data.message !== "The incoming token has expired"
          ) {
            setOpenError(true);
            setTimeout(() => {
              setOpenError(false);
            }, 3000);

            // if(error.response)
            const dataEr = error.response.data[0];
            setErrorTask("No Store available");
            if (dataEr.CallCycleNotAvailable.length > 0) {
              setErrorTask("call cycle not available");
            } else if (dataEr.NoAvailableFrequency.length > 0) {
              setErrorTask("Frequency not available");
            } else if (dataEr.StoresNotAvailable.length > 0) {
              setErrorTask(
                dataEr.StoresNotAvailable.toString() +
                  " Stores are not available"
              );
            }
            // window.location.reload();
          } else if (
            error &&
            error.response &&
            error.response.status === 401 &&
            error.response.data.message == "The incoming token has expired"
          ) {
            alert("Session timed out, please login again.");
            localStorage.clear();
            sessionStorage.clear();
            localStorage.clear();

            document.location.replace("/");
          }

          setLoading(false);
        });
    }, 500);
  };
  const resetFields = () => {
    setEditView({ edit_task: false, edit_adhoc: false });
    document.querySelector(".ck-content").innerHTML = "";
    axios({
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("idtoken"),
      },
      url: process.env.REACT_APP_API_BASE + "id-gen?type=task",
    }).then((response) => {
      const id = response.data.body;
      setOpenExcel([]);
      setTaskInfo([
        {
          task_id: id,
          appsync_id: "",
          task_name: "",
          task_priority: "",
          task_type: "",

          callCycleOptions: [],

          task_description: "",
          task_approval: "",
          task_approval_notes: "",
          task_photos: [
            {
              photo_name: "",
              task_photos_notes: "",
              client_photos_shareable: false,
            },
          ],
          task_comments: [
            {
              comment: "",
              task_comments_notes: "",
              client_comments_shareable: false,
            },
          ],
          fine_line: "",

          document_1: "",
          action: "",
          oneOff: false,
          recurring: false,
          week_number: "",
          week_startDate: "",
          week_month: "",
          recurringValue: "",
          startDate: "",
          endDate: "",
          documentAdd: [
            {
              document: "",
              requiredDoc: false,
              notes: "",
            },
          ],
          retailer: "",
          created_date: new Date(),
          supplier_name: "",
          country: [],
          state: [],
          stateOptions: [],
          store: [],
          storeOptions: [],
          csvFile: "",
          week_number: "",
          recurring: "",
          // questions: {
          //   question: '',
          //   Answers: [''],
          // },
          questions: [
            {
              question: "",
              Answers: [""],
              client_shareable: "",
            },
          ],
          state: [],
          stores: [],
          callCycle: [],
          retailer_name: "",
        },
      ]);
    });
    setNotMatch([]);
    setAdditionTasks([
      {
        additionInput: "",
        additionShareable: false,
        additionNotes: "",
      },
    ]);
    setDocumentAdd([
      {
        document: "",
        requiredDoc: false,
        notes: "",
      },
    ]);
    setCallCycleList([{ callCycleOptions: [] }]);
  };

  const [value, setValue] = React.useState(
    localStorage.getItem("userRole") == "manager" ? "2" : "1"
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [optionsOne, setOptionsOne] = React.useState([
    {
      id: "1",
      name: "Victoria",
    },
    {
      id: "2",
      name: "Queensland",
    },
    {
      id: "3",
      name: "New South Wales",
    },
    {
      id: "4",
      name: "Northern Territory",
    },
    {
      id: "5",
      name: "Western Australia",
    },
    {
      id: "6",
      name: "South Australia",
    },
    {
      id: "7",
      name: "Australian Capital Territory",
    },
  ]);
  const children = [];
  for (let i = 0; i < optionsOne.length; i++) {
    children.push(
      <Option key={optionsOne[i].name}>{optionsOne[i].name}</Option>
    );
  }

  const handleChangeStore = (value, i) => {
    setRequiredError({ store: "" });

    var storeL = value;

    value.map((rec) => {
      rec.store_name = rec.label;
    });

    taskInfo[i].stores = value;
    // taskInfo[i].stores = !taskInfo[i ].stores

    setTaskInfo([...taskInfo]);
  };
  const [storeListt, setStoreListt] = useState();
  const childrenState = [];
  for (let i = 0; i < stores.length; i++) {
    childrenState.push(
      <Option key={stores[i].label}>{stores[i].label}</Option>
    );
  }
  // var editorValue = '';
  // var editorValue;
  useEffect(() => {
    if (!props.history.location.state) {
      axios({
        method: "GET",
        headers: {
          Authorization: sessionStorage.getItem("idtoken"),
        },
        url: process.env.REACT_APP_API_BASE + "id-gen?type=task",
      }).then((response) => {
        taskInfo[0].task_id = response.data.body;
        setTaskInfo([...taskInfo]);
      });
    }
    supplierList();
    // setTimeout(() => {
    if (props.history.location.state) {
      setValue(props.history.location.state.tabValue);
      const { requestValue } = props.history.location.state;

      const supplierN = requestValue.username;
      var supplierId = "";
      axios({
        method: "GET",
        headers: {
          Authorization: sessionStorage.getItem("idtoken"),
        },
        url:
          process.env.REACT_APP_API_BASE +
          "call-cycle/list-by-supplier?supplier_username=" +
          supplierN,
      }).then((response) => {
        if (response.data != null) {
          const admindata = response.data.body;
          setStoreListt(admindata);
          admindata &&
            admindata.map((rec) => {
              return (rec.checked = true);
            });
          taskInfo[0].callCycle = [...admindata];
          taskInfo[0].storeOptions = [...admindata];

          var storess = [];
          admindata &&
            admindata.map((rec) => {
              rec.storeList.map((rec1) => {
                storess.push({
                  value: rec1.store_id,
                  label: rec1.store_name,
                  checked: true,
                  state: rec1.state,
                  store_id: rec1.store_id,
                  store_name: rec1.store_name,
                });
              });
            });
          taskInfo[0].storeOptions = storess;
          taskInfo[0].stores = storess;

          var storeIdList = [];
          var cl = [];
          if (taskInfo[0].recurring === true) {
            admindata &&
              admindata.map((rec) => {
                if (rec.call_status === "active") {
                  cl.push(rec);
                  // storeIdList = [];
                  rec.storeList.map((rec1) => {
                    storeIdList.push(rec1);
                  });
                }
              });
            setCallCycleList([{ callCycleOptions: [...cl] }]);
            taskInfo[0].callCycle = cl;
          } else {
            admindata &&
              admindata.map((rec) => {
                // storeIdList = [];
                if (rec.call_status === "active") {
                  rec.storeList.map((rec1) => {
                    storeIdList.push(rec1);
                  });
                  cl.push(rec);
                }
              });

            setCallCycleList([{ callCycleOptions: [...cl] }]);
            taskInfo[0].callCycle = cl;
          }
          setStoreLi(storeIdList);
          axios({
            method: "GET",
            headers: {
              Authorization: sessionStorage.getItem("idtoken"),
            },
            url: process.env.REACT_APP_API_BASE + "admin-portal",
          }).then((response) => {
            const admindata1 = response.data.body;
            const roles = admindata1.filter((item) => {
              if (item.role && item.role === "supplier") {
                return item;
              }
            });
            roles.map((rec) => {
              var retailer1 = [];

              if (rec.username === supplierN) {
                axios({
                  method: "GET",
                  headers: {
                    Authorization: sessionStorage.getItem("idtoken"),
                  },
                  url: process.env.REACT_APP_API_BASE + "admin-portal/retailer",
                }).then((response) => {
                  const admindata2 = response.data.body.Items;

                  admindata2.map((item) => {
                    if (item.status && item.status == "active") {
                      return item;
                    }
                  });

                  admindata2.map((rec1) => {
                    if (rec1.state == rec.state) {
                      retailer1.push(rec1);
                    }
                  });
                  setFilteredRetailer(retailer1);
                });

                taskInfo[0].supplier_id = rec.id;
                taskInfo[0].supplier_username = rec.username;
                taskInfo[0].supplier_name = rec.username;

                supplierId = rec.id;
                taskInfo[0].country = [
                  { value: rec.country, label: rec.country },
                ];
                taskInfo[0].state = [{ value: rec.state, label: rec.state }];

                var ausStateOptions = [];

                var supplier = [];
                taskInfo[0].country.map((rec) => {
                  if (rec.value === "Australia") {
                    ausStateOptions.push(
                      { label: "ACT", value: "ACT" },
                      { label: "NSW", value: "NSW" },
                      { label: "NT", value: "NT" },
                      { label: "QLD", value: "QLD" },
                      { label: "SA", value: "SA" },
                      { label: "TAS", value: "TAS" },
                      { label: "VIC", value: "VIC" },
                      { label: "WA", value: "WA" }
                    );
                    setStateData(ausStateOptions);
                  } else if (rec.value == "New Zealand") {
                    ausStateOptions.push(
                      { label: "NI", value: "NI" },
                      { label: "SI", value: "SI" }
                    );
                    setStateData(newStateOptions);
                  }
                });
                taskInfo[0].stateOptions = ausStateOptions;

                // })
                // (taskInfo[0].state).map(rec1 => {
                roles.map((rec1) => {
                  if (rec.state === rec1.state) {
                    supplier.push(rec1);
                  }
                });

                setFilteredSupplier(supplier);

                requestValue &&
                  requestValue.responseAdd &&
                  requestValue.responseAdd.map((rec) => {
                    if (rec.responseInput === "Photos") {
                      taskInfo[0].task_photos[0].task_photos_notes =
                        rec.responseNote;
                      taskInfo[0].task_photos[0].photo_name = "Required";
                      taskInfo[0].task_photos[0].client_photos_shareable = true;
                    }
                    if (rec.responseInput === "Comments") {
                      taskInfo[0].task_comments[0].task_comments_notes =
                        rec.responseNote;
                      taskInfo[0].task_comments[0].comment = "Required";
                      taskInfo[0].task_comments[0].client_comments_shareable = true;
                    }
                    if (rec.responseInput === "Surveys") {
                      taskInfo[0].questions[0].question = rec.responseInput;
                      taskInfo[0].questions[0].Answers[0] = rec.responseNote;
                    }
                  });

                var documentss = [];
                requestValue &&
                  requestValue.documentAdd &&
                  requestValue.documentAdd.map((rec) => {
                    if (rec.document != "") {
                      setEditView({ edit_adhoc: true, edit_task: false });
                    } else {
                      setEditView({ edit_adhoc: false, edit_task: false });
                    }

                    documentss.push({
                      ...rec,
                      document: rec.document,
                      notes: rec.notes,
                      requiredDoc: rec.requiredDoc ? rec.requiredDoc : false,
                    });
                    setDocumentAdd([
                      {
                        ...rec,
                        document:
                          // 'https://dev-cdn.gforceportal.com.au/supplier-documents/tasks/' +
                          // requestValue.task_id +
                          // '/' +
                          rec.document,
                        notes: rec.notes,
                        requiredDoc: rec.requiredDoc ? rec.requiredDoc : false,
                      },
                    ]);
                  });
                requestValue &&
                  taskInfo.map((rec) => {
                    setTaskInfo([
                      {
                        ...rec,

                        task_id: requestValue.task_id,
                        task_name: requestValue.taskName,

                        responseAdd: requestValue.responseAdd
                          ? requestValue.responseAdd
                          : [],
                        created_date: requestValue.create_date
                          ? new Date(requestValue.create_date)
                          : "",
                        dayOffCheck:
                          requestValue.frequency === "one off" ? true : false,
                        oneOff:
                          requestValue.frequency === "one off" ? true : false,
                        recurringCheck:
                          requestValue.frequency === "7" ||
                          requestValue.frequency === "14odd" ||
                          requestValue.frequency === "14even" ||
                          requestValue.frequency === "28"
                            ? true
                            : false,
                        recurring:
                          requestValue.frequency === "7" ||
                          requestValue.frequency === "14odd" ||
                          requestValue.frequency === "14even" ||
                          requestValue.frequency === "28"
                            ? true
                            : false,
                        recurringValue:
                          requestValue.frequency !== "one off" ||
                          requestValue.frequency !== "exception"
                            ? requestValue.frequency
                            : "",
                        week_startDate:
                          requestValue.frequency === "one off"
                            ? new Date(requestValue.create_date)
                            : "",
                        startDate:
                          requestValue.frequency === "7" ||
                          requestValue.frequency === "14odd" ||
                          requestValue.frequency === "14even" ||
                          requestValue.frequency === "28"
                            ? new Date(requestValue.create_date)
                            : "",
                        supplier_name: requestValue.username,
                        // callCycle: [],
                        supplier_id: supplierId,
                        documentAdd: documentss,
                      },
                    ]);
                  });
                requestValue &&
                  taskInfo.map((rec4) => {
                    setTaskInfo([
                      {
                        ...rec4,
                        task_id: requestValue.task_id,
                        task_name: requestValue.taskName,

                        responseAdd: requestValue.responseAdd
                          ? requestValue.responseAdd
                          : [],
                        created_date: requestValue.create_date
                          ? new Date(requestValue.create_date)
                          : "",
                        dayOffCheck:
                          requestValue.frequency == "one off" ? true : false,
                        oneOff:
                          requestValue.frequency == "one off" ? true : false,
                        recurringCheck:
                          requestValue.frequency == "7" ||
                          requestValue.frequency == "14odd" ||
                          requestValue.frequency == "14even" ||
                          requestValue.frequency == "28"
                            ? true
                            : false,
                        recurring:
                          requestValue.frequency == "7" ||
                          requestValue.frequency == "14odd" ||
                          requestValue.frequency == "14even" ||
                          requestValue.frequency == "28"
                            ? true
                            : false,
                        recurringValue:
                          requestValue.frequency != "one off" ||
                          requestValue.frequency != "exception"
                            ? requestValue.frequency
                            : "",
                        week_startDate:
                          requestValue.frequency == "one off"
                            ? new Date(requestValue.create_date)
                            : "",
                        startDate:
                          requestValue.frequency == "7" ||
                          requestValue.frequency == "14odd" ||
                          requestValue.frequency == "14even" ||
                          requestValue.frequency == "28"
                            ? new Date(requestValue.create_date)
                            : "",
                        supplier_name: requestValue.username,
                        // callCycle: [],
                        supplier_id: supplierId,
                        documentAdd: documentss,
                        task_description: requestValue.description
                          ? requestValue.description
                          : "",
                      },
                    ]);
                  });
              }
            });
          });
        } else {
          setCallCycleList([{ callCycleOptions: [] }]);
        }
      });
    }
  }, []);

  const [openError, setOpenError] = React.useState(false);
  const [openErrorMsg, setOpenErrorMsg] = React.useState(false);
  const [openFiveError, setOpenFiveError] = React.useState(false);
  const [openList, setOpenList] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseFiveError = () => {
    setOpenFiveError(false);
  };
  const handleCloseList = () => {
    setOpenList(false);
  };
  const handleCloseError = () => {
    setOpenError(false);
  };
  const handleCloseErrorMsg = () => {
    setOpenErrorMsg(false);
  };

  const [taskcheckboxArray, setTaskcheckboxArray] = useState([
    {
      isSamStores: true,
      isCheckFrequecy: true,
      isChecked: true,
    },
  ]);
  const [taskcheckbox, setTaskcheckbox] = useState({
    isSamStores: true,
    isCheckFrequecy: true,
    isChecked: true,
  });

  const getRetailerList = () => {
    axios({
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("idtoken"),
      },
      url: process.env.REACT_APP_API_BASE + "admin-portal/retailer",
    })
      .then((response) => {
        const admindata = response.data.body.Items;
        var weeks = [],
          firstDate = new Date(2022, 6, 1),
          lastDate = new Date(2022, 6 + 1, 0),
          numDays = lastDate.getDate();

        var start = 1;
        var end = 7 - firstDate.getDay();
        while (start <= numDays) {
          weeks.push({ start: start, end: end });
          start = end + 1;
          end = end + 7;
          if (end > numDays) end = numDays;
        }

        const activeRetail =
          admindata &&
          admindata.filter((item) => {
            if (item.status && item.status == "active") {
              return item;
            }
          });
        setRetailerList(activeRetail);
      })
      .catch((error) => {
        if (
          error.response.status == 401 &&
          error.response.data.message == "The incoming token has expired"
        ) {
          alert("Session timed out, please login again.");
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();

          document.location.replace("/");
        }
      });
  };
  const [retailerList, setRetailerList] = React.useState([]);
  const [notMatch, setNotMatch] = React.useState([]);
  //
  const [supplierListing, setSupplierListing] = React.useState([]);
  const supplierList = () => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_API_BASE + "admin-portal",
      headers: {
        Authorization: sessionStorage.getItem("idtoken"),
      },
    }).then((response) => {
      const admindata = response.data.body;
      const roles =
        admindata &&
        admindata.filter((item) => {
          if (item.role && item.role == "supplier" && item.status == "active") {
            return item;
          }
        });

      setSupplierListing(roles);
    });
  };
  const [idIndex, setIdIndex] = useState("");
  const [nameIndex, setNameIndex] = useState("");
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const [openExcel, setOpenExcel] = React.useState();
  const handleChangeExcel = (files, i) => {
    setOpenExcel(files);
    if (files[0]) {
      var file = files[0].name;
      var f = files[0];
      const newArr = [];
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" }); // convert to binary, make readed data
        const wsname = readedData.SheetNames[0]; // to find sheet name
        const ws = readedData.Sheets[wsname]; // To get first sheet

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          defval: "",
        }); // to get the header of sheet

        const headers = dataParse[0];

        // const indexStore = headers.indexOf('Store Name*');
        var indexStoreID;
        if (headers.indexOf("Store ID*") == -1) {
          indexStoreID = headers.indexOf("Store No.");
        } else {
          indexStoreID = headers.indexOf("Store ID*");
        }
        setIdIndex(indexStoreID);
        // setNameIndex(indexStore)
        for (let i = 1; i < dataParse.length; i++) {
          newArr.push([...dataParse[i]]);
        }
        var aa = [];
        var storeDataList = [];
        console.log(indexStoreID);

        newArr.map((rec1) => {
          console.log(rec1[indexStoreID]);

          axios({
            method: "GET",
            headers: {
              Authorization: sessionStorage.getItem("idtoken"),
            },
            url:
              process.env.REACT_APP_API_BASE +
              "admin-portal/store?store_id=" +
              rec1[indexStoreID],
          }).then((response) => {
            const detailss = response.data.body.Item;

            if (detailss && detailss.store_id === rec1[indexStoreID]) {
              taskInfo[i].callCycle.map((rec) => {
                if (taskInfo[i].retailer_name == rec.retailer) {
                  storeDataList.push(detailss);
                  storeLi.map((rec2) => {
                    if (rec1[indexStoreID] == rec2.store_id) {
                      aa.push({
                        store_name: detailss.store_name,
                        state: detailss.state,
                        store_id: rec1[indexStoreID],
                        value: rec1[indexStoreID],
                        label: detailss.store_name,
                        checked: true,
                      });
                    }
                  });
                }
              });
              console.log("aaaa", aa);
              let result = newArr.filter(
                (o1) => !aa.some((o2) => o1[indexStoreID] == o2)
              );
              let pp = aa.filter(
                (ele, ind) =>
                  ind === aa.findIndex((elem) => elem.store_id === ele.store_id)
              );
              console.log("ppppp", pp);
              console.log("inside kkk", taskInfo[i].storeOptions);
              console.log("okkkkkk", [...taskInfo[i].storeOptions, ...pp]);
              const removeduplicate = [...taskInfo[i].storeOptions, ...pp];
              let filteresResult = removeduplicate.filter(
                (ele, ind) =>
                  ind ===
                  removeduplicate.findIndex(
                    (elem) => elem.store_id === ele.store_id
                  )
              );
              taskInfo[i].storeOptions = filteresResult;
              setNotMatch(result);
              taskInfo[i].stores = pp;
              setTaskInfo([...taskInfo]);
            }
          });
        });
      };
      reader.readAsBinaryString(f);
    }
  };
  const [suppDis, setSuppDis] = useState(true);
  const retailerStore = (e, i) => {
    if (props.history.location.state) {
      setSuppDis(true);
    } else {
      setSuppDis(false);
    }
    const { value } = e.target;
    setRequiredError({ retailer: "" });
    taskInfo[i].retailer_name = value;

    var statess = [];
    reatilerSupChange(taskInfo[i].supplier_name, i);
    if (taskInfo[i].state) {
      taskInfo[i].state.map((rec) => {
        statess.push(rec.value);
      });
    }
    const stateComma = statess.join(",");
    axios({
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("idtoken"),
      },
      url:
        process.env.REACT_APP_API_BASE +
        "admin-portal/store/list-stores?state=" +
        stateComma,
    }).then((response) => {
      const admindata = response.data;
      var storeNames = [];
      admindata &&
        admindata.map((rec) => {
          if (value == rec.retailer_name) {
            storeNames.push({
              store_name: rec.store_name,
              store_id: rec.store_id,
              retailer: rec.retailer_name,
              state: rec.state,
            });
          }
        });

      var filtered = storeNames.filter(function (el) {
        return el != "";
      });
      var deduped = Array.from(new Set(filtered));

      var storeL = [];
      deduped.map((rec) => {
        storeL.push({
          label: rec.store_name,
          value: rec.store_id,
          checked: true,
          store_id: rec.store_id,
          store_name: rec.store_name,
          retailer: rec.retailer,
          state: rec.state,
        });
      });
      taskInfo[i].storeOptions = storeL;
      taskInfo[i].storeOp = admindata;
      taskInfo[i].stores = storeL;
      setTaskInfo([...taskInfo]);
    });
  };
  const [storeLi, setStoreLi] = React.useState([]);
  const reatilerSupChange = (supplierN, i) => {
    axios({
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("idtoken"),
      },
      url:
        process.env.REACT_APP_API_BASE +
        "call-cycle/list-by-supplier?supplier_username=" +
        supplierN,
    }).then((response) => {
      if (response.data != null) {
        const admindata = response.data.body;

        admindata &&
          admindata.filter((rec) => {
            if (rec.call_status == "active") {
              return rec;
            }
          });
        supplierListing.map((rec) => {
          if (rec.username == supplierN) {
            taskInfo[i].supplier_id = rec.id;
          }
        });

        var arr = [];
        taskInfo[i].callCycle.map((rec) => {
          if (rec.call_status == "active") {
            arr.push(rec.call_id);
          }
        });

        var strId = arr.toString();

        getCallCyc(strId, i);
        var storeIdList = [];
        var cl = [];
        admindata &&
          admindata.map((rec) => {
            if (rec.call_status === "active") {
              cl.push(rec);
              rec.storeList.map((rec1) => {
                storeIdList.push(rec1);
              });
            }
          });

        var storess = [];
        cl.map((rec3) => {
          if (rec3.retailer === taskInfo[i].retailer_name) {
            rec3.storeList &&
              rec3.storeList.map((rec2) => {
                taskInfo[i].state &&
                  taskInfo[i].state.map((rec1) => {
                    taskInfo[i].storeOp &&
                      taskInfo[i].storeOp.map((rec) => {
                        if (
                          rec.state === rec1.value &&
                          rec2.store_id === rec.store_id
                        ) {
                          storess.push({
                            value: rec2.store_id,
                            label: rec.store_name,
                            checked: true,
                            state: rec.state,
                            store_id: rec2.store_id,
                            store_name: rec.store_name,
                          });
                        }
                      });
                  });
              });
          }
        });

        taskInfo[i].storeOptions = storess;
        taskInfo[i].stores = storess;
        setCallCycleList([{ callCycleOptions: [...cl] }]);
        taskInfo[i].callCycle = cl;

        setStoreLi(storess);
        setTaskInfo([...taskInfo]);
      } else {
        setCallCycleList([{ callCycleOptions: [] }]);
        taskInfo[i].callCycle = [];
        setTaskInfo([...taskInfo]);
      }
      // setCallCycleList([...admindata])
    });
  };
  const supplierHnadle = (e, i) => {
    setRequiredError({ supplier_name: "" });
    var supplierN;
    if (typeof e == "string") {
      supplierN = e;
    } else {
      supplierN = e.target.value;
    }

    taskInfo[i].supplier_name = supplierN;

    reatilerSupChange(supplierN, i);
  };
  const weekDays = ["S", "M", "T", "W", "T", "F", "S"];
  const getCallCyc = (callId, i) => {
    axios({
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("idtoken"),
      },
      url: process.env.REACT_APP_API_BASE + "call-cycle?call_id=" + callId,
    }).then((response) => {
      if (response.data.body.Item) {
        const data = response.data.body.Item;

        taskInfo[i].startDateDis = new Date(data.start_date);

        taskInfo[i].endDateDis = new Date(data.end_date);
        setTaskInfo([...taskInfo]);
      }
    });
  };
  const showContent = (e, i, index) => {
    var checkBox = document.getElementById("myCheck");

    taskInfo[i].documentAdd[index].requiredDoc =
      !taskInfo[i].documentAdd[index].requiredDoc;

    setTaskInfo([...taskInfo]);
  };

  const uploadFileHandle = (e, i, index) => {
    taskInfo[i].documentAdd[index].document = e.target.files;
    // setFilePath[index] = e.target.files[0];

    setTaskInfo([...taskInfo]);
  };
  const [idError, setIdError] = React.useState("");

  const [filteredRetailer, setFilteredRetailer] = useState([]);
  const [filteredSupplier, setFilteredSupplier] = useState([]);
  const stateChange = (e, i) => {
    taskInfo[i].state = e;
    var retailer = [];
    var supplier = [];

    e.map((rec1) => {
      retailerList.map((rec) => {
        if (rec.state == rec1.value) {
          retailer.push(rec);
        }
      });
    });
    e.map((rec1) => {
      supplierListing.map((rec) => {
        if (rec.state == rec1.value) {
          supplier.push(rec);
        }
      });
    });
    setFilteredRetailer(retailer);
    setFilteredSupplier(supplier);
    setRequiredError({ state: "" });
    setTaskInfo([...taskInfo]);
  };

  return (
    <div className={loading ? "parentDisable" : ""}>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}

      <Dialog open={openList}>
        <DialogContent>
          <DialogContentText>
            <Row>
              {notMatch.map((rec) => (
                <Col md={10}>
                  <Col md={6} style={{ float: "left" }}>
                    <p>
                      <b>Store ID: </b> {rec[idIndex]}
                    </p>
                  </Col>

                  <Col md={6} style={{ float: "right" }}>
                    <p>
                      <b>Store Name: </b> {rec[nameIndex]}
                    </p>
                  </Col>
                </Col>
              ))}
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleCloseList} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openError}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <label style={{ color: "red" }}>{errorTask}</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleCloseError} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openErrorMsg}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <label style={{ color: "red" }}>{errorMsg}</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button
            onClick={handleCloseErrorMsg}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: "red" }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: "red" }}>
                  Task details successfully added
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openFiveError}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: "red" }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: "red" }}>
                  Tasks are getting inserted, you will receive an email if it
                  fails.
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button
            onClick={handleCloseFiveError}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Page>
        <Row>
          <Col xl={12} lg={12} md={12} style={{ paddingLeft: "0" }}>
            <Card id="userManagement">
              <CardHeader className="card-header-main">
                Task Management
              </CardHeader>
              <CardBody style={{ background: "#fff", padding: "1.25rem" }}>
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { background: "#D71920" } }}
                        className="tabb"
                      >
                        {localStorage.getItem("userRole") != "manager" ? (
                          <Tab
                            name="createTask"
                            label="Create Task"
                            value="1"
                            style={{
                              padding: "12px 16px",
                              color: "rgba(0, 0, 0, 0.6)",
                            }}
                          />
                        ) : null}

                        <Tab
                          name="viewProgress"
                          label="View Progress"
                          value="2"
                          style={{
                            padding: "12px 16px",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        />
                        <Tab
                          label="View Tasks"
                          name="viewTasks"
                          value="3"
                          style={{
                            padding: "12px 16px",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        />
                        <Tab
                          label="Archived Tasks"
                          value="4"
                          name="archivedTasks"
                          style={{
                            padding: "12px 16px",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        />
                        {/* Keep the comment for phase 2 development */}
                        {/* <Tab
                          label="Voided Tasks"
                          value="5"
                          name="voidedTasks"
                          style={{
                            padding: '12px 16px',
                            color: 'rgba(0, 0, 0, 0.6)',
                          }}
                        /> */}
                        {/* <Tab label="View Responses" value="4" /> */}
                      </TabList>
                    </Box>
                    <br />
                    {localStorage.getItem("userRole") != "manager" ? (
                      <TabPanel value="1" style={{ padding: "0px" }}>
                        <Form>
                          <FormGroup>
                            {taskInfo &&
                              taskInfo.map((rec, i) => (
                                <div className="taskBorder" key={i}>
                                  <Row>
                                    <Col
                                      className="taskman-black-border"
                                      md="6"
                                      style={{
                                        borderRight: "2px solid black",
                                        borderBottom: "2px solid black",
                                      }}
                                    >
                                      <Row>
                                        <Col
                                          md="12"
                                          style={{
                                            padding: "0px 20px 20px 20px",
                                            margin: "0",
                                          }}
                                        >
                                          <DropzoneArea
                                            onChange={(e) =>
                                              handleChangeExcel(e, i)
                                            }
                                            filesLimit={1}
                                            id="myDropzoneElementID"
                                            acceptedFiles={[
                                              ".xlsx, .xls, .csv",
                                            ]}
                                            showFileNames={true}
                                            dropzoneClass="FileCss"
                                            // onDelete={removF}
                                            dropzoneText={
                                              <>
                                                <h6
                                                  style={{
                                                    fontSize: "1rem !important",
                                                  }}
                                                >
                                                  Import Stores{" "}
                                                </h6>
                                                <span
                                                  style={{ fontSize: "0.8rem" }}
                                                >
                                                  Drop csv/xlsx file here to
                                                  pre-fill stores
                                                </span>

                                                <br />
                                                <span
                                                  style={{ fontSize: "0.8rem" }}
                                                >
                                                  {" "}
                                                  Or click here to browse your
                                                  files
                                                </span>
                                              </>
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row
                                        className="taskman-flex-adjust"
                                        style={{ margin: "5px" }}
                                      >
                                        <Col
                                          style={{
                                            border: "2px solid #E6E6E6",
                                            borderRadius: "32px",
                                            padding: "10px",
                                            marginRight: "10px",
                                          }}
                                        >
                                          <Row>
                                            <Col md="12">
                                              <label
                                                style={{
                                                  fontSize: "1rem",
                                                  padding: "0px 0px 0px 16px",
                                                  margin: "10px 0px 3px 0px",
                                                }}
                                              >
                                                Select Country
                                                <span style={{ color: "red" }}>
                                                  {" "}
                                                  *
                                                </span>
                                              </label>
                                              <MultiSelect
                                                options={countryOptions}
                                                // hidePlaceholder
                                                value={taskInfo[i].country}
                                                onChange={(e) =>
                                                  handleChangeCountry(e, i)
                                                }
                                                labelledBy="Select"
                                                style={{
                                                  borderRadius: "32px",
                                                }}
                                              />
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              ></span>
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col md="12">
                                              <label
                                                style={{
                                                  fontSize: "1vw",
                                                  padding: "0px 0px 0px 16px",
                                                  margin: "10px 0px 3px 0px",
                                                }}
                                              >
                                                Select State
                                                <span style={{ color: "red" }}>
                                                  {" "}
                                                  *
                                                </span>
                                              </label>
                                              <MultiSelect
                                                options={
                                                  taskInfo[i].stateOptions &&
                                                  taskInfo[i].stateOptions
                                                }
                                                value={taskInfo[i].state}
                                                onChange={(e) => {
                                                  stateChange(e, i);
                                                }}
                                                labelledBy="Select"
                                              />
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {/* {requiredError[i].state} */}
                                              </span>
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col>
                                              <label
                                                style={{
                                                  fontSize: "1vw",
                                                  padding: "0px 0px 0px 16px",
                                                  margin: "10px 0px 3px 0px",
                                                }}
                                              >
                                                Select Retailer
                                                <span style={{ color: "red" }}>
                                                  {" "}
                                                  *
                                                </span>
                                              </label>
                                              <Input
                                                type="select"
                                                name="retailer_name"
                                                value={
                                                  taskInfo[i].retailer_name
                                                }
                                                onChange={(e) => {
                                                  retailerStore(e, i);
                                                }}
                                                style={{
                                                  background:
                                                    "#E6E6E6 0% 0% no-repeat padding-box",
                                                  font: "normal normal 16px/32px Arial",
                                                  letterSpacing: "0px",
                                                  color: "#000000",
                                                  borderRadius: "32px",
                                                  marginTop: "0px",
                                                }}
                                                required
                                              >
                                                <option selected value="">
                                                  Select Retailer
                                                </option>
                                                {filteredRetailer
                                                  .sort((a, b) =>
                                                    a.full_company_name.localeCompare(
                                                      b.full_company_name
                                                    )
                                                  )
                                                  .map((item) => (
                                                    <option
                                                      value={
                                                        item.full_company_name
                                                      }
                                                    >
                                                      {item.full_company_name}
                                                    </option>
                                                  ))}
                                              </Input>
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {/* {requiredError.retailer} */}
                                              </span>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col
                                          className="taskman-margin-adjust1"
                                          style={{
                                            border: "2px solid #E6E6E6",
                                            borderRadius: "32px",
                                            padding: "10px",
                                            marginLeft: "0",
                                          }}
                                        >
                                          {/* <Row style={{ border: "2px solid #E6E6E6", borderRadius: "32px", padding: "15px 5px 15px 5px", margin: "0px 5px 20px 10px" }}> */}
                                          <Col>
                                            <label
                                              style={{
                                                fontSize: "1vw",
                                                padding: "0px 0px 0px 16px",
                                                margin: "10px 0px 3px 0px",
                                              }}
                                            >
                                              Select Supplier
                                              <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                              </span>
                                            </label>
                                            <Input
                                              type="select"
                                              name="supplier_name"
                                              value={taskInfo[i].supplier_name}
                                              // disabled={i > 0}
                                              disabled={suppDis}
                                              onChange={(e) => {
                                                supplierHnadle(e, i);
                                              }}
                                              style={{
                                                background:
                                                  "#E6E6E6 0% 0% no-repeat padding-box",
                                                font: "normal normal 16px/32px Arial",
                                                letterSpacing: "0px",
                                                color: "#000000",
                                                borderRadius: "32px",
                                                marginTop: "0px",
                                              }}
                                              required
                                            >
                                              <option selected value="">
                                                Select Supplier
                                              </option>
                                              {filteredSupplier
                                                .sort((a, b) =>
                                                  a.full_company_name.localeCompare(
                                                    b.full_company_name
                                                  )
                                                )
                                                .map((rec) => (
                                                  <option value={rec.username}>
                                                    {rec.full_company_name}
                                                  </option>
                                                ))}
                                            </Input>
                                            <span
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {/* {requiredError.supplier_name} */}
                                            </span>
                                          </Col>
                                          <Col>
                                            <label
                                              style={{
                                                fontSize: "1vw",
                                                padding: "0px 0px 0px 16px",
                                                margin: "10px 0px 3px 0px",
                                              }}
                                            >
                                              Stores
                                              <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                              </span>
                                            </label>
                                            <MultiSelect
                                              options={taskInfo[i].storeOptions}
                                              value={taskInfo[i].stores}
                                              onChange={(value) => {
                                                handleChangeStore(value, i);
                                              }}
                                              labelledBy="Select"
                                            />
                                          </Col>
                                        </Col>
                                      </Row>
                                      <Row
                                        style={{
                                          border: "2px solid #E6E6E6",
                                          borderRadius: "32px",
                                          padding: "15px 5px 15px 5px",
                                          margin: "0px 5px 20px 10px",
                                        }}
                                      >
                                        <Col md="6">
                                          <h6
                                            style={{
                                              fontSize: "0.8rem",
                                              paddingTop: "15px",
                                            }}
                                          >
                                            Frequency
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          </h6>
                                          <Row style={{ paddingLeft: "35px" }}>
                                            <Input
                                              type="radio"
                                              id={"dayOff" + i}
                                              name={"frequency" + i}
                                              value="oneOff"
                                              disabled={suppDis}
                                              onChange={(e) =>
                                                checkedFrequency(e, i)
                                              }
                                              checked={taskInfo[i].dayOffCheck}
                                            />
                                            <label
                                              htmlFor={"dayOff" + i}
                                              style={{
                                                cursor: "pointer",
                                                fontSize: "0.9vw",
                                              }}
                                            >
                                              One Off
                                            </label>
                                          </Row>
                                          {taskInfo[i].dayOffCheck ? (
                                            <Row>
                                              <Col md="12">
                                                <label>
                                                  Week Start Date
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>
                                                <DatePicker
                                                  className="textBox"
                                                  locale="en-gb"
                                                  disabled={suppDis}
                                                  dateFormat={"dd/MM/yyyy"}
                                                  style={{
                                                    border: "1px solid #ced4da",
                                                    fontSize: ".7rem",
                                                  }}
                                                  weekStartDayIndex={1}
                                                  weekDays={weekDays}
                                                  selected={
                                                    taskInfo[i].week_startDate
                                                  }
                                                  onChange={(date) => {
                                                    taskInfo[i].week_startDate =
                                                      date;
                                                    setTaskInfo([...taskInfo]);
                                                  }}
                                                  placeholderText="Week Start Date"
                                                />
                                                <span
                                                  style={{
                                                    color: "red",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {" "}
                                                  {requiredError.weekDate}
                                                </span>
                                              </Col>
                                            </Row>
                                          ) : null}
                                          <Row style={{ paddingLeft: "35px" }}>
                                            <Input
                                              type="radio"
                                              id={"recurring" + i}
                                              name={"frequency" + i}
                                              disabled={suppDis}
                                              value="recurring"
                                              onChange={(e) =>
                                                checkedFrequency(e, i)
                                              }
                                              checked={
                                                taskInfo[i].recurringCheck
                                              }
                                            />
                                            <label
                                              htmlFor={"recurring" + i}
                                              style={{
                                                cursor: "pointer",
                                                fontSize: "0.9vw",
                                              }}
                                            >
                                              Recurring
                                            </label>
                                          </Row>
                                          {taskInfo[i].recurringCheck ? (
                                            <Row>
                                              <Col md="12">
                                                <label>
                                                  Recurring
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>
                                                <Input
                                                  type="select"
                                                  name="recurringValue"
                                                  disabled={suppDis}
                                                  value={
                                                    taskInfo[i].recurringValue
                                                  }
                                                  onChange={(e) => {
                                                    // setRequiredError({
                                                    //   recurring: '',
                                                    // });
                                                    taskInfo[i].recurringValue =
                                                      e.target.value;
                                                    setTaskInfo([...taskInfo]);
                                                  }}
                                                  style={{
                                                    background:
                                                      "#E6E6E6 0% 0% no-repeat padding-box",
                                                    font: "normal normal 16px/32px Arial",
                                                    letterSpacing: "0px",
                                                    color: "#000000",
                                                    borderRadius: "32px",
                                                    margin: "0px",
                                                  }}
                                                  required
                                                >
                                                  <option value="">
                                                    Select Recurring
                                                  </option>
                                                  {/* <option value="As per call cycle">As per call cycle</option> */}
                                                  <option value="7">
                                                    Days 7
                                                  </option>
                                                  <option value="14odd">
                                                    Days 14 Odd
                                                  </option>
                                                  <option value="14even">
                                                    Days 14 Even
                                                  </option>
                                                  <option value="28">
                                                    Days 28
                                                  </option>
                                                  {/* <option>Monthly</option> */}
                                                </Input>
                                                <span
                                                  style={{
                                                    color: "red",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {" "}
                                                  {requiredError.recurring}
                                                </span>
                                              </Col>
                                              <br />
                                              <Col md="12">
                                                <label>
                                                  Start Date
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>
                                                <DatePicker
                                                  className="textBox"
                                                  dateFormat={"dd/MM/yyyy"}
                                                  style={{
                                                    border: "1px solid #ced4da",
                                                  }}
                                                  disabled={suppDis}
                                                  selected={
                                                    taskInfo[i].startDate
                                                  }
                                                  locale="en-gb"
                                                  onChange={(date) => {
                                                    taskInfo[i].startDate =
                                                      date;
                                                    setTaskInfo([...taskInfo]);
                                                  }}
                                                  minDate={
                                                    taskInfo[i].startDateDis
                                                  }
                                                  maxDate={
                                                    taskInfo[i].endDateDis
                                                  }
                                                  placeholderText="Start Date"
                                                />
                                                <span
                                                  style={{
                                                    color: "red",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {" "}
                                                  {requiredError.weekStartDate}
                                                </span>
                                              </Col>
                                              <Col md="12">
                                                <label>
                                                  End Date
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>
                                                <DatePicker
                                                  className="textBox"
                                                  dateFormat={"dd/MM/yyyy"}
                                                  disabled={suppDis}
                                                  style={{
                                                    border: "1px solid #ced4da",
                                                  }}
                                                  selected={taskInfo[i].endDate}
                                                  locale="en-gb"
                                                  minDate={
                                                    taskInfo[i].startDateDis
                                                  }
                                                  maxDate={
                                                    taskInfo[i].endDateDis
                                                  }
                                                  onChange={(date) => {
                                                    // setRequiredError({
                                                    //   weekEndDate: '',
                                                    // });
                                                    taskInfo[i].endDate = date;
                                                    setTaskInfo([...taskInfo]);
                                                  }}
                                                  placeholderText="End Date"
                                                />
                                                <span
                                                  style={{
                                                    color: "red",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {" "}
                                                  {requiredError.weekEndDate}
                                                </span>
                                              </Col>
                                            </Row>
                                          ) : null}
                                          <div style={{ clear: "both" }} />
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col
                                      className="taskman-black-border"
                                      md="6"
                                      lg="6"
                                      style={{
                                        borderBottom: "2px solid black",
                                      }}
                                    >
                                      <Row>
                                        <Col
                                          style={{
                                            border: "2px solid #E6E6E6",
                                            padding: "20px",
                                            borderRadius: "32px",
                                            margin: "0px 10px 16px 25px",
                                          }}
                                        >
                                          {/* <Row> */}
                                          <Row>
                                            <Col md="12">
                                              <label
                                                style={{
                                                  padding: "0px 0px 0px 8px",
                                                  margin: "0px 0px 0px 3px",
                                                }}
                                              >
                                                Task ID{" "}
                                                <span style={{ color: "red" }}>
                                                  {" "}
                                                  *
                                                </span>
                                              </label>
                                              <Input
                                                type="text"
                                                name="task_id"
                                                value={taskInfo[i].task_id}
                                                style={{
                                                  background:
                                                    "#E6E6E6 0% 0% no-repeat padding-box",
                                                  font: "normal normal 16px/32px Arial",
                                                  letterSpacing: "0px",
                                                  color: "#000000",
                                                  borderRadius: "32px",
                                                  textTransform: "uppercase",
                                                }}
                                                placeholder="Task ID"
                                                disabled
                                                // onChange={e => { taskIdVal(e, i) }}
                                              />
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {requiredError.task_id}
                                                {idError}
                                              </span>
                                            </Col>
                                          </Row>
                                          <Row></Row>
                                          <Row>
                                            <Col>
                                              <label
                                                style={{
                                                  padding: "0px 0px 0px 8px",
                                                  margin: "0px 0px 0px 3px",
                                                }}
                                              >
                                                Task Priority{" "}
                                                <span style={{ color: "red" }}>
                                                  {" "}
                                                  *
                                                </span>
                                              </label>
                                              <Input
                                                type="select"
                                                name="task_priority"
                                                style={{
                                                  background:
                                                    "#E6E6E6 0% 0% no-repeat padding-box",
                                                  font: "normal normal 16px/32px Arial",
                                                  letterSpacing: "0px",
                                                  color: "#000000",
                                                  borderRadius: "32px",
                                                }}
                                                value={rec.task_priority}
                                                onChange={(e) => {
                                                  taskInfo[i].task_priority =
                                                    e.target.value;
                                                  setTaskFields({
                                                    ...taskFields,
                                                    taskInfo: [...taskInfo],
                                                  });
                                                  setTaskInfo([...taskInfo]);
                                                }}
                                              >
                                                <option value="">
                                                  Select Task Priority
                                                </option>
                                                <option>Critical</option>
                                                <option>High</option>
                                                <option>Medium</option>
                                                <option>Low</option>
                                              </Input>
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {/* {requiredError.task_priority} */}
                                              </span>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md="12">
                                              <label
                                                style={{
                                                  padding: "0px 0px 0px 8px",
                                                  margin: "0px 0px 0px 3px",
                                                }}
                                              >
                                                Task Name
                                                <span style={{ color: "red" }}>
                                                  {" "}
                                                  *
                                                </span>
                                              </label>
                                              <Input
                                                type="text"
                                                name="task_name"
                                                style={{
                                                  background:
                                                    "#E6E6E6 0% 0% no-repeat padding-box",
                                                  font: "normal normal 16px/32px Arial",
                                                  letterSpacing: "0px",
                                                  color: "#000000",
                                                  borderRadius: "32px",
                                                }}
                                                placeholder="Task Name"
                                                value={rec.task_name}
                                                onChange={(e) => {
                                                  taskInfo[i].task_name =
                                                    e.target.value;
                                                  setTaskFields({
                                                    ...taskFields,
                                                    taskInfo: [...taskInfo],
                                                  });
                                                  setTaskInfo([...taskInfo]);
                                                }}
                                                required
                                              />
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {/* {requiredError.task_name} */}
                                              </span>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md="12">
                                              <label
                                                style={{
                                                  padding: "0px 0px 0px 8px",
                                                  margin: "0px 0px 0px 3px",
                                                }}
                                              >
                                                Task Description
                                              </label>
                                              <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                  // plugins: [Underline],
                                                  toolbar: [
                                                    "bold",
                                                    "italic",
                                                    "bulletedList",
                                                    "numberedList",
                                                    "undo",
                                                    "redo",
                                                  ],
                                                }}
                                                data={rec.task_description}
                                                onChange={(event, editor) => {
                                                  const data = editor.getData();
                                                  taskInfo[i].task_description =
                                                    data;
                                                  setTaskFields({
                                                    ...taskFields,
                                                    taskInfo: [...taskInfo],
                                                  });
                                                  setTaskInfo([...taskInfo]);
                                                }}
                                              />
                                            </Col>
                                            <Col className="col-md-12">
                                              <Label
                                                style={{
                                                  padding: "0px 0px 0px 8px",
                                                  margin: "0px 0px 0px 3px",
                                                }}
                                              >
                                                Does task need approval?
                                                <span style={{ color: "red" }}>
                                                  {" "}
                                                  *
                                                </span>
                                              </Label>
                                              <Input
                                                type="select"
                                                name="task_approval"
                                                style={{
                                                  background:
                                                    "#E6E6E6 0% 0% no-repeat padding-box",
                                                  font: "normal normal 16px/32px Arial",
                                                  letterSpacing: "0px",
                                                  color: "#000000",
                                                  borderRadius: "32px",
                                                }}
                                                value={rec.task_approval}
                                                onChange={(e) => {
                                                  taskInfo[i].task_approval =
                                                    e.target.value;
                                                  setTaskFields({
                                                    ...taskFields,
                                                    taskInfo: [...taskInfo],
                                                  });
                                                  setTaskInfo([...taskInfo]);
                                                }}
                                                required
                                              >
                                                {/* <option>Approval</option> */}
                                                {/* <option value="">Select Approval</option> */}
                                                <option value="required">
                                                  Required
                                                </option>
                                                <option value="not_required">
                                                  Not Required
                                                </option>
                                                {/* <option>Optional</option> */}
                                              </Input>
                                            </Col>
                                          </Row>
                                          {/* </Row> */}
                                        </Col>
                                        <Col
                                          className="taskman-margin-adjust2"
                                          style={{
                                            border: "2px solid #E6E6E6",
                                            padding: "20px",
                                            borderRadius: "32px",
                                            margin: "0px 25px 25px 10px",
                                          }}
                                        >
                                          {taskPhotos.map((rec1, index) => (
                                            <>
                                              <Row>
                                                <Col>
                                                  <label
                                                    style={{
                                                      padding:
                                                        "0px 0px 0px 8px",
                                                      margin: "0px 0px 0px 3px",
                                                    }}
                                                  >
                                                    Photo Options
                                                  </label>
                                                  <Input
                                                    type="select"
                                                    name="photo_name"
                                                    value={
                                                      taskInfo[i].task_photos &&
                                                      taskInfo[i].task_photos[
                                                        index
                                                      ].photo_name
                                                    }
                                                    style={{
                                                      background:
                                                        "#E6E6E6 0% 0% no-repeat padding-box",
                                                      font: "normal normal 16px/32px Arial",
                                                      letterSpacing: "0px",
                                                      color: "#000000",
                                                      borderRadius: "32px",
                                                    }}
                                                    onChange={(e) => {
                                                      taskInfo[i].task_photos[
                                                        index
                                                      ].photo_name =
                                                        e.target.value;

                                                      setTaskFields({
                                                        ...taskFields,
                                                        taskInfo: [...taskInfo],
                                                      });
                                                      setTaskInfo([
                                                        ...taskInfo,
                                                      ]);
                                                    }}
                                                  >
                                                    <option value="">
                                                      Photo options
                                                    </option>
                                                    <option value="Required">
                                                      Required
                                                    </option>
                                                    <option value="Not Required">
                                                      Not Required
                                                    </option>
                                                    <option value="Optional">
                                                      Optional
                                                    </option>
                                                  </Input>
                                                </Col>
                                              </Row>

                                              <Row>
                                                <Col md="12">
                                                  <label
                                                    style={{
                                                      padding:
                                                        "0px 0px 0px 8px",
                                                      margin: "0px 0px 0px 3px",
                                                    }}
                                                  >
                                                    Photo Notes/Comments
                                                  </label>
                                                  <Input
                                                    type="text"
                                                    name="task_photos_notes"
                                                    placeholder="Notes"
                                                    style={{
                                                      background:
                                                        "#E6E6E6 0% 0% no-repeat padding-box",
                                                      font: "normal normal 16px/32px Arial",
                                                      letterSpacing: "0px",
                                                      color: "#000000",
                                                      borderRadius: "32px",
                                                    }}
                                                    value={
                                                      taskInfo[i].task_photos &&
                                                      taskInfo[i].task_photos[
                                                        index
                                                      ].task_photos_notes
                                                    }
                                                    onChange={(e) => {
                                                      taskInfo[i].task_photos[
                                                        index
                                                      ].task_photos_notes =
                                                        e.target.value;
                                                      setTaskFields({
                                                        ...taskFields,
                                                        taskInfo: [...taskInfo],
                                                      });
                                                      setTaskInfo([
                                                        ...taskInfo,
                                                      ]);
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col md="8">
                                                  <label
                                                    style={{
                                                      margin:
                                                        "33px 0px 0px 8px",
                                                    }}
                                                  >
                                                    Client Shareable
                                                  </label>
                                                </Col>
                                                <Col md="4">
                                                  {/* <br />   */}
                                                  {/* <div class="d-flex flex-row align-items-center"> */}
                                                  <label
                                                    className="switch"
                                                    style={{
                                                      // marginBottom: '0',
                                                      // marginRight: '10px',
                                                      float: "right",
                                                      margin:
                                                        "28px 0px 10px 0px",
                                                    }}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      id="client_photos_shareable"
                                                      name="client_photos_shareable"
                                                      onChange={(e) => {
                                                        taskInfo[i].task_photos[
                                                          index
                                                        ].client_photos_shareable =
                                                          !taskInfo[i]
                                                            .task_photos[index]
                                                            .client_photos_shareable;

                                                        setTaskFields({
                                                          ...taskFields,
                                                          taskInfo: [
                                                            ...taskInfo,
                                                          ],
                                                        });
                                                        setTaskInfo([
                                                          ...taskInfo,
                                                        ]);
                                                      }}
                                                      checked={
                                                        taskInfo[i]
                                                          .task_photos &&
                                                        taskInfo[i].task_photos[
                                                          index
                                                        ]
                                                          .client_photos_shareable
                                                      }
                                                    />
                                                    <span className="slider round"></span>
                                                  </label>
                                                  {/* </div> */}
                                                </Col>
                                              </Row>
                                            </>
                                          ))}
                                          {taskComment.map((rec1, index) => (
                                            <>
                                              <Row>
                                                <Col>
                                                  <label
                                                    style={{
                                                      padding:
                                                        "0px 0px 0px 8px",
                                                      margin: "0px 0px 0px 3px",
                                                    }}
                                                  >
                                                    Comments
                                                  </label>
                                                  <Input
                                                    type="select"
                                                    name="comment"
                                                    style={{
                                                      background:
                                                        "#E6E6E6 0% 0% no-repeat padding-box",
                                                      font: "normal normal 16px/32px Arial",
                                                      letterSpacing: "0px",
                                                      color: "#000000",
                                                      borderRadius: "32px",
                                                    }}
                                                    value={
                                                      taskInfo[i]
                                                        .task_comments &&
                                                      taskInfo[i].task_comments[
                                                        index
                                                      ].comment
                                                    }
                                                    onChange={(e) => {
                                                      taskInfo[i].task_comments[
                                                        index
                                                      ].comment =
                                                        e.target.value;
                                                      setTaskFields({
                                                        ...taskFields,
                                                        taskInfo: [...taskInfo],
                                                      });
                                                      setTaskInfo([
                                                        ...taskInfo,
                                                      ]);
                                                    }}
                                                  >
                                                    <option value="">
                                                      Comments
                                                    </option>
                                                    <option>Required</option>
                                                    <option>
                                                      Not Required
                                                    </option>
                                                    <option>Optional</option>
                                                  </Input>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col md="12">
                                                  <label
                                                    style={{
                                                      padding:
                                                        "0px 0px 0px 8px",
                                                      margin: "0px 0px 0px 3px",
                                                    }}
                                                  >
                                                    Notes/Comments
                                                  </label>
                                                  <Input
                                                    type="text"
                                                    name="task_comments_notes"
                                                    style={{
                                                      background:
                                                        "#E6E6E6 0% 0% no-repeat padding-box",
                                                      font: "normal normal 16px/32px Arial",
                                                      letterSpacing: "0px",
                                                      color: "#000000",
                                                      borderRadius: "32px",
                                                    }}
                                                    placeholder="Notes"
                                                    value={
                                                      taskInfo[i]
                                                        .task_comments &&
                                                      taskInfo[i].task_comments[
                                                        index
                                                      ].task_comments_notes
                                                    }
                                                    onChange={(e) => {
                                                      taskInfo[i].task_comments[
                                                        index
                                                      ].task_comments_notes =
                                                        e.target.value;
                                                      setTaskFields({
                                                        ...taskFields,
                                                        taskInfo: [...taskInfo],
                                                      });
                                                      setTaskInfo([
                                                        ...taskInfo,
                                                      ]);
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col md="8">
                                                  <label
                                                    style={{
                                                      margin:
                                                        "33px 0px 0px 8px",
                                                    }}
                                                  >
                                                    Client Shareable
                                                  </label>
                                                </Col>
                                                <Col md="4">
                                                  <label
                                                    className="switch"
                                                    style={{
                                                      float: "right",
                                                      margin:
                                                        "28px 0px 10px 0px",
                                                    }}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      id="client_comments_shareable"
                                                      name="client_comments_shareable"
                                                      onChange={(e) => {
                                                        taskInfo[
                                                          i
                                                        ].task_comments[
                                                          index
                                                        ].client_comments_shareable =
                                                          !taskInfo[i]
                                                            .task_comments[
                                                            index
                                                          ]
                                                            .client_comments_shareable;

                                                        setTaskFields({
                                                          ...taskFields,
                                                          taskInfo: [
                                                            ...taskInfo,
                                                          ],
                                                        });
                                                        setTaskInfo([
                                                          ...taskInfo,
                                                        ]);
                                                      }}
                                                      checked={
                                                        taskInfo[i]
                                                          .task_comments &&
                                                        taskInfo[i]
                                                          .task_comments[index]
                                                          .client_comments_shareable
                                                      }
                                                    />
                                                    <span className="slider round"></span>
                                                  </label>
                                                </Col>
                                              </Row>
                                            </>
                                          ))}
                                        </Col>
                                      </Row>

                                      {rec.documentAdd &&
                                        rec.documentAdd.map((rec1, index) => (
                                          <Row
                                            key={index}
                                            style={{
                                              border: "2px solid #E6E6E6",
                                              borderRadius: "32px",
                                              padding: "25px 10px 10px 10px",
                                              margin: "10px 0px 20px 15px",
                                            }}
                                          >
                                            <Col>
                                              <Row md="5">
                                                <Col md="6">
                                                  <label>
                                                    Document {index + 1}
                                                  </label>
                                                  {editView.edit_adhoc ==
                                                  true ? (
                                                    <>
                                                      {props.history.location
                                                        .state &&
                                                      props.history.location
                                                        .state.requestValue &&
                                                      typeof taskInfo[i]
                                                        .documentAdd[index]
                                                        .document !=
                                                        "object" ? (
                                                        taskInfo[i].documentAdd[
                                                          index
                                                        ].document &&
                                                        taskInfo[i].documentAdd[
                                                          index
                                                        ].document ? (
                                                          <div>
                                                            <p>
                                                              <a
                                                                title="Click to download"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href={`${
                                                                  process.env
                                                                    .REACT_APP_CDN
                                                                }supplier-documents/tasks/${
                                                                  taskInfo[i]
                                                                    .task_id
                                                                }/${encodeURIComponent(
                                                                  taskInfo[i]
                                                                    .documentAdd[
                                                                    index
                                                                  ].document
                                                                )}`}
                                                                // href={`${process.env.REACT_APP_CDN}supplier-documents/tasks/${taskInfo[i].task_id}/${taskInfo[i].documentAdd[index].document}`}
                                                              >
                                                                {/* {typeof supplierInputField.supplierDoc[index].document == 'string' ? supplierInputField.supplierDoc[index].document : supplierInputField.supplierDoc[index].document.name} */}
                                                                {
                                                                  taskInfo[i]
                                                                    .documentAdd[
                                                                    index
                                                                  ].document
                                                                }
                                                              </a>
                                                            </p>
                                                          </div>
                                                        ) : null
                                                      ) : taskInfo[i]
                                                          .documentAdd[index]
                                                          .document &&
                                                        taskInfo[i].documentAdd[
                                                          index
                                                        ].document &&
                                                        typeof taskInfo[i]
                                                          .documentAdd[index]
                                                          .document !=
                                                          "object" ? (
                                                        <div>
                                                          <p>
                                                            <a
                                                              title="Click to download"
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                              href={`${
                                                                process.env
                                                                  .REACT_APP_CDN
                                                              }admin-documents/taskDocuments/${
                                                                taskInfo[i]
                                                                  .task_id
                                                              }/${encodeURIComponent(
                                                                taskInfo[i]
                                                                  .documentAdd[
                                                                  index
                                                                ].document
                                                              )}`}
                                                              // href={`${process.env.REACT_APP_CDN}admin-documents/taskDocuments/${taskInfo[i].task_id}/${taskInfo[i].documentAdd[index].document}`}
                                                            >
                                                              {/* {typeof supplierInputField.supplierDoc[index].document == 'string' ? supplierInputField.supplierDoc[index].document : supplierInputField.supplierDoc[index].document.name} */}
                                                              {
                                                                taskInfo[i]
                                                                  .documentAdd[
                                                                  index
                                                                ].document
                                                              }
                                                            </a>
                                                          </p>
                                                        </div>
                                                      ) : null}
                                                    </>
                                                  ) : null}

                                                  <Input
                                                    type="file"
                                                    id="file-input"
                                                    name="document"
                                                    accept=".xls,.xlsx,image/gif, image/jpeg, image/png,.csv, text/csv,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                                    style={{
                                                      // background: "#E6E6E6 0% 0% no-repeat padding-box",
                                                      font: "normal normal 16px/32px Arial",
                                                      letterSpacing: "0px",
                                                      color: "#000000",
                                                      // borderRadius: "32px"
                                                    }}
                                                    onChange={(e) => {
                                                      uploadFileHandle(
                                                        e,
                                                        i,
                                                        index
                                                      );
                                                    }}
                                                  />
                                                </Col>
                                                <Col md="6">
                                                  <Input
                                                    type="textarea"
                                                    name="notes"
                                                    value={rec1.notes}
                                                    style={{
                                                      background:
                                                        "#E6E6E6 0% 0% no-repeat padding-box",
                                                      font: "normal normal 16px/32px Arial",
                                                      letterSpacing: "0px",
                                                      color: "#000000",
                                                      borderRadius: "32px",
                                                    }}
                                                    placeholder="Notes"
                                                    onChange={(e) => {
                                                      taskInfo[i].documentAdd[
                                                        index
                                                      ].notes = e.target.value;
                                                      setTaskInfo([
                                                        ...taskInfo,
                                                      ]);
                                                    }}
                                                  />
                                                </Col>
                                                <Col
                                                  md="6"
                                                  style={{
                                                    marginTop: "7px",
                                                  }}
                                                >
                                                  <label>
                                                    {" "}
                                                    Required Signature
                                                  </label>
                                                </Col>
                                                <Col
                                                  md="6"
                                                  style={{
                                                    marginTop: "7px",
                                                  }}
                                                >
                                                  <label className="switch">
                                                    <Input
                                                      type="checkbox"
                                                      name="status"
                                                      id="myCheck"
                                                      // onClick={showContent}
                                                      onChange={(e) =>
                                                        showContent(e, i, index)
                                                      }
                                                      // value={statusValue}
                                                      checked={
                                                        taskInfo[i].documentAdd[
                                                          index
                                                        ].requiredDoc
                                                      }
                                                    />
                                                    <span className="slider round"></span>
                                                  </label>
                                                </Col>
                                              </Row>
                                              <Row
                                                style={{
                                                  float: "right",
                                                }}
                                              >
                                                {index ==
                                                rec.documentAdd.length - 1 ? (
                                                  <Col
                                                    md="1"
                                                    style={{
                                                      height: "30px",
                                                    }}
                                                  >
                                                    <div
                                                      onClick={(e) => {
                                                        addDoc(e, i);
                                                      }}
                                                    >
                                                      <b
                                                        style={{
                                                          fontSize: "34px",
                                                          cursor: "pointer",
                                                          color: "#D71920",
                                                        }}
                                                      >
                                                        +
                                                      </b>
                                                    </div>
                                                  </Col>
                                                ) : null}
                                                {index > 0 ||
                                                (props.location.state &&
                                                  props.location.state) ? (
                                                  <Col md="1">
                                                    <div
                                                      onClick={(e) => {
                                                        rec.documentAdd.splice(
                                                          index,
                                                          1
                                                        );
                                                        setDocumentAdd([
                                                          ...documentAdd,
                                                        ]);
                                                      }}
                                                    >
                                                      {" "}
                                                      <b
                                                        style={{
                                                          fontSize: "34px",
                                                          cursor: "pointer",
                                                          color: "#D71920",
                                                        }}
                                                      >
                                                        -
                                                      </b>{" "}
                                                    </div>
                                                  </Col>
                                                ) : null}
                                              </Row>
                                            </Col>
                                          </Row>
                                        ))}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="3"></Col>
                                  </Row>
                                  {Array.isArray(rec.questions) &&
                                    rec.questions.map((ques, quesIdx) => (
                                      <>
                                        <Row
                                          style={{
                                            border: "2px solid #E6E6E6",
                                            borderRadius: "32px",
                                            padding: "10px",
                                            margin: "0px 0px 20px 15px",
                                          }}
                                        >
                                          <Col md="6">
                                            <Row>
                                              <Col md="12">
                                                <label
                                                  style={{
                                                    padding: "0px 0px 0px 8px",
                                                  }}
                                                >
                                                  Question
                                                </label>
                                                <Input
                                                  type="text"
                                                  name="additionInput"
                                                  style={{
                                                    background:
                                                      "#E6E6E6 0% 0% no-repeat padding-box",
                                                    font: "normal normal 16px/32px Arial",
                                                    letterSpacing: "0px",
                                                    color: "#000000",
                                                    borderRadius: "32px",
                                                  }}
                                                  value={ques.question}
                                                  onChange={(e) => {
                                                    taskInfo[i].questions[
                                                      quesIdx
                                                    ].question = e.target.value;
                                                    setTaskFields({
                                                      ...taskFields,
                                                      taskInfo: [
                                                        ...additionTasks,
                                                      ],
                                                    });
                                                    setTaskInfo([...taskInfo]);
                                                  }}
                                                  placeholder="Question"
                                                />
                                              </Col>
                                              <Col md="12">
                                                <Row>
                                                  <Col md="3">
                                                    <label
                                                      style={{
                                                        padding:
                                                          "5px 0px 0px 8px",
                                                      }}
                                                    >
                                                      Client Shareable
                                                    </label>
                                                  </Col>
                                                  <Col md="2">
                                                    {/* <br />   */}
                                                    {/* <div class="d-flex flex-row align-items-center"> */}
                                                    <label
                                                      className="switch"
                                                      style={{
                                                        // marginBottom: '0',
                                                        // marginRight: '10px',
                                                        float: "right",
                                                      }}
                                                    >
                                                      <input
                                                        type="checkbox"
                                                        id="question_shareable"
                                                        name="question_shareable"
                                                        onChange={(e) => {
                                                          taskInfo[i].questions[
                                                            quesIdx
                                                          ].question_shareable =
                                                            e.target.checked;
                                                          setTaskFields({
                                                            ...taskFields,
                                                            taskInfo: [
                                                              ...taskInfo,
                                                            ],
                                                          });
                                                          setTaskInfo([
                                                            ...taskInfo,
                                                          ]);
                                                        }}
                                                      />
                                                      <span className="slider round"></span>
                                                    </label>
                                                  </Col>
                                                  <Col
                                                    md="5"
                                                    style={{
                                                      textAlign: "right",
                                                      marginTop: "10px",
                                                    }}
                                                  >
                                                    <label>
                                                      {" "}
                                                      Add Question{" "}
                                                    </label>
                                                  </Col>
                                                  {quesIdx ===
                                                  rec.questions.length - 1 ? (
                                                    <Col md="1">
                                                      <div
                                                        onClick={(e) => {
                                                          addQuestion(e, i);
                                                        }}
                                                      >
                                                        <b
                                                          style={{
                                                            fontSize: "34px",
                                                            cursor: "pointer",
                                                            color: "#D71920",
                                                          }}
                                                        >
                                                          +
                                                        </b>
                                                      </div>
                                                    </Col>
                                                  ) : null}

                                                  {/* remove button for question */}
                                                  {quesIdx !== 0 &&
                                                  quesIdx ===
                                                    rec.questions.length - 1 ? (
                                                    <Col md="1">
                                                      <div
                                                        onClick={(e) => {
                                                          taskInfo[
                                                            i
                                                          ].questions.splice(
                                                            quesIdx,
                                                            1
                                                          );
                                                          setTaskInfo([
                                                            ...taskInfo,
                                                          ]);
                                                        }}
                                                      >
                                                        {" "}
                                                        <b
                                                          style={{
                                                            fontSize: "34px",
                                                            cursor: "pointer",
                                                            color: "#D71920",
                                                          }}
                                                        >
                                                          -
                                                        </b>{" "}
                                                      </div>
                                                      <br />
                                                    </Col>
                                                  ) : null}
                                                </Row>
                                              </Col>
                                            </Row>
                                          </Col>
                                          {/* add button for question */}
                                          <Col md="6">
                                            {Array.isArray(ques.Answers) &&
                                              ques.Answers.map(
                                                (answer, ansIdx) => {
                                                  return (
                                                    <Row>
                                                      <Col md="12">
                                                        <label>Answer</label>
                                                        <Input
                                                          type="text"
                                                          name="additionNotes"
                                                          placeholder="Maximum 50 chracters"
                                                          maxLength="50"
                                                          className="admin-input"
                                                          value={
                                                            taskInfo[i]
                                                              .questions[
                                                              quesIdx
                                                            ].Answers[ansIdx]
                                                          }
                                                          onChange={(e) => {
                                                            taskInfo[
                                                              i
                                                            ].questions[
                                                              quesIdx
                                                            ].Answers[ansIdx] =
                                                              e.target.value;
                                                            setTaskInfo([
                                                              ...taskInfo,
                                                            ]);

                                                            setTaskFields({
                                                              ...taskFields,
                                                              taskInfo: [
                                                                ...taskInfo,
                                                              ],
                                                            });
                                                            setAdditionTasks([
                                                              ...additionTasks,
                                                            ]);
                                                          }}
                                                        />
                                                      </Col>
                                                      <Col md="12">
                                                        {ansIdx ==
                                                        ques.Answers.length -
                                                          1 ? (
                                                          <Row>
                                                            <Col
                                                              md="10"
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                                marginTop:
                                                                  "10px",
                                                              }}
                                                            >
                                                              <label>
                                                                Add Answer
                                                              </label>
                                                            </Col>
                                                            <Col md="1">
                                                              <b
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  addShare(
                                                                    e,
                                                                    i,
                                                                    quesIdx
                                                                  );
                                                                }}
                                                                style={{
                                                                  fontSize:
                                                                    "34px",
                                                                  cursor:
                                                                    "pointer",
                                                                  color:
                                                                    "#D71920",
                                                                }}
                                                              >
                                                                +
                                                              </b>
                                                            </Col>

                                                            {ques.Answers
                                                              .length > 1 ? (
                                                              <Col md="1">
                                                                {/* <label
                                                        style={{
                                                          color: 'transparent',
                                                        }}
                                                      >
                                                        Rem
                                                      </label>
                                                      <br /> */}
                                                                <div
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    taskInfo[
                                                                      i
                                                                    ].questions[
                                                                      quesIdx
                                                                    ].Answers.splice(
                                                                      ansIdx,
                                                                      1
                                                                    );
                                                                    setTaskInfo(
                                                                      [
                                                                        ...taskInfo,
                                                                      ]
                                                                    );
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <b
                                                                    style={{
                                                                      fontSize:
                                                                        "34px",
                                                                      cursor:
                                                                        "pointer",
                                                                      color:
                                                                        "#D71920",
                                                                    }}
                                                                  >
                                                                    -
                                                                  </b>{" "}
                                                                </div>
                                                              </Col>
                                                            ) : null}
                                                          </Row>
                                                        ) : null}
                                                      </Col>
                                                    </Row>
                                                  );
                                                }
                                              )}
                                            <Row></Row>
                                          </Col>
                                        </Row>
                                      </>
                                    ))}

                                  <Row>
                                    {i > 0 && i === taskInfo.length - 1 ? (
                                      <Col
                                        md={2}
                                        className="martop"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                          }}
                                        >
                                          <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={(e) => {
                                              taskInfo.splice(i, 1);
                                              setTaskInfo([...taskInfo]);
                                            }}
                                          >
                                            <span>
                                              {" "}
                                              <b>Remove Task</b>{" "}
                                            </span>
                                          </Button>
                                        </div>
                                      </Col>
                                    ) : null}
                                  </Row>
                                </div>
                              ))}
                            <Row
                              className="martop"
                              style={{ justifyContent: "end" }}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={(e) => {
                                  addMoreTask(e);
                                }}
                              >
                                <span>
                                  {" "}
                                  <b>Add More Task</b>{" "}
                                </span>
                              </Button>

                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={(e) => {
                                  createTask(e);
                                }}
                              >
                                <span>
                                  {" "}
                                  {editView.edit_task == true ? (
                                    <b> Update Task</b>
                                  ) : (
                                    <b> Create Task</b>
                                  )}{" "}
                                </span>
                              </Button>

                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={resetFields}
                              >
                                <span>
                                  {" "}
                                  <b> Cancel</b>{" "}
                                </span>
                              </Button>
                            </Row>
                            <Row>
                              <Col>
                                <img
                                  alt="img"
                                  src={greyBanner}
                                  className="greyBanner"
                                ></img>
                              </Col>
                            </Row>
                            <br />
                          </FormGroup>
                        </Form>
                      </TabPanel>
                    ) : null}
                    <TabPanel value="2" style={{ padding: "0px" }}>
                      <TaskManagementTable
                        retailerList={retailerList}
                        setRetailerList={setRetailerList}
                        setSupplierIdP={setSupplierIdP}
                        supplierIdP={supplierIdP}
                        value={value}
                        setValue={setValue}
                        taskPhotos={taskPhotos}
                        setTaskPhotos={setTaskPhotos}
                        taskInfo={taskInfo}
                        setTaskInfo={setTaskInfo}
                        setDataTask={setDataTask}
                        dataTask={dataTask}
                      />
                    </TabPanel>
                    <TabPanel value="3" style={{ padding: "0px" }}>
                      <ViewTasksTable
                        supplierListing={supplierListing}
                        setFilteredSupplier={setFilteredSupplier}
                        retailerList={retailerList}
                        filteredRetailer={filteredRetailer}
                        setFilteredRetailer={setFilteredRetailer}
                        setSupplierIdP={setSupplierIdP}
                        supplierIdP={supplierIdP}
                        value={value}
                        setValue={setValue}
                        taskPhotos={taskPhotos}
                        setTaskPhotos={setTaskPhotos}
                        setEditView={setEditView}
                        editView={editView}
                        taskInfo={taskInfo}
                        setTaskInfo={setTaskInfo}
                        setDataTask={setDataTask}
                        dataTask={dataTask}
                        setCallCycleList={setCallCycleList}
                        callCycleList={callCycleList}
                      />
                    </TabPanel>
                    <TabPanel value="4" style={{ padding: "0px" }}>
                      <ViewArchivedTable
                        supplierListing={supplierListing}
                        setFilteredSupplier={setFilteredSupplier}
                        retailerList={retailerList}
                        filteredRetailer={filteredRetailer}
                        setFilteredRetailer={setFilteredRetailer}
                        setSupplierIdP={setSupplierIdP}
                        supplierIdP={supplierIdP}
                        value={value}
                        setValue={setValue}
                        taskPhotos={taskPhotos}
                        setTaskPhotos={setTaskPhotos}
                        setEditView={setEditView}
                        editView={editView}
                        taskInfo={taskInfo}
                        setTaskInfo={setTaskInfo}
                        setDataTask={setDataTask}
                        dataTask={dataTask}
                        setCallCycleList={setCallCycleList}
                        callCycleList={callCycleList}
                      />
                    </TabPanel>
                  </TabContext>
                </Box>
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </div>
  );
};

export default TaskManagement;
