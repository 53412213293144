import React from 'react';
import Page from 'components/Page';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
} from 'reactstrap';
import Box from '@mui/material/Box';
import { IoMdArrowBack } from 'react-icons/io';

const TableView = props => {
  console.log(props);

  var data = props.history.location.state.rec;
  const keys = Object.keys(data);
  console.log('keys==', keys, props.history.location.state);

  const formatNameString = s => {
    const wordsList = s.split('_');
    const capitalList = wordsList.map(word => {
      if (word == 'id' || word == 'Id') {
        return 'ID';
      } else {
        const lettersList = word.split('').map((letter, index) => {
          if (index == 0) return letter.toUpperCase();
          return letter;
        });
        return lettersList.join('');
      }
    });
    return capitalList.join(' ');
  };

  return (
    <>
      <Page>
        <Row>
          <Col xl={12} lg={12} md={12} style={{ paddingLeft: '0' }}>
            <Card id="userManagement">
              <CardHeader className="card-header-main">View</CardHeader>
              <CardBody style={{ background: '#fff', padding: '1.25rem' }}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <Link
                    to={{
                      pathname: '/task',
                      state: {
                        tabValue: props.history.location.state.tabValue,
                      },
                    }}
                    style={{ color: 'black', fontSize: '25px' }}
                  >
                    <Button size="small" variant="outlined" color="primary">
                      Back
                    </Button>
                  </Link>
                  <br />
                  <Table style={{ padding: '15px', textAlign: 'left' }}>
                    {keys.map(k => {
                      return (
                        <Row>
                          <Col
                            md={6}
                            style={{
                              fontFamily: 'Arial Bold',
                              marginBottom: '0px',
                              paddingTop: '6px',
                              border: '1px solid #ddd',
                            }}
                          >
                            {formatNameString(k)}
                            <br />
                          </Col>
                          <Col
                            md={6}
                            style={{
                              paddingTop: '6px',
                              marginBottom: '0px',
                              border: '1px solid #ddd',
                            }}
                          >
                            <label style={{ fontSize: '14px' }}>
                              {data[k]}
                            </label>
                          </Col>
                        </Row>
                      );
                    })}
                  </Table>
                </Box>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </>
  );
};
export default TableView;
