import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import { Table, Tag, Space, Popconfirm } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import 'antd/dist/antd.css';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const UkgTable = props => {
  console.log(props);
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const [dataDetails, setDataDetails] = React.useState([]);
  const [openSupp, setOpenSupp] = React.useState(false);
  const [openError, setOpenError] = React.useState('');
  const [openErrorF, setOpenErrorF] = React.useState(false);
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchColText, setSearchColText] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const handleClose = () => {
    setOpenSupp(false);
  };
  const handleCloseError = () => {
    setOpenErrorF(false);
  };
  const handleCloser = () => {
    setOpen(false);
  };
  const getDetails = () => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    })
      .then(response => {
        console.log(response.data.body);
        const detailsD = response.data.body;
        setDataDetails(response.data.body);
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };
  const listukg = () => {
    setLoading(true);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'ukg',
    })
      .then(response => {
        if (response.data.statusCode == 200) {
          const callCycle = response.data.body;
          let full_name;
          callCycle.map(rec => {
            rec.roll_assigned = 'false';
            rec.full_name = rec.first_name + ' ' + rec.last_name;
            console.log('full_name', full_name);
          });
          console.log('callCycle', callCycle);
          filter(callCycle);
          setFilteredData(callCycle);
          setTableData(callCycle);
        } else {
          console.log('not 200 status', response);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log('error...', error);
        setLoading(false);
      });
  };
  console.log('tableData', tableData);

  useEffect(() => {
    listukg();
    getDetails();
  }, []);

  const handleView = (record, e) => {
    e.preventDefault();
    console.log('recccc', record);

    if (record.assignedStore.length > 0) {
      props.setStoreCheckbox(true);
    } else {
      props.setStoreCheckbox(false);
    }
    props.setIsViewModes({
      ...props.isViewModes,
      isViewMode_call: true,
    });
    const datleft = [];
    const datRight = [];
    const datStore = [];
    const newfinal = record.assignedStore.map(item => {
      datleft.push({
        FREQUENCY: item.frequency,
        ['Store Name']: item.store_name,
        ['Store No.']: item.store_code,
        State: item.state,
        Supplier: item.supplier,
        ['Task Type']: item.task_type,
        checked: item.checked,
      });
    });
    const newfinals = record.unassignedStore.map(item => {
      datRight.push({
        FREQUENCY: item.frequency,
        ['Store Name']: item.store_name,
        ['Store No.']: item.store_code,
        State: item.state,
        Supplier: item.supplier,
        ['Task Type']: item.task_type,
        checked: item.checked,
      });
    });
    const newfinalsss = record.stores.map(item => {
      datStore.push({
        FREQUENCY: item.frequency,
        ['Store Name']: item.store_name,
        ['Store No.']: item.store_code,
        State: item.state,
        Supplier: item.supplier,
        ['Task Type']: item.task_type,
        checked: item.checked,
      });
    });
    console.log(datStore);
    props.setValue('1');
    props.selectedRole({ value: 'Call Cycle', label: 'Call Cycle' });
    props.setCallCycleInput(record);
    props.setFinalLeftData(datleft);
    props.setFinalRightData(datRight);
    props.setStoresValue(datStore);
    props.setunassignedData(datRight);
    if (record.status == 'active') {
      console.log('true active');
      props.setStatusValue(true);
      props.setToggleText('Active');
    } else if (record.status == 'inactive') {
      props.setStatusValue(false);
      props.setToggleText('Inactive');
    }
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const rec =
    tableData &&
    tableData
      .sort((a, b) => a.full_name.localeCompare(b.full_name))
      .map(({ ...item }) => ({
        ...item,
        // start_date: item.start_date && new Date(item.start_date),

        // end_date: item.end_date && new Date(item.end_date),
      }));
  const [roleDetails, setRoleDetails] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  console.log('modifyCallCycle', rec);
  const AssignRole = (detail, e, i) => {
    console.log(detail);
    setRoleDetails(detail);
    setOpenSupp(true);
  };

  const filter = data => {
    data.map(rec => {
      console.log('rec====>', rec);
      setFilteredData(rec);
    });
  };

  const columns = [
    {
      title: 'Employee ID',
      dataIndex: 'employee_id',
      align: 'center',
      ...getColumnSearchProps('employee_id'),
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      align: 'center',
      ...getColumnSearchProps('full_name'),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      align: 'center',
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center',
      render: (text, record) => {
        return <p style={{ textTransform: 'lowercase' }}>{record.email}</p>;
      },
    },
    {
      title: 'Employment Type ',
      dataIndex: 'employement_type',
      align: 'center',
      filters: [
        { text: 'Casual', value: 'Casual' },
        { text: 'Full Time', value: 'Full Time' },
        { text: 'Part Time', value: 'Part Time' },
      ],
      onFilter: (value, record) => {
        console.log(
          'vvvvv',
          value == record.employement_type,
          value,
          record.employement_type,
        );
        return record['employement_type'] == value;
      },
    },
    {
      title: 'Rep Territory',
      dataIndex: 'rep_territory',
      align: 'center',
    },
    {
      title: 'Assign Role',
      dataIndex: 'assign_role',
      align: 'center',
      render: (text, record, i) => {
        return (
          <>
            {record.roll_assigned == 'true' ? (
              <p>Assigned</p>
            ) : (
              <Button
                style={{ marginRight: '0px', marginLeft: '5px' }}
                onClick={e => AssignRole(record, e, i)}
              >
                Assign Role
              </Button>
            )}
          </>
        );
      },
    },
  ];
  const [userRole, setUserRole] = React.useState({ role: '' });
  const roleChangeHandler = e => {
    console.log(e.target.name);
    console.log(e.target.value);
    setUserRole({ ...userRole, [e.target.name]: e.target.value });
    // setUserRole({ ...userRole })
  };

  const submitRole = () => {
    // console.log(userRole.role)
    // console.log(roleDetails)
    var payload;
    if (userRole.role == 'rep') {
      payload = {
        role: 'rep',
        details: [
          {
            status: roleDetails.status.toLowerCase(),
            id: roleDetails.employee_id,
            first_name: roleDetails.first_name,
            last_name: roleDetails.last_name,
            username: roleDetails.username.toString(),
            employement_type: roleDetails.employement_type,
            employment_status: roleDetails.employment_status,
            line_manager: '',
            rep_type: userRole.rep_type,
            // address: roleDetails.address.address_line_1 + ", " + roleDetails.address.city + ", " + roleDetails.address.country,
            notes: '',
            state: roleDetails.state,
            rep_region: '',

            rep_territory: roleDetails.rep_territory,
            // email: roleDetails.primary_email,
            email: 'tim@itrazotracetech.com',
            personal_email: '',
            mob_number: roleDetails.mobile,
            available: [
              {
                availablity: 'Monday',
                avail_val: '',
                start_time: '',
                end_time: '',
                hours: '',
              },
              {
                availablity: 'Tuesday',
                avail_val: '',
                start_time: '',
                end_time: '',
                hours: '',
              },
              {
                availablity: 'Wednesday',
                avail_val: '',
                start_time: '',
                end_time: '',
                hours: '',
              },
              {
                availablity: 'Thursday',
                start_time: '',
                end_time: '',
                hours: '',
              },
              {
                availablity: 'Friday',
                avail_val: '',
                start_time: '',
                end_time: '',
                hours: '',
              },
              {
                availablity: 'Saturday',
                avail_val: '',
                start_time: '',
                end_time: '',
                hours: '',
              },
              {
                availablity: 'Sunday',
                avail_val: '',
                start_time: '',
                end_time: '',
                hours: '',
              },
            ],
          },
        ],
      };
    }
    if (userRole.role == 'admin') {
      payload = {
        role: 'admin',
        status: roleDetails.status.toLowerCase(),
        id: roleDetails.employee_id,
        first_name: roleDetails.first_name,
        last_name: roleDetails.last_name,
        username: roleDetails.username.toString(),
        //business email will be primary_email
        business_email: 'tim@itrazotracetech.com',
        // address: roleDetails.address.address_line_1 + ", " + roleDetails.address.city + ", " + roleDetails.address.country,
        state: roleDetails.state,
        //personal email will be secondary_email
        personal_email: 'tim@itrazotracetech.com',

        mob_number: roleDetails.mob_number,
      };
    }
    if (userRole.role == 'manager') {
      payload = {
        role: 'manager',
        status: roleDetails.status.toLowerCase(),
        id: roleDetails.employee_id,
        first_name: roleDetails.first_name,
        last_name: roleDetails.last_name,
        username: roleDetails.username.toString(),
        business_email: '',
        // address: roleDetails.address.address_line_1 + ", " + roleDetails.address.city + ", " + roleDetails.address.country,

        state: roleDetails.state,

        email: 'tim@itrazotracetech.com',

        mob_number: roleDetails.mob_number,
      };
    }
    axios({
      method: 'POST',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
      data: payload,
    })
      .then(response => {
        console.log(response);
        if (response) {
          // setOpenError()
          // setMsgNotMatched(true)
          tableData.map((rec, i) => {
            if (roleDetails.employee_id == rec.employee_id) {
              console.log('kkkkk');
              rec.roll_assigned = 'true';
            }
          });
          setOpenSupp(false);
          axios({
            method: 'POST',
            headers: {
              Authorization: sessionStorage.getItem('idtoken'),
            },
            url: process.env.REACT_APP_API_BASE + 'ukg',
            data: payload,
          }).then(response => {
            console.log(response);
          });
          setOpen(true);
          // listukg()
          setTimeout(() => {
            setOpen(false);
          }, 3000);
        }
      })
      .catch(error => {
        // console.log('Error during service worker registration:', error);
        if (error.response.status === 401) {
          setOpenError(error.response.data.error);
          console.log(error.response.data.error);
        }
        setOpenErrorF(true);
        setTimeout(() => {
          setOpenErrorF(false);
        }, 3000);
      });
    console.log(payload);
  };

  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Dialog open={openSupp}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Assign Role</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>Role</label>
                <Input
                  type="select"
                  name="role"
                  value={userRole.role}
                  onChange={roleChangeHandler}
                >
                  <option value="">Select Role</option>
                  <option value="rep">Rep</option>
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                </Input>
              </Col>
              {userRole.role == 'rep' ? (
                <Col md={12}>
                  {/* <b style={{ color: "red" }}>Assign Role</b><br /><br /> */}
                  <label style={{ color: 'red' }}>Rep Type</label>
                  <Input
                    type="select"
                    name="rep_type"
                    value={userRole.rep_type}
                    onChange={roleChangeHandler}
                  >
                    <option value="">Select Type</option>
                    <option value="merchandiser">Merchandiser</option>
                    <option value="sales">Sales</option>
                  </Input>
                </Col>
              ) : null}
            </Row>
            <br />
          </DialogContentText>
          <Row>
            <Col md={6}>
              <Button onClick={handleClose} variant="outlined" color="primary">
                Close
              </Button>
            </Col>
            <Col md={6}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                onClick={submitRole}
              >
                Assign
              </Button>
            </Col>
            <br />
            <br />
            <br />
          </Row>
        </DialogContent>
      </Dialog>
      <Dialog open={openErrorF}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>{openError}</b>
                <br />
                <br />
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleCloseError} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Role have been assigned successfully
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleCloser} variant="outlined" color="primary">
            Close
          </Button>
          {/* <Button type='submit' onClick={submitRole}>Assign</Button> */}
        </DialogContent>
      </Dialog>
      <div className="antdTable">
        {/* {rec ? ( */}
        <Table
          columns={columns}
          dataSource={rec}
          onChange={(pagination, filters, sorter, currentPageData) =>
            filter(currentPageData.currentDataSource)
          }
          pagination={{
            total: filteredData.length,
            showSizeChanger: true,
            showTotal: total => `Total ${total} items`,
            pageSizeOptions: ['10', '50', '100', '200', '500'],
          }}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

export default UkgTable;
