import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
// import '../components/Layout/sidebar.js'
import Page from 'components/Page';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';

const cardStyle = {
  hoverForm: {
    padding: '16px 0px 16px 0px',
    '& a': {
      textDecoration: 'none',
      color: '#0000ee',
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
  },
  hoverForm: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

class AuthPage extends React.Component {
  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  render() {
    console.log(this.props);
    const pathname = window.location.pathname;
    console.log(pathname);
    if (this.props.authState == 'LOGIN') {
      return (
        // <Page title="Log In">
        <Row
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: 'white'
            backgroundImage: `url("https://gforceaus.com/wp-content/uploads/2018/08/Company-3.jpg")`,
            backgroundSize: 'cover',
          }}
        >
          <Col md={7} lg={4}>
            {/* <Card body style={{backgroundColor: '#1A1A1A'}}>  */}
            <Card
              className="login-card"
              tabindex="1"
              body
              style={{
                backgroundColor: 'red',
              }}
            >
              <AuthForm
                authState={this.props.authState}
                history={this.props.history}
                onChangeAuthState={this.handleAuthState}
                onLogoClick={this.handleLogoClick}
              />
            </Card>
          </Col>
        </Row>
        // </Page>
      );
    } else {
      return (
        // <Page title="Register / Sign Up">
        <Row
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Col md={6} lg={6} style={{ padding: '1.25rem' }}>
            <Card
              body
              style={{ backgroundColor: 'rgb(69, 69, 69)', padding: '1.25rem' }}
            >
              <AuthForm
                authState={this.props.authState}
                historypage={this.props.history}
                onChangeAuthState={this.handleAuthState}
                onLogoClick={this.handleLogoClick}
              />
            </Card>
          </Col>
        </Row>
        // </Page>
      );
    }
  }
}

export default AuthPage;
