import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tag, Space, Progress } from 'antd';
import 'antd/dist/antd.css';
import { Button, Input, Row, Col } from 'reactstrap';
import { ConsoleSqlOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import moment from 'moment';
import greyBanner from 'assets/img/logo/Banner Grey.png';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const TaskManagementTable = props => {
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchColText, setSearchColText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const searchInput = useRef(null);
  let [filteredData] = useState();
  console.log(props);
  const taskManagData = [
    {
      // 'Supplier_id': '',
      // 'supplier': '',
      // 'No of Tasks': '',
      // 'percentage': 0,
    },
  ];
  console.log(taskManagData);

  const handleRowClick = e => {
    e.preventDefault();
    console.log('You clicked.');
  };
  const rec = taskManagData.map(({ ...item }) => ({
    ...item,
  }));

  const [taskData, setTaskData] = React.useState([]);
  const groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue,
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };
  const getTasks = () => {
    setLoading(true);
    const curUserRole = localStorage.getItem('userRole');
    // let url = process.env.REACT_APP_API_BASE + 'task-management/progress';
    // let url = 'https://staging-cdn.gforceportal.com.au/progress/tasks.json';
    let url = process.env.REACT_APP_CDN + 'progress/tasks.json';

    if (curUserRole == 'manager') {
      const curManagerState = localStorage.getItem('userState');
      console.log(' manager login , get view prog only for that state task');

      // url = `${process.env.REACT_APP_API_BASE}task-management/manager/progress/${curManagerState}`;
      url = `${process.env.REACT_APP_CDN}tasks/progress/${curManagerState}/tasks.json`;
    }

    // ----------- ecs api version -----------
    // let new_url = `${process.env.REACT_APP_TASKS_BASE}progress`
    // if (curUserRole == 'manager') {
    //   const curManagerState = localStorage.getItem('userState');
    //   console.log(' manager login , get view prog only for that state task new_url');

    //   // url = `${process.env.REACT_APP_API_BASE}task-management/manager/progress/${curManagerState}`;
    //   new_url = `${process.env.REACT_APP_TASKS_BASE}progress?state=${curManagerState}`;
    // }

    // console.log('tasks api new url: ', new_url)
    // ----------- ecs api version -----------

    axios({
      method: 'GET',
      // headers: {
      //   Authorization: sessionStorage.getItem('idtoken'),
      // },
      // url: new_url,
      url: url,
    })
      .then(response => {
        console.log(response);
        // console.log(response.data.Items);
        // const data = response.data.taskSummaryCount;

        let data;
        if (curUserRole == 'manager') {
          data = response.data;
        } else {
          data = response.data.taskSummaryCount;
        }

        // data = response.data.body.taskSummaryCount;

        // const det = groupBy(data, 'supplier_id');

        // Object.entries(det)
        // console.log(Object.entries(det));

        // setTaskData(Object.entries(det))

        console.log('data.suppliers', data.suppliers);
        // var dataTask = [];
        // Object.entries(data.suppliers).map((key, value) => {
        //   console.log(key);
        //   key.map(rec => {
        //     dataTask.push(rec)
        //     console.log(rec)
        //   })

        // })

        const sortedData =
          Array.isArray(data.suppliers) &&
          data.suppliers.length > 0 &&
          data.suppliers.sort((a, b) =>
            a.supplierName
              .toLowerCase()
              .localeCompare(b.supplierName.toLowerCase()),
          );

        setTaskData(sortedData || []);
        setLoading(false);
      })
      .catch(error => {
        console.log(error.response);
        // console.log(error.response.status);
        setLoading(false);
        if (
          error &&
          error.response &&
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };
  useEffect(() => {
    getTasks();
  }, []);
  console.log('modifyTaskManage', rec);
  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div style={{ padding: 0 }}>
  //       <br />
  //       <Input
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => handleSearchCol(selectedKeys, confirm, dataIndex)}
  //         style={{ width: 167, marginLeft: '7px', display: "block", marginTop: '-10px', height: '35px' }}
  //       />
  //       <Space className='btnGap'>
  //         <Button onClick={() => handleSearchCol(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{ width: 82, height: 36 }}
  //         >
  //           Search
  //         </Button>
  //         <Button onClick={() => handleResetCol(clearFilters)}
  //           size="small"
  //           style={{ width: 82, height: 36 }}
  //         >
  //           Reset
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]
  //       ? record[dataIndex]
  //         .toString()
  //         .toLowerCase()
  //         .includes(value.toLowerCase())
  //       : "",
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{ backgroundColor: "#ffc69", padding: 0 }}
  //         searchWords={[searchColText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ""}
  //       />
  //     ) : (
  //       text
  //     ),
  // });

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <br />
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearchCol(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchCol(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),

    onFilter: (value, record) => {
      console.log('valueeee', value, record, dataIndex);
      let text;

      // if (dataIndex === 'supplier_name') {

      //   let pp = record[1] && (record[1]).filter((ele, ind) => ind === (record[1]).findIndex(elem => elem.supplier_name === ele.supplier_name))
      //   text = pp.map(rec => rec.supplier_name);

      // } else if (dataIndex === 'supplier_id') {
      //   text = record[0]
      // }

      if (dataIndex === 'supplierName') {
        text = record.supplierName;
      } else if (dataIndex === 'supplierID') {
        text = record.supplierID;
      }

      return text
        ? text.toString().toLowerCase().includes(value.toLowerCase())
        : '';
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
  });

  const handleSearchCol = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchColText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleResetCol = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const handleChange = (...sorter) => {
    console.log('sorter', sorter);
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
    const currentDataSource = sorter[3].currentDataSource;
    setTaskData(currentDataSource);
  };

  const clearAll = () => {
    setSortedInfo({});
    setSearchText('');
    // taskManagData();
  };

  const handleSearch = e => {
    setSearchText(e.target.value);
    if (e.target.value === '') {
      //  loadData();
    }
  };
  const globalSearch = () => {
    filteredData = rec.filter(value => {
      return (
        value.supplier.toLowerCase().includes(searchText.toLowerCase()) ||
        value.country.toLowerCase().includes(searchText.toLowerCase()) ||
        value.state.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    // setTaskManageData(filteredData);
  };

  // // ToDo: Once have one supplier in table record, uncomment this part for sorting alphabatically and change datasource
  //   console.log(taskData);
  //   const taskAlpha = taskData.sort((a, b) => a.supplier_name.localeCompare(b.supplier_name))
  //   .map(({ ...item }) => ({
  //     ...item,
  //   }));
  const columns = [
    {
      title: 'Supplier ID',
      dataIndex: 'supplierID',
      align: 'center',
      editable: true,
      width: 200,
      ...getColumnSearchProps('supplierID'),
      render: (text, record) => {
        console.log(record);
        console.log(searchedColumn);
        return (
          <div
            style={{ cursor: 'pointer', display: 'block' }}
            onClick={e => {
              props.setValue('3');
              props.setSupplierIdP(record.supplierID);
            }}
          >
            {searchedColumn === 'supplierID' ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchColText]}
                autoEscape
                textToHighlight={record.supplierID}
              />
            ) : (
              <>{record.supplierID}</>
            )}
          </div>
        );
        // return searchedColumn === 'supplierID' ? (
        //   <Highlighter
        //     highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        //     searchWords={[searchColText]}
        //     autoEscape
        //     textToHighlight={record.supplierID}
        //   />
        // ) : (
        //   <span
        //     style={{ cursor: 'pointer', display: 'block' }}
        //     onClick={e => {
        //       props.setValue('3');
        //       props.setSupplierIdP(record.supplierID);
        //     }}
        //   >
        //     {record.supplierID}
        //     <br />
        //   </span>
        // );
      },
    },
    {
      title: 'Supplier',
      dataIndex: 'supplierName',
      align: 'center',
      editable: true,
      width: 300,
      ...getColumnSearchProps('supplierName'),
      render: (text, record) => {
        // let pp = record[1] && (record[1]).filter((ele, ind) => ind === (record[1]).findIndex(elem => elem.supplier_name === ele.supplier_name))
        // console.log('PPu', pp)
        // let value = pp ? pp.map(rec => ' ' + rec.supplier_name) : "";
        return (
          <div
            style={{ cursor: 'pointer', display: 'block' }}
            onClick={e => {
              props.setValue('3');
              props.setSupplierIdP(record.supplierID);
            }}
          >
            {searchedColumn === 'supplierName' ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchColText]}
                autoEscape
                textToHighlight={record.supplierName}
              />
            ) : (
              <>{record.supplierName}</>
            )}
          </div>
        );
        // return searchedColumn === 'supplierName' ? (
        //   <Highlighter
        //     highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        //     searchWords={[searchColText]}
        //     autoEscape
        //     textToHighlight={record.supplierName}
        //   />
        // ) : (
        //   <>
        //     {/* {record.map(rec => */}
        //     <span
        //       style={{ cursor: 'pointer', display: 'block' }}
        //       onClick={e => {
        //         props.setValue('3');
        //         props.setSupplierIdP(record.supplierID);
        //       }}
        //     >
        //       {record.supplierName}
        //       <br />
        //     </span>
        //     {/* )} */}
        //   </>
        // );
      },
    },
    {
      title: 'No. of Tasks',
      dataIndex: 'countTask',
      align: 'center',
      editable: true,
      width: 200,
      // render: (text, record) => {

      //   return (
      //     <>

      //       <p style={{ textTransform: 'capitalize' }}>{).length}</p>

      //     </>
      //   )
      // },
    },
    {
      title: 'Progress',
      dataIndex: 'completePercent',
      align: 'center',
      editable: true,
      width: 300,
      render: (text, record) => {
        console.log(record);
        // var doneTasks = []
        // var totalTasks;
        // var percentage = 0;
        // record[1] && ((record[1]).map(rec => {
        //   // console.log(rec)
        //   if (rec.task_status == "done") {
        //     console.log(rec)
        //     doneTasks.push(rec)
        //   }

        // }))
        // totalTasks = record[1] && (record[1]).length
        // percentage = (((doneTasks.length / totalTasks) * 100).toFixed(2));

        return (
          <>
            <Progress
              percent={parseFloat(record.completePercent).toFixed(0)}
              status="active"
            />
          </>
        );
      },
    },
  ];

  return (
    <div>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      {/* <Space style={{ float: 'right', margin: '15px 0px', padding: '0px 20px 0px 0px' }}>
        <Input
          placeholder="Enter Search Text"
          onChange={handleSearch}
          type="text"
          allowClear
          value={searchText}
          className='admin-input'
        />
        <Button
          type="primary" onClick={globalSearch} style={{ borderRadius: '32px' }}>Search</Button>
        <Button
          onClick={clearAll} variant="outlined" style={{ borderRadius: '32px' }}>Clear All</Button>
      </Space>
      <div style={{ clear: 'both' }}></div> */}
      <div className="antdTable">
        {/* {taskData && taskData.length > 0 ? ( */}
        <Table
          columns={columns}
          // scroll={{ x: 1500 }}
          dataSource={taskData && taskData.length ? taskData : rec}
          // bordered

          // onChange={handleChange}
          pagination={{
            // total: taskData && taskData.length,
            showTotal: total => `Total ${total} items`,
            pageSizeOptions: ['10', '50', '100', '200', '500'],
          }}
        />
        {/* ) : null} */}
      </div>
      <Row>
        <Col>
          <img src={greyBanner} className="greyBanner"></img>
        </Col>
      </Row>
    </div>
  );
};

export default TaskManagementTable;
