import Page from 'components/Page';
import React, { useState } from 'react';
import { useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import Upload from 'rc-upload';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import PhoneInput from 'react-phone-number-input';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { BiUpload } from 'react-icons/bi';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import S3 from 'react-aws-s3';
import AWS from 'aws-sdk';
import * as XLSX from 'xlsx';
import { Checkbox } from '@material-ui/core';
import { MultiSelect } from 'react-multi-select-component';
import { set } from 'react-ga';
import * as FileSaver from 'file-saver';
import { isInvalidID, isInvalidUsername, isValidID } from './AddAdmin.js';
import { message } from 'antd';

const Supplier = props => {
  registerLocale('en-gb', enGB);

  useEffect(() => {
    window.scrollTo(0, 0);
    props.listStoreCall();
    renderSupplierUsername();
  }, []);
  const {
    callCycleInput,
    setCallCycleInput,
    callCycleHandler,
    isViewModes,
    setIsViewModes,
    retailerList,
    supplierList,
    finalData,
    setFinalData,
    finalLeftData,
    setFinalLeftData,
    finalRightData,
    setFinalRightData,
    storesValue,
    setStoresValue,
    storeCheckbox,
    setStoreCheckbox,
    unassigenedData,
    setunassignedData,
    setRequiredError,
    requiredError,
    selectedCountry,
    setSelectedCountry,
    selectedAusState,
    setSelectedAusState,
    countryOptions,
    stateList,
    setStateList,
    selectedStore,
    setSelectedStore,
    callAssignedStores,
    setCallAssignedStores,
  } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openSupp, setOpenSupp] = React.useState(false);
  const [openSuppUpd, setOpenSuppUpd] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [selectedRight, setRightSelected] = useState([]);
  // const [selectedStore, setSelectedStore] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState([]);
  // const [selectedAusState, setSelectedAusState] = useState([]);
  const [selectedNewState, setSelectedNewState] = useState([]);
  const [storeList, setStorelist] = useState([]);
  const [stateData, setStateData] = useState([]);
  // const [stateList, setStateList] = useState([]);
  const [checkedUnassigned, setCheckedUnassigned] = React.useState(false);
  // const [callAssignedStores, setCallAssignedStores] = React.useState([]);

  const convertToJson = (headers, dataParse) => {
    const rows = [];
    dataParse.forEach(row => {
      let rowData = {};
      row.forEach((element, index) => {
        rowData[headers[index]] = element;
      });
      rows.push(rowData);
    });
    return rows;
  };

  // const countryOptions = [
  //   { label: "Australia", value: "australia" },
  //   { label: "New Zealand", value: "newZealand" },
  // ];

  var ausStateOptions = [];

  const newStateOptions = [
    { label: 'NI', value: 'NI' },
    { label: 'SI', value: 'SI' },
  ];
  const handleChange = selectedCount => {
    console.log('ok');
    console.log(selectedCount);

    setRequiredError({ counrtyCall: '' });
    setSelectedCountry(selectedCount);
    selectedCount.map(rec => {
      console.log(rec);

      if (rec.value.toLowerCase() == 'australia') {
        ausStateOptions.push(
          { label: 'ACT', value: 'ACT' },
          { label: 'NSW', value: 'NSW' },
          { label: 'NT', value: 'NT' },
          { label: 'QLD', value: 'QLD' },
          { label: 'SA', value: 'SA' },
          { label: 'TAS', value: 'TAS' },
          { label: 'VIC', value: 'VIC' },
          { label: 'WA', value: 'WA' },
        );
        // setStateData(ausStateOptions)
      } else if (rec.value.toLowerCase() == 'new zealand'.toLowerCase()) {
        ausStateOptions.push(
          { label: 'NI', value: 'NI' },
          { label: 'SI', value: 'SI' },
        );
        // setStateData(newStateOptions)
      } else {
        setSelectedCountry(rec);
      }
      setStateData(ausStateOptions);
      // }
    });
    console.log(ausStateOptions);
    setStateList(ausStateOptions);
    setSelectedAusState([]);
  };
  useEffect(() => {
    // axios({
    //   method: 'GET',
    //   headers: {
    //     'x-api-key': sessionStorage.getItem('apikey'),
    //   },
    //   url: process.env.REACT_APP_API_BASE + 'call-cycle/list-stores',
    // })
    //   .then(response => {
    //     console.log('storesResponse', response);
    //     const strData = response.data;
    //     console.log('strData', strData);
    //     setCallAssignedStores(strData);
    //   })
    //   .catch(error => {
    //     console.log('call-cycle/list-stores fail...', error);
    //   });

    console.log(selectedCountry);
    if (selectedCountry.length > 0) {
      selectedCountry.map(rec => {
        if (rec.value.toLowerCase() == 'australia') {
          ausStateOptions.push(
            { label: 'ACT', value: 'ACT' },
            { label: 'NSW', value: 'NSW' },
            { label: 'NT', value: 'NT' },
            { label: 'QLD', value: 'QLD' },
            { label: 'SA', value: 'SA' },
            { label: 'TAS', value: 'TAS' },
            { label: 'VIC', value: 'VIC' },
            { label: 'WA', value: 'WA' },
          );
        } else if (rec.value.toLowerCase() == 'new zealand'.toLowerCase()) {
          ausStateOptions.push(
            { label: 'NI', value: 'NI' },
            { label: 'SI', value: 'SI' },
          );
        }
      });
      setStateData(ausStateOptions);
      setStateList(ausStateOptions);
    } else {
      setStateData([{ label: '', value: '' }]);
      setStateList([{ label: '', value: '' }]);
    }

    handleChangeState(selectedAusState);
  }, []);

  console.log('callAssignedStores', callAssignedStores);

  const handleChangeState = selectedAusState => {
    const arrState = [];
    setRequiredError({ stateCall: '' });
    setSelectedAusState(selectedAusState);
    console.log('selectedAusState', selectedAusState);
    const stateValue =
      selectedAusState &&
      selectedAusState.map(item => {
        return item.value;
      });
    arrState.push(stateValue);
    console.log(arrState);
    console.log(stateValue);
    let text = arrState.toString();
    console.log(text);

    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE +
        'admin-portal/store/list-stores?state=' +
        text,
    }).then(response => {
      console.log(response);
      if (response.data) {
        let test = [];
        const dataa = response.data;
        console.log(dataa);
        // const output = [...new Map((dataa).map(o => [o.store_name, o])).values()]
        // console.log(output);
        // output.map((rec, index) => {
        //   test.push({ 'label': rec.store_name, 'value': rec.store_name });
        //   console.log(test)
        // })
        // setStorelist(test)
      }
    });
  };

  ////// upload excel file
  const handleUpload = e => {
    e.preventDefault();
    if (e.target.files[0]) {
      var file = e.target.files,
        f = file[0]; //file info, get file
      // setFileName( f.name );
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        // console.log(data, 'data');
        let readedData = XLSX.read(data, { type: 'binary' });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        /* Convert array to json*/
        var dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '' });
        const headers = dataParse[0];
        const heads = headers.map(head => ({ title: head, field: head }));
        dataParse.splice(0, 1);
        const list = convertToJson(headers, dataParse);
        // filter according to selected freq
        var newListLeft = [];
        var newListRight = [];
        console.log(callAssignedStores);
        list.forEach(item => {
          console.log('item===>', item);
          if (
            callCycleInput.call_cycle_freq == '7' &&
            item['Frequency*'] == '7'
          ) {
            selectedAusState.map(rec => {
              if (rec.value.toLowerCase() == item['State*'].toLowerCase()) {
                console.log(' state match', item);
                const curStoreId = item['Store ID*'];
                const supplierN = item['Store ID*'];
                const curCallId = callCycleInput.call_id;
                let isIncluded = false;
                let checkedInc = true;
                let anotherDis = false;
                callAssignedStores.forEach(itm => {
                  if (
                    itm.call_id == curCallId &&
                    itm.store_id == curStoreId &&
                    callCycleInput.supplier_name == itm.supplier_name
                  ) {
                    console.log(
                      'ifcccallCycleInput.call_id',
                      callCycleInput.call_id,
                      itm.call_id,
                      curCallId,
                      itm.store_id,
                      curStoreId,
                    );
                    isIncluded = false;
                    checkedInc = true;
                    anotherDis = false;
                    return;
                  } else {
                    console.log(
                      'elsecccallCycleInput.call_id',
                      callCycleInput.call_id,
                      itm.call_id,
                      curCallId,
                      itm.store_id,
                      curStoreId,
                    );
                    if (
                      itm.store_id == curStoreId &&
                      callCycleInput.supplier_name == itm.supplier_name
                    ) {
                      isIncluded = true;
                      checkedInc = false;
                      anotherDis = true;

                      return;
                    }
                  }
                });
                console.log(' state match22', isIncluded);
                newListLeft.push({
                  ...item,
                  checked: checkedInc,
                  disabled: isIncluded,
                  anotherDis: anotherDis,
                  frequency: '7',
                });
              }
              // else {
              //   const curStoreId = item['Store ID*'];
              //   const curCallId = callCycleInput.call_id;
              //   let isIncluded = false;
              //   callAssignedStores.forEach(itm => {
              //     if (itm.store_id == curStoreId) {
              //       isIncluded = true;
              //       return;
              //     }
              //   });
              //   newListRight.push({
              //     ...item,
              //     checked: true,
              //     disabled: isIncluded,
              //   });
              // }
            });
          } else if (
            callCycleInput.call_cycle_freq == '28' &&
            (item['Frequency*'] == '28' ||
              item['Frequency*'] == '14O' ||
              item['Frequency*'] == '14o' ||
              item['Frequency*'] == '14e' ||
              item['Frequency*'] == '14E' ||
              item['Frequency*'] == '7')
          ) {
            var leftData = {};
            var rightData = {};
            selectedAusState.map(rec => {
              console.log(rec.value);
              console.log(callAssignedStores);
              if (rec.value.toLowerCase() == item['State*'].toLowerCase()) {
                const curStoreId = item['Store ID*'];
                const curCallId = callCycleInput.call_id;
                let isIncluded = false;
                let checkedInc = true;
                let anotherDis = false;

                callAssignedStores.forEach(itm => {
                  if (itm.call_id == curCallId && itm.store_id == curStoreId) {
                    console.log(
                      'ifcccallCycleInput.call_id',
                      callCycleInput.call_id,
                      itm.call_id,
                      curCallId,
                      itm.store_id,
                      curStoreId,
                    );
                    isIncluded = false;
                    checkedInc = true;
                    anotherDis = false;
                    // disabledAn = true
                    return;
                  } else {
                    if (
                      itm.store_id == curStoreId &&
                      callCycleInput.supplier_name == itm.supplier_name
                    ) {
                      isIncluded = true;
                      checkedInc = false;
                      anotherDis = true;
                      return;
                    }
                  }
                });
                newListLeft.push({
                  ...item,
                  checked: checkedInc,
                  disabled: isIncluded,
                  anotherDis: anotherDis,
                  frequency: '28',
                });
                // newListLeft.push({ ...item, checked: true, frequency: '28' });
              }
              // else {
              //   const curStoreId = item['Store ID*'];
              //   const curCallId = callCycleInput.call_id;
              //   let isIncluded = false;
              //   callAssignedStores.forEach(itm => {
              //     if (itm.store_id == curStoreId) {
              //       isIncluded = true;
              //       return;
              //     }
              //   });
              //   newListRight.push({
              //     ...item,
              //     checked: true,
              //     disabled: isIncluded,
              //   });
              // }
            });
          } else if (
            callCycleInput.call_cycle_freq == '14odd' &&
            (item['Frequency*'] == '14O' ||
              item['Frequency*'] == '14o' ||
              item['Frequency*'] == '7')
          ) {
            selectedAusState.map(rec => {
              // console.log(rec.value)
              if (rec.value.toLowerCase() == item['State*'].toLowerCase()) {
                const curStoreId = item['Store ID*'];
                const curCallId = callCycleInput.call_id;
                let isIncluded = false;
                let checkedInc = true;
                let anotherDis = false;

                callAssignedStores.forEach(itm => {
                  if (itm.call_id == curCallId && itm.store_id == curStoreId) {
                    console.log(
                      'ifcccallCycleInput.call_id',
                      callCycleInput.call_id,
                      itm.call_id,
                      curCallId,
                      itm.store_id,
                      curStoreId,
                    );
                    isIncluded = false;
                    checkedInc = true;
                    anotherDis = false;
                    return;
                  } else {
                    if (
                      itm.store_id == curStoreId &&
                      callCycleInput.supplier_name == itm.supplier_name
                    ) {
                      isIncluded = true;
                      checkedInc = false;
                      anotherDis = true;
                      return;
                    }
                  }
                });
                newListLeft.push({
                  ...item,
                  checked: checkedInc,
                  disabled: isIncluded,
                  frequency: '14odd',
                  anotherDis: anotherDis,
                });
                // newListLeft.push({ ...item, checked: true, frequency: '14odd' });
              }
              // else {
              //   const curStoreId = item['Store ID*'];
              //   const curCallId = callCycleInput.call_id;
              //   let isIncluded = false;
              //   callAssignedStores.forEach(itm => {
              //     if (itm.store_id == curStoreId) {
              //       isIncluded = true;
              //       return;
              //     }
              //   });
              //   newListRight.push({
              //     ...item,
              //     checked: true,
              //     disabled: isIncluded,
              //   });
              // }
            });
          } else if (
            callCycleInput.call_cycle_freq == '14even' &&
            (item['Frequency*'] == '14E' ||
              item['Frequency*'] == '14e' ||
              item['Frequency*'] == '7')
          ) {
            selectedAusState.map(rec => {
              // console.log(rec.value)
              if (rec.value.toLowerCase() == item['State*'].toLowerCase()) {
                const curStoreId = item['Store ID*'];
                const curCallId = callCycleInput.call_id;
                let isIncluded = false;
                let checkedInc = true;
                let anotherDis = false;
                callAssignedStores.forEach(itm => {
                  if (itm.call_id == curCallId && itm.store_id == curStoreId) {
                    console.log(
                      'ifcccallCycleInput.call_id',
                      callCycleInput.call_id,
                      itm.call_id,
                      curCallId,
                      itm.store_id,
                      curStoreId,
                    );
                    isIncluded = false;
                    checkedInc = true;
                    anotherDis = false;

                    return;
                  } else {
                    if (
                      itm.store_id == curStoreId &&
                      callCycleInput.supplier_name == itm.supplier_name
                    ) {
                      isIncluded = true;
                      checkedInc = false;
                      anotherDis = true;
                      return;
                    }
                  }
                });
                newListLeft.push({
                  ...item,
                  checked: checkedInc,
                  disabled: isIncluded,
                  anotherDis: anotherDis,

                  frequency: '14even',
                });
                // newListLeft.push({ ...item, checked: true, frequency: '14even' });
              }
              // else {
              //   const curStoreId = item['Store ID*'];
              //   const curCallId = callCycleInput.call_id;
              //   let isIncluded = false;
              //   callAssignedStores.forEach(itm => {
              //     if (itm.store_id == curStoreId) {
              //       isIncluded = true;
              //       return;
              //     }
              //   });
              //   newListRight.push({
              //     ...item,
              //     checked: true,
              //     disabled: isIncluded,
              //   });
              // }
            });
          } else {
            const curStoreId = item['Store ID*'];
            const curCallId = callCycleInput.call_id;
            let isIncluded = false;
            let checkedInc = false;
            let anotherDis = false;

            callAssignedStores.forEach(itm => {
              if (itm.store_id == curStoreId) {
                isIncluded = true;
                checkedInc = false;
                anotherDis = true;
                return;
              }
            });
            newListRight.push({
              ...item,
              checked: checkedInc,
              disabled: isIncluded,
              anotherDis: anotherDis,
            });
          }
        });

        console.log('list', list);
        console.log('newListLeft', newListLeft);
        console.log('newListRight', newListRight);
        setFinalData(list);
        setFinalLeftData(newListLeft);
        setStoresValue(newListLeft);
        setFinalRightData(newListRight);
        setunassignedData(newListRight);
      };
      reader.readAsBinaryString(f);
      e.target.value = '';
    } else {
      //no file choosen
    }
  };

  // To get storesLeft value
  const storeCheckLeftHandler = (e, details) => {
    details.checked = e.target.checked;
    console.log('onchangedetails', details);
    // console.log(e.target.checked);

    if (details.checked) {
      console.log('ok in if checked');
      finalLeftData.map(rec => {
        rec.anotherDis = false;
        if (rec['Store ID*'] == details['Store ID*']) {
          console.log(rec);
          rec.anotherDis = false;
        }
      });
      // setStoresValue([...storesValue, details]);
    } else {
      console.log('ok in else checked');

      console.log(finalLeftData);
      finalLeftData.map(rec => {
        // storesValue.map(rec1 => {
        if (rec['Store ID*'] == details['Store ID*']) {
          console.log(rec);
          rec.anotherDis = true;
        }
        // });
      });
      // setFinalLeftData([...finalLeftData, dataa]);
      const newArr = storesValue.filter(item => item !== details);
      setStoresValue(newArr);
    }
    console.log(storesValue);
    setFinalLeftData([...finalLeftData]);
    console.log(finalLeftData);
  };

  // To get storesRight value
  const storeCheckRightHandler = (e, details) => {
    details.checked = e.target.checked;
    if (details.checked) {
      setRightSelected([...selectedRight, details]);
    } else {
      const newArr = selectedRight.filter(item => item !== details);
      setRightSelected(newArr);
    }
    // console.log('check',e.target.name, e.target.value)
    console.log(selectedRight);
  };

  const leftToRight = e => {
    console.log(storesValue);
    console.log(finalRightData);
    console.log(finalLeftData);

    const dataR = [...finalRightData, ...storesValue];
    // const ids = dataR.map(o => o.id)
    // const filtered = dataR.filter((data, index) => !(data['Store ID*']).includes(data['Store ID*'], index + 1))

    // console.log(filtered)
    setRightSelected([...finalRightData, ...storesValue]);
    setMovedData([...finalRightData, ...storesValue]);
    var arr = [];

    dataR.map(rec1 => {
      finalLeftData.map((rec, index) => {
        if (
          rec['Store ID*'] == rec1['Store ID*'] &&
          rec['Frequency*'] == rec1['Frequency*']
        ) {
          console.log(index);
          // console.log(rec)
          // return;
          // dataR.map(rec3 => {
          //   console.log(rec3)
          if (rec.checked == true) {
            setFinalRightData([...finalRightData, ...storesValue]);
            arr = finalLeftData.splice(index, 1);
          }
          // rec3.checked = false
          // })
        }
      });
      setStoresValue([]);
    });
    // console.log(arr)
    setFinalLeftData(finalLeftData);
    console.log(finalLeftData);
  };
  const [movedData, setMovedData] = React.useState([]);
  const rightToLeft = () => {
    console.log(selectedRight);
    console.log([...finalLeftData, ...selectedRight]);
    const dataR = [...finalLeftData, ...selectedRight];

    setStoresValue([...selectedRight]);
    var arr = [];

    dataR.map(rec1 => {
      finalRightData.map((rec, index) => {
        if (
          rec['Store ID*'] == rec1['Store ID*'] &&
          rec['Frequency'] == rec1['Frequency']
        ) {
          console.log(rec);
          // const index = finalRightData.indexOf(rec);
          // console.log(index)
          // selectedRight.map(rec3 => {
          // console.log(rec3)
          if (rec.checked == true) {
            setFinalLeftData([...finalLeftData, ...selectedRight]);
            arr = finalRightData.splice(index, 1);
          }
          // rec3.checked = true
          // })
        }
      });
      setRightSelected([]);
    });
    // console.log(arr)
    setFinalRightData(finalRightData);

    console.log(finalRightData);
  };
  const [errorMsg, setErrorMsg] = useState();

  /// To add Call Cycle
  const addCallCycle = () => {
    console.log(selectedStore, 'selectedStore====>');
    console.log('finalLeftData====>', finalLeftData);
    console.log(callCycleInput);
    var suppUsername;
    supplierList.map(item => {
      // console.log(callCycleInput.supplier_name)
      // console.log(item.full_company_name)
      if (
        item.full_company_name &&
        item.full_company_name == callCycleInput.supplier_name
      ) {
        // console.log(item)
        suppUsername = item.username;
      }
    });
    console.log(suppUsername);

    if (callCycleInput.call_id == '' || callCycleInput.call_id == null) {
      setRequiredError({ callId: 'Please Enter ID' });
      return;
    }
    if (
      callCycleInput.call_id != '' &&
      callCycleInput.call_id &&
      isInvalidID(callCycleInput.call_id)
    ) {
      setRequiredError({
        callId:
          'Invalid ID, please remove special characters or shorten the ID',
      });
      return;
    }
    if (props.idError != '') {
      return;
    }
    if (
      callCycleInput.call_cycle_name == '' ||
      callCycleInput.call_cycle_name == null
    ) {
      setRequiredError({ callName: 'Please Enter Call Cycle Name' });
      return;
    }
    if (
      callCycleInput.call_cycle_name != '' &&
      callCycleInput.call_cycle_name != null &&
      isInvalidUsername(callCycleInput.call_cycle_name, true)
    ) {
      setRequiredError({
        callName:
          'Invalid call cycle name, please remove special characters, space or shorten the call cycle name',
      });
      return;
    }
    if (
      callCycleInput.supplier_name == '' ||
      callCycleInput.supplier_name == null
    ) {
      setRequiredError({ callSupplier: 'Please Select Supplier' });
      return;
    }
    if (callCycleInput.retailer == '' || callCycleInput.retailer == null) {
      setRequiredError({ callRetailer: 'Please Select Retailer' });
      return;
    }
    if (selectedCountry.length == 0) {
      setRequiredError({ counrtyCall: 'Please Select Country' });
      return;
    }
    if (selectedAusState.length == 0) {
      setRequiredError({ stateCall: 'Please Select State' });
      return;
    }
    if (
      callCycleInput.call_cycle_freq == '' ||
      callCycleInput.call_cycle_freq == null
    ) {
      setRequiredError({ callFrequency: 'Please Enter Frequency' });
      return;
    }
    if (callCycleInput.start_date == '' || callCycleInput.start_date == null) {
      setRequiredError({ strtDate: 'Please Select Start Date' });
      return;
    }
    if (callCycleInput.end_date == '' || callCycleInput.end_date == null) {
      setRequiredError({ endDate: 'Please Select End Date' });
      return;
    }

    const datLeft = [];
    const datRight = [];
    const datStore = [];
    finalLeftData.map(item => {
      datLeft.push({
        value: item['Store Name*'],
        label: item['Store Name*'],
        frequency: item['Frequency*'].toString(),
        store_name: item['Store Name*'],
        store_id: item['Store ID*'].toString(),
        state: item['State*'],
        supplier: item.Supplier,
        checked: item.checked,
        disabled: item.disabled,
        anotherDis: item.anotherDis,
      });
    });
    console.log(datLeft);
    finalRightData.map(item => {
      datRight.push({
        value: item['Store Name*'],
        label: item['Store Name*'],
        frequency: item['Frequency*'].toString(),
        store_name: item['Store Name*'],
        store_id: item['Store ID*'].toString(),
        state: item['State*'],
        supplier: item.Supplier,
        checked: item.checked,
        disabled: item.disabled,
        anotherDis: item.anotherDis,
      });
    });
    datLeft.map(item => {
      console.log('storesVal', item);
      // if (item.disabled == false) {
      if (item.checked == true && item.disabled == false) {
        console.log('storesVal1', item);
        datStore.push({
          value: item.store_name,
          label: item.store_name,
          frequency: item.frequency,
          store_name: item.store_name,
          store_id: item.store_id.toString(),
          state: item.state,
          supplier: item.Supplier,
          checked: item.checked,
          disabled: item.disabled,
          anotherDis: item.anotherDis,
        });
        // datStore.push({
        //   value: item['Store Name*'],
        //   label: item['Store Name*'],
        //   frequency: item['Frequency*'].toString(),
        //   store_name: item['Store Name*'],
        //   store_id: item['Store ID*'].toString(),
        //   state: item['State*'],
        //   supplier: item.Supplier,
        //   checked: item.checked,
        //   disabled: item.disabled,
        //   anotherDis: item.anotherDis,
        // });
      }
    });
    console.log(datStore);
    console.log('storeCheckbox==>', storeCheckbox);
    console.log(storesValue);
    var finalStoreData;
    // if (storeCheckbox) {
    console.log('ifcheckbox===>');
    finalStoreData = datStore;
    // } else {
    //   console.log('elsecheckbox===>', selectedStore)
    //   finalStoreData = selectedStore
    //   console.log(selectedStore)
    // }
    console.log({
      ...callCycleInput,
      stores: finalStoreData,
      assignedStore: datLeft,
      unassignedStore: datRight,
      finalStoreData,
    });
    const data = {
      ...callCycleInput,
      supplier_username: suppUsername,
      stores: finalStoreData,
      call_id: callCycleInput.call_id.toUpperCase(),
      state: selectedAusState,
      country: selectedCountry,
      // selectedStore,
      // stores: datStore,
      call_status: props.statusValue ? 'active' : 'inactive',

      assignedStore: datLeft,
      unassignedStore: datRight,
    };
    console.log('add call cycle payload', data);

    // check daa.assignedStore list should not be empty
    if (data.assignedStore.length == 0) {
      message.warning('Please import stores');
      return;
    }

    // check in the data.assignedStore list, atleast one of checked should be true, otherwise show error
    let assignedChecked = false;
    data.assignedStore.forEach(store => {
      if (store.checked) {
        assignedChecked = true;
      }
    });
    if (!assignedChecked) {
      message.warning('Please select atleast one store');
      return;
    }
    console.log('data', data);
    // return;
    axios({
      method: 'POST',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'call-cycle',
      data: [data],
    })
      .then(response => {
        console.log(response);
        if (response) {
          console.log('call cycle added');
          setOpenSupp(true);
          setErrorMsg('Call cycle details successfully added');
          setTimeout(() => {
            setOpenSupp(false);
          }, 3000);
        }
        console.log(storeCheckbox);
        setFinalLeftData([]);
        setFinalRightData([]);
        setSelectedAusState([]);
        setStorelist([]);
        setSelectedStore([]);
        setSelectedCountry([]);
        setStoreCheckbox(false);
        console.log(storeCheckbox);
        setFinalData([]);
        setFinalLeftData([]);
        setFinalRightData([]);
        setIsViewModes({
          ...isViewModes,
          isViewMode_call: false,
        });
        props.setStatusValue(true);
        props.setToggleText('Active');
        props.listStoreCall();
        setCallCycleInput({
          call_status: 'inactive',
          call_id: '',
          retailer: '',
          supplier_name: '',
          state: '',
          country: '',
          stores: [
            {
              store_name: '',
            },
          ],
          start_date: '',
          end_date: '',
          recurring_task_type: '',
          call_cycle_name: '',
          call_cycle_freq: '',
          // call_cycle_type: '',
          call_time: '',
          total_visits: '',
          unassigned_status: 'inactive',
          assignedStore: [
            {
              store_name: '',
              store_code: '',
              state: '',
              supplier: '',
              frequency: '',
              task_type: '',
            },
          ],
          unassignedStore: [
            {
              store_name: '',
              store_code: '',
              state: '',
              supplier: '',
              frequency: '',
              task_type: '',
            },
          ],
        });
      })
      .catch(error => {
        console.log('add call cycle fail...', error.response.data.status);
        setOpenSupp(true);
        setErrorMsg(error.response.data.status);
        setTimeout(() => {
          setOpenSupp(false);
        }, 3000);

        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };

  const updateCallCycle = () => {
    const datLeft = [];
    const datRight = [];
    const datStore = [];
    finalLeftData.map(item => {
      datLeft.push({
        value: item['Store Name*'],
        label: item['Store Name*'],
        frequency:
          item['Frequency*'] != '' ? item['Frequency*'].toString() : '',
        store_name: item['Store Name*'],
        store_id:
          typeof item['Store ID*'] != 'string'
            ? item['Store ID*'].toString()
            : item['Store ID*'],
        state: item['State*'],
        supplier: item.Supplier,
        checked: item.checked,
        disabled: item.disabled,
        anotherDis: item.anotherDis,
      });
    });
    console.log(datLeft);
    finalRightData.map(item => {
      datRight.push({
        value: item['Store Name*'],
        label: item['Store Name*'],
        frequency:
          item['Frequency*'] != '' ? item['Frequency*'].toString() : '',
        store_name: item['Store Name*'],
        store_id:
          typeof item['Store ID*'] != 'string'
            ? item['Store ID*'].toString()
            : item['Store ID*'],
        state: item['State*'],
        supplier: item.Supplier,
        checked: item.checked,
        disabled: item.disabled,
        anotherDis: item.anotherDis,
      });
    });
    storesValue.map(item => {
      console.log(item);
      // if (item.disabled == false) {
      if (item.checked == true && item.disabled == false) {
        datStore.push({
          value: item['Store Name*'],
          label: item['Store Name*'],
          frequency:
            item['Frequency*'] != '' ? item['Frequency*'].toString() : '',
          store_name: item['Store Name*'],
          store_id: item['Store ID*'].toString(),
          state: item['State*'],
          supplier: item.Supplier,
          checked: item.checked,
          disabled: item.disabled,
          anotherDis: item.anotherDis,
        });
      }
    });
    console.log('datStore===>', datStore);

    // var finalStoreData;
    // // if (storeCheckbox) {
    // console.log('ifcheckbox===>')
    // finalStoreData = datStore
    // } else {
    //   console.log('elsecheckbox===>', selectedStore)
    //   finalStoreData = selectedStore
    //   console.log(selectedStore)
    // }

    const data = {
      ...callCycleInput,
      stores: datStore,
      // supplier_username: suppUsername,
      state: selectedAusState,
      country: selectedCountry,
      call_id: callCycleInput.call_id.toUpperCase(),
      assignedStore: datLeft,
      call_status: props.statusValue ? 'active' : 'inactive',
      unassignedStore: datRight,
    };
    console.log('update call cycle payload', data);

    // check daa.assignedStore list should not be empty
    if (data.assignedStore.length == 0) {
      message.warning('Please import stores');
      return;
    }
    // check in the data.assignedStore list, atleast one of checked should be true, otherwise show error
    let assignedChecked = false;
    data.assignedStore.forEach(store => {
      if (store.checked) {
        assignedChecked = true;
      }
    });
    if (!assignedChecked) {
      message.warning('Please select atleast one store');
      return;
    }
    // return;
    axios({
      method: 'PUT',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'call-cycle',
      data: [data],
    })
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          console.log('call cycle updated');
          setOpenSuppUpd(true);
          setTimeout(() => {
            setOpenSuppUpd(false);
          }, 3000);
        }
        setIsViewModes({
          ...isViewModes,
          isViewMode_call: false,
        });
        props.listStoreCall();
        setSelectedAusState([]);
        setFinalLeftData([]);
        setFinalRightData([]);
        setStorelist([]);
        setSelectedStore([]);
        setSelectedCountry([]);
        setStoreCheckbox(false);
        console.log(storeCheckbox);
        setFinalData([]);
        setFinalLeftData([]);
        // props.setStatusValue(false);
        setFinalRightData([]);
        setIsViewModes({
          ...isViewModes,
          isViewMode_call: false,
        });
        props.setStatusValue(true);
        props.setToggleText('Active');
        setCallCycleInput({
          call_status: 'inactive',
          call_id: '',
          retailer: '',
          supplier_name: '',
          state: '',
          country: '',
          stores: [
            {
              store_name: '',
            },
          ],
          start_date: '',
          end_date: '',
          recurring_task_type: '',
          call_cycle_name: '',
          call_cycle_freq: '',
          call_cycle_type: '',
          call_time: '',
          total_visits: '',
          unassigned_status: 'inactive',
          assignedStore: [
            {
              store_name: '',
              store_code: '',
              state: '',
              supplier: '',
              frequency: '',
              task_type: '',
            },
          ],
          unassignedStore: [
            {
              store_name: '',
              store_code: '',
              state: '',
              supplier: '',
              frequency: '',
              task_type: '',
            },
          ],
        });
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };

  const handleClose = () => {
    props.setStatusValue(true);
    props.setToggleText('Active');
    setFinalLeftData([]);
    setFinalRightData([]);
    setRequiredError('');
    setOpenSuppUpd(false);
    setOpenSupp(false);
    setStoreCheckbox(false);
    setFinalData([]);
    setFinalLeftData([]);
    setFinalRightData([]);
    setSelectedAusState([]);
    setStorelist([]);
    setSelectedStore([]);
    setSelectedCountry([]);
    setStoreCheckbox(false);
    console.log(storeCheckbox);
    setFinalData([]);
    setFinalLeftData([]);
    setFinalRightData([]);
    setIsViewModes({
      ...isViewModes,
      isViewMode_call: false,
    });
    setCallCycleInput({
      call_status: 'inactive',
      call_id: '',
      retailer: '',
      supplier_name: '',
      state: '',
      country: '',
      stores: [
        {
          store_name: '',
        },
      ],
      start_date: '',
      end_date: '',
      recurring_task_type: '',
      call_cycle_name: '',
      call_cycle_freq: '',
      // call_cycle_type: '',
      call_time: '',
      total_visits: '',
      unassigned_status: 'inactive',
      assignedStore: [
        {
          store_name: '',
          store_code: '',
          state: '',
          supplier: '',
          frequency: '',
          task_type: '',
        },
      ],
      unassignedStore: [
        {
          store_name: '',
          store_code: '',
          state: '',
          supplier: '',
          frequency: '',
          task_type: '',
        },
      ],
    });
  };
  const [suppUsername, setSuppUsername] = useState([]);
  const renderSupplierUsername = () => {
    console.log(supplierList);
    // return supplierList.map(item => {
    //   if(item.username){
    //     setSuppUsername(item.username)
    //   }
    // })
  };
  console.log(suppUsername);
  console.log(selectedCountry);
  const renderSupplierOptions = () => {
    console.log(selectedAusState);
    var aa = [];

    selectedAusState.map(rec => {
      supplierList.map((item, i) => {
        if (rec.value == item.state) {
          aa.push(item);
        }
      });
    });

    return aa
      .sort((a, b) => a.full_company_name.localeCompare(b.full_company_name))
      .map((item, i) => (
        <option key={i} value={`${item.full_company_name}`}>
          {item.full_company_name}
        </option>
      ));
  };
  const renderRetailerOptions = () => {
    var aa = [];

    selectedAusState.map(rec => {
      retailerList.map((item, i) => {
        if (rec.value == item.state) {
          aa.push(item);
        }
      });
    });
    return aa
      .sort((a, b) => a.full_company_name.localeCompare(b.full_company_name))
      .map(item => (
        <option value={item.full_company_name}>{item.full_company_name}</option>
      ));
  };

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const exportToCSV = (csvData, fileName) => {
    console.log('data');
    console.log(csvData, fileName);
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const showUnassigenedData = () => {
    setCheckedUnassigned(!checkedUnassigned);
    console.log(!checkedUnassigned);
    if (!checkedUnassigned) {
      setFinalRightData(unassigenedData);
    } else {
      setFinalRightData(movedData);
      console.log(movedData);
    }
  };

  // }
  return (
    <>
      <Dialog open={openSupp}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>{errorMsg}</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openSuppUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Call cycle details successfully updated
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Page>
        <Row>
          <Col style={{ maxWidth: '60rem' }}>
            <div className="rep-container-1" style={{ width: '100%' }}>
              <Col>
                <Row style={{ marginLeft: '1%' }}>
                  {/* <Col style={{ paddingLeft: '15px', paddingright: '20px' }}>
                    <p style={{ fontSize: '14px' }}>
                      {callCycleInput &&
                        callCycleInput.call_status == 'inactive'
                        ? 'Inactive'
                        : 'Active'}
                    </p>
                    <label className="switch">
                      <Input
                        type="checkbox"
                        name="call_status"
                        id="myCheck"
                        onChange={callCycleHandler}
                        checked={
                          callCycleInput.call_status == 'inactive' ? false : true
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </Col> */}
                  {/* <Col>
                    <label>
                      <Input
                        type="checkbox"
                        checked={storeCheckbox}
                        onChange={() => setStoreCheckbox(!storeCheckbox)}
                      />Add Stores by Bulk Import
                    </label>
                  </Col> */}
                </Row>
                <Row>
                  <Col md="6">
                    <label>
                      Call Cycle ID<span style={{ color: 'red' }}>*</span>{' '}
                    </label>
                    <Input
                      type="text"
                      name="call_id"
                      onChange={callCycleHandler}
                      value={callCycleInput.call_id}
                      disabled={isViewModes.isViewMode_call}
                      className="admin-input"
                      placeholder="Call Cycle ID"
                      style={{ textTransform: 'uppercase' }}
                      onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                    />
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {requiredError.callId}

                      {props.idError}
                    </p>
                  </Col>
                  <Col md="6">
                    <label>
                      Call Cycle Name<span style={{ color: 'red' }}> *</span>{' '}
                    </label>
                    <Input
                      type="text"
                      name="call_cycle_name"
                      onChange={callCycleHandler}
                      value={callCycleInput.call_cycle_name}
                      className="admin-input"
                      placeholder="Call Cycle Name"
                    />
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {requiredError.callName}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <label>
                      Country <span style={{ color: 'red' }}> *</span>{' '}
                    </label>
                    <MultiSelect
                      options={countryOptions}
                      value={selectedCountry}
                      onChange={e => handleChange(e, selectedCountry)}
                      labelledBy="Select"
                      style={{
                        borderRadius: '32px',
                      }}
                    />
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {requiredError.counrtyCall}
                    </p>
                  </Col>
                  <Col md="6">
                    <label>
                      State<span style={{ color: 'red' }}> *</span>{' '}
                    </label>
                    <MultiSelect
                      options={stateList}
                      value={selectedAusState}
                      onChange={e => handleChangeState(e, selectedAusState)}
                      labelledBy="Select"
                    />
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {requiredError.stateCall}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <label>
                      Supplier<span style={{ color: 'red' }}> *</span>{' '}
                    </label>
                    <Input
                      type="select"
                      name="supplier_name"
                      onChange={callCycleHandler}
                      value={callCycleInput.supplier_name}
                      className="admin-input"
                      required
                      // placeholder='Supplier'
                    >
                      <option selected value="">
                        Select Supplier
                      </option>
                      {renderSupplierOptions()}
                    </Input>
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {requiredError.callSupplier}
                    </p>
                  </Col>
                  <Col md="6">
                    <label>
                      Retailer<span style={{ color: 'red' }}> *</span>{' '}
                    </label>
                    <Input
                      type="select"
                      name="retailer"
                      onChange={callCycleHandler}
                      value={callCycleInput.retailer}
                      className="admin-input"
                      placeholder="Retailer"
                      required
                    >
                      <option selected value="">
                        Select Retailer
                      </option>
                      {renderRetailerOptions()}
                    </Input>
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {requiredError.callRetailer}
                    </p>
                  </Col>
                </Row>

                <Row>
                  {/* <Col md="6">
                    <label>Store</label>
                    <MultiSelect
                      options={storeList}
                      value={selectedStore}
                      onChange={setSelectedStore}
                      labelledBy="Select"
                      disabled={storeCheckbox}
                    />
                  </Col> */}
                  <Col md="6">
                    <label>
                      Call Cycle Frequency
                      <span style={{ color: 'red' }}> *</span>{' '}
                    </label>
                    <Input
                      type="select"
                      name="call_cycle_freq"
                      onChange={callCycleHandler}
                      value={callCycleInput.call_cycle_freq}
                      className="admin-input"
                      required
                    >
                      <option selected value="">
                        Select call frequency
                      </option>
                      <option value="7">Days 7</option>
                      <option value="14odd">Days 14 Odd</option>
                      <option value="14even">Days 14 Even</option>
                      <option value="28">Days 28</option>
                      {/* <option >Fortnightly Even</option> */}
                    </Input>
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {requiredError.callFrequency}
                    </p>
                  </Col>
                  <Col md="6">
                    <label>Call Time</label>
                    <Input
                      type="select"
                      name="call_time"
                      onChange={callCycleHandler}
                      value={callCycleInput.call_time}
                      className="admin-input"
                      required
                    >
                      <option selected value="">
                        Select Call Time
                      </option>
                      <option>Must Visit</option>
                      <option>AM</option>
                      <option>PM</option>
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <label>
                      Start Date<span style={{ color: 'red' }}> *</span>{' '}
                    </label>
                    <DatePicker
                      className="textBox"
                      name="start_date"
                      dateFormat={'dd/MM/yyyy'}
                      // formatWeekDay={nameOfDay => nameOfDay.substr(0,3)}
                      calendarStartDay={1}
                      locale="en-gb"
                      style={{ border: '1px solid #ced4da' }}
                      selected={callCycleInput.start_date}
                      onChange={(date, e) => {
                        callCycleInput.start_date = date;
                        setCallCycleInput({ ...callCycleInput });
                        if (e.target.name == 'start_date') {
                          setRequiredError({ start_date: '' });
                        }
                      }}
                      placeholderText="Start Date"
                    />
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {requiredError.strtDate}
                    </p>
                  </Col>
                  <Col md="6">
                    <label>
                      End Date<span style={{ color: 'red' }}> *</span>{' '}
                    </label>
                    <DatePicker
                      calendarStartDay={1}
                      locale="en-gb"
                      className="textBox"
                      name="end_date"
                      dateFormat={'dd/MM/yyyy'}
                      minDate={callCycleInput.start_date}
                      style={{ border: '1px solid #ced4da' }}
                      selected={callCycleInput.end_date}
                      onChange={(date, e) => {
                        callCycleInput.end_date = date;
                        setCallCycleInput({ ...callCycleInput });

                        if (e.target.name == 'end_date') {
                          setRequiredError({ end_date: '' });
                        }
                      }}
                      placeholderText="End Date"
                    />
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {requiredError.endDate}
                    </p>
                  </Col>
                </Row>
                <Row>
                  {/* <Col md="6">
                    <label>Task Type</label>
                    <Input
                      type="select"
                      name="recurring_task_type"
                      onChange={callCycleHandler}
                      value={callCycleInput.recurring_task_type}
                      className='admin-input'
                    >
                      <option selected>Select Task Type</option>
                      <option>Order</option>
                      <option>Merch</option>
                      <option>Supplier Specific Task</option>
                      <option>None</option>
                    </Input>
                  </Col> */}
                  {/* <Col md="6" >
                    <label>Call Cycle Type </label>
                    <Input
                      type="select"
                      name="call_cycle_type"
                      onChange={callCycleHandler}
                      value={callCycleInput.call_cycle_type}
                      className='admin-input'
                    >
                      <option selected>Select Call Cycle Type</option>
                      <option>Regular Call</option>
                      <option>Seasonal Call</option>
                      <option>Free Call</option>
                    </Input>
                  </Col> */}
                </Row>
                {/* <Row>
                  <Col md="6">
                    <label>Call Time</label>
                    <Input
                      type="select"
                      name="call_time"
                      onChange={callCycleHandler}
                      value={callCycleInput.call_time}
                      className='admin-input'
                    >
                      <option selected>Select Call Time</option>
                      <option>Must Visit</option>
                      <option>AM</option>
                      <option>PM</option>
                    </Input>
                  </Col> */}
                {/* <Col md="6">
                    <label>Total Visits</label>
                    <Input
                      type="text"
                      name="total_visits"
                      onChange={callCycleHandler}
                      value={callCycleInput.total_visits}
                      className='admin-input'
                      placeholder='Total Visits'
                    />
                  </Col> */}
                {/* </Row> */}
              </Col>
            </div>
          </Col>
          {/* {storeCheckbox ?
            ( */}
          <Col style={{ flex: 'start', maxWidth: '60rem' }}>
            <div className="rep-container-22 hide-scrollbar">
              {/* <Row
                style={{
                  width: '100%',
                  padding: '0px 0px 0px 0px',
                  margin: '0px',
                  justifyContent: 'flex-end'
                }}>

                <label style={{ marginRight: '1rem', paddingTop: '0.5rem' }}> Unassigned Stores </label>
              </Row> */}
              {/*  <label className="switch">
                  <Input
                    type="checkbox"
                    id="myCheck1"
                    checked={checkedUnassigned}
                    onChange={showUnassigenedData}
                  /> */}
              {/* <Input
                          type="checkbox"
                          name="unassigned_status"
                          id="myCheck1"
                          // onClick={showContent}
                          onChange={showUnassigenedData}
                          checked={
                            callCycleInput.unassigned_status ==
                              'inactive'
                              ? false
                              : true
                          }
                        /> */}
              {/* <span className="slider round"></span>
                </label>

              </Row> */}
              <Row>
                <Col
                  style={{
                    margin: '0px',
                  }}
                >
                  <label style={{ fontSize: '1.6rem' }}>Selected Stores</label>
                  {/* <div style={{ float: 'right' }}>
                <Input style={{width: '80%', float: 'left'}} Buckettype="text" placeholder="Search.." name="search">
                </Input>
                <Button type="submit"><i class="fa fa-search"></i></Button>
                </div> */}
                </Col>
                <Col
                  style={{
                    margin: '0px',
                  }}
                >
                  <label style={{ fontSize: '1.6rem' }}>
                    Unassigned Stores
                  </label>
                  {/* <div style={{ float: 'right' }}>
                <Input style={{width: '80%', float: 'left'}} Buckettype="text" placeholder="Search.." name="search">
                </Input>
                <Button type="submit"><i class="fa fa-search"></i></Button>
                </div> */}
                </Col>
              </Row>
              <Row>
                <Col style={{ width: '45%' }}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          width: '100%',
                          border: '2px solid #E6E6E6',
                          borderRadius: '10px',
                          padding: '15px',
                          height: '341px',
                        }}
                        className="boxes"
                      >
                        <Row className="scroll_vertical">
                          <Col
                            id="addScroll"
                            // className='hide-scrollbar'
                          >
                            {finalLeftData.map(item => (
                              <label style={{ width: '100%' }}>
                                <input
                                  style={{ marginRight: '5px' }}
                                  type="checkbox"
                                  name={item['Store ID*']}
                                  value={item['Store Name*']}
                                  checked={item.disabled ? false : item.checked}
                                  // checked={item.checked}
                                  disabled={item.disabled}
                                  onChange={e => storeCheckLeftHandler(e, item)}
                                />
                                {item['Store ID*']}, {item['Store Name*']},
                                (Frequency: {item['Frequency*']})
                                {/* , State:{' '} */}
                                {/* {item['State*']}) */}
                              </label>
                            ))}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {/* <Col style={{ display: 'grid', margin: '0', padding: '0px' }}>
                  <button onClick={rightToLeft} disabled={selectedRight.length == 0 ? true : false}><LeftCircleFilled style={{ color: 'green', fontSize: '200%' }} /></button>
                  <button onClick={leftToRight} disabled={storesValue.length == 0 ? true : false}><RightCircleFilled style={{ color: 'red', fontSize: '200%' }} /></button>
                </Col> */}

                <Col style={{ width: '45%' }}>
                  <div
                    style={{
                      width: '100%',
                      border: '2px solid #E6E6E6',
                      borderRadius: '10px',
                      padding: '15px',
                      height: '341px',
                    }}
                    className="boxes"
                  >
                    <Row className="scroll_vertical">
                      <Col id="addScroll" className="hide-scrollbar">
                        {finalRightData.map(item => (
                          <p>
                            <input
                              style={{ marginRight: '5px' }}
                              type="checkbox"
                              name={item['Store ID*']}
                              value={item['Store Name*']}
                              checked={item.disabled ? false : item.checked}
                              // checked={item.checked}
                              disabled={item.disabled}
                              onChange={e => storeCheckRightHandler(e, item)}
                            />
                            {item['Store ID*']}, {item['Store Name*']},
                            (Frequency: {item['Frequency*']}){/* State:{' '} */}
                            {/* {item['State*']}) */}
                          </p>
                        ))}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: '0px' }}>
                <Col>
                  <input
                    type="file"
                    accept=" .csv, .xlsx"
                    style={{
                      opacity: '0',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                    }}
                    onChange={e => {
                      console.log('e==>', e);
                      handleUpload(e);
                    }}
                  />
                  <button
                    type="button"
                    variant="outlined"
                    // disabled={
                    //   callCycleInput.call_cycle_freq == '' ? true : false
                    // }
                    class="btn btn-primary"
                    title="frequency must be selected"
                  >
                    Import <BiUpload />
                  </button>
                </Col>
                <Col
                  style={{
                    padding: '0% 0% 0% 10%',
                  }}
                >
                  {/* <button type="button" variant="outlined" class="btn btn-primary" onClick={(e) => exportToCSV(storesValue, 'selectedStoreList')} >Export</button> */}
                </Col>
              </Row>
              {requiredError.callId ||
              requiredError.callName ||
              requiredError.counrtyCall ||
              requiredError.stateCall ||
              requiredError.callSupplier ||
              requiredError.callRetailer ||
              requiredError.strtDate ||
              requiredError.endDate ||
              requiredError.callFrequency ? (
                <p
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    marginBottom: 0,
                    visibility: 'hidden',
                  }}
                >
                  Error
                </p>
              ) : null}
            </div>
          </Col>
          {/* ) : null} */}
        </Row>
        <Row
          style={{
            padding: '20px 0px 0px 40px',
          }}
        >
          {isViewModes.isViewMode_call == false ? (
            <Col md={12} className="martop">
              <Button onClick={addCallCycle} variant="outlined" color="primary">
                <span>
                  {' '}
                  <b> Add</b>{' '}
                </span>
              </Button>
              <Button onClick={handleClose} variant="outlined" color="primary">
                <span>
                  {' '}
                  <b>Cancel</b>{' '}
                </span>
              </Button>
            </Col>
          ) : (
            <Col md={12} className="martop">
              <Button
                variant="outlined"
                color="primary"
                onClick={updateCallCycle}
              >
                <span>
                  {' '}
                  <b> Update</b>{' '}
                </span>
              </Button>
              <Button onClick={handleClose} variant="outlined" color="primary">
                <span>
                  {' '}
                  <b>Cancel</b>{' '}
                </span>
              </Button>
            </Col>
          )}
        </Row>
      </Page>
    </>
  );
};
export default Supplier;
