import {
  Row,
  Col,
  Card,
  CardHeader,
  Input,
  Button,
  Table,
  FormGroup,
  Form,
} from 'reactstrap';
import moment from 'moment';

import logo200Image from 'assets/img/logo/Banner Grey.png';
import React, { useEffect, useState, useRef } from 'react';
// import ReactToPrint from 'react-to-print';
// import { FcPrint } from 'react-icons/fc'

const PurchaseOrder = props => {
  const { data } = props;
  //   console.log(data.ShipToAddress && data.ShipToAddress.split('\r\n'));
  //   const businesNo = data.ShipToAddress && data.ShipToAddress.split('\r\n');
  const componentRef = useRef();
  console.log(data);
  return (
    <>
      <div ref={componentRef}>
        <Form className="print-screen">
          <FormGroup>
            <Row className="table-size">
              <img
                style={{
                  width: '60%',
                  textAlign: 'center',
                  margin: 'auto',
                  marginTop: '10px',
                }}
                src={logo200Image}
              />
              <Col md="12" style={{ color: '#000', paddingLeft: '20px' }}>
                <img
                  src=""
                  width="30%"
                  alt=""
                  style={{
                    marginRight: 'auto',
                    width: '65%',
                    color: '#000',
                  }}
                />
                <br />
                <br />
                <span style={{ color: '#000' }}> ABN: 19 128 604 302</span>
                <br />
                <span style={{ color: '#000' }}>16 Howleys Road,</span>
                <br />
                <span style={{ color: '#000', textTransform: 'none' }}>
                  Notting Hill VIC 3168
                </span>{' '}
                <br />
                <span style={{ color: '#000' }}> Phone: +61 395 400 770 </span>
                <span style={{ color: '#000' }}> Fax: +61 395 400 780 </span>
                <br />
                <span style={{ color: '#000' }}>
                  {' '}
                  Email: accounts@gforceaus.com{' '}
                </span>
              </Col>
              <Col md="12" style={{ paddingLeft: '20px', paddingTop: '20px' }}>
                <table>
                  <tr>
                    <td colSpan={3} style={{ width: '80%' }}>
                      <b style={{ color: '#000', fontSize: '18px' }}>
                        Bill to:
                      </b>
                      <br />
                      <b style={{ color: '#000' }}>{data.ShipToAddress}</b>
                      <br />
                      {/* <b style={{ color: '#000' }}>vic 3020</b> */}
                      <br />
                    </td>
                    {/* <td colSpan={3}>
                                            <b style={{ color: 'transparent', fontSize: '18px' }}>Bill</b><br />
                                            <b style={{ color: 'transparent' }}>dataa</b><br />
                                        </td>
                                        <td colSpan={3}>
                                            <b style={{ color: 'transparent', fontSize: '18px' }}>Bill</b><br />
                                            <b style={{ color: 'transparent' }}>dataa</b><br />
                                        </td> */}
                    <td colSpan={3}>
                      <p style={{ color: '#000' }}>
                        {' '}
                        <b>Invoive No.: </b>
                        {data.Number}
                      </p>
                      <p style={{ color: '#000' }}>
                        {' '}
                        <b>Date: </b>
                        {moment(data.Date).format('DD/MM/YYYY')}
                      </p>
                      <p style={{ color: '#000' }}>
                        {' '}
                        <b>Customer ID: </b>
                        {data.DisplayID}
                      </p>
                      <p style={{ color: '#000' }}>
                        {' '}
                        <b>Due Date:</b>{' '}
                        {moment(data.due_date).format('DD/MM/YYYY')}{' '}
                      </p>
                    </td>
                  </tr>
                </table>
              </Col>
              <Col md="12" style={{ paddingLeft: '20px', paddingTop: '20px' }}>
                <Table>
                  <tr>
                    <th
                      style={{
                        width: '20%',
                        color: '#000',
                        borderRight: '1px solid #000',
                        textAlign: 'center',
                      }}
                    >
                      {' '}
                      QTY{' '}
                    </th>
                    <th
                      style={{
                        width: '35%',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '0px',
                      }}
                    >
                      {' '}
                      DESCRIPTION{' '}
                    </th>
                    <th
                      style={{
                        width: '25%',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '0px',
                      }}
                    >
                      {' '}
                      UNIT PRICE{' '}
                    </th>
                    <th
                      style={{
                        width: '20%',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '0px',
                      }}
                    >
                      {' '}
                      LINE TOTAL{' '}
                    </th>
                  </tr>
                  {data.Lines &&
                    data.Lines.map(rec => (
                      <tr style={{ borderBottom: '1px solid #f0f0f0' }}>
                        <th
                          style={{
                            width: '20%',
                            color: '#000',
                            borderRight: '1px solid #000',
                            textAlign: 'center',
                          }}
                        >
                          {' '}
                          {rec.ShipQuantity}{' '}
                        </th>
                        <th
                          style={{
                            width: '35%',
                            color: '#000',
                            textAlign: 'center',
                            borderRadius: '0px',
                          }}
                        >
                          {' '}
                          {rec.Description}{' '}
                        </th>
                        <th
                          style={{
                            width: '25%',
                            color: '#000',
                            textAlign: 'center',
                            borderRadius: '0px',
                          }}
                        >
                          {' '}
                          ${rec.UnitPrice}{' '}
                        </th>
                        <th
                          style={{
                            width: '20%',
                            color: '#000',
                            textAlign: 'center',
                            borderRadius: '0px',
                          }}
                        >
                          $ {rec.Total}{' '}
                        </th>
                      </tr>
                    ))}
                </Table>
              </Col>
              {/* <Col md="12" style={{ paddingLeft: '20px', paddingTop: '20px' }}> */}

              <Table border="0" borderless style={{ paddingLeft: '20px' }}>
                <tbody style={{ border: '0px solid red' }}>
                  <tr style={{ border: '0px solid red' }}>
                    <td
                      style={{
                        width: '45%',
                        color: '#000',
                        paddingLeft: '20px',
                        borderRadius: '0px',
                        border: '0px solid red',
                        borderRight: '0px solid red',
                      }}
                    >
                      {' '}
                      <b>EFT Payment:</b>{' '}
                    </td>
                    <td
                      style={{
                        width: '25%',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '0px',
                        border: '0px solid red',
                        borderLeft: '0px solid red',
                      }}
                    >
                      {' '}
                      Sub Total:{' '}
                    </td>
                    <td
                      style={{
                        width: '25%',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '0px',
                        border: '0px solid red',
                      }}
                    >
                      {' '}
                      ${data.Subtotal}{' '}
                    </td>
                  </tr>
                  <tr style={{ border: '0px solid red' }}>
                    <td
                      style={{
                        width: '25%',
                        color: '#000',
                        paddingLeft: '20px',
                        borderRadius: '0px',
                        border: '0px solid red',
                        borderRight: '0px solid red',
                      }}
                    >
                      {' '}
                      <b> Account Name: G-Force Category Solutions</b>{' '}
                    </td>
                    <td
                      style={{
                        width: '35%',
                        color: '#000',
                        paddingLeft: '20px',
                        textAlign: 'center',
                        borderRadius: '0px',
                        border: '0px solid red',
                        borderLeft: '0px solid red',
                      }}
                    >
                      {' '}
                      GST:{' '}
                    </td>
                    <td
                      style={{
                        width: '35%',
                        color: '#000',
                        paddingLeft: '20px',
                        textAlign: 'center',
                        borderRadius: '0px',
                        border: '0px solid red',
                      }}
                    >
                      {' '}
                      ${data.TotalTax}{' '}
                    </td>
                  </tr>
                  <tr style={{ border: '0px solid red' }}>
                    <td
                      style={{
                        width: '20%',
                        color: '#000',
                        paddingLeft: '20px',
                        borderRadius: '0px',
                        border: '0px solid red',
                        borderRight: '0px solid red',
                      }}
                    >
                      {' '}
                      <b> BSB: 063121 Account No.: 11059358 </b>
                    </td>
                    <td
                      style={{
                        width: '20%',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '0px',
                        border: '0px solid red',
                        borderLeft: '0px solid red',
                      }}
                    >
                      {' '}
                      Total:{' '}
                    </td>
                    <td
                      style={{
                        width: '20%',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '0px',
                        border: '0px solid red',
                      }}
                    >
                      {' '}
                      ${data.TotalAmount}{' '}
                    </td>
                  </tr>
                  <tr style={{ border: '0px solid red' }}>
                    <td
                      style={{
                        width: '20%',
                        color: '#000',
                        paddingLeft: '20px',
                        borderRadius: '0px',
                        border: '0px solid red',
                        borderRight: '0px solid red',
                      }}
                    >
                      {' '}
                      <b>
                        {' '}
                        Commonwealth Bank, 324 Clayton Road, Clayton VIC 3168{' '}
                      </b>
                    </td>
                    <td
                      style={{
                        width: '20%',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '0px',
                        border: '0px solid red',
                        borderLeft: '0px solid red',
                      }}
                    >
                      {' '}
                      <b> Paid </b>
                    </td>
                    <td
                      style={{
                        width: '20%',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '0px',
                        border: '0px solid red',
                      }}
                    >
                      {' '}
                      <b>${data.TotalAmount - data.BalanceDueAmount}</b>
                    </td>
                  </tr>
                  <tr style={{ border: '0px solid red' }}>
                    <td
                      style={{
                        width: '20%',
                        color: '#000',
                        paddingLeft: '20px',
                        borderRadius: '0px',
                        border: '0px solid red',
                      }}
                    >
                      {' '}
                      <b>
                        {' '}
                        Please make cheque payable to G-Force Category Solutions{' '}
                      </b>
                    </td>
                    <td
                      style={{
                        width: '20%',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '0px',
                        border: '0px solid red',
                        borderLeft: '0px solid red',
                      }}
                    >
                      {' '}
                      <b> Balance Due: </b>
                    </td>
                    <td
                      style={{
                        width: '20%',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '0px',
                        border: '0px solid red',
                      }}
                    >
                      {' '}
                      <b> ${data.BalanceDueAmount} </b>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Col md="12">
                <p style={{ textAlign: 'center', fontSize: '18px' }}>
                  <b>Thank you for using our services.</b>
                </p>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    </>
  );
};
export default PurchaseOrder;
